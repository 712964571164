import React from 'react'

const Footer = () => {
  return (
    <footer className="fixed inset-x-0 bottom-0 w-full flex justify-center items-center p-1 bg-gray-200">
      <p className="">© 2024 Gelistrio Tüm hakkı saklıdır.</p>
    </footer>
  )
}

export default Footer

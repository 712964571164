import React, { useState, useEffect, useRef } from 'react'
import { playCorrectSound } from '../../effect/Correct'
import { playCongrulationSound } from '../../effect/Congrulation'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import Header from '../Header'
import Footer from '../Footer'
import VocabolaryServices from "../../Services/VocabolaryServices";


const SeeSort = () => {
  const [gameState, setGameState] = useState({
    isStart: false,
    isFinish: false,
    isChecking: false,
    currentWordIndex: 0,
    currentAttempt: 1,
    totalAttempts: 4,
    showRedBorder: false,
    helpCount: 0,
    currentStep: 1,
    timerPaused: false
  })

  const [userInput, setUserInput] = useState([])
  const [shuffledLetters, setShuffledLetters] = useState([])
  const [timeLeft, setTimeLeft] = useState(10)
  const [words, setWords] = useState([])
  const [exerciseTime, setExerciseTime] = useState({ minutes: 0, seconds: 0 })
  const [isConfirmed, setIsConfirmed] = useState(false)
  const token = window.localStorage.getItem('token')
  const timerId = useRef(null)
  const exerciseTimerId = useRef(null)
  const [originalWords, setOriginalWords] = useState([]); // Orijinal kelime listesi
  const [selectedLevel, setSelectedLevel] = useState('')
  const [vocabolery, setVocabolery] = useState([])
  const vocabolaryservices = new VocabolaryServices()

  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
      return
    }
    }, [])

  useEffect(() => {
    vocabolaryservices
        .getVocabolary('seesort')
        .then((response) => {
          setVocabolery(response.data)
        })
        .catch((error) => {
          console.error('Kategorileri alırken hata oluştu:', error)
        })
  }, [])


  // Timer logic for each attempt
  useEffect(() => {
    if (gameState.isStart && timeLeft > 0 && !gameState.timerPaused) {
      timerId.current = setTimeout(() => {
        setTimeLeft(prev => prev - 1)
      }, 1000)
    } else if (timeLeft === 0 && !gameState.timerPaused) {
      handleTimeUp()
    }

    return () => clearTimeout(timerId.current)
  }, [gameState.isStart, timeLeft, gameState.timerPaused])

  // Exercise timer
  useEffect(() => {
    if (gameState.isStart && !gameState.isFinish) {
      exerciseTimerId.current = setInterval(() => {
        setExerciseTime(prevTime => {
          const newSeconds = (prevTime.seconds + 1) % 60
          const newMinutes = prevTime.seconds === 59 ? prevTime.minutes + 1 : prevTime.minutes
          return { minutes: newMinutes, seconds: newSeconds }
        })
      }, 1000)
    }

    return () => clearInterval(exerciseTimerId.current)
  }, [gameState.isStart, gameState.isFinish])

  const handleLevelChange = (event) => {
    const selected = event.target.value;
    setSelectedLevel(selected);

    const selectedData = vocabolery.find((levelData) => levelData.level.toString() === selected);

    if (selectedData && selectedData.data?.[0]?.words) {
      let wordsArray = selectedData.data[0].words;

      if (Array.isArray(wordsArray) && wordsArray.length > 0) {
        // Tüm kelimeleri büyük harfe çevirirken Türkçe karakterlere dikkat et
        wordsArray = wordsArray.map(word => word.toLocaleUpperCase('tr'));

        setOriginalWords([...wordsArray]); // Orijinal listeyi sakla

        // Fisher-Yates algoritması ile kelimeleri karıştır
        for (let i = wordsArray.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [wordsArray[i], wordsArray[j]] = [wordsArray[j], wordsArray[i]];
        }

        setWords(wordsArray); // Karıştırılmış kelimeleri set et
        setGameState((prev) => ({
          ...prev,
          currentWordIndex: 0,
          currentAttempt: 1,
          currentStep: 1,
          isStart: false,
          isFinish: false,
          timerPaused: false,
        }));

        const firstWord = wordsArray[0];

        if (firstWord) {
          setShuffledLetters(shuffleNewWord(firstWord));
          setUserInput(Array(firstWord.length).fill(''));
          setTimeLeft(10);
          setExerciseTime({ minutes: 0, seconds: 0 });
        } else {
          setUserInput([]);
          setShuffledLetters([]);
        }
      } else {
        setWords([]);
        setUserInput([]);
        setShuffledLetters([]);
      }
    } else {
      setWords([]);
      setUserInput([]);
      setShuffledLetters([]);
    }
  };

  const findFirstIncorrectLetterIndex = () => {
    const currentWord = words[gameState.currentWordIndex]
    for (let i = 0; i < userInput.length; i++) {
      if (userInput[i] !== currentWord[i]) {
        return i
      }
    }
    return -1
  }

  const findFirstEmptyIndex = () => {
    return userInput.findIndex(letter => letter === '')
  }

  const handleTimeUp = () => {
    const currentWord = words[gameState.currentWordIndex]

    // Pause timer during correction
    setGameState(prev => ({ ...prev, timerPaused: true }))

    // Find first incorrect letter or first empty space
    const incorrectIndex = findFirstIncorrectLetterIndex()
    const emptyIndex = findFirstEmptyIndex()

    const newUserInput = [...userInput]

    if (incorrectIndex !== -1) {
      // Fix first incorrect letter
      newUserInput[incorrectIndex] = currentWord[incorrectIndex]
    } else if (emptyIndex !== -1) {
      // Fill first empty space
      newUserInput[emptyIndex] = currentWord[emptyIndex]
    }

    setUserInput(newUserInput)

    // Set up next attempt
    if (gameState.currentAttempt < gameState.totalAttempts) {
      const nextAttemptTime = gameState.currentAttempt === 3 ? 15 : 10

      setTimeout(() => {
        setGameState(prev => ({
          ...prev,
          currentAttempt: prev.currentAttempt + 1,
          helpCount: prev.helpCount + 1,
          timerPaused: false
        }))
        setTimeLeft(nextAttemptTime)
      }, 1000)
    } else {
      // Last attempt - show correct answer and proceed
      setUserInput([...currentWord])
      setTimeout(() => {
        handleCheck()
      }, 1000)
    }
  }

  const handleLetterClick = (letter) => {
    if (gameState.timerPaused) return // Prevent letter clicks during pause

    const emptyIndex = userInput.findIndex(l => l === '')
    if (emptyIndex !== -1) {
      const updatedUserInput = [...userInput]
      updatedUserInput[emptyIndex] = letter
      setUserInput(updatedUserInput)
    }
  }

  const handleLetterRemove = (index) => {
    if (gameState.timerPaused) return // Prevent letter removal during pause

    const updatedUserInput = [...userInput]
    updatedUserInput[index] = ''
    setUserInput(updatedUserInput)
  }

  const handleCheck = () => {
    const currentWord = words[gameState.currentWordIndex]
    const isCorrect = userInput.join('') === currentWord

    setGameState(prev => ({
      ...prev,
      isChecking: true,
      showRedBorder: !isCorrect,
      timerPaused: true
    }))

    if (isCorrect) {
      playCorrectSound()
      clearTimeout(timerId.current)

      setTimeout(() => {
        if (gameState.currentWordIndex < words.length - 1) {
          // Move to next word
          const nextWord = words[gameState.currentWordIndex + 1]
          setGameState(prev => ({
            ...prev,
            currentWordIndex: prev.currentWordIndex + 1,
            currentStep: prev.currentStep + 1,
            currentAttempt: 1,
            isChecking: false,
            showRedBorder: false,
            helpCount: 0,
            timerPaused: false
          }))
          setUserInput(Array(nextWord.length).fill(''))
          setTimeLeft(10)
          shuffleNewWord(nextWord)
        } else {
          // Exercise complete
          handleExerciseComplete()
        }
      }, 1000)
    } else {
      setTimeout(() => {
        setGameState(prev => ({
          ...prev,
          isChecking: false,
          timerPaused: false
        }))
      }, 1000)
    }
  }

  const shuffleNewWord = (word) => {
    if (!word || typeof word !== 'string') {
      return [];
    }

    const letters = word.toLocaleUpperCase('tr').split(''); // Harfleri büyük harf olarak böl
    for (let i = letters.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [letters[i], letters[j]] = [letters[j], letters[i]];
    }
    setShuffledLetters(letters);
    return letters;
  };

  const handleExerciseComplete = async () => {
    playCongrulationSound()
    setGameState(prev => ({ ...prev, isFinish: true }))
    clearInterval(exerciseTimerId.current)
  }

  const handleStart = () => {
    if (!words || words.length === 0) {
      return;
    }

    const firstWord = words[gameState.currentWordIndex]?.toLocaleUpperCase('tr');

    if (!firstWord) {
      return;
    }

    setGameState(prev => ({
      ...prev,
      isStart: true,
      timerPaused: false,
      currentWordIndex: 0,
      currentAttempt: 1,
      currentStep: 1,
      isFinish: false
    }));

    setUserInput(Array(firstWord.length).fill(''));
    shuffleNewWord(firstWord);
    setTimeLeft(10);
    setExerciseTime({ minutes: 0, seconds: 0 });

  };

  const restartExercise = () => {
    if (!originalWords || originalWords.length === 0) {
      return;
    }

    // Orijinal kelime listesini yeniden karıştır
    const shuffledWords = [...originalWords];
    for (let i = shuffledWords.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledWords[i], shuffledWords[j]] = [shuffledWords[j], shuffledWords[i]];
    }

    setWords(shuffledWords); // Karıştırılmış kelimeleri set et

    const firstWord = shuffledWords[0]?.toLocaleUpperCase('tr');

    if (!firstWord) {
      return;
    }

    setGameState({
      isStart: false,
      isFinish: false,
      isChecking: false,
      currentWordIndex: 0,
      currentAttempt: 1,
      totalAttempts: 3,
      showRedBorder: false,
      helpCount: 0,
      currentStep: 1,
      timerPaused: false
    });

    setUserInput(Array(firstWord.length).fill(''));
    shuffleNewWord(firstWord); // İlk kelimenin harflerini karıştır
    setTimeLeft(10);
    setExerciseTime({ minutes: 0, seconds: 0 });
  };
  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/word-recognition'`
  }
  return (
      <div className="w-full h-screen">
        <Header title={'Karışık Harfler'} returnType="word-recognition">
          <button
              onClick={restartExercise}
              className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
          >
            Sıfırla
          </button>
        </Header>
        <Footer/>

        {/* Timer and Progress Display */}
        <div className="absolute top-0 right-0 font-semibold rounded-md px-4 py-2">
          <div
              className="flex flex-col space-x-8 w-52 mt-24 mr-12 p-2 border-2 border-gray-300 justify-center items-center">
          <span>
            Geçen Süre: {exerciseTime.minutes}:
            {exerciseTime.seconds.toString().padStart(2, '0')}
          </span>
            <span>
            Adım: {gameState.currentStep} / {words.length}
          </span>
            <span>
            Deneme: {gameState.currentAttempt} / {gameState.totalAttempts}
          </span>
          </div>
        </div>

        {/* Game Area */}
        <div className="container mx-auto p-4 mt-60">
          {gameState.isStart && (
              <div className="bg-white p-6 rounded-md shadow-md">
                {/* Word Display Area */}
                <div className="flex gap-2 mb-4">
                  {Array(words[gameState.currentWordIndex].length)
                      .fill('')
                      .map((_, index) => (
                          <div
                              key={index}
                              className={`border ${
                                  userInput[index] === words[gameState.currentWordIndex][index]
                                      ? 'border-green-500'
                                      : gameState.showRedBorder
                                          ? 'border-red-500'
                                          : 'border-gray-400'
                              } w-12 h-12 flex items-center justify-center text-lg font-semibold rounded cursor-pointer ${
                                  userInput[index] !== '' ? 'bg-green-500 text-white' : ''
                              }`}
                              onClick={() => handleLetterRemove(index)}
                          >
                            {userInput[index]}
                          </div>
                      ))}
                </div>

                {/* Letter Selection Area */}
                <div className="flex flex-wrap gap-2">
                  {shuffledLetters.map((letter, index) => (
                      <button
                          key={index}
                          className={`bg-blue-400 text-white py-2 px-4 w-12 h-12 rounded hover:bg-blue-300 ${
                              gameState.timerPaused ? 'opacity-50 cursor-not-allowed' : ''
                          }`}
                          onClick={() => handleLetterClick(letter)}
                          disabled={
                              gameState.timerPaused ||
                              userInput.filter(l => l === letter).length >=
                              words[gameState.currentWordIndex].split('').filter(l => l === letter).length
                          }
                      >
                        {letter}
                      </button>
                  ))}
                </div>

                {/* Control Buttons */}
                {gameState.isStart && !gameState.isChecking && (
                    <div className="mt-4">
                      <button
                          className={`bg-green-500 text-white py-2 px-4 rounded hover:bg-green-400 ${
                              gameState.timerPaused ? 'opacity-50 cursor-not-allowed' : ''
                          }`}
                          onClick={handleCheck}
                          disabled={gameState.timerPaused}
                      >
                        Kontrol Et
                      </button>
                    </div>
                )}

                {/* Timer Display */}
                <div className="mt-4 flex justify-between">
                  <p className="text-gray-600">Kalan Süre: {timeLeft}</p>
                </div>
              </div>
          )}
        </div>

        {/* Start Screen */}
        {!gameState.isStart && !gameState.isFinish && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
              <div className="bg-gray-300 p-8 rounded-md text-center w-1/3">
                <h1 className="font-semibold text-2xl p-1">
                  Karışık Harfler Egzersizi
                </h1>
                {/* 📚 Seviye Seçimi */}
                <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
                  <label
                      htmlFor="levelSelect"
                      className="block text-gray-700 font-semibold mb-2 text-left"
                  >
                    📚 Seviye Seçimi:
                  </label>
                  <select
                      id="levelSelect"
                      className="w-full p-2 border border-gray-300 rounded-md"
                      value={selectedLevel}
                      onChange={handleLevelChange}
                  >
                    <option value="" disabled>Seviye Seç</option>
                    {vocabolery.map((levelData) => (
                        <option key={levelData.level} value={levelData.level}>
                          Seviye {levelData.level}
                        </option>
                    ))}
                  </select>
                </div>
                <button
                    className="bg-blue-400 text-xl text-white py-2 px-4 mt-4 rounded hover:bg-blue-300"
                    onClick={handleStart}
                >
                  Başlat 🚀

                </button>
              </div>
            </div>
        )}

        {/* Finish Screen */}
        {gameState.isFinish && (
            <div className="bg-gray-500 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
              <div className="bg-white p-8 rounded-md text-center w-300">
                <p className="text-xl">
                  Tebrikler! Egzersizini tamamladınız.
                </p>
                <p>
                  Toplam Süre: {exerciseTime.minutes}:
                  {exerciseTime.seconds.toString().padStart(2, '0')}
                </p>
                <button
                    className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
                    onClick={restartExercise}
                >
                  Tekrar
                </button>
                <div>

                  <button
                      className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
                      onClick={handleReturnDashboard}
                  >
                    Egzersiz Sayfasına Dön
                  </button>
                </div>
              </div>
            </div>
        )}

        <FullscreenAlert onConfirm={() => setIsConfirmed(true)}/>
      </div>
  )
}

export default SeeSort
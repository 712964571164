import React from 'react'

const handleReturnDashboard = (type) => {
  switch (type) {
    case 'eye-yoga':
      window.location.href = `/education/exercises/eye-yoga-peripheral-vision`
      break
    case 'speed-reading':
      window.location.href = `/education/exercises/speed-reading-comprehension`
      break
    case 'word-recognition':
      window.location.href = `/education/exercises/word-recognition`
      break
    case 'attention-memory':
      window.location.href = `/education/exercises/attention-memory-logic-math`
      break
    default:
      window.location.href = `/education/exercises`
  }
}

const FinishScreen = ({
                        onRestart,
                        message = 'Tebrikler Egzersizi tamamladınız!',
                        returnType = 'default'
                      }) => {
  return (
      <div className="bg-gray-300 bg-opacity-50 flex flex-col h-screen items-center justify-center fixed inset-0">
        <div className="bg-white p-8 rounded-md text-center">
          <p>{message}</p>
          <div>
            <button
                className="bg-gray-400 py-2 px-4 mt-4 rounded hover:bg-gray-300"
                onClick={onRestart}
            >
              Tekrarla
            </button>
          </div>
          <button
              className="bg-gray-400 py-2 px-4 mt-4 rounded hover:bg-gray-300"
              onClick={() => handleReturnDashboard(returnType)}
          >
            Egzersiz Sayfasına Dön
          </button>
        </div>
      </div>
  )
}

export default FinishScreen
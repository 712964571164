import React, { useState, useEffect } from 'react'
import LoaderSimple from '../LoadPage/LoaderSimple'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import Footer from "../Footer";

import Header from "../Header";
import VocabolaryServices from '../../Services/VocabolaryServices'

const Vocabulary = () => {
  const [syllableCount, setSyllableCount] = useState('')
  const [words, setWords] = useState([])
  const [wordIndex, setWordIndex] = useState(0)
  const [isRunning, setIsRunning] = useState(false)
  const [speed, setSpeed] = useState(200)
  const [fontSize, setFontSize] = useState(14)
  const [vocabolery, setVocabolery] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState('');

  const [text, setText] = useState('')

  const token = window.localStorage.getItem('token')

  const vocabolaryservices = new VocabolaryServices()

  useEffect(() => {
    vocabolaryservices
        .getVocabolary('vocabulary')
        .then((response) => {
          setVocabolery(response.data)
          console.log(response.data)
        })
        .catch((error) => {
          console.error('Kategorileri alırken hata oluştu:', error)
        })
  }, [])

  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  const [isConfirmed, setIsConfirmed] = useState(false)
// Fisher-Yates (Knuth) Karıştırma Algoritması
  const shuffleArray = (array) => {
    const shuffled = [...array]; // Orijinal diziyi kopyala
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // Rastgele bir indeks seç
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Elemanları takas et
    }
    return shuffled;
  };
  const handleConfirm = () => {
    setIsConfirmed(true)
  }

  // Level seçimi ve kelime güncellemesi
  const handleLevelChange = (e) => {
    const level = e.target.value;
    setSelectedLevel(level);

    // Seçilen seviyeye göre kelime listesini güncelle
    const selected = vocabolery.find(item => item.level === level);
    if (selected && selected.data && selected.data[0] && selected.data[0].words) {
      const shuffledWords = shuffleArray(selected.data[0].words); // Fisher-Yates ile karıştır

      setWords(shuffledWords); // Karıştırılmış kelime dizisini set et
      setText(shuffledWords.join(' '));  // Kelimeleri metin haline getiriyoruz (isteğe bağlı)
    } else {
      setWords([]); // Boş liste atıyoruz
      setText('');
    }
  };

  useEffect(() => {
    setWords(text.split(' '))
  }, [text])

  useEffect(() => {
    let interval
    if (isRunning && wordIndex < words.length) {
      interval = setInterval(() => {
        setWordIndex((prevIndex) => prevIndex + 1)
      }, 60000 / speed)
    } else if (wordIndex >= words.length) {
      setIsRunning(false)
    }
    return () => clearInterval(interval)
  }, [isRunning, speed, wordIndex, words.length])

  const handleStart = () => setIsRunning(true)
  const handlePause = () => setIsRunning(false)
  const handleReset = () => {
    setIsRunning(false)
    setWordIndex(0)
  }

  const handleSyllableCountChange = (e) => setSyllableCount(e.target.value)

  return (
    <div className="h-screen overflow-hidden">
      <Header title={"Kelime Hazinesi"} returnType="word-recognition">

      </Header>
      {/* Content */}
      <div className="p-4 mt-40 h-4/5">
        <div className="mb-4 flex items-center justify-center">
          <div className="mb-4 flex items-center justify-center">
            <label className="mr-2">Seviye Seçin:</label>
            <select
                value={selectedLevel}
                onChange={handleLevelChange}
                className="p-2 border"
            >
              <option value="">Seçiniz</option>
              {vocabolery.map((item) => (
                  <option key={item.level} value={item.level}>
                    {item.level}. Seviye
                  </option>
              ))}
            </select>
          </div>
          </div>
          <div className="mb-4 flex items-center justify-center">
            <label className="mr-2">Kelime Gösterim Hızı (kelime/dakika):</label>
            <input
                type="range"
                min="20"
                max="400"
                value={speed}
                onChange={(e) => setSpeed(e.target.value)}
                className="w-2/3"
            />
            <span>{speed}</span>
          </div>
          <div className="mb-4 flex items-center justify-center">
            <label className="mr-2">Kelime Boyutu:</label>
            <input
                type="range"
                min="16"
                max="140"
                value={fontSize}
                onChange={(e) => setFontSize(e.target.value)}
                className="w-1/3"
            />
            <span>{fontSize}px</span>
          </div>
          <div className="mb-4 flex justify-center">
            {!isRunning ? (
                <button
                    onClick={handleStart}
                    className="mr-2 p-2 bg-green-500 text-xl text-white rounded-xl hover:bg-green-300"
                >
                  Başlat
                </button>
            ) : (
                <button
                    onClick={handlePause}
                    className="mr-2 p-2 bg-yellow-500 text-xl rounded-xl text-white hover:bg-yellow-400"
                >
                  Durdur
                </button>
            )}
            <button
                onClick={handleReset}
                className="p-2 bg-red-500 text-white text-xl rounded-xl hover:bg-red-400"
            >
              Sıfırla
            </button>
          </div>
          <div className="text-center h-40 flex items-center justify-center bg-indigo-100">
          <span style={{fontSize: `${fontSize}px`}}>
            {words[wordIndex] || 'Metni başlatın'}
          </span>
          </div>
          <div className="mt-4 flex-col justify-center items-center">
            <div className="h-2 bg-gray-300">
              <div
                  className="h-full bg-blue-500"
                  style={{width: `${(wordIndex / words.length) * 100}%`}}
              ></div>
            </div>
            <span>{Math.round((wordIndex / words.length) * 100)}%</span>
          </div>
        </div>
        <Footer/>
        <FullscreenAlert onConfirm={handleConfirm}/>
      </div>
      )
      }

      export default Vocabulary

import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  BookOpenText,
  MessageCircle,
  Clapperboard,
  UserPen,
  CalendarX,
  LogOut,
  SquareUserRound,
  ChartNoAxesCombined,
  House,
  ChevronDown,
  ContactRound,
  CircleCheckBig,
  GraduationCap,
  ClipboardPlus,
  ChevronLeft,
  ChevronRight
} from "lucide-react";

export default function Navbar() {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isUserOperationsOpen, setIsUserOperationsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleUserOperationsMenu = () => {
    setIsUserOperationsOpen(!isUserOperationsOpen);
  };

  return (
      <nav className={`fixed top-0 left-0 h-screen bg-white shadow-xl transition-all duration-300 ease-in-out ${
          isExpanded ? "w-48" : "w-20"
      }`}>
        <div className="relative flex items-center p-4">
          <Link to="/" className={`transition-opacity duration-300 ${isExpanded ? 'opacity-100' : 'opacity-0 w-0'}`}>
            <h5 className="font-sans text-2xl font-semibold text-blue-gray-900">
              Gelis<span className="text-special_green">trio</span>
            </h5>
          </Link>
          <button
              onClick={toggleNavbar}
              className="absolute right-2 p-2 rounded-lg hover:bg-gray-100 transition-colors"
          >
            {isExpanded ? (
                <ChevronLeft size={20} />
            ) : (
                <ChevronRight size={20} />
            )}
          </button>
        </div>

        <ul className="flex flex-col gap-1 p-2 font-sans text-base font-normal text-blue-gray-700">
          {/* Ana Menü Öğeleri */}
          <li>
            <Link to="/" className="flex items-center hover:bg-gray-200 w-full p-3 rounded-lg">
              <House size={24} strokeWidth={2.5} />
              <span className={`ml-3 transition-opacity duration-300 ${isExpanded ? 'opacity-100' : 'opacity-0 w-0'}`}>
              Anasayfa
            </span>
            </Link>
          </li>

          <li>
            <Link to="/users" className="flex items-center hover:bg-gray-200 w-full p-3 rounded-lg">
              <SquareUserRound size={24} strokeWidth={2.5} />
              <span className={`ml-3 transition-opacity duration-300 ${isExpanded ? 'opacity-100' : 'opacity-0 w-0'}`}>
              Kullanıcı
            </span>
            </Link>
          </li>

          <li>
            <Link to="/analyze" className="flex items-center hover:bg-gray-200 w-full p-3 rounded-lg">
              <ChartNoAxesCombined size={24} strokeWidth={2.5} />
              <span className={`ml-3 transition-opacity duration-300 ${isExpanded ? 'opacity-100' : 'opacity-0 w-0'}`}>
              Analiz
            </span>
            </Link>
          </li>

          <li>
            <Link to="/education" className="flex items-center hover:bg-gray-200 w-full p-3 rounded-lg">
              <BookOpenText size={24} strokeWidth={2.5} />
              <span className={`ml-3 transition-opacity duration-300 ${isExpanded ? 'opacity-100' : 'opacity-0 w-0'}`}>
              Eğitimler
            </span>
            </Link>
          </li>

          {/* İşlemler Dropdown */}
          <li className="relative">
            <button
                onClick={toggleUserOperationsMenu}
                className="flex items-center justify-between w-full p-3 hover:bg-gray-200 rounded-lg"
            >
              <div className="flex items-center">
                <ContactRound size={24} strokeWidth={2.5} />
                <span className={`ml-3 transition-opacity duration-300 ${isExpanded ? 'opacity-100' : 'opacity-0 w-0'}`}>
                İşlemler
              </span>
              </div>
              {isExpanded && (
                  <ChevronDown
                      className={`transition-transform ${isUserOperationsOpen ? "rotate-180" : ""}`}
                      size={16}
                  />
              )}
            </button>
            {/* Alt Menü */}
            <ul className={`${
                isUserOperationsOpen ? "block" : "hidden"
            } ${isExpanded ? "pl-2" : "absolute left-full top-0 ml-2 bg-white shadow-lg rounded-lg w-48"}`}>
              <li>
                <Link to="/activate" className="flex items-center w-full p-2 hover:bg-gray-200 rounded-lg">
                  <CircleCheckBig size={24} strokeWidth={1.75} />
                  <span className={`ml-2 ${!isExpanded && "inline-block"}`}>Onay</span>
                </Link>
              </li>
              <li>
                <Link to="/report" className="flex items-center w-full p-2 hover:bg-gray-200 rounded-lg">
                  <ClipboardPlus size={24} strokeWidth={1.75} />
                  <span className={`ml-2 ${!isExpanded && "inline-block"}`}>Karne</span>
                </Link>
              </li>
              <li>
                <Link to="/certificate" className="flex items-center w-full p-2 hover:bg-gray-200 rounded-lg">
                  <GraduationCap size={24} strokeWidth={1.75} />
                  <span className={`ml-2 ${!isExpanded && "inline-block"}`}>Sertifika</span>
                </Link>
              </li>
              <li>
                <Link to="/communications" className="flex items-center w-full p-2 hover:bg-gray-200 rounded-lg">
                  <MessageCircle size={24} strokeWidth={1.75} />
                  <span className={`ml-2 ${!isExpanded && "inline-block"}`}>Duyuru</span>
                </Link>
              </li>
              <li>
                <Link to="/videos" className="flex items-center w-full p-2 hover:bg-gray-200 rounded-lg">
                  <Clapperboard size={24} strokeWidth={1.75} />
                  <span className={`ml-2 ${!isExpanded && "inline-block"}`}>Videolar</span>
                </Link>
              </li>
            </ul>
          </li>

          <hr className="my-2 border-blue-gray-50" />

          {/* Profil ve Takvim */}
          <li>
            <Link to="/profil" className="flex items-center hover:bg-gray-200 w-full p-3 rounded-lg">
              <UserPen size={24} strokeWidth={1.75} />
              <span className={`ml-2 transition-opacity duration-300 ${isExpanded ? 'opacity-100' : 'opacity-0 w-0'}`}>
              Profil
            </span>
            </Link>
          </li>

          <li>
            <Link to="/calendar" className="flex items-center hover:bg-gray-200 w-full p-3 rounded-lg">
              <CalendarX size={24} strokeWidth={1.75} />
              <span className={`ml-2 transition-opacity duration-300 ${isExpanded ? 'opacity-100' : 'opacity-0 w-0'}`}>
              Takvim
            </span>
            </Link>
          </li>

          {/* Çıkış Butonu */}
          <li>
            <button
                className="flex items-center w-full p-3 hover:bg-gray-200 rounded-lg"
                onClick={() => {
                  localStorage.removeItem("token");
                  window.location.href = "/login";
                }}
            >
              <LogOut size={24} strokeWidth={1.75} />
              <span className={`ml-2 transition-opacity duration-300 ${isExpanded ? 'opacity-100' : 'opacity-0 w-0'}`}>
              Çıkış
            </span>
            </button>
          </li>
        </ul>
      </nav>
  );
}
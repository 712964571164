import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Chart, ArcElement } from "chart.js";
import KnowledgeServices from "../Services/KnowledgeServices";
import TaskListServices from "../Services/TaskList";
import SuccessMessage from "../Components/SuccessMessage";
import ErrorMessage from "../Components/ErrorMessage";

Chart.register(ArcElement);

const data = {
  labels: ["Aktif", "Pasif"],
  datasets: [
    {
      data: [96, 4],
      backgroundColor: ["#4F46E5", "#E5E7EB"],
    },
  ],
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
};

function Dashboard() {
  const [knowledge, setKnowledge] = useState([]);
  const [task, setTask] = useState([]);
  const [newKnowledge, setNewKnowledge] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // SuccessMessage state
  const [failedMessage,setFailedMessage] = useState("")
  const kns = new KnowledgeServices();
  const taskSevices = new TaskListServices();

  const fetchData = async () => {
    const token = localStorage.getItem("token");
    try {
      const knowledgeResponse = await kns.GetKnowledge(token);
      if (Array.isArray(knowledgeResponse.data)) {
        setKnowledge(knowledgeResponse.data);
      } else {
        console.error("Expected an array but got:", knowledgeResponse.data);
      }

      const taskResponse = await taskSevices.getTask("");
      setTask(taskResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePublish = async (id) => {
    try {
      await kns.SetPublishKnowledge({ id: id });
      setSuccessMessage("Bilgi başarıyla yayınlandı!");

      fetchData();
    } catch (error) {
      console.error("Error publishing knowledge:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      console.log("Silinecek ID:", id);
      await kns.Deleteknowledge(id);
      setSuccessMessage("Bilgi başarıyla silindi!");
      fetchData(); // Veriyi tekrar al
      setTimeout(() => setSuccessMessage(""), 3000);
    } catch (error) {
      console.error(
          "Error deleting knowledge:",
          error.response?.data || error.message
      );
      setFailedMessage("Bilgi silinirken bir hata oluştu.");
      setTimeout(() => setSuccessMessage(""), 3000);
    }
  };

  const handleNotPublish = async (id) => {
    try {
      await kns.SetNotPublishKnowledge({ id: id });
      setSuccessMessage("Bilgi başarılı bir şekilde yayından kaldırıldı.")
      fetchData();
    } catch (error) {
      console.error("Error removing from publish:", error);
    }
  };

  const handleAddknowledge = async () => {
    if (newKnowledge.trim()) {
      try {
        await kns.Addknowledge({ content: newKnowledge });
        setSuccessMessage("Faydalı bilgi başarıyla eklendi!");

        setNewKnowledge("");
        fetchData();
      } catch (error) {
        console.error("Error adding knowledge:", error);
      }
    } else {
      console.error("Bilgi içeriği boş olamaz");
    }
  };

  return (
      <div className="p-6 bg-gray-100 min-h-screen">

        {/* Başlık */}
        <div className="mb-8 text-center">
          <h2 className="text-4xl font-bold text-gray-700">📊 GENEL DURUM</h2>
        </div>

        {/* İçerik Alanı */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Yaklaşan Görevler */}
          <div className="bg-white shadow-lg rounded-xl p-6">
            <h3 className="text-2xl font-semibold text-gray-700 mb-4 text-center">
              📅 Yaklaşan Görevler
            </h3>
            {task.length === 0 ? (
                <p className="text-gray-500 text-center">Yaklaşan görev yok</p>
            ) : (
                <ul className="space-y-2">
                  {task.map((value, index) => (
                      <li
                          key={index}
                          className="flex justify-between items-center p-2 bg-gray-50 rounded-md"
                      >
                        <span className="text-gray-600">{value.date}</span>
                        <span className="text-gray-800">{value.content}</span>
                      </li>
                  ))}
                </ul>
            )}
            <Link
                to="/calendar"
                className="block mt-4 text-center bg-indigo-500 hover:bg-indigo-600 text-white px-4 py-2 rounded-md transition"
            >
              ➕ Görev Ekle
            </Link>
          </div>

          {/* Faydalı Bilgiler */}
          <div className="bg-white shadow-lg rounded-xl p-6">
            <h3 className="text-2xl font-semibold text-gray-700 mb-4 text-center">
              💡 Faydalı Bilgiler
            </h3>
            {knowledge.length === 0 ? (
                <p className="text-gray-500 text-center">Faydalı bilgi yok</p>
            ) : (
                <ul className="space-y-2">
                  {knowledge.map((value, index) => (
                      <li
                          key={index}
                          className="flex justify-between items-center p-2 bg-gray-50 rounded-md"
                      >
                        <span className="text-gray-800">{value.content}</span>
                        <div className="flex gap-2">
                          {!value.publish ? (
                              <>
                                <button
                                    onClick={() => handlePublish(value.id)}
                                    className="bg-green-500 hover:bg-green-600 text-white px-3 py-1 rounded-md"
                                >
                                  Yayınla
                                </button>
                                <button
                                    onClick={() => handleDelete(value.id)}
                                    className="bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded-md"
                                >
                                  Sil
                                </button>
                              </>
                          ) : (
                              <button
                                  onClick={() => handleNotPublish(value.id)}
                                  className="bg-yellow-500 hover:bg-yellow-600 text-white px-3 py-1 rounded-md"
                              >
                                Kaldır
                              </button>
                          )}
                        </div>
                      </li>
                  ))}
                </ul>
            )}
          </div>

          {/* Faydalı Bilgi Ekle */}
          <div className="col-span-1 md:col-span-2 bg-white shadow-lg rounded-xl p-6">
            <h3 className="text-2xl font-semibold text-gray-700 mb-4 text-center">
              ➕ Faydalı Bilgi Ekle
            </h3>
            <div className="flex gap-4">
              <input
                  type="text"
                  value={newKnowledge}
                  onChange={(e) => setNewKnowledge(e.target.value)}
                  className="flex-1 border border-gray-300 px-4 py-2 rounded-md"
                  placeholder="Bilgi ekleyin"
              />
              <button
                  onClick={handleAddknowledge}
                  className="bg-indigo-500 hover:bg-indigo-600 text-white px-4 py-2 rounded-md"
              >
                Ekle
              </button>
            </div>
          </div>
        </div>
        {/* SuccessMessage */}
        {successMessage && (
            <SuccessMessage
                message={successMessage}
                onClose={() => setSuccessMessage("")}
            />
        )}
        {failedMessage && (
            <ErrorMessage
                message={failedMessage}
                onClose={() => setFailedMessage("")}
            />
        )}
      </div>
  );
}

export default Dashboard;
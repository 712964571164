import React, { useState, useEffect } from 'react'
import image from '../../assets/EyeYogaHorizontal.png'
import Footer from '../Footer'
import Header from '../Header'
import { playCongrulationSound } from '../../effect/Congrulation'
import FinishScreen from '../FinishScreen'
import FullscreenAlert from '../LoadPage/FullscreenAlert'

const EyeYogaHorizontal = () => {
  const positions1 = [
    { top: '10%', left: '0%' },
    { bottom: '10%', left: '0%' },
    { bottom: '10%', right: '0%' },

    { top: '10%', right: '0%' },
  ]
  const positions2 = [
    { top: '10%', left: '0%' },
    { top: '50%', left: '0%' },
    { bottom: '10%', left: '0%' },
    { bottom: '10%', left: '50%' },
    { bottom: '10%', right: '0%' },
    { top: '50%', right: '0%' },
    { top: '10%', right: '0%' },
    { top: '10%', left: '50%' },
  ]
  const positions3 = [
    {
      top: '10%',
      left: '0%',
    },
    { top: '10%', right: '0%' },
    { bottom: '10%', left: '0%' },
    { bottom: '10%', right: '0%' },
  ]
  const positions4 = [
    { top: '10%', left: '0%' },
    { top: '50%', left: '50%' },
    { bottom: '10%', right: '0%' },
    { top: '10%', right: '0%' },
    { top: '50%', left: '50%' },
    { bottom: '10%', left: '0%' },
  ]
  const positions6 = [
    { top: '10%', left: '0%' }, // Üstten başla, sol başlangıç
    { bottom: '10%', left: '0%' }, // Alttan başla, sol başlangıç
    { top: '10%', left: '5%' }, // Üstten başla, sol %5
    { bottom: '10%', left: '5%' }, // Alttan başla, sol %5
    { top: '10%', left: '10%' }, // Üstten başla, sol %10
    { bottom: '10%', left: '10%' }, // Alttan başla, sol %10
    { top: '10%', left: '15%' }, // Üstten başla, sol %15
    { bottom: '10%', left: '15%' }, // Alttan başla, sol %15
    { top: '10%', left: '20%' }, // Üstten başla, sol %20
    { bottom: '10%', left: '20%' }, // Alttan başla, sol %20
    { top: '10%', left: '25%' }, // Üstten başla, sol %25
    { bottom: '10%', left: '25%' }, // Alttan başla, sol %25
    { top: '10%', left: '30%' }, // Üstten başla, sol %30
    { bottom: '10%', left: '30%' }, // Alttan başla, sol %30
    { top: '10%', left: '35%' }, // Üstten başla, sol %35
    { bottom: '10%', left: '35%' }, // Alttan başla, sol %35
    { top: '10%', left: '40%' }, // Üstten başla, sol %40
    { bottom: '10%', left: '40%' }, // Alttan başla, sol %40
    { top: '10%', left: '45%' }, // Üstten başla, sol %45
    { bottom: '10%', left: '45%' }, // Alttan başla, sol %45
    { top: '10%', left: '50%' }, // Üstten başla, sol %50
    { bottom: '10%', left: '50%' }, // Alttan başla, sol %50
    { top: '10%', left: '55%' }, // Üstten başla, sol %55
    { bottom: '10%', left: '55%' }, // Alttan başla, sol %55
    { top: '10%', left: '60%' }, // Üstten başla, sol %60
    { bottom: '10%', left: '60%' }, // Alttan başla, sol %60
    { top: '10%', left: '65%' }, // Üstten başla, sol %65
    { bottom: '10%', left: '65%' }, // Alttan başla, sol %65
    { top: '10%', left: '70%' }, // Üstten başla, sol %70
    { bottom: '10%', left: '70%' }, // Alttan başla, sol %70
    { top: '10%', left: '75%' }, // Üstten başla, sol %75
    { bottom: '10%', left: '75%' }, // Alttan başla, sol %75
    { top: '10%', left: '80%' }, // Üstten başla, sol %80
    { bottom: '10%', left: '80%' }, // Alttan başla, sol %80
    { top: '10%', left: '85%' }, // Üstten başla, sol %85
    { bottom: '10%', left: '85%' }, // Alttan başla, sol %85
    { top: '10%', left: '90%' }, // Üstten başla, sol %90
    { bottom: '10%', left: '90%' }, // Alttan başla, sol %90
    { top: '10%', left: '95%' }, // Üstten başla, sol %95
    { bottom: '10%', left: '95%' }, // Alttan başla, sol %95
  ]
  const positions5 = [
    { top: '10%', left: '0%' }, // Üstten başla, sol başlangıç
    { top: '10%', right: '0%' }, // Üstten başla, sol başlangıç
    { top: '20%', left: '0%' }, // Üstten başla, sol başlangıç
    { top: '20%', right: '0%' }, // Üstten başla, sol başlangıç
    { top: '30%', left: '0%' }, // Üstten başla, sol başlangıç
    { top: '30%', right: '0%' }, // Üstten başla, sol başlangıç
    { top: '40%', left: '0%' }, // Üstten başla, sol başlangıç
    { top: '40%', right: '0%' }, // Üstten başla, sol başlangıç
    { top: '50%', left: '0%' }, // Üstten başla, sol başlangıç
    { top: '50%', right: '0%' }, // Üstten başla, sol başlangıç
    { top: '60%', left: '0%' }, // Üstten başla, sol başlangıç
    { top: '60%', right: '0%' }, // Üstten başla, sol başlangıç
    { top: '70%', left: '0%' }, // Üstten başla, sol başlangıç
    { top: '70%', right: '0%' }, // Üstten başla, sol başlangıç
    { top: '80%', left: '0%' }, // Üstten başla, sol başlangıç
    { top: '80%', right: '0%' }, // Üstten başla, sol başlangıç
    { top: '87%', left: '0%' }, // Üstten başla, sol başlangıç
    { top: '87%', right: '0%' }, // Üstten başla, sol başlangıç
  ]

  const [positionIndex, setPositionIndex] = useState(0)
  const [speed, setSpeed] = useState(500)
  const [isStart, setIsStart] = useState(false)
  const [isFinish, setIsFinish] = useState(false)
  const [elapsedTime, setElapsedTime] = useState(0)
  const [level, setLevel] = useState(1)
  const [isLevelChanging, setIsLevelChanging] = useState(false) // Level değişimi kontrolü
  const [totalExerciseTime, setTotalExerciseTime] = useState(1)
  const [isPaused, setIsPaused] = useState(false) // Yeni durum durdurma
  const [selectedPosition, setSelectedPosition] = useState('sequence') // Pozisyon seçimi

  const handleSpeedChange = (e) => {
    setSpeed(parseInt(e.target.value))
  }

  const handleTimeChange = (e) => {
    const minutes = parseInt(e.target.value) || 1; // En az 1 dakika
    setTotalExerciseTime(minutes); // Dakika olarak sakla
  };

  const handlePositionChange = (e) => {
    setSelectedPosition(e.target.value)
  }

  const token = window.localStorage.getItem('token')
  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  const handlePauseResume = () => {
    setIsPaused((prev) => !prev) // Durdur/Devam Et durumu değişir
  }

  const [isConfirmed, setIsConfirmed] = useState(false)

  const handleConfirm = () => {
    setIsConfirmed(true)
  }

  // Her level için süre (saniye cinsinden)
  const levelDuration = (totalExerciseTime * 60) / 6; // Dakikayı saniyeye çevir

  const getCurrentPositions = () => {
    if (selectedPosition === 'sequence') {
      switch (level) {
        case 1:
          return positions1
        case 2:
          return positions2
        case 3:
          return positions3
        case 4:
          return positions4
        case 5:
          return positions5
        case 6:
          return positions6
        default:
          return positions1
      }
    } else {
      // Seçilen pozisyonu tekrarla
      switch (selectedPosition) {
        case 'positions1':
          return positions1
        case 'positions2':
          return positions2
        case 'positions3':
          return positions3
        case 'positions4':
          return positions4
        case 'positions5':
          return positions5
        case 'positions6':
          return positions6
        default:
          return positions1
      }
    }
  }

  useEffect(() => {
    let timer
    if (isStart && !isPaused && !isLevelChanging) {
      timer = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + speed / 1000)

        // Pozisyonların döngüsel davranışı
        setPositionIndex((prevIndex) => {
          const positions = getCurrentPositions()
          return (prevIndex + 1) % positions.length
        })

        // Süre bittiğinde egzersizi sonlandır
        if (elapsedTime >= totalExerciseTime * 60) {
          setIsFinish(true);
          playCongrulationSound();
          setIsStart(false);
          clearInterval(timer);
        }

        // "Sıra ile" modunda seviyeler arası geçiş
        if (
          selectedPosition === 'sequence' &&
          elapsedTime >= levelDuration * level
        ) {
          if (level < 6) {
            setIsLevelChanging(true) // Level değişimi başlıyor
            setTimeout(() => {
              // 1 saniye bekle
              setLevel((prevLevel) => prevLevel + 1)
              setPositionIndex(0) // Level'ın ilk pozisyonuna sıfırla
              setIsLevelChanging(false) // Level değişimi bitti
            }, 1000) // 1 saniye bekleme
          } else {
            setIsFinish(true)
            playCongrulationSound()
            setIsStart(false)
          }
        }
      }, speed)
    }
    return () => clearInterval(timer)
  }, [
    isStart,
    isPaused,
    speed,
    level,
    elapsedTime,
    isLevelChanging,
    selectedPosition,
  ])

  const handleStart = () => {
    setIsStart(true)
  }

  const repeatExercise = () => {
    setIsFinish(false) // Bitmiş durumu sıfırla
    setIsStart(false) // Başlangıç ekranına dön
    setElapsedTime(0) // Geçen süreyi sıfırla
    setPositionIndex(0) // Pozisyon indeksini sıfırla
    setLevel(1) // Seviyeyi başlangıç değerine sıfırla
    setIsPaused(false)
  }

  return (
    <div>
      <div className={`${isFinish ? 'hidden' : ''}`}>
        {isStart && !isFinish && (
          <div>
            <img
              key={positionIndex}
              src={image} // "image" değişkenini tanımlamanız gerekiyor
              style={{
                ...getCurrentPositions()[positionIndex],
                width: '80px',
                height: '80px',
                margin: '10px',
                position: 'absolute',
                transition: 'left 0.3s ease-linear',
              }}
              alt=""
            />
            <Header title="Göz Yogası" returnType="eye-yoga">
              <button
                onClick={repeatExercise}
                className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
              >
                Sıfırla
              </button>
              <button
                onClick={handlePauseResume}
                className={`${
                  isPaused ? 'bg-green-500' : 'bg-yellow-500'
                } text-white px-3 py-1 rounded hover:opacity-90`}
              >
                {isPaused ? 'Devam Et' : 'Durdur'}
              </button>
            </Header>
            <Footer />
          </div>
        )}
        {!isStart && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
            <div className="bg-gray-300 p-8 rounded-md text-center">
              <h1 className="text-2xl font-bold mb-2">
                Göz Yogası
              </h1>
              <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
                <label className="block mb-2">
                  Hız: {speed} ms
                  <input
                      type="range"
                      min="100"
                      max="2000"
                      step="50"
                      value={speed}
                      onChange={handleSpeedChange}
                      className="w-full"
                  />
                  <div className="flex justify-between text-sm text-gray-500 mt-1">
                    <span>100 ms</span>
                    <span>2000 ms</span>
                  </div>
                </label>
              </div>
              <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
                <label className="block mb-2">
                  Süre (Dakika): {totalExerciseTime} dakika
                  <input
                      type="range"
                      min="1"
                      max="5"
                      value={totalExerciseTime}
                      onChange={handleTimeChange}
                      className="w-full px-2 py-1 border rounded"
                  />
                  <div className="flex justify-between text-sm text-gray-500 mt-1">
                    <span>1 dk</span>
                    <span>5 dk</span>
                  </div>
                </label>
              </div>

              <div className="bg-white p-4 rounded-lg shadow-sm mb-4">

                <label className="block mb-2">
                  Pozisyon Seçimi:
                  <select
                      value={selectedPosition}
                      onChange={handlePositionChange}
                      className="w-full px-2 py-1 border rounded"
                  >
                    <option value="sequence">Sıra ile</option>
                    <option value="positions1">Pozisyon 1</option>
                    <option value="positions2">Pozisyon 2</option>
                    <option value="positions3">Pozisyon 3</option>
                    <option value="positions4">Pozisyon 4</option>
                    <option value="positions5">Pozisyon 5</option>
                    <option value="positions6">Pozisyon 6</option>
                  </select>
                </label>
              </div>
              <button
                  className="bg-blue-400 text-white py-2 px-4 mt-4 rounded hover:bg-blue-300"
                  onClick={handleStart}
              >
                🚀 Başlat
              </button>
            </div>
          </div>
        )}
      </div>
      {isFinish && <FinishScreen onRestart={repeatExercise} returnType="eye-yoga"/>}
      <FullscreenAlert onConfirm={handleConfirm} />
    </div>
  )
}

export default EyeYogaHorizontal

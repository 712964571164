import React, { useState, useRef, useEffect } from "react";
import VideoServices from "../Services/VideoServices";

export default function Videos() {
  const vd = new VideoServices();

  const [videos, setVideos] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await vd.getVideo(token);
        const videosWithBlob = await Promise.all(
          response.data.map(async (video) => {
            const videoBlobUrl = await fetchVideo(video.url);
            return { ...video, url: videoBlobUrl }; // Blob URL'yi ayarlayın
          })
        );
        setVideos(videosWithBlob);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    const fetchVideo = async (url) => {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          token: `${token}`, // Token'ı burada kullanın
        },
      });
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    };

    fetchVideos();
  }, [token]);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("video", file);
      setIsUploading(true); // Set uploading state

      try {
        const response = await vd.AddVideo(formData, token);
        const newVideo = {
          name: response.data.name,
          filename: response.data.filename,
          url: response.data.url,
        };
        setVideos((prevVideos) => [...prevVideos, newVideo]);
      } catch (error) {
        console.error("Video upload failed:", error);
      } finally {
        setUploadProgress(0);
        setIsUploading(false); // Reset uploading state
      }
    }
  };

  const removeVideo = async (index, name) => {
    const updatedVideos = [...videos];
    try {
      const response = await vd.delVideo(name);
      if (response.status === 200) {
        alert("Video successfully deleted");
        updatedVideos.splice(index, 1);
        setVideos(updatedVideos);
      }
    } catch (error) {
      console.error("Error deleting video:", error);
    }
  };

  const handlePlusClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="w-full h-full p-4">
      <div className="mt-6 flex items-center justify-between">
        <div className="mt-12 flex items-center">
          <h2 className="text-3xl font-semibold mb-20">VİDEOLAR</h2>
        </div>
        <button
          onClick={handlePlusClick}
          className="px-4 py-2 text-xl bg-special_green text-white rounded-lg shadow-md hover:bg-special_dark_green"
        >
          Yeni Video Ekle
        </button>
      </div>

      <div className="my-6 grid gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {videos.map((video, index) => (
          <div
            key={index}
            className="relative w-full bg-white rounded-lg shadow-lg overflow-hidden"
          >
            <button
              className="absolute top-2 right-2 bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center z-10 shadow-md hover:bg-red-600"
              onClick={() => removeVideo(index, video.name)}
            >
              X
            </button>
            <video className="w-full h-48 object-cover" controls>
              <source src={video.url} type="video/mp4" />
              Tarayıcınız video etiketini desteklemiyor.
            </video>
            <div className="p-4 bg-gray-50">
              <p className="text-sm text-gray-700 truncate">{video.name}</p>
            </div>
          </div>
        ))}

        <div className="relative flex items-center justify-center w-full h-48 border-2 border-dashed border-blue-400 rounded-lg bg-gray-100">
          <label
            htmlFor="uploadvideo"
            className="cursor-pointer flex flex-col items-center justify-center"
          >
            <span className="text-4xl text-blue-400 mb-2">+</span>
            <span className="text-gray-600"></span>
          </label>
          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            onChange={handleFileUpload}
          />
          {isUploading && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-300 bg-opacity-75">
              <span className="text-lg font-semibold text-blue-500">
                Yükleniyor...
              </span>
            </div>
          )}
          {uploadProgress > 0 && (
            <div className="absolute bottom-0 left-0 w-full bg-blue-500 h-1">
              <div
                style={{ width: `${uploadProgress}%` }}
                className="h-full bg-green-500 transition-all"
              ></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useRef } from 'react'
import { CircleCheck, CircleX, CircleCheckBig } from 'lucide-react'
import CountdownAnimation from '../../animation/CountdownAnimation'
import { playCorrectSound } from '../../effect/Correct'
import { playInCorrectSound } from '../../effect/Incorrect'
import { playCongrulationSound } from '../../effect/Congrulation'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import Header from '../Header'
import Footer from '../Footer'
import VocabolaryServices from "../../Services/VocabolaryServices";


const IsEqual = () => {
  const [point, setPoint] = useState(0)
  const [currentProblemIndex, setCurrentProblemIndex] = useState(0)
  const [currentProblem, setCurrentProblem] = useState(null)
  const [elapsedTime, setElapsedTime] = useState(0)
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(null)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [isStart, setIsStart] = useState(false)
  const [isFinish, setIsFinish] = useState(false)
  const [correctNum, setCorrectNum] = useState(0)
  const [incorrectNum, setIncorrectNum] = useState(0)
  const [showCountdown, setShowCountdown] = useState(false)

  const [vocabolery, setVocabolery] = useState([])
  const vocabolaryservices = new VocabolaryServices()
  const [problems, setProblems] = useState([]) // Başlangıçta boş bir dizi olarak ayarlayın
  const [selectedLevel, setSelectedLevel] = useState('')

  const token = window.localStorage.getItem('token')
  const timerId = useRef(null)

  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  useEffect(() => {
    vocabolaryservices
        .getVocabolary('isequal')
        .then((response) => {
          setVocabolery(response.data)
        })
        .catch((error) => {
          console.error('Kategorileri alırken hata oluştu:', error)
        })
  }, [])

  const fisherYatesShuffle = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };


  const [isConfirmed, setIsConfirmed] = useState(false)

  const handleConfirm = () => {
    setIsConfirmed(true)
  }
  useEffect(() => {
    return () => {
      if (timerId.current) {
        clearInterval(timerId.current)
        timerId.current = null
      }
    }
  }, [])

  const handleStart = () => {
    setShowCountdown(true)
    // Clear any existing timer first
    if (timerId.current) {
      clearInterval(timerId.current)
      timerId.current = null
    }

    setTimeout(() => {
      setIsStart(true)
      setShowCountdown(false)
      // Start a new timer
      setElapsedTime(0) // Reset time before starting
      timerId.current = setInterval(() => {
        setElapsedTime(prev => prev + 1)
      }, 1000)
    }, 4000)
  }

  const getRandomOperation = () => {
    const unusedProblems = problems.filter((problem) => !problem.used)

    if (unusedProblems.length === 0) {
      console.log('Tüm soruları kullandınız!')
      setIsFinish(true)
      playCongrulationSound()
      return null
    }

    const randomIndex = Math.floor(Math.random() * unusedProblems.length)
    const newProblems = [...problems]
    const problem = unusedProblems[randomIndex]

    problem.used = true
    newProblems.splice(newProblems.indexOf(problem), 1, problem)

    setProblems(newProblems)
    return problem
  }

  useEffect(() => {
    if (isStart) {
      setCurrentProblem(getRandomOperation())
    }
  }, [isStart])

  useEffect(() => {
    if (isFinish && timerId.current) {
      clearInterval(timerId.current)
      timerId.current = null
    }
  }, [isFinish])

  const handleAnswer = (isCorrect) => {
    if (!currentProblem) {
      return
    }

    if (isCorrect === currentProblem.isTrue) {
      setPoint(point + 10)
      setCorrectNum(correctNum + 1)
      setIsCorrectAnswer(true)
      playCorrectSound()
    } else {
      setIncorrectNum(incorrectNum + 1)
      setIsCorrectAnswer(false)
      playInCorrectSound()
    }
    setIsTransitioning(true)
    setTimeout(() => {
      setIsCorrectAnswer(null)
      setIsTransitioning(false)
      setCurrentProblem(getRandomOperation())
    }, 1000)
  }

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes}:${
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
    }`
  }

  const restartExercise = () => {
    // Clear timer
    if (timerId.current) {
      clearInterval(timerId.current)
      timerId.current = null
    }

    // Clear current problem first
    setCurrentProblem(null)
    setIsCorrectAnswer(null)

    // Reset all states
    setIsFinish(false)
    setElapsedTime(0)
    setPoint(0)
    setCurrentProblemIndex(0)
    setIncorrectNum(0)
    setCorrectNum(0)
    setIsStart(false)
    setShowCountdown(false)
    setIsTransitioning(false)

    // Reset problems
    const selectedData = vocabolery.find((levelData) => levelData.level === selectedLevel)
    if (selectedData && selectedData.data?.[0]?.problems) {
      const shuffledProblems = fisherYatesShuffle(selectedData.data[0].problems)
      setProblems(shuffledProblems)
    } else {
      console.warn('Seçilen seviyede geçerli bir problems verisi bulunamadı!')
      setProblems([])
    }
  }

  useEffect(() => {
    if (vocabolery.length > 0) {
      const defaultLevel = vocabolery[0].level; // İlk seviyeyi varsayılan olarak al
      setSelectedLevel(defaultLevel);
      const defaultData = vocabolery.find((levelData) => levelData.level === defaultLevel);

      if (defaultData && defaultData.data?.[0]?.problems) {
        const shuffledProblems = fisherYatesShuffle(defaultData.data[0].problems); // Problemleri karıştır
        setProblems(shuffledProblems);
      } else {
        console.warn('Varsayılan seviyede geçerli bir problems verisi bulunamadı!');
        setProblems([]);
      }
    }
  }, [vocabolery]);

  const handleLevelChange = (event) => {
    const selected = event.target.value;
    setSelectedLevel(selected);
    setProblems([]); // Mevcut problem listesini temizle

    const selectedData = vocabolery.find((levelData) => levelData.level === selected);

    if (selectedData && selectedData.data?.[0]?.problems) {
      const shuffledProblems = fisherYatesShuffle(selectedData.data[0].problems); // Problemleri karıştır
      setProblems(shuffledProblems);
    } else {
      console.warn('Seçilen seviyede geçerli bir problems verisi bulunamadı!');
      setProblems([]);
    }
  };


  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/attention-memory-logic-math`
  }


  return (
    <div className="h-screen overflow-hidden">
      <Header title={'Hızlı İşlem'} returnType="attention-memory">

        <button
            onClick={restartExercise}
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
        >
          Sıfırla
        </button>
      </Header>
      <div
          className="fixed top-24 right-4 flex items-center justify-center bg-gray-400 p-2 rounded-xl text-white text-xl  w-40 h-12">
        <div className="flex justify-center items-center w-full h-full">
          {formatTime(elapsedTime)}
        </div>
      </div>
      <div className="flex-col p-2 fixed top-36 right-16 border border-gray-300 mt-2 w-20 flex justify-center items-center">
        <span className="flex gap-2 items-center">
          <CircleCheckBig size={20} color="#14cb1f" strokeWidth={1.75} />
          {correctNum}
        </span>
        <span className="flex gap-2  items-center">
          <CircleX size={20} strokeWidth={1.75} color="#ff0000" />
          {incorrectNum}
        </span>
      </div>
      <div className="flex justify-center items-center my-auto h-full">
        <div className="w-full h-1/2 flex justify-center items-center">
          <div className="flex flex-col justify-center items-center">
            {isStart && currentProblem && (
                <div
                    className={`mb-8 flex justify-center items-center rounded-xl p-3 ${
                        isCorrectAnswer === true
                            ? 'bg-green-400'
                            : isCorrectAnswer === false
                                ? 'bg-red-400'
                                : 'bg-white'
                    }`}
                >
                  <span className="text-9xl ">{currentProblem.operation}</span>
                  <span className="text-9xl pl-24 ">{currentProblem.answer}</span>
                </div>
            )}
            <div className="flex items-center gap-x-20 mt-16">
              {!showCountdown && isStart && (
                  <>
                    <button
                        onClick={() => handleAnswer(true)}
                        disabled={isTransitioning}
                    >
                      <CircleCheck size={100} color="#1cc819" strokeWidth={3}/>
                    </button>
                    <button
                        onClick={() => handleAnswer(false)}
                        disabled={isTransitioning}
                    >
                      <CircleX size={100} color="#ff0000" strokeWidth={3}/>
                    </button>
                  </>
              )}
            </div>
          </div>
          <Footer/>
        </div>
        {isFinish && (
            <div className="bg-gray-500 bg-opacity-50 flex items-center justify-center fixed inset-0">
              <div
                  className="bg-white p-8 rounded-md text-center"
                  style={{width: '300px'}}
              >
                <p style={{fontSize: '20px'}}>
                  Tebrikler! Egzersizi tamamladınız.
                </p>
                <div className="border-2 rounded-xl">
                  <p>Doğru: {correctNum}</p>
                  <p>Yanlış: {incorrectNum}</p>
                  <p>Geçen Süre: {formatTime(elapsedTime)}</p>
                </div>
                <button
                    className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
                    onClick={restartExercise}
                >
                  Tekrar
                </button>
                <div>
                  <button
                      onClick={handleReturnDashboard}
                      className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
                  >
                    Egzersiz Sayfasına Dön
                  </button>
                </div>
              </div>
            </div>
        )}
        {!isStart && !isFinish && !showCountdown && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
            <div className="bg-gray-300 p-8 rounded-md text-center w-1/3">
              <h2 className="font-semibold text-3xl p-1">
                Hızlı İşlem Egzersizi
              </h2>
              {/*
              📚 Seviye Seçimi
              */}
              <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
                <label
                    htmlFor="levelSelect"
                    className="block text-gray-700 font-semibold mb-2 text-left"
                >
                  📚 Seviye Seçimi:
                </label>
                <select
                    id="levelSelect"
                    className="w-full p-2 border border-gray-300 rounded-md"
                    value={selectedLevel}
                    onChange={handleLevelChange}
                >
                  {vocabolery.map((levelData) => (
                      <option key={levelData.level} value={levelData.level}>
                        Seviye {levelData.level}
                      </option>
                  ))}
                </select>
              </div>
              <button
                  className="bg-blue-400 text-white py-2 px-4 mt-4 rounded hover:bg-blue-300"
                  onClick={handleStart}
              >
                Başlat 🚀

              </button>
            </div>
          </div>
        )}
        {showCountdown && (
            <div className="fixed inset-0 flex items-center justify-center bg-opacity-50">
              <CountdownAnimation/>
            </div>
        )}
      </div>
      <FullscreenAlert onConfirm={handleConfirm}/>
    </div>
  )
}

export default IsEqual

import React, { useState, useEffect } from 'react'
import { playCongrulationSound } from '../../effect/Congrulation'
import { playCorrectSound } from '../../effect/Correct'
import { playInCorrectSound } from '../../effect/Incorrect'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import Footer from '../Footer'
import Header from '../Header'
import VocabolaryServices from '../../Services/VocabolaryServices'

const LetterComplete = () => {
  const [letters, setLetters] = useState(['A'])
  const [isStart, setIsStart] = useState(false)
  const [words, setWords] = useState(['MANTA'])
  const [isFinish, setIsFinish] = useState(false)

  const token = window.localStorage.getItem('token')
  const [exerciseDuration, setExerciseDuration] = useState(10) // Dakika cinsinden varsayılan süre
  const [selectedLevel, setSelectedLevel] = useState('')
  const [selectedOption, setSelectedOption] = useState('')
  const [availableOptions, setAvailableOptions] = useState([])
  const [vocabolery, setVocabolery] = useState([])
  const vocabolaryservices = new VocabolaryServices()

  const handleLevelChange = (event) => {
    setSelectedLevel(event.target.value);
    setAvailableOptions([]); // Önceki seçenekleri temizle
    setSelectedOption(''); // Seçili alt seçeneği sıfırla
    setWords([]); // Kelimeleri temizle
    setLetters([]); // Harfleri temizle
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    vocabolaryservices
      .getVocabolary('letter-complete')
      .then((response) => {
        setVocabolery(response.data)
      })
      .catch((error) => {
        console.error('Kategorileri alırken hata oluştu:', error)
      })
  }, [])

  // Set single duration in seconds (5 minutes = 300 seconds)
  const EXERCISE_DURATION = 600

  const [timeRemaining, setTimeRemaining] = useState(EXERCISE_DURATION)
  const [isConfirmed, setIsConfirmed] = useState(false)

  const handleConfirm = () => {
    setIsConfirmed(true)
  }

  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  useEffect(() => {
    if (selectedLevel) {
      const selectedLevelData = vocabolery?.find((lvl) => lvl.level === selectedLevel);
      if (selectedLevelData && selectedLevelData.data?.length > 0) {
        setAvailableOptions(
            selectedLevelData.data[0].words.map((_, index) => index)
        );
      } else {
        setAvailableOptions([]);
        console.warn('Seçilen seviye için veri bulunamadı veya uygun formatta değil.');
      }
    }
  }, [selectedLevel]);

  useEffect(() => {
    if (selectedOption) {
      const selectedLevelData = vocabolery?.find((lvl) => lvl.level === selectedLevel);
      if (
          selectedLevelData &&
          selectedLevelData.data?.length > 0 &&
          selectedLevelData.data[0].words &&
          selectedLevelData.data[0].letters
      ) {
        setWords(selectedLevelData.data[0].words[selectedOption] || []);
        setLetters(selectedLevelData.data[0].letters[selectedOption] || []);
        setSelectedLetters([]); // Seçili harfleri temizle
        setCompletedWords([]); // Tamamlanan kelimeleri temizle
        setScore(0); // Skoru sıfırla
        setIsStart(false); // Egzersizi durdur
        setIsFinish(false); // Tamamlanma durumunu sıfırla
        setTimeRemaining(exerciseDuration * 60); // Süreyi sıfırla
        console.log('Seçilen seçenek uygulandı ve arayüz güncellendi.');
      } else {
        setWords([]);
        setLetters([]);
        console.warn('Seçilen seçenek için veri bulunamadı veya uygun formatta değil.');
      }
    }
  }, [selectedOption]);

  const restartExercise = () => {
    setTimeRemaining(EXERCISE_DURATION)
    setIsFinish(false)
    setCompletedWords([])
    setScore(0)
    setSelectedLetters([])
    setIsStart(false)
  }

  const [isChecking, setIsChecking] = useState(false)
  const [completedWords, setCompletedWords] = useState([])
  const [score, setScore] = useState(0)
  const [selectedLetters, setSelectedLetters] = useState([])

  // Timer countdown effect
  useEffect(() => {
    let interval = null

    if (isStart && !isFinish && timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime <= 1) {
            endExercise(false) // end exercise with timeout condition
            return 0
          }
          return prevTime - 1
        })
      }, 1000)
    }

    return () => clearInterval(interval)
  }, [isStart, isFinish, timeRemaining])
  const formatTime = () => {
    const minutes = Math.floor(timeRemaining / 60)
    const seconds = timeRemaining % 60
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`
  }

  // Rest of the component logic remains the same
  const selectLetter = (letter) => {
    setSelectedLetters([...selectedLetters, letter])
  }

  const clearSelectedLetters = () => {
    setSelectedLetters([])
  }

  const renderSelectedLetters = () => {
    return selectedLetters.map((item, index) => {
      const { letter, color } =
        typeof item === 'object' ? item : { letter: item, color: 'blue' }
      return (
        <div
          key={index}
          className={`m-1 p-2 rounded-full h-14 w-14 flex justify-center items-center text-white text-lg cursor-pointer ${
            color === 'red'
              ? 'bg-red-500'
              : color === 'green'
              ? 'bg-green-500'
              : 'bg-gray-500'
          }`}
        >
          {letter}
        </div>
      )
    })
  }

  const handleClear = () => {
    clearSelectedLetters()
  }

  const maxLength = Math.max(...words.map((word) => word.length))

  const handleLetterClick = (letter) => {
    if (selectedLetters.length < maxLength) {
      setSelectedLetters([...selectedLetters, letter])
    }
  }

  const renderLetters = () => {
    return letters.map((letter, index) => (
      <div
        key={index}
        className="bg-gray-400 m-1 p-2 rounded-full h-14 w-14 flex justify-center items-center text-white text-lg cursor-pointer hover:bg-gray-300"
        onClick={() => handleLetterClick(letter)}
      >
        {letter}
      </div>
    ))
  }

  // Kelimeleri 7'li gruplara bölen yardımcı fonksiyon
  const chunkWords = (arr, size) => {
    const chunks = []
    for (let i = 0; i < arr.length; i += size) {
      chunks.push(arr.slice(i, i + size))
    }
    return chunks
  }

  const renderGroupedWords = () => {
    return Object.entries(groupedWords).map(([length, words]) => {
      // Her grup için kelimeleri 7'lik parçalara ayırıyoruz
      const columns = chunkWords(words, 7)

      return (
        <div key={length} className="bg-gray-100 rounded-lg p-4 shadow-md">
          <h2 className=" text-center text-xl font-semibold text-gray-500 mb-4">
            {length} Harfli Kelimeler
          </h2>
          <div className="flex space-x-4">
            {columns.map((columnWords, colIndex) => (
              <div key={colIndex} className="flex flex-col space-y-1">
                {columnWords.map((word) => (
                  <div key={word} className="flex">
                    {renderWordBlocks(word)}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )
    })
  }

  const renderWordBlocks = (word) => {
    return word.split('').map((letter, index) => (
      <div
        key={index}
        className={`bg-gray-400 mt-0.5 mr-0.5 p-1 h-10 w-9 flex justify-center items-center rounded text-white text-sm  ${
          completedWords.includes(word) || isFinish ? 'bg-green-400' : ''
        }`}
      >
        {completedWords.includes(word) || isFinish ? letter : ''}
      </div>
    ))
  }

  const groupedWords = words.reduce((acc, word) => {
    const length = word.length
    if (!acc[length]) {
      acc[length] = []
    }
    acc[length].push(word)
    return acc
  }, {})

  const [wordStatus, setWordStatus] = useState({})

  const updateWordStatus = (word, status) => {
    setWordStatus({ ...wordStatus, [word]: status })
  }
  // Function to handle exercise completion
  const endExercise = (completedAll) => {
    clearInterval(timeRemaining)
    setIsFinish(true)

    if (completedAll) {
      playCongrulationSound()
    }
  }
  const handleCheck = () => {
    if (!isChecking) {
      setIsChecking(true)
      const selectedWord = selectedLetters.join('')
      if (words.includes(selectedWord)) {
        playCorrectSound()
        setSelectedLetters(
          selectedLetters.map((letter) => ({ letter, color: 'green' }))
        )
        setTimeout(() => {
          const newCompletedWords = [...completedWords, selectedWord]
          setCompletedWords(newCompletedWords)
          updateWordStatus(selectedWord, true)
          setScore((prevScore) => prevScore + 100)
          clearSelectedLetters()
          setIsChecking(false)

          // Check if all words are found
          if (newCompletedWords.length === words.length) {
            endExercise(true)
          }
        }, 1000)
      } else {
        playInCorrectSound()
        setSelectedLetters(
          selectedLetters.map((letter) => ({ letter, color: 'red' }))
        )
        setTimeout(() => {
          clearSelectedLetters()
          setIsChecking(false)
        }, 1000)
      }
    }
  }


  const handleDurationChange = (event) => {
    setExerciseDuration(event.target.value)
  }

  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/word-recognition'`
  }

  const handleStart = () => {
    setIsStart(true)
    setTimeRemaining(exerciseDuration * 60) // Dakikayı saniyeye çevir

  }

  const renderFinishMessage = () => {
    const allWordsFound = completedWords.length === words.length
    const timeExpired = timeRemaining === 0

    if (allWordsFound) {
      return (
        <div>
          Tebrikler! Egzersizi tamamladınız. <br />
          <div className="flex flex-col">
            <span>Kalan Süre: {formatTime()}</span>
            <span>Toplam Puan: {score}</span>
          </div>
        </div>
      )
    } else if (timeExpired) {
      return (
        <div>
          Süre doldu! <br />
          <div className="flex flex-col">
            <span>Bulunan Kelime: {completedWords.length}</span>
            <span>Kalan Kelime: {words.length - completedWords.length}</span>
            <span>Toplam Puan: {score}</span>
          </div>
        </div>
      )
    }
  }
  return (
    <div className="h-screen flex flex-col ">
      <Header title={'Türetmece'} returnType="word-recognition">
        <button
          onClick={restartExercise}
          className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
        >
          Sıfırla
        </button>
      </Header>
      <Footer />
      <div className="flex-grow flex flex-col justify-between bg-gray-300 overflow-auto relative">
        <div className="absolute top-0 right-0 m-4 flex space-x-4">
          <span className="flex justify-center text-lg font-semibold rounded-xl bg-gray-500 p-1 text-white w-32">
            Puan: {score}
          </span>
          <span className="flex justify-center text-lg w-24 font-semibold rounded-xl bg-gray-500 p-1 text-white">
            {formatTime()}
          </span>
        </div>
        <div className="flex justify-center space-x-6 mt-16 w-full ">
          {renderGroupedWords()}
        </div>
        <div className="flex justify-center mt-2">{renderLetters()}</div>
        <div className="flex justify-center mt-2 h-16 mb-8">
          {renderSelectedLetters()}
        </div>
        <div className="flex justify-center mb-16">
          <div className="flex justify-center space-x-4">
            <button
              className="bg-gray-400 text-white py-2 px-4 rounded-xl hover:bg-red-300"
              onClick={handleClear}
            >
              Temizle
            </button>
            <button
              className="bg-green-400 text-white py-4 px-8 flex justify-center items-center rounded-xl hover:bg-green-300"
              onClick={handleCheck}
            >
              Kontrol Et
            </button>
          </div>
        </div>
      </div>

      {isFinish && (
        <div className="bg-gray-500 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
          <div
            className="bg-white p-8 rounded-md text-center"
            style={{ width: '300px' }}
          >
            <p style={{ fontSize: '20px' }}>{renderFinishMessage()}</p>
            <button
              className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
              onClick={restartExercise}
            >
              Tekrar
            </button>
            <button
              onClick={handleReturnDashboard}
              className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
            >
              Egzersiz Sayfasına Dön
            </button>
          </div>
        </div>
      )}

      {!isStart && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
          <div className="bg-gray-300 p-8 rounded-md text-center w-1/3">
            <h2 className="font-semibold text-2xl p-1">Türetmece Egzersizi</h2>
            <div className="space-y-6">
              {/* Level Selection */}
              <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
                <label
                    htmlFor="levelSelect"
                    className="block text-gray-700 font-semibold mb-2 text-left"
                >
                  📚 Seviye Seçimi:
                </label>
                <select
                    id="levelSelect"
                    className="w-full p-2 border border-gray-300 rounded-md"
                    value={selectedLevel}
                    onChange={handleLevelChange}
                >
                  <option value="" disabled>Seviye Seç</option>
                  {vocabolery.map((levelData) => (
                      <option key={levelData.level} value={levelData.level}>
                        Seviye {levelData.level}
                      </option>
                  ))}
                </select>
              </div>
              {/* Alt Seçenek */}
              {availableOptions.length > 0 && (
                  <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
                    <label
                        htmlFor="optionSelect"
                        className="block text-gray-700 font-semibold mb-2 text-left"
                    >
                      📝 Alt Seçenek Seçimi:
                    </label>
                    <select
                        id="optionSelect"
                        className="w-full p-2 border border-gray-300 rounded-md"
                        value={selectedOption}
                        onChange={handleOptionChange}
                    >
                      <option value="" disabled>Bir Seçenek Seç</option>
                      {availableOptions.map((option, index) => (
                          <option key={index} value={option}>
                             {index + 1}
                          </option>
                      ))}
                    </select>
                  </div>
              )}
              <div className="bg-white p-4 rounded-lg shadow-sm">
                <label
                    htmlFor="duration"
                    className="block text-gray-700 font-semibold mb-2 text-left"
                >
                  🕒 Süre (Dakika): {exerciseDuration}
                </label>
                <input
                    id="numgame"
                    type="range"
                    min="1"
                    max="10"
                    step="1"
                    value={exerciseDuration}
                    onChange={handleDurationChange}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-blue-500"
                />
                <div className="flex justify-between text-sm text-gray-500 mt-1">
                  <span>1</span>
                  <span>10</span>
                </div>
              </div>
            </div>
            <button
                className="bg-blue-400 text-white py-2 px-4 mt-4 rounded hover:bg-blue-300"
                onClick={handleStart}
            >
              🚀 Başlat
            </button>
          </div>
        </div>
      )}

      <FullscreenAlert onConfirm={handleConfirm}/>
    </div>
  )
}

export default LetterComplete

import React, { useState, useEffect } from 'react'
import MessagesServices from '../Services/MessageServices'
import SuccessMessage from "../Components/SuccessMessage";
import ErrorMessage from "../Components/ErrorMessage";

export default function Communications() {
  const [formIsOpen, setFormOpen] = useState(false)
  const [messages, setMessages] = useState([])
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [formData, setFormData] = useState({
    header: '',
    user_name: '',
    content: '',
    date: '',
    sender: '',
    time: '',
  })

  useEffect(() => {
    // Assuming getUsers is a function in UserServices to fetch users
    msgServices
      .getMessages()
      .then((response) => {
        if (response.status === 200) {
          setMessages(response.data)
        }
      })
      .catch((error) => {
        console.error('Error fetching users:', error)
      })
  }, [])

  const msgServices = new MessagesServices()

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value.trim(), // trim() ile boşlukları kaldır
    });
  };

  const handleSubmit = () => {
    const newMessage = {
      header: formData.header.trim(),
      content: formData.content.trim(),
      sender_username: formData.user_name.trim(),
      time: '',
      date: new Date().toLocaleDateString(),
    }

    msgServices
      .AddMessages(newMessage, '')
      .then((response) => {
        if (response.status === 200) {
          setMessages([...messages, newMessage])
          setFormOpen(false)
          setSuccessMessage("Bildiri başarıli bir şekilde gönderildi.")

        } else {
          setErrorMessage('Bildiri gönderilemedi!')
        }
      })
      .catch((error) => {
        console.error('Hata oluştu:', error)
      })

    setFormData({
      header: '',
      user_name: '',
      content: '', // content'i de sıfırla
    })
  }

  const handleDelete = (index) => {
    const deletedMessage = messages[index].header // Silinen mesajın başlığını al
    msgServices
      .delMessages(deletedMessage, '')

      .then((response) => {
        if (response.status === 200) {
          const newMessages = [...messages]
          newMessages.splice(index, 1)
          setMessages(newMessages)
          setSuccessMessage("Bildiri başarılı bir şekilde silindi.")
        } else {
          setErrorMessage('İstek şuanda silinemiyor.')
        }
      })
      .catch((error) => {
        console.error('Hata oluştu:', error)
      })

    // deletedMessage değişkeni içinde silinen mesajın başlığı bulunmaktadır.
  }

  const handleSaveButton = () => {
    setFormOpen(!formIsOpen)
  }

  return (
      <div className="w-full min-h-screen  p-6">
        {/* Başarı Mesajı */}
        {successMessage && (
            <SuccessMessage
                message={successMessage}
                onClose={() => setSuccessMessage('')}
            />
        )}
        {errorMessage && (
            <ErrorMessage
                message={errorMessage}
                onClose={() => setErrorMessage('')}
            />
        )}
        <div className="mt-8 flex items-center justify-between">
          <div className="mt-12 flex items-center">
            <h2 className="text-3xl font-semibold mb-20">DUYURU OLUŞTURMA</h2>
          </div>
          <button
              onClick={handleSaveButton}
              className="bg-blue-600 hover:bg-blue-500 text-white font-semibold py-2 px-6 rounded-lg shadow-md transition duration-300"
          >
            Bildiri Gönder
          </button>
        </div>

        {formIsOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg p-8 w-11/12 max-w-md">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-2xl font-semibold text-gray-800">Yeni Bildiri</h2>
                  <button
                      onClick={handleSaveButton}
                      className="text-gray-500 hover:text-gray-700"
                  >
                    ✕
                  </button>
                </div>
                <div className="space-y-4">
                  <div>
                    <label className="block text-xl font-medium text-gray-700 mt-2">
                      Başlık
                    </label>
                    <input
                        type="text"
                        name="header"
                        value={formData.header}
                        onChange={handleInputChange}
                        className="w-full h-12 border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        placeholder=" Başlık girin"
                    />
                  </div>
                  <div>
                    <label className="block text-xl font-medium text-gray-700 mt-2">
                      Kullanıcı Adı
                    </label>
                    <input
                        type="text"
                        name="user_name"
                        value={formData.user_name}
                        onChange={handleInputChange}
                        className="w-full  h-12 border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        placeholder=" Kullanıcı adı girin"
                    />
                  </div>
                  <div>
                    <label className="block text-xl font-medium text-gray-700 pt-4 pb-4">
                      Mesaj İçeriği
                    </label>
                    <textarea
                        name="content"
                        value={formData.content}
                        onChange={handleInputChange}
                        className="w-full  border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        rows="4"
                        placeholder=" Mesaj içeriği"
                    />
                  </div>
                  <button
                      onClick={handleSubmit}
                      className="w-full bg-green-600 hover:bg-green-500 text-white  text-xl font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300"
                  >
                    Kaydet
                  </button>
                </div>
              </div>
            </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8 ">
          {messages.map((message, index) => (
              <div
                  key={index}
                  className="bg-white rounded-lg shadow-lg p-6 relative text-xl cursor-pointer hover:bg-gray-100 rounded-lg shadow-md p-6 "
              >
                <button
                    className="absolute top-2 right-2 text-red-500 hover:text-red-700"
                    onClick={() => handleDelete(index)}
                >
                  ✕
                </button>
                <h3 className="text-2xl font-semibold text-blue-600">
                  {message.header}
                </h3>
                <p className="mt-2 text-lg text-gray-600">{message.content}</p>
                <p className="mt-4 text-xl text-gray-500">
                  Gönderen: {message.sender}
                </p>
                <p className="text-xs text-gray-500">
                  {message.time} {message.date}
                </p>
              </div>
          ))}
        </div>
      </div>
  )
}
import React, { useState, useEffect, useRef } from 'react'
import finishSound from '../../effect/congrulation.mp3'
import LoadingSpinner from '../LoadingSpinner'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import Header from '../Header'
import Footer from '../Footer'
import VocabolaryServices from "../../Services/VocabolaryServices";


const GrowingSquare = () => {
  const [words, setWords] = useState([])
  const [currentWords, setCurrentWords] = useState(['', '', '', ''])
  const [rectangleSize, setRectangleSize] = useState({
    width: 200,
    height: 200,
  })
  const [wordIndex, setWordIndex] = useState(0)
  const [started, setStarted] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const [GreetingScreen, setGreetingScreen] = useState(false)
  const [isFinish, setIsFinish] = useState(false)
  const [speed, setSpeed] = useState(500) // Dikdörtgen büyüme hızı
  const [duration, setDuration] = useState(1) // Egzersiz süresi (saniye)
  const [remainingTime, setRemainingTime] = useState(duration) // Kalan süre
  const token = window.localStorage.getItem('token')
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [isFirst, setIsFirst] = useState(true)
  const timerId = useRef(null)
  const [vocabolery, setVocabolery] = useState([])
  const [selectedLevel, setSelectedLevel] = useState('')
  const [isLoading, setIsLoading] = useState(true); // ✅ Yükleniyor durumu

  const vocabolaryservices = new VocabolaryServices()
  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  useEffect(() => {
    vocabolaryservices
        .getVocabolary('growingsquare')
        .then((response) => {
          setVocabolery(response.data);
          setIsLoading(false); // ✅ Yüklenme tamamlandı
        })
        .catch((error) => {
          console.error('Kategorileri alırken hata oluştu:', error);
          setIsLoading(false); // Hata durumunda da durdur
        });
  }, []);

  const handleLevelChange = (event) => {
    const level = parseInt(event.target.value);
    setSelectedLevel(level);

    const selectedLevelData = vocabolery.find(levelData => levelData.level === level);

    if (selectedLevelData) {
      setWords(selectedLevelData.data[0]?.texts || []);
    } else {
      console.error(`Seviye ${level} bulunamadı.`);
      setWords([]);
    }
  };

  const handleConfirm = () => {
    setIsConfirmed(true)
  }

  const closeScreen = () => {
    setGreetingScreen(true)
  }

  useEffect(() => {
    if (started && !isPaused && remainingTime > 0) {
      timerId.current = setTimeout(() => {
        setRemainingTime((prevTime) => prevTime - 1)
      }, 1000)
    } else if (remainingTime <= 0) {
      setStarted(false) // Egzersizi durdur
      setIsFinish(true)
      setIsFirst(false)
      new Audio(finishSound).play()
    }

    return () => clearTimeout(timerId.current)
  }, [started, isPaused, remainingTime, isFirst])

  useEffect(() => {
    let interval
    if (started && !isPaused && !isFinish) {
      // isFinish kontrolü eklendi
      interval = setInterval(() => {
        setRectangleSize((prevSize) => {
          const newWidth = prevSize.width + 30
          const newHeight = prevSize.height + 10
          if (newWidth >= 900 || newHeight >= 900) {
            return { width: 200, height: 200 } // Başlangıç boyutuna dön
          }
          return { width: newWidth, height: newHeight }
        })
        setWordIndex((prevIndex) => {
          const newIndex = prevIndex + 4
          return newIndex >= words.length ? 0 : newIndex
        })
      }, speed)
    }

    return () => clearInterval(interval)
  }, [rectangleSize, started, isPaused, wordIndex, words, speed, isFinish])

  useEffect(() => {
    setCurrentWords(words.slice(wordIndex, wordIndex + 4))
  }, [wordIndex, words])

  const handlePause = () => {
    setIsPaused(true)
  }

  const handleResume = () => {
    setIsPaused(false)
  }

  const handleStart = () => {
    setStarted(true)
    setRemainingTime(duration *60) // Direkt duration kullanımı
  }

  const handleReset = () => {
    setStarted(false)
    setIsPaused(false)
    setIsFinish(false)
    setRemainingTime(duration * 60)
    setRectangleSize({ width: 200, height: 200 })
    setWordIndex(0)
    setGreetingScreen(false)
  }

  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/eye-yoga-peripheral-vision`
  }
  const formatTime = () => {
    const minutes = Math.floor(remainingTime / 60); // Toplam dakikayı hesapla
    const seconds = remainingTime % 60; // Dakikalardan arta kalan saniyeyi hesapla

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="h-screen overflow-hidden flex flex-col items-center justify-between">
      <div className="w-full fixed top-0">
        <Header title={'Büyüyen Dikdörtgen'} returnType="eye-yoga">
          <button
              onClick={handleReset}
              className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
          >
            Sıfırla
          </button>
        </Header>

      </div>
      {isLoading ? (
          <div className="flex items-center justify-center h-full">
            <LoadingSpinner/>
          </div>
      ) : (
          <>
            <div className="text-2xl font-bold mb-4 absolute top-16 right-12 ">
        Kalan Süre: {formatTime()}
      </div>

      <div className="flex-grow flex justify-center items-center">
        <div
          className="outer-rectangle relative border-2 border-lightblue flex justify-center items-center bg-warning-300 rounded-xl"
          style={{
            width: `${rectangleSize.width}px`,
            height: `${rectangleSize.height}px`,
          }}
        >
          <div
            className="absolute font-bold text-blue text-xl "
            style={{ top: '-30px', left: '50%', transform: 'translateX(-50%)' }}
          >
            {currentWords[0]}
          </div>
          <div
            className="absolute font-bold text-blue text-xl"
            style={{
              top: '50%',
              right: '-90px',
              transform: 'translateY(-50%)',
            }}
          >
            {currentWords[1]}
          </div>
          <div
            className="absolute font-bold text-blue text-xl"
            style={{
              bottom: '-30px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            {currentWords[2]}
          </div>
          <div
            className="absolute font-bold text-blue text-xl"
            style={{
              top: '50%',
              left: '-90px',
              transform: 'translateY(-50%)',
            }}
          >
            {currentWords[3]}
          </div>
          <div className="w-3 h-3 bg-red-500 rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
        </div>
      </div>
      <div className="flex space-x-4 mb-12">
        {!started && !isFinish && (
          <button
            className="btn btn-start w-28 h-14 bg-blue-300 text-white rounded-xl text-xl hover:bg-blue-200"
            onClick={handleStart}
          >
            Başla
          </button>
        )}
        {started && !isPaused && (
          <button
            className="btn btn-pause w-28 h-14 bg-yellow-500 text-white  text-xl rounded-xl hover:bg-yellow-400"
            onClick={handlePause}
          >
            Durdur
          </button>
        )}
        {isPaused && (
          <button
            className="btn btn-resume w-28 h-14 bg-green-500 text-white  rounded-xl text-xl hover:bg-green-400"
            onClick={handleResume}
          >
            Devam Et
          </button>
        )}
      </div>
      <Footer />
          </>
          )}
      {!GreetingScreen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
          <div className="bg-gray-300 p-8 rounded-md text-center w-1/3">
            <h2 className="font-semibold text-2xl p-1">
              Büyüyen Dikdörtgen Egzersizi
            </h2>
            {/* 📚 Seviye Seçimi */}
            <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
              <label
                  htmlFor="levelSelect"
                  className="block text-gray-700 font-semibold mb-2 text-left"
              >
                📚 Seviye Seçimi:
              </label>
              <select
                  id="levelSelect"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={selectedLevel}
                  onChange={handleLevelChange}
              >
                <option value="" disabled>Seviye Seç</option>
                {vocabolery.map((levelData) => (
                    <option key={levelData.level} value={levelData.level}>
                      Seviye {levelData.level}
                    </option>
                ))}
              </select>
            </div>
            {/* Hız Ayarı */}
            <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
              <label className="block text-gray-700 font-semibold mb-2">⚡ Hız Ayarı: {speed} ms</label>
              <input
                  type="range"
                  min="300"
                  max="2000"
                  step="50"
                  value={speed}
                  onChange={(e) => setSpeed(parseInt(e.target.value))}
                  className="w-full"
              />
              <div className="flex justify-between text-sm text-gray-500 mt-1">
                <span>300 ms</span>
                <span>2000 ms</span>
              </div>
            </div>
            {/* Zaman Ayarı */}
            <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
              <label className="block text-gray-700 font-semibold mb-2">🕒 Süre: {duration} dk</label>
              <input
                  type="range"
                  min="1"
                  max="10"
                  step="1"
                  value={duration}
                  onChange={(e) => setDuration(parseInt(e.target.value))}
                  className="w-full"
              />
              <div className="flex justify-between text-sm text-gray-500 mt-1">
                <span>1 dk</span>
                <span>5 dk</span>
              </div>
            </div>

            <button
              className="bg-blue-400 text-white py-2 px-4 mt-4 rounded hover:bg-blue-300"
              onClick={closeScreen}
            >
              🚀 Başlat
            </button>
          </div>
        </div>
      )}
      {isFinish && (
        <div className="bg-gray-500 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
          <div
            className="bg-white p-8 rounded-md text-center"
            style={{ width: '300px' }}
          >
            <p style={{ fontSize: '20px' }}>
              Tebrikler! Egzersizi tamamladınız.
            </p>
            <button
              className="mt-4 bg-blue-400 hover:bg-blue-300 text-white font-bold py-2 px-4 rounded"
              onClick={handleReset}
            >
              Tekrar
            </button>
            <div>
              <button
                onClick={handleReturnDashboard}
                className="mt-4 bg-blue-400 hover:bg-blue-300 text-white font-bold py-2 px-4 rounded"
              >
                Egzersiz Sayfasına Dön
              </button>
            </div>
          </div>
        </div>
      )}
      <FullscreenAlert onConfirm={handleConfirm} />
    </div>
  )
}

export default GrowingSquare

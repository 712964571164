import React, { useState, useEffect } from 'react'
import { playCongrulationSound } from '../../effect/Congrulation'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import LoaderSimple from '../LoadPage/LoaderSimple'
import Header from '../Header'
import Footer from '../Footer'



const CornerExercise = () => {
  const [level, setLevel] = useState('Hard')
  const [awayLevel, setAwayLevel] = useState('Start')
  const [numberLeft, setNumberLeft] = useState('')
  const [numberRight, setNumberRight] = useState('')
  const [exerciseTime, setExerciseTime] = useState(1)
  const [count, setCount] = useState(100)
  const [isFinish, setIsFinish] = useState(false)
  const [exerciseStart, setExerciseStart] = useState(false)
  const [isStart, setIsStart] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const [remainingTime, setRemainingTime] = useState(exerciseTime * 1000* 60  )
  const [pauseStartTime, setPauseStartTime] = useState(null)
  const [totalPausedTime, setTotalPausedTime] = useState(0)
  const [displayTime, setDisplayTime] = useState(exerciseTime)
  const [customLevel, setCustomLevel] = useState(level); // Kullanıcıya özel level
  const [customAwayLevel, setCustomAwayLevel] = useState(awayLevel); // Kullanıcıya özel awayLevel
  const token = window.localStorage.getItem('token')
  const [isConfirmed, setIsConfirmed] = useState(false)


  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  const handleConfirm = () => {
    setIsConfirmed(true)
  }


  useEffect(() => {
    let intervalId
    let timerId
    let timeDisplayInterval

    if (exerciseStart && !isFinish && !isPaused) {
      const generateRandomNumbers = () => {
        switch (level) {
          case 'Start':
            setNumberLeft(Math.floor(Math.random() * 10))
            setNumberRight(Math.floor(Math.random() * 10))
            break
          case 'Normal':
            setNumberLeft(Math.floor(Math.random() * 90 + 10))
            setNumberRight(Math.floor(Math.random() * 90 + 10))
            break
          case 'Little-Hard':
            setNumberLeft(Math.floor(Math.random() * 900 + 100))
            setNumberRight(Math.floor(Math.random() * 900 + 100))
            break
          case 'Hard':
            setNumberLeft(Math.floor(Math.random() * 9000 + 1000))
            setNumberRight(Math.floor(Math.random() * 9000 + 1000))
            break
          default:
            setNumberLeft('')
            setNumberRight('')
        }
      }

      const startExercise = () => {
        generateRandomNumbers()
        intervalId = setInterval(generateRandomNumbers, count)

        timeDisplayInterval = setInterval(() => {
          setDisplayTime((prevTime) => {
            if (prevTime <= 0) {
              clearInterval(intervalId)
              clearInterval(timeDisplayInterval)
              playCongrulationSound()
              setIsFinish(true)
              return 0
            }
            return prevTime - 1
          })
        }, 1000)
      }

      startExercise()

      return () => {
        clearInterval(intervalId)
        clearInterval(timeDisplayInterval)
      }
    } else if (isPaused) {
      clearInterval(intervalId)
      clearInterval(timeDisplayInterval)
    }
  }, [exerciseStart, level, count, isFinish, isPaused, remainingTime])

  const handleStartExercise = () => {
    if (exerciseStart) {
      if (isPaused) {
        // Devam et
        const pauseDuration = Date.now() - pauseStartTime
        setTotalPausedTime(totalPausedTime + pauseDuration)
        setIsPaused(false)
      } else {
        // Durdur
        setPauseStartTime(Date.now())
        setIsPaused(true)
      }
    } else {
      // İlk başlangıç
      setExerciseStart(true)
      setPauseStartTime(null)
      setTotalPausedTime(0)
      setRemainingTime(exerciseTime * 1000 * 60) // Dakikayı milisaniyeye çevir
      setDisplayTime(exerciseTime * 60) // Görünen süreyi saniyeye çevir
    }
  }

  const handleResetExercise = () => {
    setExerciseStart(false)
    setIsPaused(false)
    setIsFinish(false)
    setNumberLeft('')
    setNumberRight('')
    setRemainingTime(exerciseTime * 1000)
    setDisplayTime(exerciseTime)
    setTotalPausedTime(0)
    setPauseStartTime(null)
    setIsStart(false)

  }

  const handleStart = () => {
    setIsStart(true)
  }

  const handleRepeatExercise = () => {
    setExerciseStart(false)
    setIsFinish(false)
    setRemainingTime(exerciseTime * 1000)
    setDisplayTime(exerciseTime)
    setTotalPausedTime(0)
    setPauseStartTime(null)
  }

  const getDynamicClass = (level, awayLevel, side) => {
    if (awayLevel === 'Hard' && level !== 'Hard') {
      // awayLevel 'Hard' olduğunda level'a göre sınıflar
      if (level === 'Start') return `${side}-custom-single`;
      if (level === 'Normal') return `${side}-custom-two`;
      if (level === 'Little-Hard') return `${side}-custom-three`;
       return `${side}-custom-4`;
    } else {
      // awayLevel 'Hard' değilse awayLevel durumuna göre sınıflar
      if (awayLevel === 'Start') return `${side}-custom-1`;
      if (awayLevel === 'Normal') return `${side}-custom-2`;
      if (awayLevel === 'Little-Hard') return `${side}-custom-3`;
      if (awayLevel === 'Hard') return `${side}-custom-4`;
    }
  };
  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/eye-yoga-peripheral-vision`
  }

  return (
    <div className="h-screen overflow-hidden">
      <Header title="Köşesel Egzersiz"  returnType="eye-yoga"/>
      <Footer />
      <div className="flex-col h-5/6">
        <div className="text-center text-2xl mb-4">
          {exerciseStart && <>Kalan Süre: {displayTime} saniye</>}
        </div>
        <div className="flex flex-row justify-center h-5/6 items-center m-4 custom-div">
          <div
              className={
                'flex items-center justify-end text-custom-8xl h-4/5 w-4/5 border-8 border-blue-400'
              }
          >
  <span
      className={getDynamicClass(level, awayLevel, 'pr')}
  >
    {numberLeft}
  </span>
          </div>
          <div
              className={
                'flex items-center justify-start text-custom-8xl h-4/5 w-4/5 border-8 custom-div border-blue-400'
              }
          >
  <span
      className={getDynamicClass(level, awayLevel, 'pl')}
  >
    {numberRight}
  </span>
          </div>
        </div>
        <div className="flex justify-center items-center space-x-4">
          <button
              className="text-2xl bg-blue-400 p-3 rounded-xl text-white hover:bg-blue-200"
              onClick={handleStartExercise}
          >
            {exerciseStart ? (isPaused ? 'Devam Et' : 'Durdur') : 'Başla'}
          </button>
          {exerciseStart && (
              <button
                  className="text-2xl bg-red-300 p-3 rounded-xl text-white hover:bg-red-200"
              onClick={handleResetExercise}
            >
              Sıfırla
            </button>
          )}
        </div>
      </div>
      {!isStart && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
            <div className="bg-white p-8 rounded-md text-center w-1/3 space-y-4">
              <h2 className="font-semibold text-2xl p-1">🛠️ Egzersiz Ayarları</h2>

              {/* Level Seçimi */}
              <div>
                <label className="block text-gray-700 font-semibold">Seviye:</label>
                <select
                    value={level}
                    onChange={(e) => setLevel(e.target.value)}
                    className="p-2 border rounded-md"
                >
                  <option value="Start">Başlangıç</option>
                  <option value="Normal">Normal</option>
                  <option value="Little-Hard">Orta</option>
                  <option value="Hard">Zor</option>
                </select>
              </div>

              {/* Away Level Seçimi */}
              <div>
                <label className="block text-gray-700 font-semibold">Uzaklık Seviyesi:</label>
                <select
                    value={awayLevel}
                    onChange={(e) => setAwayLevel(e.target.value)}
                    className="p-2 border rounded-md"
                >
                  <option value="Start">Başlangıç</option>
                  <option value="Normal">Normal</option>
                  <option value="Little-Hard">Orta</option>
                  <option value="Hard">Zor</option>
                </select>
              </div>


              {/* Time Control */}
              <div className="bg-white p-4 rounded-lg shadow-sm">
                <label htmlFor="speed" className="block text-gray-700 font-semibold mb-2">
                  🕒 Süre Ayarı: {exerciseTime}
                </label>
                <input
                    id="time"
                    type="range"
                    min="1"
                    max="5"
                    step="1"
                    value={exerciseTime}
                    onChange={(e) => setExerciseTime(parseInt(e.target.value))}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-blue-500"
                />
                <div className="flex justify-between text-sm text-gray-500 mt-1">
                  <span>1</span>
                  <span>5</span>
                </div>
              </div>

               {/* Speed Control */}
                <div className="bg-white p-4 rounded-lg shadow-sm">
                <label htmlFor="speed" className="block text-gray-700 font-semibold mb-2">
                  ⚡ Hız Ayarı: {count} ms
            </label>
            <input
                id="speed"
                type="range"
                min="200"
                max="2000"
                step="50"
                value={count}
                onChange={(e) => setCount(parseInt(e.target.value))}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-blue-500"
            />
            <div className="flex justify-between text-sm text-gray-500 mt-1">
              <span>Hızlı</span>
              <span>Yavaş</span>
            </div>
          </div>

              {/* Başlat Butonu */}
              <button
                  onClick={handleStart}
                  className="bg-blue-500 text-white py-2 px-4 mt-4 rounded hover:bg-blue-400"
              >
                🚀 Egzersizi Başlat
              </button>
            </div>
          </div>
      )}
      {isFinish && (
        <div className="bg-gray-300 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
          <div className="bg-white p-8 rounded-md text-center">
            <p>Tebrikler! Egzersizi tamamlandınız.</p>
            <button
              onClick={handleReturnDashboard}
              className="bg-blue-400 text-white py-2 px-4 mt-4 rounded"
            >
              Egzersiz Sayfasına Dön
            </button>
            <div>
              <button
                className="bg-blue-400 text-white py-2 px-4 mt-4 rounded"
                onClick={handleResetExercise}
              >
                Tekrarla
              </button>
            </div>
          </div>
        </div>
      )}
      <FullscreenAlert onConfirm={handleConfirm} />
    </div>
  )
}

export default CornerExercise

import './App.css'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'

import Dashboard from './Pages/Dashboard'
import User from './Pages/User'
import Communications from './Pages/Communications'
import Videos from './Pages/Videos'
import Profile from './Pages/Profile'
import Calendar from './Components/Calender'
import Educations from './Pages/Educations'
import LoginPage from './Pages/LoginPage'
import Activate from './Pages/Activate'
import GenerateCertificate from './Pages/GenerateCertificate'
import Report from './Pages/Report'
import PasswordChange from './Pages/PasswordChange'
import StudentReport from './Pages/StudentReport'
import Exercises from './Pages/Exercises'
import Navbar from './Components/Navbar'
import IsEqual from './Components/Exercises/IsEqual'
import ExercisesForAll from './Pages/ExercisesForAll'

import Takistoskop from './Components/Exercises/Takistoskop'
import GrowingCircle from './Components/Exercises/GrowingCircle'
import BlockRead from './Components/Exercises/BlockRead'
import Nanogram from './Components/Exercises/Nanogram'
import MatchPair from './Components/Exercises/MatchPair'
import RecedingNumbers from './Components/Exercises/RecedingNumbers'
import EyeYogaHorizontal from './Components/Exercises/EyeYogaHorizontal'
import Grouping from './Components/Exercises/Grouping'
import HowManyCharacter from './Components/Exercises/HowManyCharacter'
import Shading from './Components/Exercises/Shading'
import GreenLight from './Components/Exercises/GreenLight'
import ZigZag from './Components/Exercises/ZigZagYoga'
import SameOrDifferent from './Components/Exercises/SameOrDifferent'
import Follow from './Components/Exercises/Follow'
import GrowingSquare from './Components/Exercises/GrowingSquare'
import Ikara from './Components/Exercises/Ikara'
import FirstLetterSecondLetter from './Components/Exercises/FirstLetterSecondLetter'
import SentenceCreate from './Components/Exercises/SentenceCreate'
import LetterFind from './Components/Exercises/LetterFind'
import LetterComplete from './Components/Exercises/LetterComplete'
import Resfebe from './Components/Exercises/Resfebe'
import DifferentPicture from './Components/Exercises/DifferentPicture'
import CornerExercise from './Components/Exercises/CornerExercise'
import SameLetter from './Components/Exercises/SameLetter'
import FindMeaning from './Components/Exercises/FindMeaning'
import AwayWords from './Components/Exercises/AwayWords'
import SeeSort from './Components/Exercises/SeeSort'
import Vocabulary from './Components/Exercises/Vocabulary'
import FastRead from './Components/Exercises/FastRead'
import ReadUnderstandReply from './Components/Exercises/ReadUnderstandReply'
import BodyCode from './Components/Exercises/BodyCode'
import HintProverb from './Components/Exercises/HintProverb'
import RowBlockReadig from './Components/Exercises/RowBlockReadig'
import Colum from './Components/Exercises/Colum'
import UserServices from './Services/UserServices'
import ColorMistake from './Components/Exercises/ColorMistake'
import Peripheral2 from "./Components/Exercises/Peripheral2";
import ExercisesForEyeYoga from "./Pages/ExercisesForEyeYoga";
import ExercisesForSpeedReading from "./Pages/ExercisesForSpeedReading";
import ExercisesForWordRecog from "./Pages/ExercisesForWordRecog";
import ExercisesForAttenMemory from "./Pages/ExercisesForAttenMemory";
import NotFound from "./Pages/NotFound";

function App() {
  const navigate = useNavigate()
  const location = useLocation()
  const [isNavbarExpanded, setIsNavbarExpanded] = useState(true)

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!token) {
      navigate('/login')
    } else {
      const userServices = new UserServices()
      userServices
        .getUserByToken(token)
        .then((response) => {
          console.log('response', response.data)
          if (response.data.isAdmin) {
            console.log('admin case in app.js at stiation ifs')
          } else {
            console.log('else case in app.js')
            navigate('/login')
          }
        })
        .catch((error) => {
          navigate('/login')
        })
    }
  }, [navigate])

  // Navbar'ın görünmemesi gereken rotaları listeledik
  const noNavbarRoutes = [
    '/login',
    '/education/exercises/eye-yoga-peripheral-vision/:exerciseId',
    '/education/exercises/speed-reading-comprehension/:exerciseId',
    '/education/exercises/word-recognition/:exerciseId',
    '/education/exercises/attention-memory-logic-math/:exerciseId',
  ];

  const shouldShowNavbar = !noNavbarRoutes.some((route) =>
      matchRoute(route, location.pathname)
  )

  return (
    <div className="flex w-full">
      {shouldShowNavbar && (
          <div className={`transition-all duration-300 ${isNavbarExpanded ? 'w-48' : 'w-20'}`}>
            <Navbar isExpanded={isNavbarExpanded} onExpandChange={setIsNavbarExpanded} />
          </div>
      )}
      <div className={`transition-all duration-300 ${
          shouldShowNavbar
              ? (isNavbarExpanded ? 'w-[calc(100%-16rem)] ml-6' : 'w-[calc(100%-7rem)] ml-4')
              : 'w-full'
      }`}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/analyze" element={<StudentReport />} />
          <Route path="/exercises" element={<Exercises />} />
          <Route path="/activate" element={<Activate />} />
          <Route path="/certificate" element={<GenerateCertificate />} />
          <Route path="/report" element={<Report />} />
          <Route path="/password-change" element={<PasswordChange />} />
          <Route path="/users" element={<User />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/communications" element={<Communications />} />
          <Route path="/education" element={<Educations />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/education/exercises/eye-yoga-peripheral-vision" element={<ExercisesForEyeYoga />} />
          <Route path="/education/exercises/speed-reading-comprehension" element={<ExercisesForSpeedReading />} />
          <Route path="/education/exercises/word-recognition" element={<ExercisesForWordRecog />} />
          <Route path="/education/exercises/attention-memory-logic-math" element={<ExercisesForAttenMemory />} />
          {/* Dinamik rotaları burada ekleyin */}
          <Route
            path="/education/exercises/attention-memory-logic-math/takistoskop"
            element={<Takistoskop />}
          />
          <Route
            path="/education/exercises/attention-memory-logic-math/growingcircle"
            element={<GrowingCircle />}
          />
          <Route
            path="/education/exercises/speed-reading-comprehension/blockread"
            element={<BlockRead />}
          />
          <Route path="/education/exercises/attention-memory-logic-math/nanogram" element={<Nanogram />} />
          <Route
            path="/education/exercises/attention-memory-logic-math/matchpair"
            element={<MatchPair />}
          />
          <Route
            path="/education/exercises/eye-yoga-peripheral-vision/recedingnumbers"
            element={<RecedingNumbers />}
          />
          <Route
            path="/education/exercises/eye-yoga-peripheral-vision/eye-yoga-horizontal"
            element={<EyeYogaHorizontal />}
          />
          <Route path="/education/exercises/speed-reading-comprehension/grouping" element={<Grouping />} />
          <Route
            path="/education/exercises/attention-memory-logic-math/howmanycharacters"
            element={<HowManyCharacter />}
          />
          <Route path="/education/exercises/speed-reading-comprehension/shading" element={<Shading />} />
          <Route
            path="/education/exercises/attention-memory-logic-math/greenlight"
            element={<GreenLight />}
          />
          <Route path="/education/exercises/attention-memory-logic-math/zigzagyoga" element={<ZigZag />} />
          <Route
            path="/education/exercises/attention-memory-logic-math/same-or-dif"
            element={<SameOrDifferent />}
          />
          <Route path="/education/exercises/speed-reading-comprehension/follow" element={<Follow />} />

          <Route
            path="/education/exercises/eye-yoga-peripheral-vision/growingsquare"
            element={<GrowingSquare />}
          />

          <Route path="/education/exercises/attention-memory-logic-math/ikara" element={<Ikara />} />
          <Route path="/education/exercises/attention-memory-logic-math/isequal" element={<IsEqual />} />
          <Route
            path="/education/exercises/speed-reading-comprehension/firstlettersecondletter"
            element={<FirstLetterSecondLetter />}
          />
          <Route
            path="/education/exercises/word-recognition/sentence-create"
            element={<SentenceCreate />}
          />
          <Route
            path="/education/exercises/word-recognition/letter-find"
            element={<LetterFind />}
          />
          <Route
            path="/education/exercises/word-recognition/letter-complete"
            element={<LetterComplete />}
          />
          <Route path="/education/exercises/attention-memory-logic-math/resfebe" element={<Resfebe />} />
          <Route
            path="/education/exercises/attention-memory-logic-math/different-picture"
            element={<DifferentPicture />}
          />
          <Route
            path="/education/exercises/eye-yoga-peripheral-vision/cornerexercise"
            element={<CornerExercise />}
          />
          <Route
            path="/education/exercises/attention-memory-logic-math/sameletter"
            element={<SameLetter />}
          />
          <Route
            path="/education/exercises/word-recognition/findmeaning"
            element={<FindMeaning />}
          />
          <Route
            path="/education/exercises/eye-yoga-peripheral-vision/awaywords"
            element={<AwayWords />}
          />
          <Route path="/education/exercises/word-recognition/seesort" element={<SeeSort />} />
          <Route path="/education/exercises/eye-yoga-peripheral-vision/peripheral" element={<Peripheral2 />} />

          <Route
            path="/education/exercises/vocabulary"
            element={<Vocabulary />}
          />
          <Route path="/education/exercises/fast-read" element={<FastRead />} />
          <Route
            path="/education/exercises/speed-reading-comprehension/readunderstandreply"
            element={<ReadUnderstandReply />}
          />
          <Route
              path="/education/exercises/word-recognition/vocabulary"
              element={<Vocabulary />}
          />
          <Route path="/education/exercises/body-code" element={<BodyCode />} />
          <Route
            path="/education/exercises/word-recognition/hint-proverb"
            element={<HintProverb />}
          />
          <Route
            path="/education/exercises/speed-reading-comprehension/rowblockread"
            element={<RowBlockReadig />}
          />
          <Route path="/education/exercises/speed-reading-comprehension/colum" element={<Colum />} />
          <Route
            path="/education/exercises/attention-memory-logic-math/colormistake"
            element={<ColorMistake />}
          />
          <Route path="*" element={<NotFound />} />

        </Routes>

      </div>
    </div>
  )
}

// Yol eşleştirmeyi kontrol etmek için bir yardımcı fonksiyon
function matchRoute(pattern, path) {
  const regex = new RegExp(
    `^${pattern.replace(/:\w+/g, '[^/]+')}$` // Dinamik parametreleri regex'e çevirir
  )
  return regex.test(path)
}

export default App

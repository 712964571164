import React, { useEffect } from 'react';
import { FaCheckCircle } from "react-icons/fa";

/**
 * ErrorMessage Component
 * @param {string} message - Görüntülenecek hata mesajı
 * @param {function} onClose - Mesajı kapatma fonksiyonu
 */
export default function SuccessMessage({ message, onClose }) {
    useEffect(() => {
        // Mesaj 2 saniye sonra otomatik kapanır
        const timer = setTimeout(() => {
            onClose();
        }, 1000);

        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <div className="fixed top-4 right-4 z-50 flex items-center w-auto max-w-sm bg-green-500 text-white text-lg py-3 px-4 rounded-md shadow-md animate-slideIn">
            {/* Hata İkonu */}
            <FaCheckCircle className="mr-2 text-2xl" />

            {/* Mesaj İçeriği */}
            <span className="flex-1">{message}</span>

            {/* Kapatma Butonu */}
            <button
                onClick={onClose}
                className="ml-2 text-white hover:text-gray-200"
            >
                ✖
            </button>
        </div>
    );
}
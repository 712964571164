import React, { useState, useEffect, useRef} from "react";
import {playStepSound } from "../../effect/Step";
import { playCongrulationSound } from "../../effect/Congrulation";
import LoaderSimple from "../LoadPage/LoaderSimple";
import FullscreenAlert from "../LoadPage/FullscreenAlert";
import VocabolaryServices from "../../Services/VocabolaryServices";
import Footer from "../Footer"
import Header from "../Header"
import FinishScreen from "../FinishScreen";

const BlockRead = ({ dayNumber }) => {
  const exerciseName = "blockread";
  const [paragraph, setParagraph] = useState("deneme");
  const [words, setWords] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showWord, setShowWord] = useState(false);
  const [wordCountToShow, setWordCountToShow] = useState(1);
  const [isStart, setIsStart] = useState(false);
  const [isFinish, setIsFinish] = useState(false);
  const [speed, setSpeed] = useState(100); // Hız ayarını buradan yapabilirsiniz
  const [isLoading, setIsLoading] = useState(true); // Yüklenme durumu
  const token = window.localStorage.getItem("token");
  const [isFirst, setIsFirst] = useState(true);
  const [vocabolery, setVocabolery] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (!token) {
      window.location.href = "/login";
    }
  }, []);

  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleConfirm = () => {
    setIsConfirmed(true);
  };

  const vocabolaryservices = new VocabolaryServices();


  useEffect(() => {
    setWords(splitParagraphIntoWords(paragraph));
  }, [paragraph]);

  useEffect(() => {
    if (showWord && !isPaused) {
      timeoutRef.current = setTimeout(() => {
        setCurrentIndex((prevIndex) => {
          if (prevIndex + wordCountToShow >= words.length) {
            setShowWord(false);
            setIsFinish(true);
            if (isFirst) {
            }
            setIsFirst(false);
            playCongrulationSound();
            return 0;
          }
          return prevIndex + wordCountToShow;
        });
      }, speed);

      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }
  }, [currentIndex, words, showWord, wordCountToShow, speed, isPaused]);


  const splitParagraphIntoWords = (paragraph) => {
    return paragraph.split(" ");
  };

  const handleButtonClick = () => {
    setShowWord(true);
    setIsPaused(false);

  };

  const handleRepeatExercise = () => {
    setIsFinish(false);
    setShowWord(false);
    setCurrentIndex(0);
    setIsStart(false); // Egzersizi tekrar başlat
    setSelectedCategory(null);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory((prevCategory) =>
        prevCategory === category ? null : category
    );
  };

  const handleSetTitle = (text) => {
    setParagraph(text);
  };

  useEffect(() => {
    setIsLoading(true); // Yüklenme başlatılıyor
    vocabolaryservices
        .getVocabolary("vocabolary_library")
        .then((response) => {
          setVocabolery(response.data);
          setIsLoading(false); // Yüklenme tamamlandı
        })
        .catch((error) => {
          console.error("Kategorileri alırken hata oluştu:", error);
          setIsLoading(false); // Hata olsa bile yüklenmeyi durdur
        });
  }, []);

  const handleStopClick = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsPaused(true);
  };

  const handleContinueClick = () => {
    setIsPaused(false);
  };


  return (
      <div className="flex flex-col h-screen justify-center items-center">
        <div className="w-full fixed top-0">
          <Header title="Blok Okuma" returnType="speed-reading">
            <button
                onClick={handleRepeatExercise}
                className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
            >
              Sıfırla
            </button>
          </Header>
        </div>

        <div className="bg-gray-300 flex justify-center w-2/3 h-2/3 m-2">
          <div className=" bg-white flex justify-center w-2/3 m-4 items-center">
            {showWord && (
                <p style={{ textAlign: "center", fontSize: "25px" }}>
                  {words
                      .slice(currentIndex, currentIndex + wordCountToShow)
                      .join(" ")}
                </p>
            )}
          </div>
        </div>
        <div className="flex justify-center gap-2 mt-4">
          {/* Butonlar Sabit Genişlikte Tutuluyor */}
          {!showWord ? (
              <button
                  onClick={handleButtonClick}
                  className="w-32 px-6 py-2 bg-blue-500 text-white rounded-xl text-base font-medium
        transition-all duration-300 hover:bg-blue-600 hover:shadow-lg
        active:transform active:scale-95 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Başla 🚀
              </button>
          ) : (
              <div className="flex gap-2">
                <button
                    onClick={handleStopClick}
                    className="w-32 px-6 py-2 bg-red-500 text-white rounded-xl text-base font-medium
          transition-all duration-300 hover:bg-red-600 hover:shadow-lg
          active:transform active:scale-95"
                >
                  Durdur
                </button>
                <button
                    onClick={handleContinueClick}
                    className="w-32 px-6 py-2 bg-green-500 text-white rounded-xl text-base font-medium
          transition-all duration-300 hover:bg-green-600 hover:shadow-lg
          active:transform active:scale-95"
                >
                  Devam Et
                </button>
              </div>
          )}
        </div>
        <Footer/>
        {!isStart && (
            <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50 overflow-auto">
              {isLoading ? (
                  <div className="text-white text-lg font-semibold">Yükleniyor...</div>
              ) : (
                  <div className="bg-white p-6 rounded-xl shadow-lg w-full max-w-6xl">
                    <h1 className="text-3xl font-bold text-center mb-1">Blok Okuma Egzersizine Hoşgeldiniz!</h1>
                    <h2 className="text-3xl font-bold text-center mb-6">📚 Metin Kütüphanesi</h2>

                    {/* Kategori Kartları */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 overflow-auto max-h-[60vh]">
                      {[...new Set(vocabolery.map((item) => item.category))].map(
                          (category, index) => (
                              <div
                                  key={index}
                                  className="relative group rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 cursor-pointer"
                              >
                                <div
                                    onClick={() => handleCategoryClick(category)}
                                    className={`bg-gradient-to-br from-blue-500 to-blue-700 text-white p-6 h-32 flex items-center justify-center ${
                                        selectedCategory === category ? 'border-4 border-green-500' : ''
                                    }`}
                                >
                                  <h3 className="text-xl font-semibold text-center">
                                    {category}
                                  </h3>
                                </div>
                                {selectedCategory === category && (
                                    <div className="absolute inset-0 bg-white p-4 rounded-lg shadow-lg z-10 overflow-auto max-h-48">
                                      <div className="flex justify-between items-center mb-4">
                                        <h4 className="text-lg font-semibold text-gray-800">📝 Başlıklar</h4>
                                        <button
                                            onClick={() => setSelectedCategory(null)}
                                            className="text-gray-500 hover:text-red-500 transition-colors"
                                        >
                                          ❌
                                        </button>
                                      </div>
                                      <div className="space-y-2">
                                        {vocabolery
                                            .filter((item) => item.category === category)
                                            .map((item, idx) => (
                                                <div
                                                    key={idx}
                                                    onClick={() => handleSetTitle(item.text)}
                                                    className={`p-2 rounded cursor-pointer transition-colors ${
                                                        paragraph === item.text
                                                            ? 'bg-green-100 text-green-700'
                                                            : 'bg-gray-100 hover:bg-blue-50'
                                                    }`}
                                                >
                                                  {item.name}
                                                </div>
                                            ))}
                                      </div>
                                    </div>
                                )}
                              </div>
                          )
                      )}
                    </div>

                    {/* Ayarlar Bölümü */}
                    <div className="mt-8 bg-gray-100 p-4 rounded-lg shadow-inner space-y-4">
                      {/* Hız Ayarı */}
                      <div>
                        <label htmlFor="speed" className="block text-gray-700 font-semibold mb-2">
                          🕒 Hız Ayarı: {speed} ms
                        </label>
                        <input
                            id="speed"
                            type="range"
                            min="200"
                            max="2000"
                            step="50"
                            value={speed}
                            onChange={(e) => setSpeed(parseInt(e.target.value))}
                            className="w-full"
                        />
                      </div>

                      {/* Kelime Sayısı Ayarı */}
                      <div>
                        <label htmlFor="wordCount" className="block text-gray-700 font-semibold mb-2">
                          🔢 Gösterilecek Kelime Sayısı: {wordCountToShow}
                        </label>
                        <input
                            id="wordCount"
                            type="range"
                            min="1"
                            max="10"
                            value={wordCountToShow}
                            onChange={(e) => setWordCountToShow(parseInt(e.target.value))}
                            className="w-full"
                        />
                      </div>
                    </div>

                    {/* Başla Butonu */}
                    <div className="text-center mt-6">
                      <button
                          onClick={() => setIsStart(true)}
                          className="bg-green-500 text-white px-6 py-2 rounded-lg text-lg hover:bg-green-400 transition-colors"
                      >
                        🚀 Devam
                      </button>
                    </div>
                  </div>
              )}
            </div>
        )}

        {isFinish && <FinishScreen onRestart={handleRepeatExercise} returnType="speed-reading" />}

        <FullscreenAlert onConfirm={handleConfirm} />
      </div>
  );
};

export default BlockRead;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HomeIcon, RefreshCw } from 'lucide-react';
import loginSvg from '../assets/DifPic/gelistrio.svg';

const NotFound = () => {
    const [countdown, setCountdown] = useState(5);
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        if (countdown === 0) {
            clearInterval(timer);
            navigate('/');
        }

        return () => clearInterval(timer);
    }, [countdown, navigate]);

    return (
        <div className="fixed inset-0 bg-gradient-to-br from-gray-50 via-blue-50 to-indigo-50 flex flex-col items-center justify-center p-4">
            {/* Logo container with responsive sizing */}
            <div className="w-32 md:w-48 lg:w-64 mb-8">
                <img
                    src={loginSvg}
                    alt="Gelistrio Logo"
                    className="w-full h-auto object-contain filter drop-shadow-lg hover:scale-105 transition-transform duration-300"
                />
            </div>

            {/* Content container with max height constraints */}
            <div className="max-w-md w-full bg-white/90 backdrop-blur-sm rounded-3xl shadow-2xl p-6 md:p-8 text-center relative overflow-hidden">
                {/* Decorative elements */}
                <div className="absolute -top-10 -right-10 w-24 h-24 md:w-32 md:h-32 bg-indigo-50 rounded-full opacity-50" />
                <div className="absolute -bottom-10 -left-10 w-24 h-24 md:w-32 md:h-32 bg-blue-50 rounded-full opacity-50" />

                {/* Main content with adjusted spacing */}
                <div className="relative z-10">
                    <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 text-transparent bg-clip-text mb-2 animate-bounce">404</h1>
                    <div className="w-16 h-1 bg-gradient-to-r from-blue-600 to-indigo-600 mx-auto mb-4 animate-pulse rounded-full"></div>
                    <h2 className="text-xl md:text-2xl font-semibold text-gray-800 mb-3">Sayfa Bulunamadı</h2>
                    <p className="text-sm md:text-base text-gray-600 mb-6 leading-relaxed">
                        Aradığınız sayfa mevcut değil veya taşınmış olabilir.
                        <br />
                        <span className="font-medium bg-gradient-to-r from-blue-600 to-indigo-600 text-transparent bg-clip-text">
                            {countdown} saniye
                        </span>{' '}
                        sonra anasayfaya yönlendirileceksiniz.
                    </p>

                    <div className="flex flex-col sm:flex-row gap-3 justify-center items-center">
                        <button
                            onClick={() => navigate('/')}
                            className="w-full sm:w-auto flex items-center justify-center gap-2 bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700 text-white font-semibold py-2 px-4 rounded-xl transition-all duration-300 transform hover:scale-105 shadow-lg text-sm md:text-base"
                        >
                            <HomeIcon size={18} />
                            Anasayfaya Dön
                        </button>

                        <button
                            onClick={() => window.location.reload()}
                            className="w-full sm:w-auto flex items-center justify-center gap-2 border-2 border-blue-600 text-blue-600 hover:bg-blue-50 font-semibold py-2 px-4 rounded-xl transition-all duration-300 text-sm md:text-base"
                        >
                            <RefreshCw size={18} className="animate-spin-slow" />
                            Sayfayı Yenile
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
import React, { useState, useEffect } from 'react'
import { playCongrulationSound } from '../../effect/Congrulation'
import VocabolaryServices from "../../Services/VocabolaryServices";
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import Footer from '../Footer'
import Header from '../Header'

const Follow = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isFinish, setIsFinish] = useState(false)
  const [exerciseStarted, setExerciseStarted] = useState(false)
  const [text, setText] = useState('')
  const words = text.split(' ')
  const [index, setIndex] = useState(0)
  const [timerSpeed, setTimerSpeed] = useState(200)
  const [isStart, setIsStart] = useState(false)
  const [currentPage, setCurrentPage] = useState(0) // Sayfa numarası için state
  const [title, setTitle] = useState('')
  const token = window.localStorage.getItem('token')
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [vocabolery, setVocabolery] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);


  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  const handleConfirm = () => {
    setIsConfirmed(true)
  }

  const handleStart = () => {
    setIsStart(true)
  }

  const vocabolaryservices = new VocabolaryServices();

  useEffect(() => {
    vocabolaryservices
        .getVocabolary("vocabolary_library")
        .then((response) => {
          setVocabolery(response.data);
        })
        .catch((error) => {
          console.error("Kategorileri alırken hata oluştu:", error);
        });
  }, []);

  useEffect(() => {
    let timer
    if (!isFinish && exerciseStarted) {
      timer = setInterval(() => {
        setIndex((prevIndex) => {
          const newIndex = prevIndex + 1
          // Her sayfa için kelime sayısı (örnek: 8 satır * 12 kelime)
          const wordsPerPage = 8 * 12

          // Sadece çizgi mevcut sayfayı geçerse sayfa değiştirilsin
          if (
              Math.floor(newIndex / wordsPerPage) >
              Math.floor(prevIndex / wordsPerPage)
          ) {
            setCurrentPage((prevPage) => prevPage + 1)
          }

          if (newIndex < words.length) {
            return newIndex
          } else {
            clearInterval(timer)
            setIsFinish(true)
            playCongrulationSound()
            return words.length
          }
        })
      }, timerSpeed / 12)
    }
    return () => clearInterval(timer)
  }, [isFinish, timerSpeed, words.length, exerciseStarted])
  const startExercise = () => {
    setExerciseStarted(true)
  }

  const stopExercise = () => {
    setExerciseStarted(false)
  }

  const restartExercise = () => {
    setIndex(0)
    setIsFinish(false)
    setCurrentPage(0)
    setExerciseStarted(false)
    setIsStart(false)
    setText('')
    setSelectedCategory(null)
  }

  const restartFollow = () => {
    setIndex(0)
    setIsFinish(false)
    setCurrentPage(0)
    setExerciseStarted(false)
  }

  const handleCategoryClick = (category) => {
    setSelectedCategory((prevCategory) =>
        prevCategory === category ? null : category
    );
  };

  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/speed-reading-comprehension`
  }

  // Her sayfada gösterilecek satır sayısı
  const linesPerPage = 8
  // Her satırda gösterilecek kelime sayısı
  const wordsPerLine = 12

  // Metni satırlara böl
  const lines = text
      .split(' ')
      .reduce((acc, word, i) => {
        const lineIndex = Math.floor(i / wordsPerLine)
        if (!acc[lineIndex]) {
          acc[lineIndex] = []
        }
        acc[lineIndex].push(word)
        return acc
      }, [])
      .map((line) => line.join(' '))

  // Satırları sayfalara böl
  const pages = lines.reduce((acc, line, i) => {
    const pageIndex = Math.floor(i / linesPerPage)
    if (!acc[pageIndex]) {
      acc[pageIndex] = []
    }
    acc[pageIndex].push(line)
    return acc
  }, [])

  useEffect(() => {
    setIsLoading(true); // Yüklenme başlatılıyor
    vocabolaryservices
        .getVocabolary("vocabolary_library")
        .then((response) => {
          setVocabolery(response.data);
          setIsLoading(false); // Yüklenme tamamlandı
        })
        .catch((error) => {
          console.error("Kategorileri alırken hata oluştu:", error);
          setIsLoading(false); // Hata olsa bile yüklenmeyi durdur
        });
  }, []);

  return (
      <div className="flex flex-col h-screen items-center justify-center">
        <div className="w-full absolute top-0">
          <Header title={'İzleyici'} returnType="speed-reading">
            <button
                onClick={restartExercise}
                className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
            >
              Sıfırla
            </button>
            </Header>
        </div>
        <div className="absolute top-24 font-semibold text-xl shadow-xl p-3 rounded-xl">
          {title}
        </div>

        <div className="m-6 h-3/4 w-full flex justify-center items-center border-4 overflow-auto">
          <div className="inline-block  text-xl break-words  relative">
            {pages[currentPage].map((line, lineIndex) => {
              const absoluteLineIndex = currentPage * linesPerPage + lineIndex
              return (
                  <div
                      key={lineIndex}
                      id={`line-${absoluteLineIndex}`}
                      className="relative mb-4"
                  >
                    {line.split(' ').map((word, wordIndex) => {
                      const absoluteIndex =
                          absoluteLineIndex * wordsPerLine + wordIndex
                      return (
                          <span
                              key={wordIndex}
                              className={`word mr-1 ${
                                  absoluteIndex <= index ? 'underline-red' : ''
                              }`}
                          >
                      {word}
                    </span>
                      )
                    })}
                    {absoluteLineIndex === Math.floor(index / wordsPerLine) && (
                        <div
                            className="absolute left-0 bottom-0 h-1 bg-red-500"
                            style={{
                              width: (() => {
                                const wordElements = document.querySelectorAll(
                                    `#line-${absoluteLineIndex} .word`
                                )
                                let totalWidth = 0
                                for (
                                    let i = 0;
                                    i <= index % wordsPerLine && i < wordElements.length;
                                    i++
                                ) {
                                  const wordWidth =
                                      wordElements[i].getBoundingClientRect().width
                                  if (i === index % wordsPerLine) {
                                    totalWidth += wordWidth / 2 + 5
                                  } else {
                                    totalWidth += wordWidth + 5
                                  }
                                }
                                return `${totalWidth}px`
                              })(),
                              transition: 'width 0.1s linear',
                            }}
                        />
                    )}
                  </div>
              )
            })}
          </div>
        </div>

        <div className="mb-2">
          {exerciseStarted ? (
              <button
                  onClick={stopExercise}
                  className="bg-red-300 text-white p-2 text-xl mb-3 rounded-xl hover:bg-red-300"
              >
                Durdur
              </button>
          ) : (
              <button
                  onClick={startExercise}
                  className="bg-blue-300 text-white p-2 text-xl mb-3 rounded-xl hover:bg-blue-300"
              >
                Başla
              </button>
          )}
          {exerciseStarted && (
              <button
                  onClick={restartFollow}
                  className="bg-blue-300 text-white p-2 text-xl mb-3 rounded-xl ml-2 hover:bg-blue-300"
              >
                Yeniden Başlat
              </button>
          )}
        </div>
        <Footer />
        {!isStart && (
            <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50 overflow-auto">
              {isLoading ? (
                  <div className="text-white text-lg font-semibold">Yükleniyor...</div>
              ) : (
                  <div className="bg-white p-6 rounded-xl shadow-lg w-full max-w-6xl">
                    <h1 className="text-3xl font-bold text-center mb-1">İzleyici Egzersizine Hoşgeldiniz!</h1>
                    <h2 className="text-3xl font-bold text-center mb-6">📚 Metin Kütüphanesi</h2>

                    {/* Kategori Kartları */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 overflow-auto max-h-[60vh]">
                      {[...new Set(vocabolery.map((item) => item.category))].map(
                          (category, index) => (
                              <div
                                  key={index}
                                  className="relative group rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 cursor-pointer"
                              >
                                <div
                                    onClick={() => handleCategoryClick(category)}
                                    className={`bg-gradient-to-br from-blue-500 to-blue-700 text-white p-6 h-32 flex items-center justify-center ${
                                        selectedCategory === category ? 'border-4 border-green-500' : ''
                                    }`}
                                >
                                  <h3 className="text-xl font-semibold text-center">
                                    {category}
                                  </h3>
                                </div>
                                {selectedCategory === category && (
                                    <div className="absolute inset-0 bg-white p-4 rounded-lg shadow-lg z-10 overflow-auto max-h-48">
                                      <div className="flex justify-between items-center mb-4">
                                        <h4 className="text-lg font-semibold text-gray-800">📝 Başlıklar</h4>
                                        <button
                                            onClick={() => setSelectedCategory(null)}
                                            className="text-gray-500 hover:text-red-500 transition-colors"
                                        >
                                          ❌
                                        </button>
                                      </div>
                                      <div className="space-y-2">
                                        {vocabolery
                                            .filter((item) => item.category === category)
                                            .map((item, idx) => (
                                                <div
                                                    key={idx}
                                                    onClick={() => setText(item.text)}
                                                    className={`p-2 rounded cursor-pointer transition-colors ${
                                                        text === item.text
                                                            ? 'bg-green-100 text-green-700'
                                                            : 'bg-gray-100 hover:bg-blue-50'
                                                    }`}
                                                >
                                                  {item.name}
                                                </div>
                                            ))}
                                      </div>
                                    </div>
                                )}
                              </div>
                          )
                      )}
                    </div>

                    {/* Ayarlar Bölümü */}
                    <div className="mt-8 bg-gray-100 p-4 rounded-lg shadow-inner space-y-4">
                      {/* Hız Ayarı */}
                      <div>
                        <label htmlFor="speed" className="block text-gray-700 font-semibold mb-2">
                          🕒 Hız Ayarı: {timerSpeed} ms
                        </label>
                        <input
                            id="speed"
                            type="range"
                            min="200"
                            max="2000"
                            step="50"
                            value={timerSpeed}
                            onChange={(e) => setTimerSpeed(parseInt(e.target.value))}
                            className="w-full"
                        />
                      </div>

                    </div>

                    {/* Başla Butonu */}
                    <div className="text-center mt-6">
                      <button
                          onClick={() => handleStart()}
                          className="bg-green-500 text-white px-6 py-2 rounded-lg text-lg hover:bg-green-400 transition-colors"
                      >
                        🚀 Devam
                      </button>
                    </div>
                  </div>
              )}
            </div>
        )}
        {isFinish && (
            <div className="bg-gray-400 bg-opacity-50 flex items-center justify-center fixed inset-0">
              <div className="bg-white p-8 rounded-md text-center">
                <p>Tebrikler! Egzersizi tamamladınız.</p>
                <button
                    onClick={handleReturnDashboard}
                    className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-200"
                >
                  Egzersiz Sayfasına Dön
                </button>
                <div>
                  <button
                      onClick={restartExercise}
                      className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-200"
                  >
                    Tekrarla
                  </button>
                </div>
              </div>
            </div>
        )}
        <FullscreenAlert onConfirm={handleConfirm} />
      </div>
  )
}

export default Follow

import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import TaskListServices from "../Services/TaskList";
import SuccessMessage from "./SuccessMessage";

function MyCalendar() {
  const [date, setDate] = useState(new Date());
  const [task, setTask] = useState("");
  const [tasks, setTasks] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const onChange = (selectedDate) => {
    setDate(selectedDate);
  };

  const handleTaskChange = (event) => {
    setTask(event.target.value);
  };

  const taskServices = new TaskListServices();

  useEffect(() => {
    taskServices
      .getTask("")
      .then((response) => {
        if (response.status === 200) {
          const groupedTasks = {};
          response.data.forEach((task) => {
            const taskDate = task.date;
            if (!groupedTasks[taskDate]) {
              groupedTasks[taskDate] = [];
            }
            groupedTasks[taskDate].push(task);
          });
          setTasks(groupedTasks);
        }
      })
      .catch((error) => {
        console.error("Error fetching tasks:", error);
      });
  }, []);

  const addTask = () => {
    if (task === "") return;

    const taskDate = date.toLocaleDateString();
    taskServices
      .AddTask({ content: task, date: taskDate }, "")
      .then((response) => {
        const newTask = {
          content: task,
          task_id: response.data.id,
          date: taskDate,
        };
        const newTasks = { ...tasks };
        if (!newTasks[taskDate]) {
          newTasks[taskDate] = [];
        }
        newTasks[taskDate].push(newTask);
        setTasks(newTasks);
        setTask("");
        setSuccessMessage("Görev başarıyla eklendi!");
      })
      .catch((error) => {
        console.error("Error adding task:", error);
      });
  };

  const removeTask = (taskId) => {
    const taskDate = date.toLocaleDateString();

    taskServices
      .delTask(taskId, localStorage.getItem("token"))
      .then((response) => {
        if (response.status === 200) {
          setTasks((prevTasks) => {
            const newTasks = { ...prevTasks };
            if (newTasks[taskDate]) {
              newTasks[taskDate] = newTasks[taskDate].filter(
                (task) => task.task_id !== taskId
              );
            }
            return newTasks;
          });
        }
      })
      .catch((error) => {
        console.error(
          "Error deleting task:",
          error.response?.data || error.message
        );
      });
  };

  return (
    <div className="flex flex-col md:flex-row gap-8 p-6 md:p-12 bg-gray-50 min-h-screen">
      {/* Takvim Bölümü */}
      <div className="flex-1 max-w-md mx-auto md:mx-0">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-bold mb-4 text-center">📅 Takvim</h2>
          <Calendar
            onChange={onChange}
            value={date}
            className="w-full border border-gray-300 rounded-lg shadow-sm"
          />
        </div>
      </div>

      {/* Görevler Bölümü */}
      <div className="flex-1 max-w-lg mx-auto md:mx-0">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-bold mb-4 text-center">✅ Görevler</h2>
          <p className="text-center text-gray-600 mb-4">
            Seçili Tarih: <strong>{date.toLocaleDateString()}</strong>
          </p>
          <div className="flex items-center gap-2 mb-4">
            <input
              type="text"
              placeholder="Görevi girin"
              value={task}
              onChange={handleTaskChange}
              className="flex-1 border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              onClick={addTask}
              className="bg-blue-500 hover:bg-blue-600 text-white font-medium px-4 py-2 rounded-lg focus:outline-none"
            >
              Ekle
            </button>
          </div>
          {/* Görev Listesi */}
          <div className="max-h-60 overflow-y-auto border-t border-gray-200 pt-4">
            {tasks[date.toLocaleDateString()] ? (
              <ul className="space-y-2">
                {tasks[date.toLocaleDateString()].map((task) => (
                  <li
                    key={task.task_id}
                    className="flex justify-between items-center bg-gray-100 rounded-lg px-4 py-2"
                  >
                    <span className="text-gray-800">{task.content}</span>
                    <button
                      onClick={() => removeTask(task.task_id)}
                      className="text-red-500 hover:text-red-700 focus:outline-none"
                    >
                      Sil
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-center text-gray-500">Görev yok</p>
            )}
          </div>
        </div>
      </div>
      {/* Başarı Mesajı */}
      {successMessage && (
        <SuccessMessage
          message={successMessage}
          onClose={() => setSuccessMessage("")}
        />
      )}
    </div>
  );
}

export default MyCalendar;

import React, { useState, useEffect, useRef } from 'react'
import { playCorrectSound } from '../../effect/Correct'
import { playInCorrectSound } from '../../effect/Incorrect'
import { playCongrulationSound } from '../../effect/Congrulation'
import LoaderSimple from '../LoadPage/LoaderSimple'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import { CircleCheckBig, CircleX } from 'lucide-react'
import Header from '../Header'
import Footer from '../Footer'
import VocabolaryServices from "../../Services/VocabolaryServices";


const Takistoskop = () => {
  const token = window.localStorage.getItem('token')
  const [isLoading, setIsLoading] = useState(true)
  const [words, setWords] = useState([''])
  const [originalWords, setOriginalWords] = useState(['']) // Keep original word order

  const [visibilityTime, setVisibilityTime] = useState(40)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const inputRef = useRef(null)
  const [selectedLevel, setSelectedLevel] = useState(1);
  const [currentWordIndex, setCurrentWordIndex] = useState(0)
  const [typedWord, setTypedWord] = useState('')
  const [isCorrect, setIsCorrect] = useState(null)
  const [isUnCorrect, setIsUnCorrect] = useState(null)
  const [showText, setShowText] = useState(false)
  const [showInputArea, setShowInputArea] = useState(false)
  const [isStart, setIsStart] = useState(false)
  const [isBorder, setIsBorder] = useState(false)
  const [isGame, setIsGame] = useState(false)
  const [isFinish, setIsFinish] = useState(false)
  const [correctAnswer, setCorrectAnswer] = useState(0)
  const [incorrectAnswer, setIncorrectAnswer] = useState(0)
  const [isRandomMode, setIsRandomMode] = useState(false) // Yeni state: isRandomMode
  const [wordPosition, setWordPosition] = useState({ x: '50%', y: '50%' });
  const [vocabolery, setVocabolery] = useState([]);
  const timerRef = useRef(null); // Zamanlayıcıları tutmak için ref ekleyelim


  const vocabolaryservices = new VocabolaryServices();

  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };




  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  const handleConfirm = () => {
    setIsConfirmed(true)
  }



  useEffect(() => {
    setIsLoading(true);
    vocabolaryservices
        .getVocabolary("takistoskop")
        .then((response) => {
          setVocabolery(response.data);

          // Set initial words for level 1
          const initialLevelData = response.data.find(levelData => levelData.level === 1);
          if (initialLevelData) {
            const initialWords = initialLevelData.data[0].words;
            setOriginalWords(initialWords);
            setWords(shuffleArray(initialWords));
          }

          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Kategorileri alırken hata oluştu:", error);
          setIsLoading(false);
        });
  }, []);



  const handleLevelChange = (event) => {
    setSelectedLevel(parseInt(event.target.value));

    // Seçilen seviyeye ait kelimeleri bul
    const selectedLevelData = vocabolery.find(levelData => levelData.level === selectedLevel);

    // Eğer seviye bulunduysa, kelimeleri words state'ine ata
    if (selectedLevelData) {
      setWords(selectedLevelData.data[0].words);
    } else {
      // Seviye bulunamadıysa, bir hata mesajı gösterebilir veya varsayılan bir kelime listesi kullanabilirsiniz.
      console.error(`Seviye ${selectedLevel} bulunamadı.`);
      setWords([]); // veya varsayılan kelime listesi
    }

  };

  useEffect(() => {
    if (isRandomMode && showText) {
      const containerWidth = document.querySelector('.word-container')?.offsetWidth;
      const containerHeight = document.querySelector('.word-container')?.offsetHeight;
      const wordWidth = document.querySelector('.word')?.offsetWidth;
      const wordHeight = document.querySelector('.word')?.offsetHeight;

      if (containerWidth && containerHeight && wordWidth && wordHeight) {
        const maxX = containerWidth - wordWidth;
        const maxY = containerHeight - wordHeight;
        const randomX = Math.random() * maxX;
        const randomY = Math.random() * maxY;

        setWordPosition({ x: `${randomX}px`, y: `${randomY}px` });
      }
    } else {
      setWordPosition({ x: '50%', y: '50%' });
    }
  }, [isRandomMode, showText]);

  useEffect(() => {
    if (showInputArea && inputRef.current) {
      inputRef.current.focus()
    }
  }, [showInputArea])


  const borderSos = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    setIsBorder(true);
    timerRef.current = setTimeout(() => {
      setIsBorder(false);
      timerRef.current = setTimeout(() => {
        setIsBorder(true);
        timerRef.current = setTimeout(() => {
          setIsBorder(false);
          timerRef.current = setTimeout(() => {
            setShowText(true);
            timerRef.current = setTimeout(() => {
              setShowText(false);
              setShowInputArea(true);
              timerRef.current = null;
            }, visibilityTime);
          }, 200);
        }, 200);
      }, 200);
    }, 200);
  };

  console.log(visibilityTime)
  const handleNewGame = () => {
    if (isFinish) return

    setShowInputArea(false)
    setIsCorrect(null)
    setIsUnCorrect(null)
    borderSos()
    setIsGame(true)
  }

  const handleStart = () => {
    // Seçili level için kelimeleri yükle
    const selectedLevelData = vocabolery.find(levelData => levelData.level === selectedLevel);
    if (selectedLevelData) {
      const selectedLevelWords = selectedLevelData.data[0].words;
      setOriginalWords(selectedLevelWords);
      setWords(shuffleArray(selectedLevelWords));
    }
    setIsStart(true);
  };

  const handleInputChange = (event) => {
    const lowercaseValue = event.target.value.toLocaleLowerCase('tr')
    setTypedWord(lowercaseValue)
  }
  //
  // const handleRestart = () => {
  //   setCurrentWordIndex(0)
  //   setTypedWord('')
  //   setIsCorrect(null)
  //   setIsUnCorrect(null)
  //   setShowInputArea(false)
  //   setIsGame(false)
  //   setIsFinish(false)
  //   setCorrectAnswer(0)
  //   setIncorrectAnswer(0)
  // }

  const resetGame = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    setCurrentWordIndex(0);
    setIsStart(false)
    setTypedWord('');
    setIsCorrect(null);
    setIsUnCorrect(null);
    setShowText(false);
    setShowInputArea(false);
    setIsGame(false);
    setIsFinish(false);
    setCorrectAnswer(0);
    setIncorrectAnswer(0);
    setVisibilityTime(1000)
    setIsRandomMode(false)
    setOriginalWords([""])
    setWords([""])
    // Shuffle words again for new game
  };

  const handleAnswerCheck = () => {
    if (isFinish) return

    if (words[currentWordIndex].toLocaleLowerCase('tr') === typedWord.toLocaleLowerCase('tr')) {
      playCorrectSound()
      setIsCorrect(true)
      setCorrectAnswer(correctAnswer + 1)
      console.log('Cevap Doğru')
    } else {
      playInCorrectSound()
      setIsUnCorrect(true)
      setIncorrectAnswer(incorrectAnswer + 1)
      console.log('Cevap Yanlış')
    }
    setShowInputArea(false)
    setIsGame(false)
    setCurrentWordIndex(currentWordIndex + 1)
    setTypedWord('')

    if (currentWordIndex === words.length - 1) {
      playCongrulationSound()
      setIsFinish(true)
    }
  }

  useEffect(() => {
    if (isRandomMode && showText) {
      const intervalId = setInterval(() => {
        setShowText(false)
        setShowText(true)
      }, 1000)

      return () => clearInterval(intervalId)
    }
  }, [isRandomMode, showText])


  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/attention-memory-logic-math`
  }
  return (
    <div className="h-screen">
      <Header title="Takistoskop Egzersizi" returnType="attention-memory">
        <button
            onClick={resetGame}
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
        >
          Sıfırla
        </button>
      </Header>
      {/* Footer Kısmı*/}
      <div className="fixed inset-x-0 bottom-0">
        <Footer />
      </div>
      <div className="absolute top-20 right-12  h-12 w-32 border-2  border-gray-300 bg-white flex items-center justify-center">
        <div className=" flex space-x-8">
          <span className="flex gap-2 items-center">
            <CircleCheckBig size={20} color="#14cb1f" strokeWidth={1.75} />
            {correctAnswer}
          </span>

          <span className="flex gap-2 items-center">
            <CircleX size={20} strokeWidth={1.75} color="#ff0000" />
            {incorrectAnswer}
          </span>
        </div>
      </div>
      <div className="flex-col h-5/6  relative">
        <div
            className={`word-container bg-gray-300 mt-40 w-2/3 h-1/2 ${isCorrect && 'bg-green-500'} ${isUnCorrect && 'bg-red-500'} w-1/2 h-1/4 m-auto flex justify-center items-center ${isBorder && 'border-blue-500 border-4'}`}
            style={{
              position: 'relative',
              overflow: 'hidden',
              padding: '8px',
            }}
        >
          {showText && (
              <div
                  className="word absolute"
                  style={{
                    left: isRandomMode ? wordPosition.x : '50%',
                    top: isRandomMode ? wordPosition.y : '50%',
                    transform: isRandomMode ? 'none' : 'translate(-50%, -50%)',
                    fontSize: '1.6rem',
                  }}
              >
                {words[currentWordIndex].toLocaleLowerCase('tr')}
              </div>
          )}
      </div>

      <div className="w-1/2 h-1/4 m-auto flex justify-center items-center">
        {/* Bu alan metin görünüp kaybolduktan sonra gözükmesi ve sonrasında yeni oyun başladığında yeniden kaybolması için */}
        {showInputArea && (
            <div className="flex-col bg-gray-300 w-10/12 h-3/4 flex justify-center items-center">
              <label className="mb-3" htmlFor="">
                Gördüğünüz kelimeyi yazınız.
              </label>
              <input
                  ref={inputRef}
                  className="w-10/12 focus:outline-none text-center rounded-sm"
                  type="text"
                  placeholder="Buraya yazı yazın"
                  value={typedWord.toLocaleLowerCase('tr')}
                  onChange={handleInputChange}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                    handleAnswerCheck()
                  }
                }}
              />
              <button
                className="bg-blue-400 text-xl hover:bg-blue text-white py-2 px-4 mt-4 rounded"
                onClick={handleAnswerCheck}
              >
                Tamam
              </button>
            </div>
          )}
          {!isGame && (
            <button
              className="bg-blue-300 text-white text-xl py-2 px-4 mt-2 rounded hover:bg-blue-200"
              onClick={handleNewGame}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleNewGame()
                }
              }}
              autoFocus
            >
              Başla
            </button>
          )}
        </div>
        <div className="h-1/4 flex items-center justify-center">
          {/* Bu alan, isGame false ise gözükecek, true ise kaybolacak (yani buton) */}
        </div>
      </div>
      {!isStart && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
            <div className="bg-gray-300 p-8 rounded-md text-center max-w-md w-full">
              <h1 className="font-semibold text-2xl mb-6 text-gray-800">
                Takistoskop Egzersizi
              </h1>

              <div className="space-y-6">
                {/* Level Selection */}
                <div className="bg-white p-4 rounded-lg shadow-sm">
                  <label htmlFor="levelSelect" className="block text-left text-gray-700 font-semibold mb-2">
                    📚 Seviye Seçimi:
                  </label>
                  <select
                      id="levelSelect"
                      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                      value={selectedLevel}
                      onChange={handleLevelChange}
                  >
                    {vocabolery.map(levelData => (
                        <option key={levelData.level} value={levelData.level}>
                          Seviye {levelData.level}
                        </option>
                    ))}
                  </select>
                </div>

                {/* Speed Control */}
                <div className="bg-white p-4 rounded-lg shadow-sm">
                  <label htmlFor="speed" className="block text-gray-700 font-semibold mb-2">
                    🕒 Hız Ayarı: {visibilityTime} ms
                  </label>
                  <input
                      id="speed"
                      type="range"
                      min="40"
                      max="1000"
                      step="10"
                      value={visibilityTime}
                      onChange={(e) => setVisibilityTime(parseInt(e.target.value))}
                      className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-blue-500"
                  />
                  <div className="flex justify-between text-sm text-gray-500 mt-1">
                    <span>Hızlı</span>
                    <span>Yavaş</span>
                  </div>
                </div>

                {/* Random Mode Toggle */}
                <div className="bg-white p-4 rounded-lg shadow-sm">
                  <label className="flex items-center justify-center gap-3 cursor-pointer">
                    <input
                        type="checkbox"
                        checked={isRandomMode}
                        onChange={(e) => setIsRandomMode(e.target.checked)}
                        className="form-checkbox h-5 w-5 text-blue-600 rounded"
                    />
                    <span className="text-gray-700 font-semibold">
              🎲 Rastgele Konum Modu
            </span>
                  </label>
                  {isRandomMode && (
                      <p className="text-sm text-gray-600 mt-2 italic">
                        Kelimeler ekranda rastgele konumlarda görünecek
                      </p>
                  )}
                </div>
              </div>

              <button
                  className="mt-8 bg-blue-500 text-white text-xl py-3 px-6 rounded-lg hover:bg-blue-600 transition-colors duration-200 w-full"
                  onClick={handleStart}
              >
                Başla
              </button>
            </div>
          </div>
      )}
      {isFinish && (
          <div className="bg-gray-300 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
            <div className="bg-white p-8 rounded-md text-center">
              <p>Tebrikler! Egzersizi tamamladınız.</p>
              <p>Doğru: {correctAnswer}</p>
              <p>Yanlış: {incorrectAnswer}</p>
              <div>
                <button
                    className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-200"
                    onClick={resetGame} // Call handleRestart to reset the game
                >
                  Tekrarla
                </button>
              </div>
              <button
                  className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-200"
                  onClick={handleReturnDashboard}
            >
              Egzersiz Sayfasına Dön
            </button>
          </div>
        </div>
      )}
      <FullscreenAlert onConfirm={handleConfirm} />
    </div>
  )
}

export default Takistoskop

import React from 'react'

const handleReturnDashboard = (type) => {
  switch (type) {
    case 'eye-yoga':
      window.location.href = `/education/exercises/eye-yoga-peripheral-vision`
      break
    case 'speed-reading':
      window.location.href = `/education/exercises/speed-reading-comprehension`
      break
    case 'word-recognition':
      window.location.href = `/education/exercises/word-recognition`
      break
    case 'attention-memory':
      window.location.href = `/education/exercises/attention-memory-logic-math`
      break
    default:
      window.location.href = `/education/exercises`
  }
}

const Header = ({ title, children, returnType }) => (
    <div className="drop-shadow-lg bg-gray-200 rounded-lg p-2">
      <header className="w-full relative flex items-center justify-between">
        {/* Sol taraftaki butonlar */}
        <div className="flex items-center space-x-4">
          <button
              onClick={() => handleReturnDashboard(returnType)}
              className="bg-special_green text-white px-3 py-1 rounded hover:bg-gray-400"
          >
            Geri Dön
          </button>
          {children}
        </div>

        {/* Başlık ortalanmış */}
        <h1 className="absolute left-1/2 transform -translate-x-1/2 text-3xl font-semibold">
          {title}
        </h1>
      </header>
    </div>
)

export default Header
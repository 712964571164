import React, { useState, useEffect } from 'react'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import Footer from '../Footer'
import Header from '../Header'
import VocabolaryServices from "../../Services/VocabolaryServices";


const Grouping = ({ dayNumber }) => {
  const [title, setTitle] = useState('')
  const [text, setText] = useState('')
  const [splitValue, setSplitValue] = useState(1)
  const [wordsPerLine, setWordsPerLine] = useState(6)
  const linesPerPage = 10
  const [splittedPages, setSplittedPages] = useState([])
  const [timer, setTimer] = useState({ minutes: 0, seconds: 0 })
  const [isRunning, setIsRunning] = useState(false)
  const [showIndex, setShowIndex] = useState(0)
  const [isFinish, setIsFinish] = useState(false)
  const [isStart, setIsStart] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const token = window.localStorage.getItem('token')
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [vocabolery, setVocabolery] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Yüklenme durumu

  // Egzersiz hızı ve kelime grubu seçimi için state
  const [exerciseSpeed, setExerciseSpeed] = useState(600)
  const [selectedWordGroup, setSelectedWordGroup] = useState(1)

  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  const vocabolaryservices = new VocabolaryServices();



  const handleConfirm = () => {
    setIsConfirmed(true)
  }
  useEffect(() => {
    vocabolaryservices
        .getVocabolary("vocabolary_library")
        .then((response) => {
          setVocabolery(response.data);
        })
        .catch((error) => {
          console.error("Kategorileri alırken hata oluştu:", error);
        });
  }, []);
  const handleCategoryClick = (category) => {
    setSelectedCategory((prevCategory) =>
        prevCategory === category ? null : category
    );
  };
  const splitTextByLine = (text, wordsPerLine) => {
    const words = text.split(' ')
    const lines = []
    for (let i = 0; i < words.length; i += wordsPerLine) {
      lines.push(words.slice(i, i + wordsPerLine).join(' '))
    }
    return lines
  }

  const splitLineBySplitValue = (line, splitValue) => {
    const words = line.split(' ')
    const groups = []
    for (let i = 0; i < words.length; i += splitValue) {
      groups.push(words.slice(i, i + splitValue).join(' '))
    }
    return groups
  }

  const screenStart = () => {
    setIsStart(true)
  }

  const startTimer = () => {
    setIsRunning(true)
  }

  const pauseTimer = () => {
    setIsRunning(false)
  }

  const resetTimer = () => {
    setIsRunning(false)
    setTimer({ minutes: 0, seconds: 0 })
    setShowIndex(0)
    setCurrentPage(0)
  }

  const resetAndStartExercise = () => {
    resetTimer()
    setIsFinish(false)
    setIsStart(false)
    setText("")
    setSelectedCategory(null)
  }

  // Metni satırlara, kelime gruplarına ve sayfalara ayırma
  useEffect(() => {
    if (!text) return; // Eğer text boşsa işlemi iptal et

    if (splitValue === 2) {
      setWordsPerLine(8);
    } else {
      setWordsPerLine(9);
    }
    const lines = splitTextByLine(text, wordsPerLine);
    const pages = [];
    for (let i = 0; i < lines.length; i += linesPerPage) {
      pages.push(
          lines
              .slice(i, i + linesPerPage)
              .map((line) => splitLineBySplitValue(line, splitValue))
      );
    }
    setSplittedPages(pages);
  }, [text, wordsPerLine, splitValue, linesPerPage]);

  useEffect(() => {
    let timerInterval
    let exerciseInterval

    if (isRunning) {
      timerInterval = setInterval(() => {
        setTimer((prevTimer) => {
          const seconds = prevTimer.seconds === 59 ? 0 : prevTimer.seconds + 1
          const minutes =
            prevTimer.seconds === 59 ? prevTimer.minutes + 1 : prevTimer.minutes
          return { minutes, seconds }
        })
      }, 1000)

      exerciseInterval = setInterval(() => {
        setShowIndex((prevIndex) => {
          const currentPageLength = splittedPages[currentPage].flat().length
          const nextIndex = prevIndex + 1

          if (nextIndex >= currentPageLength) {
            if (currentPage < splittedPages.length - 1) {
              setCurrentPage(currentPage + 1)
              return 0 // Yeni sayfaya geç
            } else {
              setIsFinish(true) // Son sayfadaysa bitir
              setIsRunning(false) // Interval'i durdur
              return prevIndex // Son kelimede kal
            }
          } else {
            return nextIndex
          }
        })
      }, exerciseSpeed)
    }

    return () => {
      clearInterval(timerInterval)
      clearInterval(exerciseInterval)
    }
  }, [isRunning, splittedPages, exerciseSpeed, currentPage])

  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/speed-reading-comprehension`
  }

  useEffect(() => {
    setIsLoading(true); // Yüklenme başlatılıyor
    vocabolaryservices
        .getVocabolary("vocabolary_library")
        .then((response) => {
          setVocabolery(response.data);
          setIsLoading(false); // Yüklenme tamamlandı
        })
        .catch((error) => {
          console.error("Kategorileri alırken hata oluştu:", error);
          setIsLoading(false); // Hata olsa bile yüklenmeyi durdur
        });
  }, []);



  return (
    <div className="flex flex-col w-full h-screen items-center justify-center">
      <div className="absolute top-0 w-full">
        <Header title={'Okunan Yazı'} returnType="speed-reading">
          <button
              onClick={resetAndStartExercise}
              className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
          >
            Sıfırla
          </button>
        </Header>
      </div>
      <div className="fixed top-32 m-2 p-2 rounded-xl font-semibold text-2xl text-center">
        {title}
      </div>
      <div
          className="absolute top-20 right-8 bg-gray-400 rounded-md p-1 text-white text-xl flex justify-center items-center w-20 h-12">
        <h1>
          {timer.minutes.toString().padStart(2, '0')}:
          {timer.seconds.toString().padStart(2, '0')}
        </h1>
      </div>

      <div
          className="w-full h-3/4 ml-52 mt-60 px-6 text-xl text-left overflow-auto transition-all duration-500 ease-in-out">
        {(splittedPages[currentPage] || []).map((line, lineIndex) => (
            <div key={lineIndex} className="flex justify-start">
              {line.map((group, groupIndex) => (
                  <span
                      className="border-2 border-blue-300 text-black mx-1"
                      key={groupIndex}
                      style={{
                        transitionDelay: `${groupIndex * 0.0004}s`,
                        opacity:
                            showIndex >=
                            (splittedPages[currentPage]?.slice(0, lineIndex).flat().length || 0) +
                            groupIndex
                                ? 1
                                : 0,
                      }}
                  >
          {group}
        </span>
              ))}
            </div>
        ))}
      </div>

      {/* Kontrol Butonları */}
      <div className="fixed bottom-10 flex justify-center gap-4 w-full max-w-lg mx-auto">
        {!isRunning && (
            <button
                className="bg-green-500 hover:bg-green-700 text-white  py-2 px-4 rounded-xl"
                onClick={startTimer}
            >
              Başla
            </button>
        )}
        {isRunning && (
            <button
                className="bg-yellow-500 hover:bg-yellow-700 text-white py-2 px-4 rounded-xl"
                onClick={pauseTimer}
            >
              Durdur
            </button>
        )}
        <button
            className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-xl"
            onClick={resetTimer}
        >
          Sıfırla
        </button>
      </div>

      <Footer />

      {/* Başlangıç Ekranı */}
      {!isStart && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50 overflow-auto">
            {isLoading ? (
                <div className="text-white text-lg font-semibold">Yükleniyor...</div>
            ) : (
                <div className="bg-white p-6 rounded-xl shadow-lg w-full max-w-6xl">
                  <h1 className="text-3xl font-bold text-center mb-1">Okunan Yazı Egzersizine Hoşgeldiniz!</h1>
                  <h2 className="text-3xl font-bold text-center mb-6">📚 Metin Kütüphanesi</h2>

                  {/* Kategori Kartları */}
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 overflow-auto max-h-[60vh]">
                    {[...new Set(vocabolery.map((item) => item.category))].map(
                        (category, index) => (
                            <div
                                key={index}
                                className="relative group rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 cursor-pointer"
                            >
                              <div
                                  onClick={() => handleCategoryClick(category)}
                                  className={`bg-gradient-to-br from-blue-500 to-blue-700 text-white p-6 h-32 flex items-center justify-center ${
                                      selectedCategory === category ? 'border-4 border-green-500' : ''
                                  }`}
                              >
                                <h3 className="text-xl font-semibold text-center">
                                  {category}
                                </h3>
                              </div>
                              {selectedCategory === category && (
                                  <div className="absolute inset-0 bg-white p-4 rounded-lg shadow-lg z-10 overflow-auto max-h-48">
                                    <div className="flex justify-between items-center mb-4">
                                      <h4 className="text-lg font-semibold text-gray-800">📝 Başlıklar</h4>
                                      <button
                                          onClick={() => setSelectedCategory(null)}
                                          className="text-gray-500 hover:text-red-500 transition-colors"
                                      >
                                        ❌
                                      </button>
                                    </div>
                                    <div className="space-y-2">
                                      {vocabolery
                                          .filter((item) => item.category === category)
                                          .map((item, idx) => (
                                              <div
                                                  key={idx}
                                                  onClick={() => setText(item.text)}
                                                  className={`p-2 rounded cursor-pointer transition-colors ${
                                                      text === item.text
                                                          ? 'bg-green-100 text-green-700'
                                                          : 'bg-gray-100 hover:bg-blue-50'
                                                  }`}
                                              >
                                                {item.name}
                                              </div>
                                          ))}
                                    </div>
                                  </div>
                              )}
                            </div>
                        )
                    )}
                  </div>

                  {/* Ayarlar Bölümü */}
                  <div className="mt-8 bg-gray-100 p-4 rounded-lg shadow-inner space-y-4">
                    {/* Hız Ayarı */}
                    <div>
                      <label htmlFor="speed" className="block text-gray-700 font-semibold mb-2">
                        🕒 Hız Ayarı: {exerciseSpeed} ms
                      </label>
                      <input
                          id="speed"
                          type="range"
                          min="200"
                          max="2000"
                          step="50"
                          value={exerciseSpeed}
                          onChange={(e) => setExerciseSpeed(parseInt(e.target.value))}
                          className="w-full"
                      />
                    </div>

                    {/* Kelime Sayısı Ayarı */}
                    {/* Kelime Grubu Seçimi */}
                    <div className="mb-4">
                      <label htmlFor="wordGroup" className="block text-left mb-2">
                        Kelime Grubu Seçimi: {splitValue}
                      </label>
                      <select
                          id="wordGroup"
                          className="w-full p-2 border"
                          value={splitValue}
                          onChange={(e) => setSplitValue(parseInt(e.target.value))}
                      >
                        <option value={1}>1 Kelime</option>
                        <option value={2}>2 Kelime</option>
                        <option value={3}>3 Kelime</option>
                      </select>
                    </div>
                  </div>

                  {/* Başla Butonu */}
                  <div className="text-center mt-6">
                    <button
                        onClick={() => setIsStart(true)}
                        className="bg-green-500 text-white px-6 py-2 rounded-lg text-lg hover:bg-green-400 transition-colors"
                    >
                      🚀 Devam
                    </button>
                  </div>
                </div>
            )}
          </div>
      )}

      {/* Bitiş Ekranı */}
      {isFinish && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
          <div className="bg-white p-8 rounded-md text-center w-full max-w-lg mx-4">
            <p>Tebrikler! Egzersizi tamamladınız.</p>
            <div className="flex flex-col items-center">
              <button
                onClick={handleReturnDashboard}
                className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-200"
              >
                Egzersiz Sayfasına Dön
              </button>
              <button
                  className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-200"
                onClick={resetAndStartExercise}
              >
                Tekrarla
              </button>
            </div>
          </div>
        </div>
      )}

      <FullscreenAlert onConfirm={handleConfirm} />
    </div>
  )
}

export default Grouping

import React, { useEffect, useState } from "react";
//import PDFGenerator from '../Components/PDFGenerator'
import PDFGenerator, { generatePDF } from "../Components/PDFGenerator";
import UserServices from "../Services/UserServices";

const users = [
  {
    id: 1,
    name: "Ahmet Yılmaz",
    level: "1. Seviye",
    startDate: "2024-01-10",
    endDate: "2024-06-10",
    teacher: "Elvan Sürel",
  },
  {
    id: 2,
    name: "Mehmet Kaya",
    level: "2. Seviye",
    startDate: "2024-02-15",
    endDate: "2024-07-15",
    teacher: "Elvan Sürel",
  },
  {
    id: 3,
    name: "Ayşe Demir",
    level: "3. Seviye",
    startDate: "2024-03-20",
    endDate: "2024-08-20",
    teacher: "Elvan Sürel",
  },
  {
    id: 4,
    name: "Fatma Çelik",
    level: "1. Seviye",
    startDate: "2024-04-25",
    endDate: "2024-09-25",
    teacher: "Elvan Sürel",
  },
  {
    id: 5,
    name: "Ali Vural",
    level: "2. Seviye",
    startDate: "2024-05-30",
    endDate: "2024-10-30",
    teacher: "Elvan Sürel",
  },
  {
    id: 6,
    name: "Elif Öztürk",
    level: "3. Seviye",
    startDate: "2024-06-05",
    endDate: "2024-11-05",
    teacher: "Elvan Sürel",
  },
  {
    id: 7,
    name: "Hasan Aydın",
    level: "1. Seviye",
    startDate: "2024-07-10",
    endDate: "2024-12-10",
    teacher: "Elvan Sürel",
  },
  {
    id: 8,
    name: "Zeynep Korkmaz",
    level: "2. Seviye",
    startDate: "2024-08-15",
    endDate: "2025-01-15",
    teacher: "Elvan Sürel",
  },
  {
    id: 9,
    name: "Mert Taş",
    level: "3. Seviye",
    startDate: "2024-09-20",
    endDate: "2025-02-20",
    teacher: "Elvan Sürel",
  },
  {
    id: 10,
    name: "Nur Karaca",
    level: "1. Seviye",
    startDate: "2024-10-25",
    endDate: "2025-03-25",
    teacher: "Elvan Sürel",
  },
];

const Report = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setusers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    readingSpeedStart: "",
    readingSpeedEnd: "",
    silentReadingSpeedStart: "",
    silentReadingSpeedEnd: "",
    comprehensionRateStart: "",
    comprehensionRateEnd: "",
    readingSpeedIncreaseRateStart: "",
    readingSpeedIncreaseRateEnd: "",
    exerciseCompletionRateStart: "",
    exerciseCompletionRateEnd: "",
    instructorComments: "",
  });

  const usr = new UserServices();

  useEffect(() => {
    usr.getUser("").then((response) => {
      console.log(response);
      setusers(response.data);
    });
  }, []); // boş bağımlılık dizisi, useEffect'in yalnızca bileşen ilk yüklendiğinde çalışmasını sağlar

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  const handleCreateReport = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
      <div className="h-full w-full p-6 bg-gray-50">

        <div className="mt-12 flex items-center">
          <h2 className="text-3xl font-semibold mb-4">KARNE OLUŞTURMA</h2>
        </div>
        <div className="mt-10 flex flex-col md:flex-row gap-6">
          {/* Kullanıcı Tablosu */}
          <div className="w-full md:w-1/2 bg-white rounded-lg shadow-lg overflow-hidden">
            <table className="min-w-full text-left">
              <thead className="bg-gray-500 text-white">
              <tr>
                <th className="py-3 px-5">Adı Soyadı</th>
                <th className="py-3 px-5">Seviye</th>
                <th className="py-3 px-5">Başlama Tarihi</th>
                <th className="py-3 px-5">Bitiş Tarihi</th>
              </tr>
              </thead>
              <tbody>
              {users
                  .filter((user) => user.user_type === "STUDENT")
                  .map((user) => (
                      <tr
                          key={user.id}
                          onClick={() => handleUserClick(user)}
                          className="hover:bg-gray-100 cursor-pointer border-b"
                      >
                        <td className="py-3 px-5">{user.name}</td>
                        <td className="py-3 px-5">{user.level}</td>
                        <td className="py-3 px-5">{user.kayit_tarihi}</td>
                        <td className="py-3 px-5">{user.endDate}</td>
                      </tr>
                  ))}
              </tbody>
            </table>
          </div>

          {/* Seçilen Kullanıcı */}
          {selectedUser && (
              <div className="w-full md:w-1/2 p-6 bg-white rounded-lg shadow-lg">
                <h2 className="text-2xl font-semibold text-gray-700 mb-4">Seçilen Öğrenci</h2>
                <p>
                  <strong>Adı Soyadı:</strong> {selectedUser.name}
                </p>
                <p>
                  <strong>Seviye:</strong> {selectedUser.level}
                </p>
                <p>
                  <strong>Başlama Tarihi:</strong> {selectedUser.kayit_tarihi}
                </p>
                <p>
                  <strong>Bitiş Tarihi:</strong> {selectedUser.endDate}
                </p>
                <button
                    onClick={handleCreateReport}
                    className="mt-4 bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded shadow"
                >
                  Karnesini Oluştur
                </button>
              </div>
          )}
        </div>

        {/* Modal */}
        {showModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg relative">
                <button
                    onClick={handleCloseModal}
                    className="absolute top-3 right-3 text-gray-500 hover:text-gray-800"
                >
                  &#x2715;
                </button>
                <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                  Kurs Sonu Değerler
                </h2>
                <div className="space-y-4 max-h-96 overflow-y-auto">
                  {/* Input Fields */}
                  {[
                    {label: "Sesli Okuma Hızı (Kelime/dk)", fields: ["readingSpeedStart", "readingSpeedEnd"]},
                    {
                      label: "Sessiz Okuma Hızı (Kelime/dk)",
                      fields: ["silentReadingSpeedStart", "silentReadingSpeedEnd"]
                    },
                    {label: "Anlama Oranı (%)", fields: ["comprehensionRateStart", "comprehensionRateEnd"]},
                    {label: "Okuma Hızı Artış Oranı (%)", fields: ["readingSpeedIncreaseRateStart"]},
                    {label: "Egzersiz Tamamlama Oranı (%)", fields: ["exerciseCompletionRateStart"]},
                  ].map(({label, fields}) => (
                      <div key={label}>
                        <label className="block text-gray-600 font-medium mb-1">{label}</label>
                        <div className="flex gap-2">
                          {fields.map((field) => (
                              <input
                                  key={field}
                                  type="text"
                                  name={field}
                                  placeholder={field.includes("Start") ? "Başlangıç" : "Bitiş"}
                                  value={formData[field]}
                                  onChange={handleInputChange}
                                  className="border p-2 rounded w-full focus:outline-blue-600"
                              />
                          ))}
                        </div>
                      </div>
                  ))}
                  {/* Eğitici Görüşü */}
                  <div>
                    <label className="block text-gray-600 font-medium mb-1">Eğitici Görüşü</label>
                    <textarea
                        name="instructorComments"
                        value={formData.instructorComments}
                        onChange={handleInputChange}
                        className="border p-2 rounded w-full h-24 focus:outline-blue-600"
                        placeholder="Eğitici Görüşü"
                    />
                  </div>
                  <PDFGenerator selectedUser={selectedUser} formData={formData}/>
                </div>
              </div>
            </div>
        )}
      </div>
  );
};

export default Report;

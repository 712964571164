import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const EduCard = ({ title, href }) => {
    const location = useLocation(); // Mevcut URL'yi almak için useLocation kullanıyoruz.

    return (
        <div className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 p-4">
            <Link
                to={`${location.pathname}${href}`} // Mevcut URL'yi alıp sonuna href ekliyoruz.
                className="block w-full p-6 bg-gray-50 border border-gray-300 rounded-lg shadow-sm hover:shadow-md hover:border-special_green transition-all duration-200 ease-in-out"
            >
                <div className="flex items-center justify-between mb-4">
                    {/* İkon ya da sembolik alan */}
                    <div className="w-12 h-12 flex items-center justify-center bg-blue-100 text-blue-600 rounded-full">
                        <span className="text-xl font-bold">{title[0]}</span>
                    </div>

                </div>
                <h5 className="text-lg font-medium text-gray-700">{title}</h5>
            </Link>
        </div>
    );
};

export default EduCard;
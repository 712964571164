import React, { useState, useEffect } from 'react'
import apple from '../../assets/apple.jpeg'
import banana from '../../assets/banana.jpeg'
import cherry from '../../assets/cherry.jpeg'
import grape from '../../assets/grape.jpeg'
import orange from '../../assets/orange.jpeg'
import pear from '../../assets/pear.jpeg'
import strawberry from '../../assets/strawberry.jpeg'
import watermelon from '../../assets/watermelon.jpeg'
import lemon from '../../assets/lemnon.png'
import blackberry from '../../assets/blackberry.png'
import plum from '../../assets/plum.png'
import pomegranate from '../../assets/pomegranate.png'
import kiwi from '../../assets/kiwi.png'
import fig from '../../assets/fig.png'
import blackmulberry from '../../assets/blackmulberry.png'

import { playCorrectSound } from '../../effect/Correct'
import { playInCorrectSound } from '../../effect/Incorrect'
import { playCongrulationSound } from '../../effect/Congrulation'
import { playStepSound } from '../../effect/Step'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import Header from '../Header'
import Footer from '../Footer'

const fruitImages = [
  plum,
  pomegranate,
  kiwi,
  fig,
  blackmulberry,
  apple,
  banana,
  cherry,
  grape,
  orange,
  pear,
  strawberry,
  watermelon,
  lemon,
  blackberry,
]
const matrixOptions = [
  [2, 2],
  [2, 3],
  [4, 4],
    [4, 5],
    [5, 6],
]
const MatchPair = () => {
  const [cards, setCards] = useState([])
  const [flippedCards, setFlippedCards] = useState([])
  const [matchedCards, setMatchedCards] = useState([])
  const [cardView, setCardView] = useState(false)
  const [isStart, setIsStart] = useState(false)
  const [gridSize, setGridSize] = useState({ rows: 4, cols: 4 })
  const token = window.localStorage.getItem('token')
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [totalRounds, setTotalRounds] = useState(3)
  const [currentRound, setCurrentRound] = useState(1)
  const [elapsedSeconds, setElapsedSeconds] = useState(0)
  const [roundStart, setRoundStart] = useState(false)
  const [isFinish, setIsFinish] = useState(false)
  const [matrixSize, setMatrixSize] = useState(matrixOptions[0])
  const [clickable, setClickable] = useState(true)

  useEffect(() => {
    setGridSize({
      rows: matrixSize[currentRound - 1][0],
      cols: matrixSize[currentRound - 1][1],
    })
  }, [matrixSize, currentRound])

  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  const handleConfirm = () => {
    setIsConfirmed(true)
  }

  const generateCards = () => {
    const totalCards = gridSize.rows * gridSize.cols
    const selectedImages = fruitImages.slice(0, totalCards / 2)
    const duplicatedImages = [...selectedImages, ...selectedImages]
    const shuffledImages = duplicatedImages.sort(() => Math.random() - 0.5)

    setCards(
      shuffledImages.map((image, index) => ({
        id: index,
        image,
        isFlipped: false,
        isMatched: false,
      }))
    )
  }

  const handleStart = () => {
    setIsStart(true)
    setCurrentRound(1)
    setElapsedSeconds(0)
    setRoundStart(false)
  }

  const handleRestart = () => {
    setIsStart(false)
    setCurrentRound(1)
    setElapsedSeconds(0)
    setRoundStart(false)
    setMatchedCards([])
    setFlippedCards([])
    setIsFinish(false)
    setCardView(false)
    generateCards()
  }

  const flipCard = (index) => {
    if (
      flippedCards.length === 2 ||
      cards[index].isFlipped ||
      cards[index].isMatched ||
      !roundStart
    )
      return

    playStepSound()
    setCards((prevCards) =>
      prevCards.map((card, i) =>
        i === index ? { ...card, isFlipped: true } : card
      )
    )
    setFlippedCards((prevFlipped) => [...prevFlipped, index])
  }

  const startRound = () => {
    setRoundStart(true)
  }

  const endRound = () => {
    if (currentRound < totalRounds) {
      setRoundStart(false)
      setMatchedCards([])
      generateCards()
      seeCards()
      setCurrentRound(currentRound + 1)
    } else {
      setIsFinish(true)
      setRoundStart(false) // Oyun bittiğinde roundStart'ı false yap
    }
  }

  useEffect(() => {
    const checkMatch = async () => {
      if (flippedCards.length === 2) {
        setClickable(false) // Disable clicking immediately

        const [firstIndex, secondIndex] = flippedCards
        if (cards[firstIndex].image === cards[secondIndex].image) {
          playCorrectSound()
          const newMatched = [...matchedCards, firstIndex, secondIndex]
          await new Promise((resolve) => setTimeout(resolve, 500)) // Wait 1 second
          setCards((prevCards) =>
            prevCards.map((card) =>
              newMatched.includes(card.id) ? { ...card, isMatched: true } : card
            )
          )
          setMatchedCards(newMatched)
        } else {
          playInCorrectSound()
          await new Promise((resolve) => setTimeout(resolve, 500)) // Wait 1 second – even for incorrect matches
          setCards((prevCards) =>
            prevCards.map((card) =>
              matchedCards.includes(card.id)
                ? card
                : { ...card, isFlipped: false }
            )
          )
        }
        setFlippedCards([]) // Clear flipped cards *after* the timeout
        setClickable(true) // Re-enable clicking after the timeout
      }
    }

    checkMatch()
  }, [flippedCards, cards, matchedCards])

  useEffect(() => {
    let intervalId
    if (roundStart) {
      intervalId = setInterval(() => {
        setElapsedSeconds((prevSeconds) => prevSeconds + 1)
      }, 1000)
    }

    return () => clearInterval(intervalId)
  }, [roundStart])

  useEffect(() => {
    if (matchedCards.length === gridSize.rows * gridSize.cols && roundStart) {
      setTimeout(() => {
        endRound()
      }, 1000)
    }
  }, [matchedCards, gridSize, roundStart])

  const seeCards = () => {
    setCardView(false)
    setTimeout(() => {
      setCardView(true)
      setTimeout(() => {
        setCardView(false)
        startRound()
      }, 2000)
    }, 2000)
  }

  useEffect(() => {
    generateCards()
    if (isStart) {
      seeCards()
    }
  }, [isStart, gridSize])

  useEffect(() => {
    let timer
    if (isFinish) {
      playCongrulationSound()
      clearInterval(timer)
    }

    return () => clearInterval(timer)
  }, [isFinish])

  const formattedTime = new Date(elapsedSeconds * 1000)
    .toISOString()
    .substr(14, 5)

  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/attention-memory-logic-math`
  }

  const handleGridSizeChange = (rows, cols) => {
    if (rows * cols <= 10 && rows <= 5 && cols <= 5) {
      setGridSize({ rows, cols })
    }
  }

  const handleMatrixChange = (index) => {
    // Seçilen matris boyutunu total round sayısı kadar tekrarla
    const selectedMatrix = matrixOptions[index];
    const newMatrixSizes = Array(totalRounds).fill(selectedMatrix);
    setMatrixSize(newMatrixSizes);
  }


  const handleRoundsChange = (e) => {
    setTotalRounds(parseInt(e.target.value))
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <div className="w-full fixed top-0">
        <Header title={"Eşini Bul"} returnType="attention-memory">
          <button
              onClick={handleRestart}
              className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
          >
            Sıfırla
          </button>
        </Header>
      </div>


      <div
          className="fixed right-4 top-24 w-38 h-16 bg-gray-500 rounded-lg p-4 flex flex-col items-center justify-center">
        <div className="text-xl text-white">Süre: {formattedTime}</div>{' '}
        {/* Güncellenmiş süre */}
        <div className="text-xl text-white">
          Tur: {currentRound}/{totalRounds}
        </div>
      </div>

      <div
        className="grid gap-4 mt-20"
        style={{
          gridTemplateColumns: `repeat(${gridSize.cols}, minmax(0, 1fr))`,
        }}
      >
        {cards.map((card, index) => (
          <div
            key={index}
            className={`w-24 h-24 ${
              card.isMatched ? '' : 'border border-gray-300'
            } rounded-md flex justify-center items-center 
                      ${clickable ? 'cursor-pointer' : 'cursor-not-allowed'} 
                      ${
                        !matchedCards.includes(card.id)
                          ? 'bg-gray-200'
                          : 'bg-transparent'
                      }`}
            onClick={() =>
              clickable && !matchedCards.includes(card.id) && flipCard(index)
            }
          >
            {cardView && (
              <img
                src={card.image}
                alt={`card-${index}`}
                className="max-w-full max-h-full"
              />
            )}
            {!matchedCards.includes(card.id) && card.isFlipped ? (
              <img
                src={card.image}
                alt={`card-${index}`}
                className="max-w-full max-h-full"
              />
            ) : (
              <div className="w-full h-full"></div>
            )}
          </div>
        ))}
      </div>

      <Footer/>

      {/* Oyun Sonu Ekranı */}
      {isFinish && (
        <div className="bg-gray-500 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
          <div
            className="bg-white p-8 rounded-md text-center"
            style={{ width: '300px' }}
          >
            <p style={{ fontSize: '20px' }}>
              Tebrikler! Egzersizi Tamamladınız.
              <br />
              Tamamlanma Süresi: {formattedTime}
            </p>
            <button
              onClick={handleReturnDashboard}
              className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
            >
              Egzersiz Sayfasına Dön
            </button>
            <button
              onClick={handleRestart}
              className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
            >
              Tekrar
            </button>
          </div>
        </div>
      )}

      {!isStart && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
          <div className="bg-gray-300 p-8 rounded-md text-center w-1/3">
            <h2 className="font-semibold text-2xl p-1">Eşini Bul Egzersizi</h2>

            {/* Matris Ayarı */}
            <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                📐 Matris Boyutu:
              </label>
              <div className="flex justify-center gap-4">
                {matrixOptions.map((size, index) => (
                    <button
                        key={index}
                        onClick={() => handleMatrixChange(index)}
                        className={`button-click-effect px-3 py-2 rounded transition-all duration-200 ${
                            gridSize.rows === size[0] && gridSize.cols === size[1]
                                ? 'bg-blue-500 text-white shadow-md scale-105'
                                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                        }`}
                    >
                      {size[0]} x {size[1]}
                    </button>
                ))}
              </div>
            </div>

            {/* Toplam Tur Ayarı */}
            <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
              <label
                  htmlFor="rounds"
                  className="block text-gray-700 font-semibold mb-2"
              >
                🔄 Toplam Tur: {totalRounds}
              </label>
              <input
                  id="rounds"
                  type="range"
                  min="1"
                  max="10"
                  value={totalRounds}
                  onChange={handleRoundsChange}
                  className="w-full"
              />
              <div className="flex justify-between text-sm text-gray-500 mt-1">
                <span>1</span>
                <span>10</span>
              </div>
            </div>

            <button
              className="bg-blue-400 text-white py-2 px-4 mt-4 rounded hover:bg-blue-300"
              onClick={handleStart}
            >
              🚀 Başla
            </button>
          </div>
        </div>
      )}
      <FullscreenAlert onConfirm={handleConfirm} />
    </div>
  )
}

export default MatchPair

import React, { useState, useEffect, useRef } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { playCongrulationSound } from '../../effect/Congrulation'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import VocabolaryServices from "../../Services/VocabolaryServices";


const Colum = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0)
  const [started, setStarted] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [isStart, setIsStart] = useState(false)
  const [speed, setSpeed] = useState(300)
  const [currentPage, setCurrentPage] = useState(0)
  const wordRefs = useRef([])
  const gridRef = useRef(null)
  const [wordsArray, setWordsArray] = useState([]);
  const [columns, setColumns] = useState(2)
  const [wordsPerPage, setWordsPerPage] = useState(12)
  const [customColumns, setCustomColumns] = useState(columns); // Geçici sütun ayarı
  const [customSpeed, setCustomSpeed] = useState(speed); // Geçici hız ayarı
  const [isLoading, setIsLoading] = useState(true)
  const token = window.localStorage.getItem('token')
  const vocabolaryservices = new VocabolaryServices();
  const [vocabolery, setVocabolery] = useState([]);
  const [isPaused, setIsPaused] = useState(false); // Egzersiz duraklatma durumu
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedText, setSelectedText] = useState(null); // Seçili metni takip etmek için yeni state

  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  const [isConfirmed, setIsConfirmed] = useState(false)

  const handleConfirm = () => {
    setIsConfirmed(true)
  }


  useEffect(() => {
    setIsLoading(true);
    vocabolaryservices
        .getVocabolary("vocabolary_library")
        .then((response) => {
          setVocabolery(response.data);

          const initialLevelData = response.data.find((levelData) => levelData.level === 1);
          if (initialLevelData && typeof initialLevelData.text === "string") {
            const words = initialLevelData.text.split(" "); // Metni kelime dizisine çevir
            setWordsArray(words);
          } else {
            setWordsArray([]); // Boş bir dizi ata
          }

          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Veri alınırken hata oluştu:", error);
          setIsLoading(false);
        });
  }, []);


  const togglePause = () => {
    setIsPaused((prev) => !prev); // Durumu tersine çevir
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory((prevCategory) =>
        prevCategory === category ? null : category
    );

    const selectedLevelData = vocabolery.filter((item) => item.category === category);

    if (selectedLevelData.length > 0 && selectedLevelData[0].text) {
      // Ensure text is treated as a string and split into words
      const words = selectedLevelData[0].text.toString().split(/\s+/).filter(word => word.length > 0);
      setWordsArray(words);
    } else {
      setWordsArray([]);
    }
  };


  const handleTextSelect = (text) => {
    if (typeof text === 'string' && text.length > 0) {
      const words = text.split(/\s+/).filter(word => word.length > 0);
      setWordsArray(words);
      setSelectedText(text); // Seçili metni kaydet
    } else {
      setWordsArray([]);
      setSelectedText(null);
    }
  };


  useEffect(() => {
    let interval;
    if (started && !completed && !isPaused) {
      interval = setInterval(() => {
        setCurrentWordIndex((prev) => {
          const nextIndex = prev + 1;
          if (nextIndex >= (currentPage + 1) * wordsPerPage) {
            if (nextIndex >= wordsArray.length) {
              setCompleted(true);
              playCongrulationSound();
              setStarted(false);
              return prev;
            } else {
              setCurrentPage(currentPage + 1); // Yeni sayfa
              return nextIndex;
            }
          }

          wordRefs.current[nextIndex]?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });

          return nextIndex;
        });
      }, speed);
    }

    return () => clearInterval(interval);
  }, [started, completed, speed, currentPage, isPaused]);

  const startTracking = () => {
    if (!started) {
      setCompleted(false);
      setStarted(true);
      setIsPaused(false); // Egzersiz başladığında duraklatılmış olmamalı
      setCurrentWordIndex(0);
      setCurrentPage(0);
    }
  };

  const handleStart = () => {
    setColumns(customColumns); // Kullanıcının seçtiği sütun sayısını uygula
    setSpeed(customSpeed); // Kullanıcının seçtiği hızı uygula
    setIsStart(true); // Başlat
  };

  const restartExercise = () => {
    setIsStart(false)
    setCompleted(false)
    setStarted(false)
    setCurrentWordIndex(0)
    setCurrentPage(0) // Sayfa sıfırla
    if (wordRefs.current[0]) {
      wordRefs.current[0].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
    setSelectedCategory(null)
  }

  const getGridStyle = () => {
    return {
      gridTemplateColumns: `repeat(${columns}, 1fr)`,
      gridAutoRows: 'minmax(40px, auto)',
      wordWrap: 'break-word',
      padding: '5px',
      boxSizing: 'border-box',
      // Eklenen özellikle:      flexDirection: 'column', // Öğeleri dikey olarak hizalar
      alignItems: 'center', // Öğeleri yatayda ortalar
      justifyContent: 'center', // Öğeleri dikeyde ortalar
    }
  }

  const getHighlightedStyle = (index) => {
    return index === currentWordIndex
      ? {
          backgroundColor: 'orange',
          padding: '7px',
          fontSize: '1.25rem',
        }
      : { padding: '7px', fontSize: '1.25rem' }
  }

  const getCurrentPageWords = () => {
    if (!Array.isArray(wordsArray)) {
      console.warn("wordsArray is not an array:", wordsArray);
      return [];
    }
    const startIndex = currentPage * wordsPerPage;
    const endIndex = startIndex + wordsPerPage;
    return wordsArray.slice(startIndex, endIndex);
  };

  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/speed-reading-comprehension`
  }

  return (
      <div className="w-full h-screen flex flex-col justify-between">
        <Header title={'Sütun Okuma'} returnType='speed-reading'>

          <button
              onClick={restartExercise}
              className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
          >
            Sıfırla
          </button>
        </Header>
        <div className="flex-grow flex justify-center items-center overflow-hidden">
          <div
              className="bg-blue-200 h-3/4 w-full rounded-lg sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3 flex justify-center items-center p-4"
              ref={gridRef}
          >
            <div
                className="grid gap-4 w-full h-full font-bold text-xl"
                style={getGridStyle()}
            >
              {getCurrentPageWords().map((word, index) => (
                  <div
                      key={index}
                      className="text-center"
                      style={getHighlightedStyle(currentPage * wordsPerPage + index)}
                      ref={(el) =>
                          (wordRefs.current[currentPage * wordsPerPage + index] = el)
                      }
                  >
                    {word}
                  </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center mb-20 space-x-4">
          {/* Başla Butonu */}
          {!started && (
              <button
                  onClick={startTracking}
                  className="text-xl p-2 w-32 bg-blue-400 hover:bg-blue-300 text-white rounded"
                  disabled={started}

              >
                Başla
              </button>
          )}

          {/* Durdur ve Devam Et Butonu */}
          {started && (
              <button
                  onClick={togglePause}
                  className={`text-xl p-2 w-32 ${
                      isPaused ? 'bg-green-400 hover:bg-green-300' : 'bg-yellow-400 hover:bg-yellow-300'
                  } text-white rounded`}
              >
                {isPaused ? 'Devam Et' : 'Durdur'}
              </button>
          )}

          {/* Sıfırla Butonu */}
          {started && (
              <button
                  onClick={restartExercise}
                  className="text-xl p-2 w-32 bg-red-400 hover:bg-red-300 text-white rounded"
              >
                Sıfırla
              </button>
          )}
        </div>

        <Footer/>
        {completed && (
            <div>
              <div className="bg-gray-500 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
                <div
                    className="bg-white p-8 rounded-md text-center"
                    style={{width: '300px'}}
                >
                  <p style={{fontSize: '20px'}}>
                    Tebrikler! Egzersizi tamamladınız.
                  </p>

                  <button
                      className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-200"
                      onClick={restartExercise}
                  >
                    Tekrar
                  </button>
                  <div>
                    <button
                        className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-200"
                        onClick={handleReturnDashboard}
                    >
                      Egzersiz Sayfasına Dön
                    </button>
                  </div>
                </div>
              </div>
            </div>
        )}
        {!isStart && (
            <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50 overflow-auto">
              {isLoading ? (
                  <div className="text-white text-lg font-semibold">Yükleniyor...</div>
              ) : (
                  <div className="bg-white p-6 rounded-xl shadow-lg w-full max-w-6xl">
                    <h1 className="text-3xl font-bold text-center mb-1">Sütun Blok Okuma Egzersizine Hoşgeldiniz!</h1>
                    <h2 className="text-3xl font-bold text-center mb-6">📚 Metin Kütüphanesi</h2>

                    {/* Kategori Kartları */}
                    <div
                        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 overflow-auto max-h-[60vh]">
                      {[...new Set(vocabolery.map((item) => item.category))].map(
                          (category, index) => (
                              <div
                                  key={index}
                                  className="relative group rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 cursor-pointer"
                              >
                                <div
                                    onClick={() => handleCategoryClick(category)}
                                    className={`bg-gradient-to-br from-blue-500 to-blue-700 text-white p-6 h-32 flex items-center justify-center ${
                                        selectedCategory === category ? 'border-4 border-green-500' : ''
                                    }`}
                                >
                                  <h3 className="text-xl font-semibold text-center">
                                    {category}
                                  </h3>
                                </div>
                                {selectedCategory === category && (
                                    <div
                                        className="absolute inset-0 bg-white p-4 rounded-lg shadow-lg z-10 overflow-auto max-h-48">
                                      <div className="flex justify-between items-center mb-4">
                                        <h4 className="text-lg font-semibold text-gray-800">📝 Başlıklar</h4>
                                        <button
                                            onClick={() => setSelectedCategory(null)}
                                            className="text-gray-500 hover:text-red-500 transition-colors"
                                        >
                                          ❌
                                        </button>
                                      </div>
                                      <div className="space-y-2">
                                        {vocabolery
                                            .filter((item) => item.category === category)
                                            .map((item, idx) => (
                                                <div
                                                    key={idx}
                                                    onClick={() => handleTextSelect(item.text)} // setWordsArray yerine handleTextSelect kullanıyoruz
                                                    // handleTextSelect ile doğru metin aktarılır
                                                    className={`p-2 rounded cursor-pointer transition-colors ${
                                                        selectedText === item.text
                                                            ? 'bg-green-100 text-green-700'
                                                            : 'bg-gray-100 hover:bg-blue-50'
                                                    }`}
                                                >
                                                  {item.name}
                                                </div>
                                            ))}
                                      </div>
                                    </div>
                                )}
                              </div>
                          )
                      )}
                    </div>

                      <div className="flex flex-col items-center mt-6">

                        {/* Sütun Sayısı Ayarı */}
                        <div className="bg-white p-4 mt-4 rounded-lg shadow-sm w-1/3 min-w-[250px] items-center">
                          <label htmlFor="columns" className="block text-gray-700 font-semibold mb-2">
                            🧩 Sütun Sayısı: {customColumns}
                          </label>
                          <input
                              id="columns"
                              type="range"
                              min="2"
                              max="3"
                              step="1"
                              value={customColumns}
                              onChange={(e) => setCustomColumns(parseInt(e.target.value))}
                              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-blue-500"
                          />
                          <div className="flex justify-between text-sm text-gray-500 mt-1">
                            <span>2</span>
                            <span>3</span>
                          </div>
                        </div>

                        {/* Hız Ayarı */}
                        <div className="bg-white p-4  mt-4 rounded-lg shadow-sm w-1/3 min-w-[250px] items-center">
                          <label htmlFor="speed" className="block text-gray-700 font-semibold mb-2">
                            🚀 Hız Ayarı: {customSpeed} ms
                          </label>
                          <input
                              id="speed"
                              type="range"
                              min="100"
                              max="1000"
                              step="50"
                              value={customSpeed}
                              onChange={(e) => setCustomSpeed(parseInt(e.target.value))}
                              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-blue-500"
                          />
                          <div className="flex justify-between text-sm text-gray-500 mt-1">
                            <span>Hızlı</span>
                            <span>Yavaş</span>
                          </div>
                        </div>
                      </div>
                      {/* Başla Butonu */}
                      <div className="text-center mt-6">
                        <button
                            onClick={() => handleStart(true)}
                            className="bg-green-500 text-white px-6 py-2 rounded-lg text-lg hover:bg-green-400 transition-colors"
                        >
                          Başlat 🚀
                        </button>
                      </div>
                    </div>
                    )}
                  </div>
              )}
              <FullscreenAlert onConfirm={handleConfirm}/>
            </div>
  )
}

export default Colum

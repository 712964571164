import React, { useState, useEffect } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { playCongrulationSound } from '../../effect/Congrulation'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import { playStepSound } from '../../effect/Step'
import VocabolaryServices from '../../Services/VocabolaryServices'


const RowBlockReading = () => {
  const [text, setText] = useState('')
  const [mode, setMode] = useState('normal') // start, normal, middleHard, hard
  const [stopsPerLine, setStopsPerLine] = useState(5) // Durak sayısı
  const [highlightedWord, setHighlightedWord] = useState(0)
  const [highlightedLine, setHighlightedLine] = useState(0)
  const [started, setStarted] = useState(false)
  const [minutes, setMinutes] = useState(500)
  const [isFinish, setIsFinish] = useState(false)
  const [isStart, setIsStart] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [formattedPages, setFormattedPages] = useState([])
  const [currentStopIndex, setCurrentStopIndex] = useState(0)
  const [vocabolery, setVocabolery] = useState([])
  const token = window.localStorage.getItem('token')
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Yüklenme durumu
  const [alertMessage, setAlertMessage] = useState('') // Uyarı Mesajı


  const vocabolaryservices = new VocabolaryServices()

  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  useEffect(() => {
    setIsLoading(true); // Yüklenme başlatılıyor
    vocabolaryservices
        .getVocabolary("vocabolary_library")
        .then((response) => {
          setVocabolery(response.data);
          setIsLoading(false); // Yüklenme tamamlandı
        })
        .catch((error) => {
          console.error("Kategorileri alırken hata oluştu:", error);
          setIsLoading(false); // Hata olsa bile yüklenmeyi durdur
        });
  }, []);
  const handleCategoryClick = (category) => {
    setSelectedCategory((prevCategory) =>
        prevCategory === category ? null : category
    );
  };
  useEffect(() => {
    // Mode'a göre durak sayısını ayarla
    switch (mode) {
      case 'start':
        setStopsPerLine(5)
        break
      case 'normal':
        setStopsPerLine(4)
        break
      case 'middleHard':
        setStopsPerLine(3)
        break
      case 'hard':
        setStopsPerLine(2)
        break
      default:
        setStopsPerLine(5)
    }
  }, [mode])

  const [isConfirmed, setIsConfirmed] = useState(false)
  const modeOptions = [
    { label: 'Basit', value: 'start' },
    { label: 'Normal', value: 'normal' },
    { label: 'Orta-Zor', value: 'middleHard' },
    { label: 'Zor', value: 'hard' },
  ]
  const handleSetTitle = (text) => {
    setText(text);
  };

  const handleConfirm = () => {
    setIsConfirmed(true)
  }

  const handleRestart = () => {
    setHighlightedWord(0)
    setHighlightedLine(0)
    setStarted(false)
    setIsFinish(false)
    setIsStart(false)
    setCurrentPage(0)
    setCurrentStopIndex(0)
  }
  const handleStart = () => {
    if (!text) {
      setAlertMessage('Lütfen bir metin seçiniz!')
      return
    }
    if (!mode) {
      setMode('normal') // Varsayılan mod
    }
    if (!minutes) {
      setMinutes(500) // Varsayılan hız
    }
    setIsStart(true)
  }

  const handleCloseAlert = () => {
    setAlertMessage('')
  }

  useEffect(() => {
    const paginateText = () => {
      const words = text.split(' ')
      const wordsPerLine = 10
      const linesPerPage = 8
      let pages = []

      for (let i = 0; i < words.length; i += wordsPerLine * linesPerPage) {
        const pageWords = words.slice(i, i + wordsPerLine * linesPerPage)
        let pageContent = []

        for (let j = 0; j < pageWords.length; j += wordsPerLine) {
          const lineWords = pageWords.slice(j, j + wordsPerLine)
          pageContent.push(lineWords)
        }

        pages.push(pageContent)
      }

      setFormattedPages(pages)
    }

    paginateText()
  }, [text])

  useEffect(() => {
    let interval
    if (started) {
      interval = setInterval(() => {
        // Sadece satırda 10 kelime olduğunda ses çal
        const currentLine = formattedPages[currentPage]?.[highlightedLine] || []
        if (currentLine.length >= 8) {
          playStepSound()
        }

        setCurrentStopIndex((prevIndex) => {
          if (prevIndex >= stopsPerLine - 1) {
            const currentPageLines = formattedPages[currentPage]?.length || 0
            if (highlightedLine < currentPageLines - 1) {
              setHighlightedLine((prev) => prev + 1)
              return 0
            } else if (currentPage < formattedPages.length - 1) {
              setCurrentPage((prev) => prev + 1)
              setHighlightedLine(0)
              return 0
            } else {
              setIsFinish(true)
              clearInterval(interval)
              return 0
            }
          }
          return prevIndex + 1
        })
      }, minutes)
    }

    return () => clearInterval(interval)
  }, [formattedPages, started, currentPage, highlightedLine])

  useEffect(() => {
    if (isFinish) {
      playCongrulationSound()
      setStarted(false)
    }
  }, [isFinish])

  const calculateStopPositions = () => {
    const positions = []
    const totalWords = 10 // Satırdaki toplam kelime sayısı
    const segmentSize = totalWords / stopsPerLine // Her durak arasındaki kelime sayısı

    // İlk durak noktasını hesapla
    const firstStop = Math.floor(segmentSize / 2)
    positions.push(firstStop)

    // Diğer durak noktalarını hesapla
    for (let i = 1; i < stopsPerLine; i++) {
      positions.push(Math.floor(firstStop + i * segmentSize))
    }

    return positions
  }

  const formatText = () => {
    if (!formattedPages[currentPage]) return null

    return formattedPages[currentPage].map((line, lineIndex) => {
      const stopPositions = calculateStopPositions()

      return (
        <div key={lineIndex} className="relative mb-8">
          <div className="flex flex-wrap">
            {line.map((word, wordIndex) => (
              <span key={wordIndex} className="relative mr-2">
                {word}
                {lineIndex === highlightedLine &&
                  stopPositions[currentStopIndex] === wordIndex && (
                    <span className="absolute bottom-[-8px] left-1/2 transform -translate-x-1/2 w-2 h-2 bg-red-500 rounded-full"></span>
                  )}
              </span>
            ))}
          </div>
        </div>
      )
    })
  }

  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/speed-reading-comprehension`
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen">

      <div className="absolute top-0 w-full">
        <Header title={'Satır Blok Okuma'} returnType="speed-reading">
          <button
              onClick={handleRestart}
              className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
          >
            Sıfırla
          </button>
        </Header>
      </div>
      <div className="w-5/6 min-h-80 p-8 text-lg shadow-lg text-center rounded-xl">
        <div className="text-2xl text-left">{formatText()}</div>
      </div>
      <div className="h-8 mt-2">
        {!started && (
            <button
                onClick={() => setStarted(true)}
                className="bg-blue-300 hover:bg-blue-200 text-white font-bold py-2 px-4 rounded-xl mt-4"
          >
            Başla
          </button>
        )}
      </div>
      <Footer />
      {isFinish && (
        <div className="bg-gray-500 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
          <div
            className="bg-white p-8 rounded-md text-center"
            style={{ width: '300px' }}
          >
            <p style={{ fontSize: '20px' }}>
              Tebrikler! Egzersizi tamamladınız.
            </p>
            <button
              onClick={handleRestart}
              className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
            >
              Tekrar
            </button>
            <div>
              <button
                onClick={handleReturnDashboard}
                className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
              >
                Egzersiz Sayfasına Dön
              </button>
            </div>
          </div>
        </div>
      )}
      {!isStart && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50 overflow-auto">
            {isLoading ? (
                <div className="text-white text-lg font-semibold">Yükleniyor...</div>
            ) : (
                <div className="bg-white p-6 rounded-xl shadow-lg w-full max-w-6xl">
                  <h1 className="text-3xl font-bold text-center mb-1">📚 Satır Blok Okuma Egzersizine Hoşgeldiniz!</h1>

                  {/* 📚 Kategori Seçimi */}
                  <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-6">
                    <h2 className="text-xl font-semibold mb-4">📚 Metin Kütüphanesi</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 overflow-auto max-h-[40vh]">
                      {[...new Set(vocabolery.map((item) => item.category))].map((category, index) => (
                          <div
                              key={index}
                              className="relative group rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 cursor-pointer"
                          >
                            <div
                                onClick={() => handleCategoryClick(category)}
                                className={`bg-gradient-to-br from-blue-500 to-blue-700 text-white p-6 h-32 flex items-center justify-center ${
                                    selectedCategory === category ? 'border-4 border-green-500' : ''
                                }`}
                            >
                              <h3 className="text-xl font-semibold text-center">{category}</h3>
                            </div>
                            {selectedCategory === category && (
                                <div className="absolute inset-0 bg-white p-4 rounded-lg shadow-lg z-10 overflow-auto max-h-48">
                                  <div className="flex justify-between items-center mb-4">
                                    <h4 className="text-lg font-semibold text-gray-800">📝 Başlıklar</h4>
                                    <button
                                        onClick={() => setSelectedCategory(null)}
                                        className="text-gray-500 hover:text-red-500 transition-colors"
                                    >
                                      ❌
                                    </button>
                                  </div>
                                  <div className="space-y-2">
                                    {vocabolery
                                        .filter((item) => item.category === category)
                                        .map((item, idx) => (
                                            <div
                                                key={idx}
                                                onClick={() => handleSetTitle(item.text)}
                                                className={`p-2 rounded cursor-pointer transition-colors ${
                                                    text === item.text
                                                        ? 'bg-green-100 text-green-700'
                                                        : 'bg-gray-100 hover:bg-blue-50'
                                                }`}
                                            >
                                              {item.name}
                                            </div>
                                        ))}
                                  </div>
                                </div>
                            )}
                          </div>
                      ))}
                    </div>
                  </div>

                  {/* 🧠 Mod Seçimi */}
                  <div className="bg-gray-50 p-4 rounded-lg shadow-md mb-6">
                    <h2 className="text-xl font-semibold mb-4">🧠 Mod Seçimi</h2>
                    <div className="flex justify-center gap-4">
                      {modeOptions.map(({ label, value }) => (
                          <button
                              key={value}
                              onClick={() => setMode(value)}
                              className={`px-4 py-2 rounded ${
                                  mode === value
                                      ? 'bg-blue-500 text-white shadow-md'
                                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                              }`}
                          >
                            {label}
                          </button>
                      ))}
                    </div>
                  </div>

                  {/* ⚡ Hız Ayarı */}
                  <div className="bg-gray-50 p-4 rounded-lg shadow-md mb-6">
                    <h2 className="text-xl font-semibold mb-4">⚡ Hız Ayarı</h2>
                    <label className="block text-gray-700 font-semibold mb-2">
                      Hız: {minutes} ms
                    </label>
                    <input
                        type="range"
                        min="300"
                        max="2000"
                        step="100"
                        value={minutes}
                        onChange={(e) => setMinutes(parseInt(e.target.value))}
                        className="w-full"
                    />
                    <div className="flex justify-between text-sm text-gray-500 mt-1">
                      <span>300 ms</span>
                      <span>2000 ms</span>
                    </div>
                  </div>

                  {/* 🚀 Başla Butonu */}
                  <div className="text-center mt-6">
                    <button
                        onClick={handleStart}
                        className="bg-green-500 text-white px-6 py-2 rounded-lg text-lg hover:bg-green-400 transition-colors"
                    >
                      🚀 Başlat
                    </button>
                  </div>
                </div>
            )}
            {/* 🚨 Uyarı Mesajı */}
            {alertMessage && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75">
                  <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                    <p className="text-lg font-semibold">{alertMessage}</p>
                    <button
                        onClick={handleCloseAlert}
                        className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
                    >
                      Kapat
                    </button>
                  </div>
                </div>
            )}
          </div>
      )}
      <FullscreenAlert onConfirm={handleConfirm} />
    </div>
  )
}

export default RowBlockReading

import React, { useState, useEffect, useRef } from 'react'
import { playCongrulationSound } from '../../effect/Congrulation'
import { playStepSound } from '../../effect/Step'
import Header from '../Header'
import Footer from '../Footer'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import VocabolaryServices from "../../Services/VocabolaryServices";


const AwayWords = () => {
  const EXERCISE_DURATION = 120 // 3 dakika (saniye cinsinden)
  const [customDurationMinutes, setCustomDurationMinutes] = useState(2); // Dakika cinsinden varsayılan süre
  const [customDuration, setCustomDuration] = useState(customDurationMinutes * 60); // Saniyeye çevir
  const [initialArr, setInitialArr] = useState([])
  const [isStart, setIsStart] = useState(false)
  const [exerciseCompleted, setExerciseCompleted] = useState(false)
  const [words, setWords] = useState([])
  const [currentWordIndex, setCurrentWordIndex] = useState(0)
  const wordRefs = useRef([])
  const [exerciseStart, setExerciseStart] = useState(false)
  const [sliceStartIndex, setSliceStartIndex] = useState(0)
  const [lampIndices, setLampIndices] = useState(Array(11).fill(false))
  const [speed, setSpeed] = useState(1000)
  const [isPaused, setIsPaused] = useState(false)
  const [direction, setDirection] = useState('outward')
  const [timeRemaining, setTimeRemaining] = useState(EXERCISE_DURATION)
  const [isLoading, setIsLoading] = useState(true)
  const token = window.localStorage.getItem('token')
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [vocabolery, setVocabolery] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState(1);

  const vocabolaryservices = new VocabolaryServices();


  useEffect(() => {
    setIsLoading(true);
    vocabolaryservices
        .getVocabolary("awaywords")
        .then((response) => {
          setVocabolery(response.data);

          // Varsayılan olarak 1. seviyeyi ayarla
          const initialLevelData = response.data.find(levelData => levelData.level === 1);
          if (initialLevelData) {
            const initialWords = initialLevelData.data[0]?.initial_ar || [];
            setInitialArr(initialWords);
            setWords(initialWords.slice(0, 11));
            setSelectedLevel(1); // Seviye 1'i otomatik ayarla
          }

          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Kategorileri alırken hata oluştu:", error);
          setIsLoading(false);
        });
  }, []);

  useEffect(() => {
    let timer
    if (exerciseStart && !isPaused && timeRemaining > 0) {
      timer = setInterval(() => {
        setTimeRemaining((prev) => {
          if (prev <= 1) {
            setExerciseCompleted(true)
            playCongrulationSound()
            return 0
          }
          return prev - 1
        })
      }, 1000)
    }
    return () => clearInterval(timer)
  }, [exerciseStart, isPaused, timeRemaining])



  const handleLevelChange = (event) => {
    const selected = parseInt(event.target.value);
    setSelectedLevel(selected);

    const selectedLevelData = vocabolery.find((levelData) => levelData.level === selected);

    if (selectedLevelData) {
      const words = selectedLevelData.data?.[0]?.initial_ar || [];
      setInitialArr(words);
      setWords(words.slice(0, 11));
      setSliceStartIndex(0);
      setCurrentWordIndex(0);
    } else {
      console.error(`Seviye ${selected} bulunamadı.`);
      setInitialArr([]);
      setWords([]);
    }
  };

  const handleConfirm = () => {
    setIsConfirmed(true)
  }

  const handleStart = () => {
    setIsStart(true);
    setWords(initialArr.slice(0, 11))
    setLampIndices(Array(11).fill(false))
    setTimeRemaining(customDurationMinutes * 60); // Dakikayı saniyeye çevir

  };

  const resetExercise = () => {
    wordRefs.current.forEach((ref) => {
      if (ref) {
        ref.textContent = ''
      }
    })

    setIsStart(false)
    setExerciseCompleted(false)
    setExerciseStart(false)
    setWords([])
    setCurrentWordIndex(0)
    setSliceStartIndex(0)
    setLampIndices(Array(11).fill(false))
    setIsPaused(false)
    setDirection('outward')
    setTimeRemaining(EXERCISE_DURATION)
  }

  useEffect(() => {
    if (exerciseStart && !isPaused && timeRemaining > 0) {
      const middleIndex = Math.floor(wordRefs.current.length / 2)
      const interval = setInterval(() => {
        if (currentWordIndex < words.length) {
          wordRefs.current.forEach((ref) => {
            if (ref) {
              ref.textContent = ''
            }
          })
          setLampIndices(Array(11).fill(false))

          let targetIndices = []
          if (direction === 'outward') {
            if (currentWordIndex === 0) {
              targetIndices = [middleIndex]
            } else {
              const offset = Math.floor((currentWordIndex + 1) / 2)
              targetIndices =
                currentWordIndex % 2 === 1
                  ? [middleIndex - offset, middleIndex + offset]
                  : [middleIndex + offset, middleIndex - offset]
            }
          } else {
            const offset = Math.floor((currentWordIndex + 1) / 2)
            targetIndices =
              currentWordIndex % 2 === 1
                ? [offset - 1, 10 - offset]
                : [offset, 10 - offset]
          }

          targetIndices.forEach((index, i) => {
            if (index >= 0 && index < wordRefs.current.length) {
              wordRefs.current[index].textContent = words[currentWordIndex + i]
              setLampIndices((prevLampIndices) => {
                const newLampIndices = [...prevLampIndices]
                newLampIndices[index] = true
                return newLampIndices
              })
            }
          })

          setCurrentWordIndex(currentWordIndex + targetIndices.length)

          if (currentWordIndex + targetIndices.length >= words.length) {
            clearInterval(interval)

            // Sonraki kelime grubuna geç veya başa dön
            const nextSliceStartIndex = sliceStartIndex + 11
            setTimeout(() => {
              let nextSlice
              let nextStartIndex

              if (nextSliceStartIndex < initialArr.length) {
                // Hala gösterilecek kelimeler varsa
                nextSlice = initialArr.slice(
                  nextSliceStartIndex,
                  nextSliceStartIndex + 11
                )
                nextStartIndex = nextSliceStartIndex
              } else {
                // Kelimelerin sonuna gelindiyse başa dön
                nextSlice = initialArr.slice(0, 11)
                nextStartIndex = 0
              }

              setWords(nextSlice)
              setSliceStartIndex(nextStartIndex)
              setCurrentWordIndex(0)
              // Yönü sadece 11'li grup bittiğinde değiştir
              setDirection((prevDirection) =>
                nextStartIndex === 0
                  ? 'outward'
                  : prevDirection === 'outward'
                  ? 'inward'
                  : 'outward'
              )
            })
          }
        }
      }, speed)

      return () => clearInterval(interval)
    }
  }, [
    exerciseStart,
    words,
    currentWordIndex,
    sliceStartIndex,
    isPaused,
    direction,
    timeRemaining,
  ])

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
  }

  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/eye-yoga-peripheral-vision`
  }

  return (
    <div className="h-screen overflow-hidden">
      <Header title={'Uzaklaşan Kelimeler'} returnType="eye-yoga">
        <button
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
            onClick={resetExercise}
        >
          Sıfırla
        </button>
      </Header>

      <div className="relative h-3/4 w-full flex flex-col items-center justify-center mt-4 text-xl text-center">
        <div className="absolute top-4 right-8 text-2xl font-bold">
          {formatTime(timeRemaining)}
        </div>

        <div
          id="kelimeler"
          className="relative flex flex-row justify-between text-center mt-36 overflow-hidden h-28 text-xl font-semibold"
        >
          {Array.from({ length: 11 }).map((_, index) => (
            <div key={index} className="flex flex-col items-center">
              <span
                className={`border-2 p-2 w-32 h-12  ${
                  index === Math.floor(11 / 2) ? 'bg-green-300' : ''
                }`}
                ref={(el) => (wordRefs.current[index] = el)}
              ></span>
              {lampIndices[index] && (
                <div className="w-4 h-4 bg-red-500 rounded-full mt-2 "></div>
              )}
            </div>
          ))}
        </div>

        <div className="flex justify-center items-center mt-64 space-x-4">
          {!exerciseStart && (
            <button
              className="text-lg p-2 text-white rounded-xl w-32 bg-blue-400 hover:bg-green-400"
              disabled={exerciseCompleted}
              onClick={() => setExerciseStart(true)}
            >
              Başla
            </button>
          )}
          {exerciseStart && (
            <>
              <button
                className="text-lg p-2 text-white rounded-xl bg-yellow-400 w-32 hover:bg-green-400"
                onClick={() => setIsPaused(!isPaused)}
                onMouseEnter={(e) =>
                  (e.target.textContent = isPaused ? 'Devam Et' : 'Durdur')
                }
                onMouseLeave={(e) =>
                  (e.target.textContent = isPaused ? 'Durdur' : 'Durdur')
                }
              >
                Durdur
              </button>
            </>
          )}
        </div>
      </div>

      <Footer />

      {!isStart && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
            <div className="bg-gray-300 p-8 rounded-md text-center w-1/3">
              <h2 className="font-semibold text-2xl p-1">
                Uzaklaşan Kelime Egzersizi
              </h2>
              <div className="space-y-4">
                {/* Seviye Seçimi */}
                <div className="bg-white p-4 rounded-lg shadow-sm m-4 flex flex-col justify-between h-32">
                  <label htmlFor="levelSelect" className="block text-left text-gray-700 font-semibold mb-2">
                    📚 Seviye Seçimi:
                  </label>
                  <select
                      id="levelSelect"
                      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                      value={selectedLevel}
                      onChange={handleLevelChange}
                  >
                    {vocabolery.map((levelData) => (
                        <option key={levelData.level} value={levelData.level}>
                          Seviye {levelData.level}
                        </option>
                    ))}
                  </select>
                </div>

                {/* Hız Ayarı */}
                <div className="bg-white p-4 rounded-lg shadow-sm m-4 flex flex-col justify-between h-32">
                  <label htmlFor="speed" className="block text-gray-700 font-semibold mb-2">
                    🕒 Hız Ayarı: {speed} ms
                  </label>
                  <input
                      id="speed"
                      type="range"
                      min="200"
                      max="2000"
                      step="50"
                      value={speed}
                      onChange={(e) => setSpeed(parseInt(e.target.value))}
                      className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-blue-500"
                  />
                  <div className="flex justify-between text-sm text-gray-500 mt-1">
                    <span>Hızlı</span>
                    <span>Yavaş</span>
                  </div>
                </div>
              </div>
              {/* Süre Ayarı (Dakika) */}
              <div className="bg-white p-4 rounded-lg shadow-sm m-4 flex flex-col justify-between h-32">
                <label htmlFor="duration" className="block text-gray-700 font-semibold mb-2">
                  ⏳ Süre Ayarı: {customDurationMinutes} dakika
                </label>
                <input
                    id="duration"
                    type="range"
                    min="1"
                    max="10"
                    step="1"
                    value={customDurationMinutes}
                    onChange={(e) => {
                      const minutes = parseInt(e.target.value);
                      setCustomDurationMinutes(minutes);
                      setCustomDuration(minutes * 60); // Saniyeye çevir
                    }}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-blue-500"
                />
                <div className="flex justify-between text-sm text-gray-500 mt-1">
                  <span>1 dk</span>
                  <span>10 dk</span>
                </div>
              </div>
              {/* Devam Butonu */}
              <button
                  className="bg-blue-400 text-white py-2 px-4 mt-4 rounded hover:bg-blue-300 w-full"
                  onClick={() => handleStart()}
              >
                Devam
              </button>
            </div>
          </div>
      )}
      {exerciseCompleted && (
          <div className="bg-gray-300 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
            <div className="bg-white p-8 rounded-md text-center">
              <p>Tebrikler! Egzersizi tamamladınız.</p>
              <button
                  className="bg-blue-400 text-white py-2 px-4 mt-4 rounded"
                  onClick={handleReturnDashboard}
              >
                Egzersiz Sayfasına Dön
              </button>
              <div>
                <button
                    className="bg-blue-400 text-white py-2 px-4 mt-4 rounded"
                    onClick={resetExercise}
                >
                  Tekrarla
                </button>
              </div>
            </div>
          </div>
      )}
          <FullscreenAlert onConfirm={handleConfirm}/>
        </div>
      )
      }

      export default AwayWords

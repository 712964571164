import axios from "axios";
import { Component } from "react";
import { API_URL, BASE_URL } from "../config/api";

class UserServices extends Component {
  constructor(props) {
    super(props);
    this.url = API_URL;
    this.token = localStorage.getItem("token");
  }

  AddUser(formData) {
    return axios.post(this.url + "/adduser", formData, {
      headers: {
        token: this.token,
      },
    });
  }

  AddTeacher(formData) {
    return axios.post(this.url + "/addteacher", formData, {
      headers: {
        token: this.token,
      },
    });
  }

  getUser(token) {
    console.log("getuser token değeri boş", token);
    return axios.get(this.url + "/user", {
      headers: {
        token: this.token,
      },
    });
  }

  delUser(token, user_name) {
    console.log("getuser token değeri boş", token);

    return axios.get(this.url + "/deluser", {
      headers: {
        token: this.token,
        username: user_name,
      },
    });
  }

  firstdelluser(token) {
    return axios.get(this.url + "/firstdelluser", {
      headers: {
        token: this.token,
      },
    });
  }

  updateUser(token, formData) {
    console.log("updateuser token değeri boş", formData.user_name);
    return axios.post(
      this.url + "/updateuser/" + formData.user_name,
      formData,
      {
        headers: {
          token,
        },
      }
    );
  }

  Login(formData) {
    return axios.post(`${BASE_URL}/login`, formData);
  }

  Activate(formData) {
    return axios.post(this.url + "/user/isactivate", formData, {
      headers: {
        token: this.token,
      },
    });
  }

  getCode(email) {
    return axios.post(`${API_URL}/sendcode`, { email });
  }

  updatePassword(formData) {
    return axios.post(
      "https://api.gelistrio.com/teacher/updatepassword",
      { formData },
      {
        headers: {},
      }
    );
  }

  getUserByToken(token) {
    return axios.get("https://api.gelistrio.com/getuserbytoken", {
      headers: {
        token: token,
      },
    });
  }
}

export default UserServices;

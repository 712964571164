import React, { useState, useEffect } from 'react'
import { playCongrulationSound } from '../../effect/Congrulation'
import { playCorrectSound } from '../../effect/Correct'
import { playInCorrectSound } from '../../effect/Incorrect'
import LoaderSimple from '../LoadPage/LoaderSimple'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import Header from '../Header'
import Footer from '../Footer'

const Ikara = () => {
  const [isFinish, setIsFinish] = useState(false)
  const [isStart, setIsStart] = useState(false)
  const [remainingGames, setRemainingGames] = useState(1)

  const [counter, setCounter] = useState(0)
  const [startTime, setStartTime] = useState(null)
  const [elapsedTime, setElapsedTime] = useState('00:00')
  const [time, setTime] = useState(0)
  const [mode, setMode] = useState('start') // start, normal, middleHard, hard
  const [errorMessage, setErrorMessage] = useState('')

  const levelLabels = {
    start: 'Kolay',
    normal: 'Normal',
    middleHard: 'Orta Zor',
    hard: 'Zor',
  }

  const generateNumberByMode = (mode) => {
    switch (mode) {
      case 'start':
        return Math.floor(Math.random() * 9 + 1) // 1-9 arası
      case 'normal':
        return Math.floor(Math.random() * 90 + 10) // 10-99 arası
      case 'middleHard':
        return Math.floor(Math.random() * 900 + 100) // 100-999 arası
      case 'hard':
        return Math.floor(Math.random() * 9000 + 1000) // 1000-9999 arası
      default:
        return Math.floor(Math.random() * 90 + 10)
    }
  }

  const [operation, setOperation] = useState({
    num1: generateNumberByMode(mode),
    num2: generateNumberByMode(mode),
    operator: '+',
    result: 0,
    extraDigits: [Math.floor(Math.random() * 9), Math.floor(Math.random() * 9)],
    positions: [],
  })
  console.log(mode)
  const token = window.localStorage.getItem('token')
  const [isConfirmed, setIsConfirmed] = useState(false)

  const [totalRounds, setTotalRounds] = useState(3)

  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  const handleConfirm = () => {
    setIsConfirmed(true)
  }

  const handleStart = () => {
    setIsStart(true)
    setStartTime(Date.now())
  }

  const generateNewGame = () => {
    let operator = Math.random() < 0.5 ? '+' : '-'
    let num1 = generateNumberByMode(mode)
    let num2 = generateNumberByMode(mode)
    let result = 0
    const temp = num2

    if (operator === '+') {
      result = num1 + num2
    }
    if (operator === '-') {
      if (num1 > num2) {
        result = num1 - num2
      } else {
        num2 = num1
        num1 = temp
        result = num1 - num2
      }
    }

    let extraDigits = [
      Math.floor(Math.random() * 9),
      Math.floor(Math.random() * 9),
    ]

    // Ensure the extra digits do not match adjacent digits
    while (
      extraDigits[0] === parseInt(num1.toString()[0]) ||
      extraDigits[0] === parseInt(num1.toString().slice(-1))
    ) {
      extraDigits[0] = Math.floor(Math.random() * 9)
    }

    while (
      extraDigits[1] === parseInt(num2.toString()[0]) ||
      extraDigits[1] === parseInt(num2.toString().slice(-1))
    ) {
      extraDigits[1] = Math.floor(Math.random() * 9)
    }

    const positions = [
      Math.floor(Math.random() * 2),
      Math.floor(Math.random() * 2) + 3,
    ]

    // Set the operation object
    setOperation({ num1, num2, operator, result, extraDigits, positions })

    // Create operationArray based on operation
    const operationString = `${num1}${operator}${num2}=${result}`
    let operationArray = operationString.split('')

    if (positions.length === 2) {
      operationArray.splice(positions[0], 0, extraDigits[0].toString())
      operationArray.splice(
        positions[1] + num1.toString().length + 1,
        0,
        extraDigits[1].toString()
      )
    }
  }
  useEffect(() => {
    if (counter === 0 && isStart) {
      setStartTime(Date.now())
    }
    // Change this condition to check remainingGames against totalRounds
    if (remainingGames > totalRounds) {
      playCongrulationSound()
      setIsFinish(true)
      setCounter(0)
      setStartTime(null)
    } else if (isStart) {
      generateNewGame()
    }

    const timer = setInterval(() => {
      if (!isFinish && startTime !== null && isStart) {
        const now = Date.now()
        const timePassed = Math.floor((now - startTime) / 1000)
        setTime(timePassed)
        const minutes = ('0' + Math.floor(timePassed / 60)).slice(-2)
        const seconds = ('0' + (timePassed % 60)).slice(-2)
        setElapsedTime(`${minutes}:${seconds}`)
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [counter, isFinish, totalRounds, remainingGames, startTime, isStart])

  const [selectedPositions, setSelectedPositions] = useState([])
  const [numClick, setNumClick] = useState(0)

  const togglePosition = (position) => {
    if (numClick < 2 || selectedPositions.includes(position)) {
      if (selectedPositions.includes(position)) {
        setSelectedPositions(selectedPositions.filter((p) => p !== position))
        setNumClick(numClick - 1)
      } else {
        setSelectedPositions([...selectedPositions, position])
        setNumClick(numClick + 1)
      }
    }
  }

  const checkAnswer = () => {
    // Orijinal işlem stringi
    const operationStringWithoutExtra = `${operation.num1}${operation.operator}${operation.num2}=${operation.result}`

    // Kullanıcı tarafından düzenlenmiş işlem
    let currentOperationArray = [...operationArray]

    // Seçilen pozisyonları işleme dizisinden kaldır
    selectedPositions
      .sort((a, b) => b - a) // Ters sırada sort, doğru index'leri kaldırmak için
      .forEach((position) => {
        currentOperationArray.splice(position, 1)
      })

    const filteredOperationString = currentOperationArray.join('')

    // Karşılaştırmayı kontrol et
    if (filteredOperationString === operationStringWithoutExtra) {
      playCorrectSound()
      setCounter((prev) => prev + 1)
      setRemainingGames((prev) => prev + 1)
      setSelectedPositions([])
      setNumClick(0)
      setErrorMessage('') // Hata mesajını temizle
    } else {
      playInCorrectSound()
      setErrorMessage('Yanlış kombinasyon. Tekrar deneyin.')

      // 1 saniye sonra hata mesajını kaldır
      setTimeout(() => {
        setErrorMessage('')
      }, 800)
    }
  }
  const restartGame = () => {
    setIsFinish(false)
    setCounter(0)
    setRemainingGames(1) // Reset to 1 instead of numGame
    setSelectedPositions([])
    setNumClick(0)
    setTime(0)
    setElapsedTime('00:00')
    setStartTime(Date.now())
    setMode(mode)
    generateNewGame()
    setIsStart(false)
  }
  const operationString = `${operation.num1}${operation.operator}${operation.num2}=${operation.result}`
  let operationArray = operationString.split('')

  if (operation.positions.length === 2) {
    operationArray.splice(
      operation.positions[0],
      0,
      operation.extraDigits[0].toString()
    )
    operationArray.splice(
      operation.positions[1] + operation.num1.toString().length + 1,
      0,
      operation.extraDigits[1].toString()
    )
  }

  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/attention-memory-logic-math`
  }

  return (
    <div>
      <div className="absolute top-0 w-full">
        <Header title="İkara" returnType="attention-memory">
          <button
            onClick={restartGame}
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
          >
            Sıfırla
          </button>
        </Header>
      </div>

      <div className="fixed right-4 top-24 w-40 h-16 bg-blue-400 rounded-lg p-4 flex flex-col items-center justify-center">
        <div className="text-xl text-white">Süre: {elapsedTime}</div>
        <div className="text-xl text-white">
          Tur: {remainingGames}/{totalRounds}
        </div>
      </div>

      <div className="flex flex-col items-center justify-center h-screen">
        <div className="flex justify-center mb-4 text-4xl">
          {operationArray.map((digit, index) => (
            <div
              key={index}
              className={`w-16 h-16 flex justify-center items-center ${
                selectedPositions.includes(index) &&
                digit !== '=' &&
                digit !== '+' &&
                digit !== '-'
                  ? 'bg-white'
                  : 'bg-blue-300'
              } text-center cursor-pointer border-2 border-blue-500 rounded-lg m-1 ${
                digit === '=' || digit === '+' || digit === '-'
                  ? 'pointer-events-none'
                  : ''
              }`}
              onClick={() => togglePosition(index)}
            >
              {digit}
            </div>
          ))}
        </div>
        <button
          className="bg-blue-600 text-white px-4 py-2 rounded text-lg"
          onClick={checkAnswer}
        >
          Kontrol Et
        </button>
        <div className="mt-2 min-h-[24px]">
          {errorMessage ? (
            <div className="text-red-500">{errorMessage}</div>
          ) : (
            <div>&nbsp;</div>
          )}
        </div>
        {isFinish && (
          <div className="bg-gray-300 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
            <div className="bg-white p-8 rounded-md text-center">
              <p className="text-2xl">Tebrikler! Egzersizi tamamlandınız.</p>
              <div>
                <p style={{ fontSize: '24px' }}>
                  Tamamlama Süreniz: {elapsedTime}
                </p>
                <button
                  className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
                  onClick={restartGame}
                >
                  Tekrar
                </button>
                <div>
                  <button
                    onClick={handleReturnDashboard}
                    className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
                  >
                    Egzersiz Sayfasına Dön
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
      {!isStart && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
          <div className="bg-gray-300 p-8 rounded-md text-center w-1/3">
            <h2 className="font-semibold text-2xl p-1">İkara Egzersizi</h2>
            {/* Süre Ayarı */}
            <div className="bg-white p-4 rounded-lg shadow-md mb-6">
              <label
                htmlFor="numgame"
                className="block text-gray-700 font-semibold mb-2 text-left"
              >
                🕒 İşlem Sayısı: <span className="font-bold">{totalRounds}</span>
              </label>
              <input
                id="numgame"
                type="range"
                min="1"
                max="10"
                step="1"
                value={totalRounds}
                onChange={(e) => setTotalRounds(parseInt(e.target.value))}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-blue-500"
              />
              <div className="flex justify-between text-sm text-gray-500 mt-1">
                <span>1</span>
                <span>10</span>
              </div>
            </div>

            {/* Zorluk Seviyesi Ayarı */}
            <div className="bg-white p-4 rounded-lg shadow-md mb-6">
              <label
                htmlFor="difficultyLevel"
                className="block text-gray-700 font-semibold mb-2 text-left"
              >
                🧠 Zorluk Seviyesi:{' '}
                <span className="font-bold">
                  {levelLabels[mode] || 'Seviye Seçin'}
                </span>
              </label>
              <select
                id="difficultyLevel"
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-400 focus:outline-none"
                value={mode}
                onChange={(e) => setMode(e.target.value)}
              >
                <option value="start">Kolay</option>
                <option value="normal">Normal</option>
                <option value="middleHard">Orta Zor</option>
                <option value="hard">Zor</option>
              </select>
            </div>

            <button
              className="bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-400 transition duration-200"
              onClick={handleStart}
            >
              🚀 Başla
            </button>
          </div>
        </div>
      )}
      <FullscreenAlert onConfirm={handleConfirm} />
    </div>
  )
}

export default Ikara

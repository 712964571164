import React from 'react';

/**
 * LoadingSpinner - Genel yükleniyor animasyonu bileşeni.
 * @param {string} message - İsteğe bağlı yükleniyor mesajı.
 */
const LoadingSpinner = ({ message = 'Yükleniyor...' }) => {
    return (
        <div className="flex flex-col justify-center items-center h-screen">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-green-500 m-2"></div>
            <p className="text-lg font-semibold text-gray-600">{message}</p>
        </div>
    );
};

export default LoadingSpinner;
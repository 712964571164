import React, { useState, useEffect } from 'react'
import { playCorrectSound } from '../../effect/Correct'
import { playCongrulationSound } from '../../effect/Congrulation'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import Footer from '../Footer'
import Header from '../Header'
import VocabolaryServices from '../../Services/VocabolaryServices'

const turkishToUpperCase = (str) => {
  const charMap = {
    'i': 'İ',
    'ı': 'I',
    'ş': 'Ş',
    'ğ': 'Ğ',
    'ü': 'Ü',
    'ö': 'Ö',
    'ç': 'Ç'
  };

  return str.replace(/[iışğüöç]/g, char => charMap[char] || char).toUpperCase();
};

const generateRandomLetter = () => {
  const alphabet = 'ABCDEFGHİJKLMNOÖÜŞIPRSTUVYZ'
  return alphabet[Math.floor(Math.random() * alphabet.length)]
}

const placeWordsInMatrix = (matrix, wordsToFind) => {
  const placedWords = new Set()
  const rows = matrix.length
  const columns = matrix[0].length

  const checkSpace = (row, col, word, vertical) => {
    for (let i = 0; i < word.length; i++) {
      const newRow = vertical ? row + i : row
      const newCol = vertical ? col : col + i
      if (
        newRow >= rows ||
        newCol >= columns ||
        matrix[newRow][newCol] !== ''
      ) {
        return false
      }
    }
    return true
  }

  const placeWord = (row, col, word, vertical) => {
    for (let i = 0; i < word.length; i++) {
      const newRow = vertical ? row + i : row
      const newCol = vertical ? col : col + i
      matrix[newRow][newCol] = turkishToUpperCase(word[i]);
    }
  }

  wordsToFind.forEach((word) => {
    let placed = false
    while (!placed) {
      const vertical = Math.random() < 0.5
      const row = Math.floor(Math.random() * rows)
      const col = Math.floor(Math.random() * columns)

      if (checkSpace(row, col, word, vertical)) {
        placeWord(row, col, word, vertical)
        placed = true
        placedWords.add(turkishToUpperCase(word));
      }
    }
  })

  return placedWords
}

const generateMatrix = (wordsToFind, rows, columns) => {
  const matrix = Array.from({ length: rows }, () =>
    Array.from({ length: columns }, () => '')
  )

  const placedWords = placeWordsInMatrix(matrix, wordsToFind)

  for (let i = 0; i < rows; i++) {
    for (let j = 0; j < columns; j++) {
      if (matrix[i][j] === '') {
        matrix[i][j] = generateRandomLetter()
      }
    }
  }

  return { matrix, placedWords }
}

const LetterFind = () => {
  const [matrix, setMatrix] = useState([])
  const [allWords, setAllWords] = useState([])
  const [wordsToFind, setWordsToFind] = useState([])
  const [foundWords, setFoundWords] = useState([])
  const [selectedCells, setSelectedCells] = useState([])
  const [isStart, setIsStart] = useState(false)
  const [isFinish, setIsFinish] = useState(false)
  const [currentStage, setCurrentStage] = useState(1)
  const [rows, setRows] = useState(17)
  const [columns, setColumns] = useState(20)
  const [vocabolery, setVocabolery] = useState([])
  const [selectedLevel, setSelectedLevel] = useState('')
  const [selectedOption, setSelectedOption] = useState('')
  const [availableOptions, setAvailableOptions] = useState([])
  const token = window.localStorage.getItem('token')
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [elapsedTime, setElapsedTime] = useState(0)
  const vocabolaryservices = new VocabolaryServices()
  const [selectedMatrixSize, setSelectedMatrixSize] = useState('14x14');
  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  const handleLevelChange = (event) => {
    setSelectedLevel(event.target.value);
    setAvailableOptions([]); // Önceki seçenekleri temizle
    setSelectedOption(''); // Seçili alt seçeneği sıfırla
    setAllWords([]); // Kelimeleri temizle
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    vocabolaryservices
        .getVocabolary('letter-find')
        .then((response) => {
          setVocabolery(response.data)
          console.log(response.data)
        })
        .catch((error) => {
          console.error('Kategorileri alırken hata oluştu:', error)
        })
  }, [])

  useEffect(() => {
    if (selectedLevel) {
      const selectedLevelData = vocabolery?.find((lvl) => lvl.level === parseInt(selectedLevel));
      if (selectedLevelData && selectedLevelData.data?.[0]?.words?.length > 0) {
        setAvailableOptions(
            selectedLevelData.data[0].words.map((_, index) => `Seçenek ${index + 1}`)
        );
      } else {
        setAvailableOptions([]);
        console.warn('Seçilen seviye için veri bulunamadı veya uygun formatta değil.');
      }
    }
  }, [selectedLevel]);

  useEffect(() => {
    if (selectedOption !== '') {
      const selectedLevelData = vocabolery?.find((lvl) => lvl.level === parseInt(selectedLevel));
      if (
          selectedLevelData &&
          selectedLevelData.data?.[0]?.words?.[parseInt(selectedOption)]
      ) {
        setAllWords(selectedLevelData.data[0].words[parseInt(selectedOption)] || []);
        setIsStart(false); // Egzersizi durdur
        setIsFinish(false); // Tamamlanma durumunu sıfırla
        console.log('Seçilen seçenek uygulandı ve arayüz güncellendi.');
      } else {
        setAllWords([]);
        console.warn('Seçilen seçenek için veri bulunamadı veya uygun formatta değil.');
      }
    }
  }, [selectedOption]);

  const handleConfirm = () => {
    setIsConfirmed(true)
  }

  useEffect(() => {
    if (isStart && !isFinish) {
      const timer = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1)
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [isStart, isFinish])


  useEffect(() => {
    if (allWords.length > 0) {
      const wordsForStage = allWords.slice(
        (currentStage - 1) * 6,
        currentStage * 6
      )
      setWordsToFind(wordsForStage)
    }
  }, [allWords, currentStage])

  useEffect(() => {
    const { matrix, placedWords } = generateMatrix(wordsToFind, rows, columns)
    setMatrix(matrix)
  }, [wordsToFind, rows, columns])



  const restartExercise = () => {
    setIsFinish(false)
    setFoundWords([])
    setSelectedCells([])
    setCurrentStage(1)
    const { matrix, placedWords } = generateMatrix(wordsToFind, rows, columns)
    setMatrix(matrix)
    setElapsedTime(0)
    setIsStart(false)
  }

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
  }

  const handleCellClick = (i, j) => {
    let selectedWord = null
    let selectedWordOrientation = null
    let selectedWordPosition = null

    const directions = [
      { name: 'horizontal', dx: 1, dy: 0 },
      { name: 'vertical', dx: 0, dy: 1 },
    ]

    for (const direction of directions) {
      for (const word of wordsToFind) {
        for (let k = 0; k < word.length; k++) {
          const startRow = i - k * direction.dy
          const startCol = j - k * direction.dx
          let checkWord = ''
          for (let l = 0; l < word.length; l++) {
            const currentRow = startRow + l * direction.dy
            const currentCol = startCol + l * direction.dx
            if (
              currentRow < 0 ||
              currentRow >= rows ||
              currentCol < 0 ||
              currentCol >= columns
            ) {
              break
            }
            checkWord += matrix[currentRow][currentCol]
          }
          // Türkçe uyumlu büyük harf karşılaştırması
          if (turkishToUpperCase(checkWord) === turkishToUpperCase(word)) {
            selectedWord = word;
            selectedWordOrientation = direction.name;
            selectedWordPosition = { row: startRow, col: startCol };
            break;
          }
        }
        if (selectedWord) break
      }
      if (selectedWord) break
    }

    if (selectedWord && !foundWords.includes(selectedWord)) {
      setFoundWords([...foundWords, selectedWord])
      setSelectedCells((cells) => [
        ...cells,
        ...getWordCells(
          matrix,
          selectedWord,
          selectedWordPosition.row,
          selectedWordPosition.col,
          selectedWordOrientation
        ),
      ])

      if (foundWords.length + 1 === wordsToFind.length) {
        if (currentStage === 3) {
          playCongrulationSound()
          setIsFinish(true)
        } else {
          setTimeout(() => {
            setCurrentStage(currentStage + 1)
            setFoundWords([])
            setSelectedCells([])
            playCorrectSound()
          }, 2000)
        }
      } else {
        playCorrectSound()
      }
    }
  }

  const handleStart = () => {
    const [selectedRows, selectedColumns] = selectedMatrixSize.split('x').map(Number);
    setRows(selectedRows);
    setColumns(selectedColumns);

    // Oyunu başlat
    setIsStart(true);
    const { matrix, placedWords } = generateMatrix(wordsToFind, selectedRows, selectedColumns);
    setMatrix(matrix);
  }

  const getWordCells = (matrix, word, row, col, orientation) => {
    const cells = []
    if (orientation === 'horizontal') {
      for (let c = col; c < col + word.length; c++) {
        cells.push({ row, col: c })
      }
    } else if (orientation === 'vertical') {
      for (let r = row; r < row + word.length; r++) {
        cells.push({ row: r, col })
      }
    }
    return cells
  }

  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/word-recognition`
  }


  return (
    <div className="h-screen w-full flex justify-center items-center">
      <div className="w-full fixed top-0">
        <Header title={"Kelime Avı"} returnType="word-recognition">
          <button
              onClick={restartExercise}
              className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
          >
            Sıfırla
          </button>
        </Header>
      </div>
      <div
          className="absolute top-0 right-0 mt-16 mr-8 p-4 w-40 flex flex-col justify-center items-center border-2 border-b-gray-100 rounded-md text-lg font-semibold">
        {formatTime(elapsedTime)}
      </div>
      <div className="flex flex-col justify-center items-center mt-4 ml-6">
        <div className="mb-4 bg-blue-100 h-5/6 mt-4 rounded-xl overflow-auto">
          <table>
            <tbody>
              {matrix.map((row, i) => (
                <tr key={i}>
                  {row.map((cell, j) => (
                    <td
                      key={j}
                      className={`border text-sm font-semibold h-4 border-gray-300 p-3 cursor-pointer ${
                        selectedCells.some((c) => c.row === i && c.col === j)
                          ? 'bg-green-500'
                          : 'hover:bg-blue-200'
                      }`}
                      onClick={() => handleCellClick(i, j)}
                    >
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
      <div className="w-1/4 h-4/5  ml-4 mr-4 mt-11 flex-col flex justify-center items-center">
        <h2 className="text-xl font-semibold">Bulunacak Kelimeler:</h2>
        <div className="flex flex-col">
          {wordsToFind.map((word, index) => (
              <div
                  key={index}
                  className={`my-4 border border-gray-300 p-3 text-center ${
                      foundWords.includes(word) ? 'bg-green-500' : 'bg-blue-200'
                  } rounded-xl`}
              >
                {turkishToUpperCase(word)}
              </div>
          ))}
        </div>
      </div>
      {!isStart && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
          <div className="bg-gray-300 p-8 rounded-md text-center w-1/3">
            <h2 className="font-semibold text-2xl p-1">Kelime Avı Egzersizi</h2>
            {/* Seviye Seçimi */}
            <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
              <label
                  htmlFor="levelSelect"
                  className="block text-gray-700 font-semibold mb-2 text-left"
              >
                📚 Seviye Seçimi:
              </label>
              <select
                  id="levelSelect"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={selectedLevel}
                  onChange={handleLevelChange}
              >
                <option value="" disabled>Seviye Seç</option>
                {vocabolery.map((levelData) => (
                    <option key={levelData.level} value={levelData.level}>
                      Seviye {levelData.level}
                    </option>
                ))}
              </select>
            </div>
            {/* Alt Seçenek */}
            {availableOptions.length > 0 && (
                <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
                  <label
                      htmlFor="optionSelect"
                      className="block text-gray-700 font-semibold mb-2 text-left"
                  >
                    📝 Alt Seçenek Seçimi:
                  </label>
                  <select
                      id="optionSelect"
                      className="w-full p-2 border border-gray-300 rounded-md"
                      value={selectedOption}
                      onChange={handleOptionChange}
                  >
                    <option value="" disabled>Bir Seçenek Seç</option>
                    {availableOptions.map((option, index) => (
                        <option key={index} value={index}>
                          {option}
                        </option>
                    ))}
                  </select>
                </div>
            )}
            {/* Matris Boyutu Seçimi */}
            <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
              <label
                  htmlFor="matrixSizeSelect"
                  className="block text-gray-700 font-semibold mb-2 text-left"
              >
                📏 Matris Boyutu Seçimi:
              </label>
              <select
                  id="matrixSizeSelect"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={selectedMatrixSize}
                  onChange={(e) => setSelectedMatrixSize(e.target.value)}
              >
                <option value="14x14">14 x 14</option>
                <option value="14x17">14 x 17</option>
                <option value="14x22">14 x 22</option>
              </select>
            </div>
            <button
              className="bg-blue-400 text-white py-2 px-4 mt-4 rounded hover:bg-blue-300"
              onClick={handleStart}
            >
              🚀 Başla
            </button>
          </div>
        </div>
      )}
      {isFinish && (
        <div className="bg-gray-500 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
          <div
            className="bg-white p-8 rounded-md text-center"
            style={{ width: '300px' }}
          >
            <p style={{ fontSize: '20px' }}>
              Tebrikler! Egzersizi tamamladınız.
            </p>
            <p>Geçen Süre: {formatTime(elapsedTime)}</p>
            <button
              className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
              onClick={restartExercise}
            >
              Tekrar
            </button>
            <div>
              <button
                onClick={handleReturnDashboard}
                className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
              >
                Egzersiz Sayfasına Dön
              </button>
            </div>
          </div>
        </div>
      )}
      <FullscreenAlert onConfirm={handleConfirm} />
    </div>
  )
}

export default LetterFind

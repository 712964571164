const API_CONFIG = {
   BASE_URL: "https://api.gelistrio.com",
    PROD_URL: "https://api.gelistrio.com",
    TEACHER_PATH: "/teacher",
 };

// const API_CONFIG = {
//     BASE_URL: "http://localhost:5000",
//     PROD_URL: "http://localhost:5000",
//     TEACHER_PATH: "/teacher",
// };


// Development ortamında local URL'i, production'da gerçek URL'i kullan
const BASE_URL = process.env.NODE_ENV === 'production' ? API_CONFIG.PROD_URL : API_CONFIG.BASE_URL;
const API_URL = `${BASE_URL}${API_CONFIG.TEACHER_PATH}`;

export { API_URL, BASE_URL }; 
import { React, useState, useEffect } from 'react'
import { playCorrectSound } from '../../effect/Correct'
import { playCongrulationSound } from '../../effect/Congrulation'
import { playInCorrectSound } from '../../effect/Incorrect'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import Footer from '../Footer'
import Header from '../Header'
import VocabolaryServices from "../../Services/VocabolaryServices";

const FindMeaning = () => {
  const token = window.localStorage.getItem('token')
  const [isLoading, setIsLoading] = useState(true)
  const [questions, setQuestions] = useState([''])
  const [isConfirmed, setIsConfirmed] = useState(false)

  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  const handleConfirm = () => {
    setIsConfirmed(true)
  }
// Rastgele sıralama fonksiyonu
  const shuffleArray = (array) => {
    return array
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
  };
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [userAnswer, setUserAnswer] = useState('')
  const [correct, setCorrect] = useState(0)
  const [incorrect, setIncorrect] = useState(0)
  const [timer, setTimer] = useState(0)
  const [isStart, setIsStart] = useState(false)
  const [isFinish, setIsFinish] = useState(false)
  const [elapsedSeconds, setElapsedSeconds] = useState(0)
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [vocabolery, setVocabolery] = useState([])
  const [selectedLevel, setSelectedLevel] = useState('')

  const vocabolaryservices = new VocabolaryServices()

  useEffect(() => {
    vocabolaryservices
        .getVocabolary('findmeaning')
        .then((response) => {
          if (response.data && Array.isArray(response.data)) {
            setVocabolery(response.data);

            // İlk seviye otomatik yüklenirse soruları rastgele sırala
            if (response.data[0]?.data[0]?.questions) {
              const shuffledQuestions = response.data[0].data[0].questions.map((question) => ({
                ...question,
                options: shuffleArray(question.options),
              }));
              setQuestions(shuffledQuestions);
            }

            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error('Veri alınırken hata oluştu:', error);
          setIsLoading(false);
        });
  }, []);

  const uniqueLevels = vocabolery.length > 0
      ? Array.from(new Set(vocabolery.map(item => item.level_exercise)))
      : []




  const handleStart = () => {
    setIsStart(true)
    setTimer(getInitialTimerValue())
  }

  const handleOptionClick = (option) => {
    if (!showCorrectAnswer) { // Only allow clicks if the correct answer is not being shown
      setUserAnswer(option);
    }
  };

  const handleLevelChange = (event) => {
    const selected = event.target.value;
    setSelectedLevel(selected);

    // API'den gelen veriyi kontrol et
    console.log('Selected Level:', selected);
    console.log('Full Vocabulary Data:', vocabolery);

    const selectedData = vocabolery.find(
        (levelData) => levelData.level_exercise === selected
    );

    console.log('Selected Data:', selectedData);

    if (selectedData && Array.isArray(selectedData.data[0].questions)) {
      setQuestions(selectedData.data[0].questions);
      setCurrentQuestionIndex(0);
      setCorrect(0);
      setIncorrect(0);
      setIsFinish(false);
      setIsStart(false);
      setElapsedSeconds(0);
    } else {
      console.warn('Seçilen seviyede geçerli veri bulunamadı!');
      setQuestions([]);
    }
  };

  useEffect(() => {
    let interval
    if (isStart && !isFinish) {
      interval = setInterval(() => {
        setElapsedSeconds((prevSeconds) => prevSeconds + 1)
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [isStart, isFinish])

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
        .toString()
        .padStart(2, '0')}`
  }

  const restartExercise = () => {
    setCurrentQuestionIndex(0)
    setCorrect(0)
    setIncorrect(0)
    setIsFinish(false)
    setIsStart(false)
    setTimer(getInitialTimerValue())
    setElapsedSeconds(0)
    setShowCorrectAnswer(false); // Reset showCorrectAnswer
    setCorrectAnswer(''); // Reset correctAnswer
  }

  const getInitialTimerValue = () => {
    switch (questions[currentQuestionIndex]?.level) {
      case 'Easy':
        return 7
      case 'Normal':
        return 5
      case 'Hard':
        return 4
      default:
        return 0 // or some other default value
    }
  }

  useEffect(() => {
    if (currentQuestionIndex >= questions.length) {
      if (incorrect > 0) {
        setIsFinish(true)
      } else {
        setIsFinish(true)
        playCongrulationSound()
      }
    }
  }, [currentQuestionIndex])


  useEffect(() => {
    let intervalId

    if (isStart && !isFinish && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1)
      }, 1000)
    } else {
      clearInterval(intervalId)
    }

    if (timer === 0 && isStart && !isFinish && !showCorrectAnswer) {
      setShowCorrectAnswer(true);
      setCorrectAnswer(questions[currentQuestionIndex].response);
      playInCorrectSound();
      setTimeout(() => {
        setShowCorrectAnswer(false);
        setCorrectAnswer('');
        setIncorrect((prevIncorrect) => prevIncorrect + 1);
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        setUserAnswer('');
        setTimer(getInitialTimerValue());
      }, 2000);
    }

    return () => clearInterval(intervalId)
  }, [isStart, isFinish, timer, showCorrectAnswer])


  useEffect(() => {
    if (userAnswer !== '') {
      if (userAnswer === questions[currentQuestionIndex].response) {
        playCorrectSound()
        setCorrect((prevCorrect) => prevCorrect + 1)
      } else {
        playInCorrectSound();
        setShowCorrectAnswer(true);
        setCorrectAnswer(questions[currentQuestionIndex].response);

        setTimeout(() => {
          setShowCorrectAnswer(false);
          setCorrectAnswer('');
          setIncorrect((prevIncorrect) => prevIncorrect + 1);
          setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
          setUserAnswer('');
          setTimer(getInitialTimerValue());
        }, 2000);

        return;
      }

      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setUserAnswer('');
      setTimer(getInitialTimerValue());
    }
  }, [userAnswer])

  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/word-recognition'`
  }

  return (
      <div className="overflow-hidden h-screen flex justify-center items-center">
        <div className="w-full fixed top-0">
          <Header title={'Anlam Bilgisi'} returnType="word-recognition">
            <button
                onClick={restartExercise}
                className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
            >
              Sıfırla
            </button>
          </Header>
        </div>
        <div className="flex flex-col items-center justify-center h-3/4 w-3/4">
          <div className="text-center w-3/4">
            {questions.length > 0 && questions[currentQuestionIndex] ? (
                <>
                  <h1 className="text-3xl font-semibold mb-4 break-words">
                    {questions[currentQuestionIndex]?.definition || 'Soru yükleniyor...'}
                  </h1>
                  <div className="flex flex-wrap justify-center">
                    {questions[currentQuestionIndex]?.options?.map((option, index) => (
                        <button
                            key={index}
                            className={`bg-blue-300 text-xl text-white py-2 px-4 mr-4 mt-4 mb-2 rounded hover:bg-blue-200 ${
                                showCorrectAnswer && option === correctAnswer
                                    ? 'bg-green-500'
                                    : showCorrectAnswer && option === userAnswer
                                        ? 'bg-red-500'
                                        : ''
                            }`}
                            onClick={() => handleOptionClick(option)}
                            disabled={showCorrectAnswer}
                        >
                          {option}
                        </button>
                    ))}
                  </div>
                </>
            ) : (
                <h2 className="text-xl font-semibold">Sorular yükleniyor veya mevcut değil...</h2>
            )}
          </div>        </div>

        <div className="w-1/5 h-full flex flex-col justify-center -z-10 items-center fixed right-0 top-0 bg-gray-100">
          <div>
            <div className="mb-4 flex justify-center">
              <h2 className="text-lg font-semibold">Doğru: {correct}</h2>
            </div>
            <div className="mb-4 flex justify-center">
              <h2 className="text-lg font-semibold">Yanlış: {incorrect} </h2>
            </div>
            <div className="mb-4 flex justify-center">
              <h2 className="text-lg font-semibold">Kalan Süre: {timer}</h2>
            </div>
          </div>
        </div>
        <Footer />
        {isFinish && (
            <div>
              <div className="bg-gray-500 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
                <div
                    className="bg-white p-8 rounded-md text-center"
                    style={{ width: '300px' }}
                >
                  <p style={{ fontSize: '20px' }}>
                    Tebrikler! Egzersizi tamamladınız.
                  </p>
                  <p>Doğru: {correct}</p>
                  <p>Yanlış: {incorrect}</p>
                  <p>Geçen Süre: {formatTime(elapsedSeconds)}</p>
                  <button
                      className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
                      onClick={restartExercise}
                  >
                    Tekrar
                  </button>
                  <div>
                    <button
                        onClick={handleReturnDashboard}
                        className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
                    >
                      Egzersiz Sayfasına Dön
                    </button>
                  </div>
                </div>
              </div>
            </div>
        )}
        {!isStart && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
              <div className="bg-gray-300 p-8 rounded-md text-center w-1/3">
                <h2 className="font-semibold text-2xl p-1">
                  Anlamını Bilgisi Egzersizi
                </h2>
                {/* 📚 Seviye Seçimi */}
                <div className="bg-white p-4 rounded-lg shadow-sm m-4">
                  <label
                      htmlFor="levelSelect"
                      className="block text-gray-700 font-semibold mb-2 text-left"
                  >
                    📚 Seviye Seçimi:
                  </label>
                  <select
                      id="levelSelect"
                      className="w-full p-2 border border-gray-300 rounded-md"
                      value={selectedLevel}
                      onChange={handleLevelChange}
                  >
                    {uniqueLevels.map((level, index) => (
                        <option key={index} value={level}>
                          Seviye {level}
                        </option>
                    ))}
                  </select>
                </div>
                <button
                    className={`bg-blue-400 text-white py-2 px-4 mt-4 rounded hover:bg-blue-300 ${
                        !selectedLevel || questions.length === 0 ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    onClick={handleStart}
                    disabled={!selectedLevel || questions.length === 0}
                >
                  Başlat 🚀

                </button>
              </div>
            </div>
        )}
        <FullscreenAlert onConfirm={handleConfirm}/>
      </div>
  )
}

export default FindMeaning
import React, { useState, useEffect, useRef } from 'react'
import { playCongrulationSound } from '../../effect/Congrulation'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import { CircleCheckBig, CircleX } from 'lucide-react'
import Header from '../Header'
import Footer from '../Footer'
import VocabolaryServices from "../../Services/VocabolaryServices";


const SameOrDifferent = () => {
  const [isTrue, setTrue] = useState(0)
  const [isFalse, setFalse] = useState(0)
  const [arr1, setArr1] = useState([])
  const [arr2, setArr2] = useState([])
  const [currentWordIndex, setCurrentWordIndex] = useState(0)
  const [isFinish, setFinish] = useState(false)
  const [showItems, setShowItems] = useState(false)
  const [isStart, setIsStart] = useState(false)
  const [isExerciseStarted, setIsExerciseStarted] = useState(false)
  const [isMode, setIsMode] = useState('Start')
  const [wordDisplayTime, setWordDisplayTime] = useState(2000)
  const timerId = useRef(null)
  const [selectedLevel, setSelectedLevel] = useState('')

  const [isSplit,setIsSplit] = useState('')
  const token = window.localStorage.getItem('token')
  const [isConfirmed, setIsConfirmed] = useState(false)
  const blankScreenTimerId = useRef(null) // Boş ekran zamanlayıcısı için yeni useRef
  const [vocabolery, setVocabolery] = useState([])
  const vocabolaryservices = new VocabolaryServices()

  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])
  useEffect(() => {
    vocabolaryservices
        .getVocabolary('same-or-dif')
        .then((response) => {
          setVocabolery(response.data)
        })
        .catch((error) => {
          console.error('Kategorileri alırken hata oluştu:', error)
        })
  }, [])
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && !isExerciseStarted && !isFinish && isStart) {
        handleStart()
      }
    }

    window.addEventListener('keypress', handleKeyPress)

    return () => {
      window.removeEventListener('keypress', handleKeyPress)
    }
  }, [isExerciseStarted, isFinish, isStart])

  const handleConfirm = () => {
    setIsConfirmed(true)
  }

  const handleLevelChange = (event) => {
    const selected = event.target.value;
    setSelectedLevel(selected);

    // Seçilen seviyenin verilerini bul
    const selectedData = vocabolery.find((levelData) => levelData.level === selected);

    if (selectedData && selectedData.data && Array.isArray(selectedData.data) && selectedData.data.length > 0 && selectedData.data[0].arrays) { // data ve arrays'in varlığını ve geçerliliğini kontrol et
      const [firstArray, secondArray] = selectedData.data[0].arrays;
      console.log(firstArray)
      if (Array.isArray(firstArray) && Array.isArray(secondArray)) {
        setArr1(firstArray);
        setArr2(secondArray);
        console.log('Arr1 (Kelime Dizisi 1):', firstArray);
        console.log('Arr2 (Kelime Dizisi 2):', secondArray);
      } else {
        console.warn('handleLevelChange: Diziler geçerli formatta değil!');
        setArr1([]);
        setArr2([]);
      }

      setCurrentWordIndex(0);
    } else {
      console.warn('Seçilen seviyede geçerli veri bulunamadı!');
      setArr1([]);
      setArr2([]);
    }
  };
  useEffect(() => {
    if (isExerciseStarted && !isFinish) {
      // Her kelime gösterildiğinde zamanlayıcıyı başlat
      timerId.current = setTimeout(() => {
        // Süre dolduğunda yanlış cevap olarak değerlendir
        setFalse(isFalse + 1)
        handleNextWord()
      }, wordDisplayTime)
    }

    return () => {
      clearTimeout(timerId.current)
      clearTimeout(blankScreenTimerId.current) // Boş ekran zamanlayıcısını da temizle
    } // Component unmount olduğunda veya state değiştiğinde zamanlayıcıyı temizle
  }, [currentWordIndex, isExerciseStarted, isFinish])

  const handleNextWord = () => {
    clearTimeout(timerId.current) // Bir sonraki kelimeye geçmeden önce mevcut zamanlayıcıyı temizle
    setShowItems(false) // Kelimeleri gizle

    // wordDisplayTime saniye sonra bir sonraki kelime grubunu göster
    blankScreenTimerId.current = setTimeout(() => {
      if (currentWordIndex < arr1.length - 1) {
        setCurrentWordIndex(currentWordIndex + 1)
        setShowItems(true) // Kelimeleri tekrar göster
      } else {
        playCongrulationSound()
        setFinish(true)
      }
    }, wordDisplayTime) // wordDisplayTime saniye bekle
  }
  useEffect(() => {
    if (!isExerciseStarted || !showItems) return

    const handleKeyDown = (event) => {
      if (!isFinish) {
        if (event.keyCode === 37) {
          // Sol tuş
          if (arr1[currentWordIndex] === arr2[currentWordIndex]) {
            setFalse(isFalse + 1)
          } else {
            setTrue(isTrue + 1)
          }
          handleNextWord()
        } else if (event.keyCode === 39) {
          // Sağ tuş
          if (arr1[currentWordIndex] === arr2[currentWordIndex]) {
            setTrue(isTrue + 1)
          } else {
            setFalse(isFalse + 1)
          }
          handleNextWord()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [
    currentWordIndex,
    arr1,
    arr2,
    isTrue,
    isFalse,
    isFinish,
    isExerciseStarted,
  ])

  const handleStart = () => {
    setIsExerciseStarted(true)
    setShowItems(true)
  }

  const handleStartScreen = () => {
    setIsStart(true)
  }

  const handleReset = () => {
    setCurrentWordIndex(0)
    setTrue(0)
    setFalse(0)
    setFinish(false)
    setShowItems(false)
    setIsStart(false)
    setIsExerciseStarted(false)
  }

  const shuffleArray = (array) => {

    if (!array || !Array.isArray(array) || array.length === 0) {
      console.warn('shuffleArray: Geçersiz veya boş bir dizi sağlandı!');
      return []; // Geçersiz durumda boş bir dizi döndür
    }

    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
    }
    return array
  }
  useEffect(() => {
    if (vocabolery.length > 0 && !selectedLevel) {
      const defaultLevel = vocabolery[0].level; // İlk seviye otomatik alınır
      setSelectedLevel(defaultLevel);

      // İlk seviyeye ait veriler çekilir
      const selectedData = vocabolery.find((levelData) => levelData.level === defaultLevel);

      if (selectedData && selectedData.data?.[0]?.arrays) {
        const [firstArray, secondArray] = selectedData.data[0].arrays;
        if (Array.isArray(firstArray) && Array.isArray(secondArray)) {
          setArr1(firstArray);
          setArr2(secondArray);
          console.log('Arr1 (Kelime Dizisi 1):', firstArray);
          console.log('Arr2 (Kelime Dizisi 2):', secondArray);
        }
      }
    }
  }, [vocabolery, selectedLevel]);
  const getShuffledWords = (mode, word1, word2) => {
    let words
    if (mode === 'Start') {
      words = [word1, word2]
    } else if (mode === 'Normal') {
      words = [word1, word1, word2]
    } else if (mode === 'Hard') {
      words = [word1, word1, word1, word2]
    }
    return shuffleArray(words)
  }

  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/attention-memory-logic-math`
  }

  return (
    <div className="flex flex-col h-screen w-full overflow-hidden">
      <Header title={'Aynı Farklı'} returnType="attention-memory" >
        <button
            onClick={handleReset}
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
        >
          Sıfırla
        </button>
      </Header>

      <div className="m-6 h-1/2 flex justify-center items-center text-xl border-4">
        {showItems && (
            <>
              {getShuffledWords(
                  isMode,
                  arr1[currentWordIndex],
                  arr2[currentWordIndex]
              ).map((word, index) => (
                  <div
                      key={index}
                      className="mr-4 pr-24 text-3xl"
                      style={{
                        transform: isSplit
                            ? `translateY(${index % 2 === 0 ? '-10px' : '10px'})`
                            : 'none',
                      }}
                  >
                    {word}
                  </div>
              ))}
            </>
        )}
      </div>

      <div className="flex justify-center items-center text-xl w-96 h-60 flex-col p-2 mb-12 mt-12 mx-auto">
        <div className="flex justify-center text-black space-x-8 mb-1">
          <h5 className="border-2 font-semibold p-1 text-xl">
            {'<='} Sol Tık(Farklı)
          </h5>
          <h5 className="border-2 font-semibold p-1 text-xl">
            Sağ Tık(Aynı) {'=>'}
          </h5>
        </div>
        <div className="fixed inset-x-0 bottom-0">
          <Footer />
        </div>
        <div className="flex justify-center">
          <div>
            {!isFinish && (
              <>
                <div className="border border-gray-300 m-2 px-4">
                  <h4 className="text-black text-xl font-semibold pb-2">
                    <span className="flex gap-2 items-center">
                      <CircleCheckBig
                        size={20}
                        color="#14cb1f"
                        strokeWidth={1.75}
                      />
                      {isTrue}
                    </span>

                    {}
                  </h4>
                  <h4 className="text-black text-xl font-semibold pb-2">
                    <span className="flex gap-2 items-center">
                      <CircleX size={20} strokeWidth={1.75} color="#ff0000" />
                      {isFalse}
                    </span>
                  </h4>
                </div>
              </>
            )}
            {isFinish && (
              <h4 className="text-green text-xl font-semibold pb-2">
                Tamamlandı!
              </h4>
            )}
          </div>
        </div>
        <div className="flex justify-center mb-8">
          {!isFinish && !isExerciseStarted && (
            <button
              className="p-1 border-2 font-semibold rounded-xl text-xl border-b-gray-300 text-black hover:bg-blue-200"
              onClick={handleStart}
            >
              Başla
            </button>
          )}
        </div>
      </div>
      {!isStart && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
            <div className="bg-gray-300 p-8 rounded-md text-center w-1/3">
              <h2 className="font-semibold text-2xl p-1">
                Aynı-Farklı Egzersizi
              </h2>

              {/* 📚 Seviye Seçimi */}
              <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
                <label
                    htmlFor="levelSelect"
                    className="block text-gray-700 font-semibold mb-2 text-left"
                >
                  📚 Seviye Seçimi:
                </label>
                <select
                    id="levelSelect"
                    className="w-full p-2 border border-gray-300 rounded-md"
                    value={selectedLevel}
                    onChange={handleLevelChange}
                >
                  {vocabolery.map((levelData) => (
                      <option key={levelData.level} value={levelData.level}>
                        Seviye {levelData.level}
                      </option>
                  ))}
                </select>
              </div>
              {/* 🧠 Mod Seçimi */}
              <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
                <label
                    htmlFor="modeSelect"
                    className="block text-gray-700 font-semibold mb-2 text-left"
                >
                  🧠 Mod Seçimi:
                </label>
                <select
                    id="modeSelect"
                    className="w-full p-2 border border-gray-300 rounded-md"
                    value={isMode}
                    onChange={(e) => setIsMode(e.target.value)}
                >
                  <option value="Start">Basit</option>
                  <option value="Normal">Orta</option>
                  <option value="Hard">Zor</option>
                </select>
              </div>

              {/* ⚡ Hız Ayarı */}
              <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
                <label className="block text-gray-700 font-semibold mb-2">
                  ⚡ Hız Ayarı: {wordDisplayTime} ms
                </label>
                <input
                    type="range"
                    min="500"
                    max="2000"
                    step="50"
                    value={wordDisplayTime}
                    onChange={(e) => setWordDisplayTime(parseInt(e.target.value))}
                    className="w-full"
                />
                <div className="flex justify-between text-sm text-gray-500 mt-1">
                  <span>1000 ms</span>
                  <span>2000 ms</span>
                </div>
              </div>
              {/* 🪄 Kelime Konumu Seçimi */}
              <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
                <label
                    htmlFor="splitSelect"
                    className="block text-gray-700 font-semibold mb-2 text-left"
                >
                  🪄 Kelime Konumu:
                </label>
                <select
                    id="splitSelect"
                    className="w-full p-2 border border-gray-300 rounded-md"
                    value={isSplit}
                    onChange={(e) => setIsSplit(e.target.value === 'true')}
                >
                  <option value="false">Aynı Sıra</option>
                  <option value="true">Aşağı-Yukarı</option>
                </select>
              </div>
              {/* 🚀 Devam Butonu */}
              <button
                  className="bg-blue-400 text-white py-2 px-4 mt-4 rounded hover:bg-blue-300"
                  onClick={handleStartScreen}
              >
                Başlat 🚀
              </button>
            </div>
          </div>
      )}
      {isFinish && (
        <div className="bg-gray-300 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
          <div className="bg-white p-8 rounded-md text-center">
            <p>Tebrikler! Egzersizi tamamladınız.</p>
            <p>Doğru Sayısı: {isTrue}</p>
            <p>Yanlış Sayısı: {isFalse}</p>

            <button
              onClick={handleReturnDashboard}
              className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
            >
              Egzersiz Sayfasına Dön
            </button>
            <div>
              <button
                  className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
                onClick={handleReset}
              >
                Tekrarla
              </button>
            </div>
          </div>
        </div>
      )}
      <FullscreenAlert onConfirm={handleConfirm} />
    </div>
  )
}

export default SameOrDifferent

import React, { useState, useEffect } from 'react'
import { playCongrulationSound } from '../../effect/Congrulation'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import Footer from '../Footer'
import Header from '../Header'


const RecedingNumbers = () => {
  const [offset, setOffset] = useState(0)
  const [isStart, setIsStart] = useState(true)
  const [isFinish, setIsFinish] = useState(false)
  const [remainingTime, setRemainingTime] = useState(0)
  const [exerciseDuration, setExerciseDuration] = useState(30)
  const [speed, setSpeed] = useState(500)
  const maxOffsetY = 6
  const maxOffsetX = 10
  const [showExercise, setShowExercise] = useState(false)

  const token = window.localStorage.getItem('token')
  const [numbers, setNumbers] = useState([])
  const[mode, setMode] = useState("")

  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  const [isConfirmed, setIsConfirmed] = useState(false)

  const handleConfirm = () => {
    setIsConfirmed(true)
  }

  const modeOptions = [
    { label: 'Basit', value: 'Start' },
    { label: 'Orta', value: 'Normal' },
    { label: 'Zor', value: 'Advanced' },
  ]



  useEffect(() => {
    // Sayıları yalnızca offset veya mode değiştiğinde güncelle
    let maxDigits;
    let minValue;
    if (mode === 'Start') {
      maxDigits = 1;
      minValue = 0;
    } else if (mode === 'Normal') {
      maxDigits = 2;
      minValue = 10;
    } else {
      maxDigits = 3;
      minValue = 100;
    }

    setNumbers([
      Math.floor(Math.random() * (Math.pow(10, maxDigits) - minValue)) + minValue,
      Math.floor(Math.random() * (Math.pow(10, maxDigits) - minValue)) + minValue,
      Math.floor(Math.random() * (Math.pow(10, maxDigits) - minValue)) + minValue,
      Math.floor(Math.random() * (Math.pow(10, maxDigits) - minValue)) + minValue,
      Math.floor(Math.random() * (Math.pow(10, maxDigits) - minValue)) + minValue,
    ]);
  }, [offset, mode]);

  useEffect(() => {
    if (showExercise) {
      setRemainingTime(exerciseDuration * 60) // dakikayı saniyeye çevir

      const interval = setInterval(() => {
        setOffset((prevOffset) => {
          if (prevOffset >= maxOffsetY) {
            return 0
          }
          if (prevOffset >= maxOffsetX) {
            return 0
          }
          return prevOffset + 1
        })
      }, speed)

      const timerInterval = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 1) {
            playCongrulationSound()
            clearInterval(interval)
            clearInterval(timerInterval)
            setIsFinish(true)
            return 0
          }
          return prevTime - 1
        })
      }, 1000)

      return () => {
        clearInterval(interval)
        clearInterval(timerInterval)
      }
    }
  }, [showExercise, maxOffsetX, maxOffsetY, exerciseDuration])

  const handleStart = () => {
    setIsStart(false)
    setExerciseDuration(exerciseDuration)
    setShowExercise(true)
  }

  const handleRepeat = () => {
    setOffset(0)
    setIsStart(true)
    setIsFinish(false)
    setRemainingTime(exerciseDuration)
    setShowExercise(false)
  }

  const calculatePosition = (axis, direction) => {
    const start = direction * 3
    let move
    if (axis === 'Y') {
      move = direction * Math.min(offset, maxOffsetY) * 2
    } else {
      move = direction * Math.min(offset, maxOffsetX) * 4
    }
    const value = `${start + move}rem`
    return { transform: `translate${axis}(${value})` }
  }

  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/eye-yoga-peripheral-vision`
  }

  return (
    <div className="flex justify-center items-center relative h-screen">
      <div className="absolute top-0 w-full">
        <Header title={'Genişleyen Sayılar'} returnType="eye-yoga">
          <button
              onClick={handleRepeat}
              className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
          >
            Sıfırla
          </button>
        </Header>
      </div>
      {showExercise && (
          <>
            <div className="relative">
              <div
                  className="absolute top-1/2 left-1/2 w-14 h-14 rounded-full text-xl text-black border-2 border-black flex justify-center items-center  font-semibold">
              {numbers[0]}
            </div>
            <div
              style={calculatePosition('X', -1.25)}
              className="absolute top-1/2 left-1/2 w-14 h-14 rounded-full text-xl text-black border-2 border-black flex justify-center items-center  font-semibold"
            >
              {numbers[1]}
            </div>
            <div
              style={calculatePosition('X', 1.25)}
              className="absolute top-1/2 left-1/2 w-14 h-14 rounded-full text-xl text-black border-2 border-black flex justify-center items-center  font-semibold"
            >
              {numbers[2]}
            </div>
            <div
              style={calculatePosition('Y', -1.25)}
              className="absolute top-1/2 left-1/2 w-14 h-14 rounded-full text-xl text-black border-2 border-black flex justify-center items-center  font-semibold"
            >
              {numbers[3]}
            </div>
            <div
              style={calculatePosition('Y', 1.25)}
              className="absolute top-1/2 left-1/2 w-14 h-14 rounded-full text-xl text-black border-2 border-black flex justify-center items-center  font-semibold"
            >
              {numbers[4]}
            </div>
          </div>
          <div className="w-20 h-12 absolute top-20 right-4 m-4 bg-gray-500 text-white p-1 rounded-xl flex justify-center items-center">
            {`${Math.floor(remainingTime / 60)
              .toString()
              .padStart(2, '0')}:${(remainingTime % 60)
              .toString()
              .padStart(2, '0')}`}
          </div>
        </>
      )}
      <Footer />
      {!showExercise && !isFinish && (
        <button
          className="btn btn-start w-28 h-14 bg-gray-500 text-white rounded-xl text-xl hover:bg-gray-400"
          onClick={handleStart}
        >
          Başla
        </button>
      )}
      {isStart && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
          <div className="bg-gray-300 p-8 rounded-md text-center w-1/3">
            <h2 className="font-semibold text-2xl p-2">
              Genişleyen Sayılar Egzersizi
            </h2>
            {/* Egzersiz Süresi */}
            <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                ⏱️ Egzersiz Süresi: {exerciseDuration} dakika
              </label>
              <input
                  type="range"
                  min="1"
                  max="10"
                  value={exerciseDuration}
                  onChange={(e) => setExerciseDuration(parseInt(e.target.value))}
                  className="w-full"
              />
            </div>

            {/* Hız Ayarı */}
            <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                ⚡ Hız: {speed} ms
              </label>
              <input
                  type="range"
                  min="300"
                  max="2000"
                  step="100"
                  value={speed}
                  onChange={(e) => setSpeed(parseInt(e.target.value))}
                  className="w-full"
              />
            </div>

            {/* Mod Seçimi */}
            <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                🧠 Mod Seçimi:
              </label>
              <div className="flex justify-center gap-4">
                {modeOptions.map(({ label, value }) => (
                    <button
                        key={value}
                        onClick={() => setMode(value)}
                        className={`px-3 py-2 rounded ${
                            mode === value
                                ? 'bg-blue-500 text-white shadow-md'
                                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                        }`}
                    >
                      {label}
                    </button>
                ))}
              </div>
            </div>
            <button
              className="bg-blue-400 text-white py-2 px-4 mt-4 rounded hover:bg-blue-300"
              onClick={() => setIsStart(false)}
            >
              🚀 Başlat
            </button>
          </div>
        </div>
      )}
      {isFinish && (
        <div className="bg-gray-300 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
          <div className="bg-white p-8 rounded-md text-center">
            <p>Tebrikler Egzersizi bitirdiniz!</p>
            <p></p>
            <button
                className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
              onClick={handleRepeat}
            >
              Tekrarla
            </button>
            <div>
              <button
                onClick={handleReturnDashboard}
                className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
              >
                Egzersiz Sayfasına Dön
              </button>
            </div>
          </div>
        </div>
      )}
      <FullscreenAlert onConfirm={handleConfirm} />
    </div>
  )
}

export default RecedingNumbers

import React, { useState, useEffect, useRef } from 'react'
import { playCongrulationSound } from '../../effect/Congrulation'
import Footer from '../Footer'
import Header from '../Header'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import VocabolaryServices from "../../Services/VocabolaryServices";


const FirstLetterSecondLetter = () => {
  const [content, setContent] = useState("" )
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isStarted, setIsStarted] = useState(false)
  const [isFinish, setIsFinish] = useState(false)
  const [isStart, setIsStart] = useState(false)
  const contentRef = useRef(null)
  const [highlightIndex, setHighlightIndex] = useState(0) // 0: ilk kelime, 1: son kelime
  const [currentPage, setCurrentPage] = useState(0) // Sayfa numarası (0'dan başlıyor)
  const [speed, setSpeed] = useState(200)
  const wordsPerLine =10 // Her satırda kaç kelime olacağı
  const linesPerPage =8 // Her sayfada kaç satır olacağı
  const [isLoading, setIsLoading] = useState(true)
  const token = window.localStorage.getItem('token')
  const [vocabolery, setVocabolery] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  const [isConfirmed, setIsConfirmed] = useState(false)

  const vocabolaryservices = new VocabolaryServices();

  useEffect(() => {
    vocabolaryservices
        .getVocabolary("vocabolary_library")
        .then((response) => {
          setVocabolery(response.data);
        })
        .catch((error) => {
          console.error("Kategorileri alırken hata oluştu:", error);
        });
  }, []);

  const handleConfirm = () => {
    setIsConfirmed(true)
  }


  // Metni satırlara ayır
  const wordsArray = content.split(' ')
  const lines = []
  for (let i = 0; i < wordsArray.length; i += wordsPerLine) {
    lines.push(wordsArray.slice(i, i + wordsPerLine))
  }

  // Satırları sayfalara ayır
  const pages = []
  for (let i = 0; i < lines.length; i += linesPerPage) {
    pages.push(lines.slice(i, i + linesPerPage))
  }

  const handleStop = () => {
    setIsStarted(false)
  }

  const handleCategoryClick = (category) => {
    setSelectedCategory((prevCategory) =>
        prevCategory === category ? null : category
    );
  };

  useEffect(() => {
    let interval
    if (isStarted) {
      interval = setInterval(() => {
        setHighlightIndex((prevIndex) => {
          // Eğer son kelimedeysek ve son sayfanın son satırındaysak
          if (
            prevIndex === 1 &&
            currentIndex === pages[currentPage].length - 1 &&
            currentPage === pages.length - 1
          ) {
            setIsFinish(true)
            setIsStarted(false)
            playCongrulationSound()
            return 0
          } else if (prevIndex === 1) {
            // Eğer son kelimedeysek
            setCurrentIndex(
              (prevIndex) => (prevIndex + 1) % pages[currentPage].length
            )
            // Sonraki sayfa varsa sayfa numarasını arttır
            if (
              currentIndex === pages[currentPage].length - 1 &&
              currentPage < pages.length - 1
            ) {
              setCurrentPage(currentPage + 1)
            }
            return 0
          } else {
            // İlk kelimedeysek
            return 1
          }
        })

        if (contentRef.current) {
          const currentWordElement = contentRef.current.querySelector(
            `#word-${currentIndex}-${highlightIndex === 0 ? 'first' : 'last'}`
          )
          if (currentWordElement) {
            currentWordElement.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            })
          }
        }
      }, speed)
    }

    return () => clearInterval(interval)
  }, [isStarted, currentIndex, highlightIndex, currentPage])

  const handleStart = () => {
    setIsStart(true)
  }

  const handleStarted = () => {
    setIsStarted(true)
    setIsFinish(false)
    if (contentRef.current) {
      contentRef.current.scrollTop = 0
    }
  }


  const restartExercise = () => {
    setIsStarted(false)
    setIsFinish(false)
    setCurrentIndex(0)
    setCurrentPage(0)
    if (contentRef.current) {
      contentRef.current.scrollTop = 0
    }
    setIsStart(false)
    setContent("")
    setSelectedCategory(null)
  }

  const handleReset = () => {
    setIsStarted(false)
    setIsFinish(false)
    setCurrentIndex(0)
    setCurrentPage(0)
    setHighlightIndex(0)
    if (contentRef.current) {
      contentRef.current.scrollTop = 0
    }
  }

  useEffect(() => {
    setIsLoading(true); // Yüklenme başlatılıyor
    vocabolaryservices
        .getVocabolary("vocabolary_library")
        .then((response) => {
          setVocabolery(response.data);
          setIsLoading(false); // Yüklenme tamamlandı
        })
        .catch((error) => {
          console.error("Kategorileri alırken hata oluştu:", error);
          setIsLoading(false); // Hata olsa bile yüklenmeyi durdur
        });
  }, []);
  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/speed-reading-comprehension`
  }
  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <div className="absolute top-0 w-full">
        <Header title={'İlk Kelime Son Kelime'} returnType="speed-reading">
          <button
              onClick={restartExercise}
              className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
          >
            Sıfırla
          </button>
        </Header>
      </div>
      <div
          className="flex flex-col justify-center text-2xl h-3/4 p-3 w-full overflow-auto shadow-lg text-left"
          ref={contentRef}
          style={{ maxWidth: '1250px' }} // Genişliği artırmak için stil eklendi
      >
        {pages[currentPage].map((line, lineIndex) => (
          <p key={lineIndex} className="text-left break-words">
            {line.map((word, wordIndex) => (
              <span
                key={wordIndex}
                id={`word-${lineIndex}-${wordIndex === 0 ? 'first' : 'last'}`}
                style={{
                  background:
                    lineIndex === currentIndex &&
                    ((highlightIndex === 0 && wordIndex === 0) ||
                      (highlightIndex === 1 && wordIndex === line.length - 1))
                      ? 'yellow'
                      : 'transparent',
                }}
              >
                {word}
                {wordIndex !== line.length - 1 && ' '}
              </span>
            ))}
          </p>
        ))}
      </div>

      <div className="b mt-5">
        {!isStarted && (
          <button
            className="bg-blue-400 p-2 rounded-xl text-white hover:bg-blue-300"
            onClick={handleStarted}
          >
            Başla
          </button>
        )}

        {isStarted && (
          <>
            <button
              className=" bg-green-400 p-2 rounded-xl mr-4 text-white hover:bg-green-300"
              onClick={handleStop}
            >
              Durdur
            </button>
            <button
              className="bg-red-400 p-2 rounded-xl text-white hover:bg-red-300"
              onClick={handleReset}
            >
              Sıfırla
            </button>
          </>
        )}
      </div>
      <Footer />
      {!isStart && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50 overflow-auto">
            {isLoading ? (
                <div className="text-white text-lg font-semibold">Yükleniyor...</div>
            ) : (
                <div className="bg-white p-6 rounded-xl shadow-lg w-full max-w-6xl">
                  <h1 className="text-3xl font-bold text-center mb-1">İlk ve Son Kelime Egzersizine Hoşgeldiniz!</h1>
                  <h2 className="text-3xl font-bold text-center mb-6">📚 Metin Kütüphanesi</h2>

                  {/* Kategori Kartları */}
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 overflow-auto max-h-[60vh]">
                    {[...new Set(vocabolery.map((item) => item.category))].map(
                        (category, index) => (
                            <div
                                key={index}
                                className="relative group rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 cursor-pointer"
                            >
                              <div
                                  onClick={() => handleCategoryClick(category)}
                                  className={`bg-gradient-to-br from-blue-500 to-blue-700 text-white p-6 h-32 flex items-center justify-center ${
                                      selectedCategory === category ? 'border-4 border-green-500' : ''
                                  }`}
                              >
                                <h3 className="text-xl font-semibold text-center">
                                  {category}
                                </h3>
                              </div>
                              {selectedCategory === category && (
                                  <div className="absolute inset-0 bg-white p-4 rounded-lg shadow-lg z-10 overflow-auto max-h-48">
                                    <div className="flex justify-between items-center mb-4">
                                      <h4 className="text-lg font-semibold text-gray-800">📝 Başlıklar</h4>
                                      <button
                                          onClick={() => setSelectedCategory(null)}
                                          className="text-gray-500 hover:text-red-500 transition-colors"
                                      >
                                        ❌
                                      </button>
                                    </div>
                                    <div className="space-y-2">
                                      {vocabolery
                                          .filter((item) => item.category === category)
                                          .map((item, idx) => (
                                              <div
                                                  key={idx}
                                                  onClick={() => setContent(item.text)}
                                                  className={`p-2 rounded cursor-pointer transition-colors ${
                                                      content === item.text
                                                          ? 'bg-green-100 text-green-700'
                                                          : 'bg-gray-100 hover:bg-blue-50'
                                                  }`}
                                              >
                                                {item.name}
                                              </div>
                                          ))}
                                    </div>
                                  </div>
                              )}
                            </div>
                        )
                    )}
                  </div>

                  {/* Ayarlar Bölümü */}
                  <div className="mt-8 bg-gray-100 p-4 rounded-lg shadow-inner space-y-4">
                    {/* Hız Ayarı */}
                    <div>
                      <label htmlFor="speed" className="block text-gray-700 font-semibold mb-2">
                        🕒 Hız Ayarı: {speed} ms
                      </label>
                      <input
                          id="speed"
                          type="range"
                          min="200"
                          max="2000"
                          step="50"
                          value={speed}
                          onChange={(e) => setSpeed(parseInt(e.target.value))}
                          className="w-full"
                      />
                    </div>

                  </div>

                  {/* Başla Butonu */}
                  <div className="text-center mt-6">
                    <button
                        onClick={() => handleStart()}
                        className="bg-green-500 text-white px-6 py-2 rounded-lg text-lg hover:bg-green-400 transition-colors"
                    >
                      🚀 Devam
                    </button>
                  </div>
                </div>
            )}
          </div>
      )}
      {isFinish && (
        <div className="bg-gray-300 bg-opacity-50 flex items-center justify-center fixed inset-0">
          <div className="bg-white p-8 rounded-md text-center">
            <p>Tebrikler! Egzersizi tamamladınız.</p>
            <button
                className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-200"
                onClick={handleReturnDashboard}
            >
              Egzersiz Sayfasına Dön
            </button>
            <div>
              <button
                onClick={restartExercise}
                className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-200"
              >
                Tekrarla
              </button>
            </div>
          </div>
        </div>
      )}
      <FullscreenAlert onConfirm={handleConfirm} />
    </div>
  )
}

export default FirstLetterSecondLetter

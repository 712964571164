import { FaCheckCircle } from "react-icons/fa";
import React, { useEffect } from "react";
import { FaTimesCircle } from 'react-icons/fa'; // Hata ikonu için react-icons kütüphanesi

export default function ErrorMessage({ message, onClose }) {
    useEffect(() => {
        // Mesaj 2 saniye sonra otomatik kapanır
        const timer = setTimeout(() => {
            if (onClose) {
                onClose();
            }
        }, 1000);

        return () => clearTimeout(timer); // Component unmount olduğunda temizlenir
    }, [onClose]);

    return (
        <div className="fixed top-4 right-4 z-50 flex items-center w-auto max-w-sm bg-red-500 text-white text-lg py-3 px-4 rounded-md shadow-md animate-slideIn">
            {/* Başarı İkonu */}
            <FaTimesCircle className="mr-2 text-2xl" />

            {/* Mesaj İçeriği */}
            <span className="flex-1">{message}</span>

            {/* Kapatma Butonu */}
            <button
                onClick={onClose}
                className="ml-2 text-white hover:text-gray-200"
            >
                ✖
            </button>
        </div>
    );
}
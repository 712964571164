import React from "react";
import { Link } from "react-router-dom";

const exercises = [
    { name: "Takistoskop", url: "/takistoskop" },
    { name: "Eşini Bul", url: "/matchpair" },
    { name: "Hızlı İşlem", url: "/isequal" },
    { name: "Hangisi Farklı", url: "/different-picture" },
    { name: "Renk Egzersizi", url: "/colormistake" },
    { name: "Resfebe", url: "/resfebe" },
    { name: "Büyüyen Daire", url: "/growingcircle" },
    { name: "Nanogram", url: "/nanogram" },
    { name: "Aynı Farklı", url: "/same-or-dif" },
    { name: "İkara", url: "/ikara" },
    { name: "Harf Odak", url: "/howmanycharacters" },
    { name: "Bir Yönden Benzer", url: "/sameletter" },
    { name: "Hafıza", url: "/greenlight" },
    { name: "Zig Zag Yoga", url: "/zigzagyoga" },

];

const vibrantColors = [
    "border-red-500",
    "border-green-500",
    "border-blue-500",
    "border-pink-500",
    "border-yellow-500",
    "border-purple-500",
    "border-orange-500",
    "border-teal-500",
];

const getRandomVibrantColor = () => {
    return vibrantColors[Math.floor(Math.random() * vibrantColors.length)];
};

const ExercisesForAll = () => {
    return (
        <div>
            <div className="mt-16 ml-8 flex items-center justify-between">
                <header className="text-3xl font-semibold">EGZERSİZLER</header>
            </div>
            <div className="flex flex-wrap justify-center gap-4 p-8 mt-3">
                {exercises.map((exercise) => (
                    <div
                        key={exercise.name}
                        className={`w-52 p-4 m-2 bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl ${getRandomVibrantColor()} border-2`}
                    >
                        {/* Link */}
                        <Link
                            to={`/education/exercises/attention-memory-logic-math${exercise.url}`}
                            className="text-gray-800 font-semibold text-center block hover:text-blue-500"
                        >
                            <h3>{exercise.name}</h3>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ExercisesForAll;

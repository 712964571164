import React, { useState, useEffect } from 'react'
import { playCongrulationSound } from '../../effect/Congrulation'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import Header from "../Header";
import Footer from "../Footer";
import FinishScreen from "../FinishScreen";

const ZigZag = () => {
  const [positionX, setPositionX] = useState(0)
  const [positionY, setPositionY] = useState(0)
  const [speed, setSpeed] = useState(300)
  const [moveRight, setMoveRight] = useState(true)
  const [minutes, setMinutes] = useState(1) // Varsayılan dakika
  const [seconds, setSeconds] = useState(0) // Saniye
  const [gameOver, setGameOver] = useState(false)
  const [isStart, setIsStart] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const token = window.localStorage.getItem('token')
  const day = window.localStorage.getItem('day')
  const [isFirst, setIsFirst] = useState(true)
  const [isConfirmed, setIsConfirmed] = useState(false)

  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  useEffect(() => {
    if (isStart && !isPaused) {
      const interval = setInterval(() => {
        if (!gameOver) {
          moveZigzag()
        }
      }, speed)

      return () => clearInterval(interval)
    }
  }, [positionX, positionY, gameOver, speed, isStart, isPaused])

  useEffect(() => {
    if (isStart && !isPaused) {
      const countdown = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1)
        } else {
          if (minutes === 0) {
            playCongrulationSound()
            clearInterval(countdown)
            setGameOver(true)
            if (isFirst) {
              setIsFirst(false)
            }
          } else {
            setMinutes(minutes - 1)
            setSeconds(59)
          }
        }
      }, 1000)

      return () => clearInterval(countdown)
    }
  }, [isStart, minutes, seconds, isPaused])

  const handlePauseResume = () => {
    setIsPaused((prev) => !prev)
  }

  const handleStart = () => {
    setIsStart(true)
    setIsPaused(false)
  }

  const restartGame = () => {
    setPositionX(0)
    setPositionY(0)
    setMinutes(1)
    setSeconds(0)
    setGameOver(false)
    setIsStart(false)
    setIsPaused(false)
    setSpeed(300)
  }

  const moveZigzag = () => {
    const screenWidth = 1400
    const screenHeight = 320
    const stepX = 100
    const stepY = 300

    if (moveRight) {
      if (positionX + stepX <= screenWidth) {
        setPositionX((prevX) => prevX + stepX)
      } else {
        setMoveRight(false)
      }
    } else {
      if (positionX - stepX >= 0) {
        setPositionX((prevX) => prevX - stepX)
      } else {
        setMoveRight(true)
      }
    }

    if (positionY + stepY <= screenHeight) {
      setPositionY((prevY) => prevY + stepY)
    } else {
      setPositionY((prevY) => prevY - stepY)
    }
  }

  const handleConfirm = () => {
    setIsConfirmed(true)
  }

  const handleSpeedChange = (e) => {
    setSpeed(parseInt(e.target.value))
  }

  const handleTimeChange = (e) => {
    setMinutes(parseInt(e.target.value))
    setSeconds(0)
  }

  return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="absolute top-0 w-full">
          <Header title="Zig Zag Yoga" returnType="attention-memory" >
            <button
                className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
                onClick={restartGame}
            >
              Sıfırla
            </button>
            <button
                onClick={handlePauseResume}
                className={`${
                    isPaused ? 'bg-green-500' : 'bg-yellow-500'
                } text-white px-3 py-1 rounded hover:opacity-90`}
            >
              {isPaused ? 'Devam Et' : 'Durdur'}
            </button>
          </Header>
        </div>
        <div
            className="absolute top-20 right-0 m-4 mr-8 bg-gray-400 p-2 text-white rounded-lg w-[60px] flex justify-center items-center"
        >
          {`${minutes < 10 ? '0' + minutes : minutes}:${
              seconds < 10 ? '0' + seconds : seconds
          }`}
        </div>
        <Footer />
        {!isStart && (
            <div
                className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50"
                style={{ zIndex: isStart ? -1 : 1 }}
            >
              <div className="bg-gray-300 p-8 rounded-md text-center w-1/3">
                <h2 className="font-semibold text-2xl p-1">
                  Zig Zag Yoga Egzersizi
                </h2>
                <p className="pb-2">
                  Ekrandaki topu kafanızı hareket ettirmeden sadece gözünüz ile
                  takip edin
                </p>
                <div className="settings my-4">
                  <label className="block mb-2">
                    Hız (ms):
                    <input
                        type="range"
                        min="100"
                        max="2000"
                        step="100"
                        value={speed}
                        onChange={handleSpeedChange}
                        className="w-full"
                    />
                    <span>{speed} ms</span>
                  </label>
                  <label className="block mb-2">
                    Süre (dakika):
                    <input
                        type="range"
                        min="1"
                        max="10"
                        step="1"
                        value={minutes}
                        onChange={handleTimeChange}
                        className="w-full"
                    />
                    <span>{minutes} dakika</span>
                  </label>
                </div>
                <button
                    className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
                    onClick={handleStart}
                >
                  Devam
                </button>
              </div>
            </div>
        )}
        {gameOver && <FinishScreen onRestart={restartGame} returnType="attention-memory" />}

        {!gameOver && (
            <div
                className="border-2 relative w-full"
                style={{
                  height: '320px',
                  maxWidth: '1415px',
                  margin: '0 auto',
                }}
            >
              <div
                  className="person bg-red-500"
                  style={{
                    left: positionX + 'px',
                    top: positionY + 'px',
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    position: 'absolute',
                  }}
              ></div>
            </div>
        )}
        <FullscreenAlert onConfirm={handleConfirm} />
      </div>
  )
}

export default ZigZag
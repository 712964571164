import React from 'react';
import EduCard from '../Components/EduCard';

const Educations = () => {
    return (
        <div className="h-full w-full p-4">
            {/* Header */}
            <div className="mt-12 flex items-center">
                <h2 className="text-3xl font-semibold mb-4">EĞİTİMLER</h2>
            </div>

            {/* Cards */}
            <div className="flex flex-wrap justify-center md:justify-around gap-2 mt-8">
            <EduCard title={'Göz Yogası ve Periferik Görme'} href={'/exercises/eye-yoga-peripheral-vision'} />
                <EduCard title={'Hızlı Okuma ve Okuduğunu Anlama'} href={'/exercises/speed-reading-comprehension'} />
                <EduCard title={'Kelime Tanıma Egzersizleri'} href={'/exercises/word-recognition'} />
                <EduCard title={'Dikkat - Hafıza - Mantık - Matematik'} href={'/exercises/attention-memory-logic-math'} />
            </div>
        </div>
    );
};

export default Educations;
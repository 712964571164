import Meyve from '../../assets/DifPic/Meyve.png'
import Meyve2 from '../../assets/DifPic/Meyve2.png'
import Meyve3 from '../../assets/DifPic/Meyve3.png'
import Meyve4 from '../../assets/cherry.jpeg'
import Meyve5 from '../../assets/lemnon.png'
import Meyve6 from '../../assets/grape.jpeg'
import Meyve7 from '../../assets/watermelon.jpeg'

import React, { useState, useEffect, useRef } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { playCongrulationSound } from '../../effect/Congrulation'
import FullscreenAlert from '../LoadPage/FullscreenAlert'


const Peripheral2 = () => {
  const [exerciseDurationSeconds, setExerciseDurationSeconds] = useState(10)
  const [imageDisplayTime, setImageDisplayTime] = useState(1000)
  const [positionChangeDelay, setPositionChangeDelay] = useState(300)
  const [isStarted, setIsStarted] = useState(false)
  const [targetImage, setTargetImage] = useState(null)
  const [targetCount, setTargetCount] = useState(0)
  const [currentPositionIndex, setCurrentPositionIndex] = useState(0)
  const [showResult, setShowResult] = useState(false)
  const [userAnswer, setUserAnswer] = useState('')
  const [remainingSeconds, setRemainingSeconds] = useState(
    exerciseDurationSeconds
  )
  const [startScreen, setStartScreen] = useState(false)
  const [isFinish, setIsFinish] = useState(false)
  const [currentImage, setCurrentImage] = useState(null)
  const intervalId = useRef(null)
  const countdownIntervalId = useRef(null)
  const images = [Meyve, Meyve2, Meyve3, Meyve4, Meyve5, Meyve6, Meyve7]
  const [answerResult, setAnswerResult] = useState(null)
  const [isAnswerSubmitting, setIsAnswerSubmitting] = useState(false);
  const [totalRounds, setTotalRounds] = useState(3)


  const positions = [
    { top: '10%', left: '5%' },
    { bottom: '10%', left: '5%' },
    { bottom: '10%', left: '85%' },
    { top: '10%', left: '85%' },
    { top: '10%', left: '5%' },
    { bottom: '10%', left: '5%' },
    { bottom: '10%', left: '85%' },
    { top: '10%', left: '85%' },

    { top: '10%', left: '5%' },
    { top: '45%', left: '5%' },
    { bottom: '10%', left: '5%' },
    { bottom: '10%', left: '45%' },
    { bottom: '10%', left: '85%' },
    { bottom: '45%', left: '85%' },
    { top: '10%', left: '85%' },
    { top: '10%', left: '45%' },
    { top: '10%', left: '5%' },

    { top: '10%', left: '5%' },
    { top: '10%', left: '85%' },
    { top: '45%', left: '5%' },
    { top: '45%', left: '85%' },
    { bottom: '10%', left: '5%' },
    { bottom: '10%', left: '85%' },
    { bottom: '10%', left: '5%' },
    { top: '45%', left: '85%' },
    { top: '45%', left: '5%' },
    { top: '10%', left: '85%' },
    { top: '10%', left: '5%' },
  ]

  const token = window.localStorage.getItem('token')
  const [currentInterval, setCurrentInterval] = useState(1)

  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  const [isConfirmed, setIsConfirmed] = useState(false)

  const handleConfirm = () => {
    setIsConfirmed(true)
  }

  const handleScreen = () => {
    setStartScreen(true)
  }

  useEffect(() => {
    const randomTargetIndex = Math.floor(Math.random() * images.length)
    setTargetImage(images[randomTargetIndex])
  }, [])

  useEffect(() => {
    if (isStarted) {
      startInterval()
    }
  }, [isStarted])

  const startInterval = () => {
    let timeoutId
    let intervalDuration = exerciseDurationSeconds / totalRounds // Değişiklik burada - toplam tur sayısına göre süre bölünüyor

    let randomImageIndex = Math.floor(Math.random() * images.length)
    setCurrentImage(images[randomImageIndex])

    intervalId.current = setInterval(() => {
      timeoutId = setTimeout(() => {
        setCurrentPositionIndex(
          (prevIndex) => (prevIndex + 1) % positions.length
        )

        do {
          randomImageIndex = Math.floor(Math.random() * images.length)
        } while (images[randomImageIndex] === currentImage)
        setCurrentImage(images[randomImageIndex])
      }, positionChangeDelay) // Kullanıcının seçtiği gecikme süresi
    }, imageDisplayTime) // Kullanıcının seçtiği görüntüleme süresi


    countdownIntervalId.current = setInterval(() => {
      setRemainingSeconds((prevSeconds) => {
        if (prevSeconds <= 1) {
          clearInterval(countdownIntervalId.current)
          clearInterval(intervalId.current)
          clearTimeout(timeoutId)
          handleIntervalEnd()
          return 0
        } else {
          return prevSeconds - 1
        }
      })
    }, 1000)

    return () => {
      clearInterval(intervalId.current)
      clearInterval(countdownIntervalId.current)
      clearTimeout(timeoutId)
    }
  }

  const handleIntervalEnd = () => {
    clearInterval(countdownIntervalId.current)
    setShowResult(true)
  }

  useEffect(() => {
    if (isStarted && currentImage === targetImage) {
      setTargetCount((prevCount) => prevCount + 1)
    }
  }, [currentPositionIndex, isStarted, currentImage, targetImage])

  const handleStart = () => {
    setIsStarted(true)
    setRemainingSeconds(exerciseDurationSeconds / totalRounds) // Değişiklik burada - tur başına düşen süre
  }

  const handleSubmitAnswer = () => {
    if (isAnswerSubmitting) return; // Eğer işlem devam ediyorsa yeni girişleri engelle
    setIsAnswerSubmitting(true); // İşlem devam ediyor olarak işaretle

    if (parseInt(userAnswer) === targetCount) {
      setAnswerResult('Doğru!');
    } else {
      setAnswerResult(`Yanlış. Doğru cevap: ${targetCount}`);
    }

    if (currentInterval < totalRounds) { // Değişiklik burada - sabit 3 yerine totalRounds kullanılıyor
      setTimeout(() => {
        setCurrentInterval(currentInterval + 1);
        setRemainingSeconds(exerciseDurationSeconds / totalRounds) // Değişiklik burada - tur başına düşen süre
        setUserAnswer('');
        setShowResult(false);
        setTargetCount(0);
        startInterval();
        setAnswerResult('');
        setIsAnswerSubmitting(false); // İşlem tamamlandığında durumu sıfırla
      }, 2000);
    } else {
      playCongrulationSound();
      setTimeout(() => {
        setIsFinish(true);
        setIsAnswerSubmitting(false); // İşlem tamamlandığında durumu sıfırla
      }, 2000);
    }
  };

  const handleRestart = () => {
    setIsStarted(false)
    setTargetImage(null)
    setTargetCount(0)
    setCurrentPositionIndex(0)
    setShowResult(false)
    setUserAnswer('')
    setIsFinish(false)
    setAnswerResult(null)
    setCurrentInterval(1)
    setRemainingSeconds(exerciseDurationSeconds)
    setStartScreen(false)
    const randomTargetIndex = Math.floor(Math.random() * images.length)
    setTargetImage(images[randomTargetIndex])
  }

  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/eye-yoga-peripheral-vision`
  }

  return (
    <div className="h-screen w-full">
      <div className="absolute top-0 w-full">
        <Header title={'Periferik Görme'} returnType='eye-yoga'>
          <button
              onClick={handleRestart}
              className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
          >
            Sıfırla
          </button>
        </Header>
      </div>

      {!isStarted && (
          <div className="flex flex-col items-center justify-center h-full">
            <div className="flex flex-col items-center text-xl m4 w-1/2 text-center">
            <img src={targetImage} alt="" />
            <p>
              Bu egzersizle bakış merkezinden uzakta olan kelimeleri görmeyi öğreneceksin.
              Bu egzersiz 3 aşamadan oluşmaktadır.
              Her aşamada ortadaki siyah noktadan gözlerini ayırmadan yukarıdaki meyve görselinin
              kaç tane göründüğünü say ve senden istendiğinde sonucu yaz.

            </p>
          </div>
          <button
            onClick={handleStart}
            className="bg-blue-300 mt-4 hover:bg-blue-200 text-white font-bold py-2 px-4 rounded"
          >
            Başlat
          </button>
        </div>
      )}

      {isStarted && !showResult && (
        <div className="relative h-full w-full overflow-hidden">
          <div className="absolute top-0 right-0 p-4 text-white text-lg font-bold">
            Kalan Süre: {remainingSeconds}
          </div>
          {currentImage && (
            <img
              src={currentImage}
              alt="Meyve"
              className="absolute"
              style={{
                ...positions[currentPositionIndex],
                transition: 'all 0.2s ease-in-out',
              }}
            />
          )}
        </div>
      )}

      {showResult && (
        <div className="flex flex-col items-center justify-center h-full">
          <p>Hedef resim:</p>
          <img src={targetImage} alt="Hedef" className="w-32 h-32" />
          <p>Kaç defa gördünüz?</p>
          <input
            type="text"
            value={userAnswer}
            onChange={(e) => {
              const value = e.target.value
              // Pozitif sayılar için sadece sayıları kabul et ve negatif girişleri engelle
              if (!isNaN(value) && Number(value) >= 0) {
                setUserAnswer(value)
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmitAnswer()
              }
            }}
            className="border border-gray-400 rounded px-3 py-2"
          />
          <button
            onClick={handleSubmitAnswer}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4"
          >
            Cevabı Gönder
          </button>
          {answerResult && (
            <p className="mt-4 text-lg font-bold">{answerResult}</p>
          )}
        </div>
      )}

      <Footer />
      {!startScreen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
          <div className="bg-gray-300 p-8 rounded-md text-center w-1/3">
            <h2 className="font-semibold text-2xl p-1">
              Periferik Görme Egzersizi
            </h2>
            {/* Adım Süresi */}
            <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                ⏱️ Her Tur Süresi: {exerciseDurationSeconds} saniye
              </label>
              <input
                  type="range"
                  min="10"
                  max="60"
                  value={exerciseDurationSeconds}
                  onChange={(e) => setExerciseDurationSeconds(parseInt(e.target.value))}
                  className="w-full"
              />
              <div className="flex justify-between text-sm text-gray-500 mt-1">
                <span>10 sn</span>
                <span>60 sn</span>
              </div>
            </div>

            {/* Toplam Tur Sayısı */}
            <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                🔄 Toplam Tur Sayısı: {totalRounds}
              </label>
              <input
                  type="range"
                  min="1"
                  max="10"
                  value={totalRounds}
                  onChange={(e) => setTotalRounds(parseInt(e.target.value))}
                  className="w-full"
              />
              <div className="flex justify-between text-sm text-gray-500 mt-1">
                <span>1</span>
                <span>10</span>
              </div>
            </div>

            {/* Görsel Gösterim Süresi */}
            <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                🖼️ Görsel Gösterim Süresi: {imageDisplayTime} ms
              </label>
              <input
                  type="range"
                  min="500"
                  max="3000"
                  step="100"
                  value={imageDisplayTime}
                  onChange={(e) => setImageDisplayTime(parseInt(e.target.value))}
                  className="w-full"
              />
              <div className="flex justify-between text-sm text-gray-500 mt-1">
                <span>500</span>
                <span>3000</span>
              </div>
            </div>

            {/* Konum Değişim Gecikmesi */}
            <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                🕒 Konum Değişim Gecikmesi: {positionChangeDelay} ms
              </label>
              <input
                  type="range"
                  min="500"
                  max="2000"
                  step="50"
                  value={positionChangeDelay}
                  onChange={(e) => setPositionChangeDelay(parseInt(e.target.value))}
                  className="w-full"
              />
              <div className="flex justify-between text-sm text-gray-500 mt-1">
                <span>500</span>
                <span>2000</span>
              </div>
            </div>

            {/* Başlat Butonu */}
            <button
                className="bg-blue-400 text-white py-2 px-4 mt-4 rounded hover:bg-blue-300"
                onClick={handleScreen}
            >
              🚀 Başlat

            </button>
          </div>
        </div>
      )}
      {isFinish && (
        <div className="bg-gray-300 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
          <div className="bg-white p-8 rounded-md text-center">
            <p className="text-2xl">Tebrikler! Egzersizi tamamlandınız.</p>
            <div>
              <button
                className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
                onClick={handleRestart}
              >
                Tekrar
              </button>
              <div>
                <button
                  onClick={handleReturnDashboard}
                  className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
                >
                  Egzersiz Sayfasına Dön
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {!showResult && isStarted && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div
            className="rounded-full bg-black"
            style={{ width: '20px', height: '20px' }}
          >
            .
          </div>
        </div>
      )}
      <FullscreenAlert onConfirm={handleConfirm} />
    </div>
  )
}

export default Peripheral2

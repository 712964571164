import React, { useEffect, useState } from "react";
import { playCongrulationSound } from "../../effect/Congrulation";
import { playStepSound } from "../../effect/Step";
import FullscreenAlert from "../LoadPage/FullscreenAlert";
import Footer from "../Footer";
import Header from "../Header";
import FinishScreen from "../FinishScreen";
import ExerciseServices from "../../Services/ExerciseServices";
import VocabolaryServices from "../../Services/VocabolaryServices";
import { error } from "pdf-lib";

const GrowingCircle = () => {
  const exerciseName = "growingcircle";
  const [circles, setCircles] = useState([{ size: 3 }]);
  const [speed, setSpeed] = useState(1000);
  const [isFinish, setIsFinish] = useState(false);
  const [countdown, setCountdown] = useState(1); // Dakika cinsinden başlatıyoruz
  const [isStart, setIsStart] = useState(false);
  const [isPaused, setIsPaused] = useState(false); // Egzersizi durdurma

  const token = window.localStorage.getItem("token");
  const [isConfirmed, setIsConfirmed] = useState(false);

  /*
    egzersiz servisi tanımlıyoruz
  */

  useEffect(() => {
    const vocaBolaryServices = new VocabolaryServices();

    vocaBolaryServices
      .getVocabolary("growingsquare")
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  /** */
  useEffect(() => {
    if (!token) {
      window.location.href = "/login";
    }
  }, []);

  const handleConfirm = () => {
    setIsConfirmed(true);
  };

  useEffect(() => {
    let interval;
    let countdownInterval;

    const startGrowing = () => {
      interval = setInterval(() => {
        setCircles((prevCircles) => {
          const lastCircle = prevCircles[prevCircles.length - 1];
          const newSize = lastCircle.size + 5;

          if (newSize > 40) {
            return [{ size: 3 }];
          } else if (countdown === 0) {
            clearInterval(interval);
            playCongrulationSound();
            setIsFinish(true);
            return prevCircles;
          } else {
            return [...prevCircles, { size: newSize }];
          }
        });
        playStepSound();
      }, speed);

      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1 / 60); // Dakika cinsinden azaltıyoruz
      }, 1000);
    };

    if (!isFinish && isStart && !isPaused) {
      startGrowing();
    }

    return () => {
      clearInterval(interval);
      clearInterval(countdownInterval);
    };
  }, [isFinish, countdown, speed, isStart, isPaused]);

  const handleStart = () => {
    setIsStart(true);
    setCountdown(countdown * 60); // Dakikayı saniyeye çeviriyoruz
  };

  const minutes = Math.floor(countdown / 60); // Dakika kısmını alıyoruz
  const seconds = Math.floor((countdown % 1) * 60); // Geriye kalan saniyeler
  const formattedCountdown = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  const restartExercise = () => {
    setCircles([{ size: 3 }]);
    setSpeed(1000);
    setIsFinish(false);
    setCountdown(1); // 1 dakika olarak sıfırlıyoruz
    setIsStart(false);
  };

  const handleSpeedChange = (e) => {
    setSpeed(parseInt(e.target.value));
  };

  const handleTimeChange = (e) => {
    setCountdown(parseInt(e.target.value)); // Dakika olarak ayarlıyoruz
  };

  const handlePauseResume = () => {
    setIsPaused((prev) => !prev); // Durdur/Devam Et durumu değişir
  };

  return (
    <div>
      <div className="flex justify-center items-center h-screen">
        <div className="absolute top-0 w-full">
          <Header title={"Büyüyen Daire"} returnType="attention-memory">
            <button
              onClick={restartExercise}
              className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
            >
              Sıfırla
            </button>
            <button
              onClick={handlePauseResume}
              className={`${
                isPaused ? "bg-green-500" : "bg-yellow-500"
              } text-white px-3 py-1 rounded hover:opacity-90`}
            >
              {isPaused ? "Devam Et" : "Durdur"}
            </button>
          </Header>
        </div>
        <div className="relative">
          {circles.map((circle, index) => (
            <div
              key={index}
              className="rounded-full border-2 border-blue-500"
              style={{
                width: `${circle.size}rem`,
                height: `${circle.size}rem`,
                position: "absolute",
                borderWidth: 5,
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div className="w-3 h-3 bg-red-500 rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
            </div>
          ))}
        </div>
        <Footer />
      </div>
      <div className="absolute top-20 right-0 m-4">
        <div
          className="text-white font-bold bg-gray-300 rounded-md p-2"
          style={{ width: "60px" }}
        >
          {formattedCountdown}
        </div>
      </div>
      {!isStart && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
          <div className="bg-gray-300 p-8 rounded-md text-center w-1/3">
            <h2 className="font-semibold text-2xl p-1">
              Büyüyen Daire Egzersizi
            </h2>
            <p className="pb-2">
              Adım adım büyüyen daireleri kırmızı noktaya bakarak takip edin.
            </p>
            <label className="block mb-2">
              Hız (ms):
              <input
                type="range"
                min="100"
                max="2000"
                step="100"
                value={speed}
                onChange={handleSpeedChange}
                className="w-full"
              />
              <span>{speed} ms</span>
            </label>
            <label className="block mb-2">
              Süre (dakika):
              <input
                type="range"
                min="1"
                max="10"
                step="1"
                value={countdown}
                onChange={handleTimeChange}
                className="w-full"
              />
              <span>{countdown} dk</span>
            </label>
            <button
              className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
              onClick={handleStart}
            >
              Devam
            </button>
          </div>
        </div>
      )}
      {isFinish && <FinishScreen onRestart={restartExercise}  returnType="attention-memory"/>}
      <FullscreenAlert onConfirm={handleConfirm} />
    </div>
  );
};

export default GrowingCircle;

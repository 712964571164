import React, { useEffect, useState, useRef } from 'react'
import { playCongrulationSound } from '../../effect/Congrulation'
import { playCorrectSound } from '../../effect/Correct'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import Header from '../Header'
import Footer from '../Footer'
import { trueColors, generateFalseColors } from '../Colors'

const ColorMismatchGame = () => {
  const [sizeX, setSizeX] = useState(5)
  const [sizeY, setSizeY] = useState(8)
  const [randomMatrix, setRandomMatrix] = useState([])
  const [falseArray, setFalseArray] = useState([])
  const [selectedFalseColors, setSelectedFalseColors] = useState([])
  const [allColorsFound, setAllColorsFound] = useState(false)
  const [isStart, setIsStart] = useState(true)
  const [isFinish, setIsFinish] = useState(false)
  const [falseLimit, setFalseLimit] = useState(6)
  const [step, setStep] = useState(1) // Adım sayısı
  const [round, setRound] = useState(1) // toplam round sayısı

  const [elapsedTime, setElapsedTime] = useState(0) // Geçen süre (saniye)
  const timerId = useRef(null)

  const token = window.localStorage.getItem('token')
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [isFirst, setIsFirst] = useState(true)
  const falseColors = generateFalseColors();

  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  useEffect(() => {
    let intervalId
    if (!isStart && !isFinish) {
      intervalId = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1)
      }, 1000)
    } else {
      clearInterval(intervalId)
    }

    return () => clearInterval(intervalId)
  }, [isStart, isFinish])

  const handleConfirm = () => {
    setIsConfirmed(true)
  }

  useEffect(() => {
    if (!isStart) {
      generateRandomMatrix()
    }
  }, [sizeX, sizeY, isStart, step])

  const generateRandomMatrix = () => {
    let i = 0;
    let falseCount = 0;
    const tempFalseArray = [];
    const tempFalseColors = [...falseColors];
    let flatMatrix = []; // Düz bir dizi oluştur

    // Düz diziye renkleri ekle
    for (let x = 0; x < sizeX; x++) {
      for (let y = 0; y < sizeY; y++) {
        const randomIndex = Math.floor(Math.random() * 2);
        if (i <= (sizeX * sizeY) / 2) {
          if (randomIndex === 0 || falseCount >= falseLimit) {
            const trueColorslen = Math.floor(Math.random() * trueColors.length);
            flatMatrix.push({ ...trueColors[trueColorslen], border: false });
          } else {
            i++;
            if (tempFalseColors.length > 0 && falseCount < falseLimit) {
              const index = Math.floor(Math.random() * tempFalseColors.length);
              const selectedColor = tempFalseColors.splice(index, 1)[0];
              tempFalseArray.push(selectedColor);
              falseCount++;
              flatMatrix.push({ ...selectedColor, border: false });
            }
          }
        } else {
          const trueColorslen = Math.floor(Math.random() * trueColors.length);
          flatMatrix.push({ ...trueColors[trueColorslen], border: false });
        }
      }
    }

    // Düz diziyi karıştır (Fisher-Yates shuffle algoritması)
    for (let k = flatMatrix.length - 1; k > 0; k--) {
      const j = Math.floor(Math.random() * (k + 1));
      [flatMatrix[k], flatMatrix[j]] = [flatMatrix[j], flatMatrix[k]];
    }

    // Karıştırılmış düz diziyi matrise dönüştür
    const randomMatrix = [];
    for (let x = 0; x < sizeX; x++) {
      randomMatrix.push(flatMatrix.splice(0, sizeY));
    }

    setFalseArray(tempFalseArray);
    setRandomMatrix(randomMatrix);
    setSelectedFalseColors([]);
    setAllColorsFound(false);
  };
  const handleColorsClick = (color) => {
    if (falseArray.find((item) => item.id === color.id)) {
      const updatedFalseArray = falseArray.filter(
        (item) => item.id !== color.id
      )
      setFalseArray(updatedFalseArray)
      playCorrectSound()
      setSelectedFalseColors((prev) => [...prev, color])
      const updatedMatrix = randomMatrix.map((row) =>
        row.map((col) => (col.id === color.id ? { ...col, border: true } : col))
      )
      setRandomMatrix(updatedMatrix)
      if (updatedFalseArray.length === 0) {
        setAllColorsFound(true)
        playCongrulationSound()
        if (step < round) {
          setStep((prevStep) => prevStep + 1)
          setAllColorsFound(false) // Sonraki adıma geçerken allColorsFound'u sıfırla
        } else {
          setIsFinish(true)
          setIsFirst(false)
          setIsStart(false)
        }
      }
    }
  }

  const handleStart = () => {
    setIsStart(false)
    generateRandomMatrix()
  }

  const restartExercise = () => {
    setStep(1) // Adımı başa sar
    setElapsedTime(0) // Süreyi sıfırla
    generateRandomMatrix()
    setIsFinish(false)
    setIsStart(true)
    setSelectedFalseColors([])
    setAllColorsFound(false)
    setFalseArray([])
    setFalseLimit(6)
    setRound(1)
  }

  const renderColors = () => {
    return (
        <div className="color-table">
          <table className="w-full border-collapse border border-gray-300">
            <tbody>
            {randomMatrix.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((color, colIndex) => (
                      <td
                          key={`${rowIndex}-${colIndex}`}
                          className="w-32 h-32 border border-gray-300 text-center cursor-pointer hover:bg-gray-100"
                          style={{
                            color: color.color,
                            border: color.border ? '2px solid green' : '1px solid gray',
                            fontSize: '14px',
                          }}
                          onClick={() => handleColorsClick(color)}
                      >
                        <p className="font-bold text-center p-2" style={{ fontSize: '18px' }}>
                          {color.name.toUpperCase()}
                        </p>
                      </td>
                  ))}
                </tr>
            ))}
            </tbody>
          </table>
        </div>
    );
  };
  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/attention-memory-logic-math`
  }

  const selectedFalseColorsCount = selectedFalseColors.length
  const remainingFalseColorsCount = falseArray.length
  const totalFalseColorsCount =
    selectedFalseColorsCount + remainingFalseColorsCount
  const falseColorsPercentage =
    totalFalseColorsCount > 0
      ? ((remainingFalseColorsCount / totalFalseColorsCount) * 100).toFixed(2)
      : 0

  // Süreyi dakika ve saniye cinsinden formatla
  const minutes = Math.floor(elapsedTime / 60)
  const seconds = elapsedTime % 60
  return (
    <div className="container_mismatch flex flex-col h-screen relative">
      <Header title={'Renk Egzersizi'} returnType="attention-memory">
        <button
            onClick={restartExercise}
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
        >
          Sıfırla
        </button>
      </Header>

      {!isStart && (
          <div className=" absolute top-10 w-44 right-0 m-2 items-center flex flex-col rounded-xl text-white bg-blue-300 ">
            <p className="text-lg font-bold">
            Kalan Yanlış: {falseColorsPercentage}%
          </p>
          <p className="text-lg font-bold">
            Süre: {minutes}:{seconds}
          </p>
          <p className="text-lg font-bold">Adım: {step}/{round}</p>
        </div>
      )}

      <div className="flex flex-col items-center justify-center flex-grow">
        {!isStart && <div className="table">{renderColors()}</div>}

        <Footer />

        {allColorsFound && (
          <div className="bg-gray-500 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
            <div
              className="bg-white p-8 rounded-md text-center"
              style={{ width: '300px' }}
            >
              <p style={{ fontSize: '20px' }}>
                Tebrikler! Egzersizi tamamladınız. <br />
              </p>
              <button
                  className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
                onClick={generateRandomMatrix}
              >
                Tekrar
              </button>
              <button                       className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
              >
                Egzersiz Sayfasına Dön
              </button>
            </div>
          </div>
        )}

        {isStart && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
            <div className="bg-gray-300 p-8 rounded-md text-center w-1/3">
              {/* Adım Sayısı Ayarı */}
              <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
                <label htmlFor="step" className="block text-gray-700 font-semibold mb-2">
                  📊 Adım Sayısı: {round}
                </label>
                <input
                    id="step"
                    type="range"
                    min="1"
                    max="5"
                    step="1"
                    value={round}
                    onChange={(e) => setRound(parseInt(e.target.value))}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-blue-500"
                />
                <div className="flex justify-between text-sm text-gray-500 mt-1">
                  <span>1</span>
                  <span>5</span>
                </div>
              </div>

              {/* Yanlış Sayısı Ayarı */}
              <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
                <label htmlFor="falseLimit" className="block text-gray-700 font-semibold mb-2">
                  ❌ Yanlış Renk Sınırı: {falseLimit}
                </label>
                <input
                    id="falseLimit"
                    type="range"
                    min="1"
                    max="10"
                    step="1"
                    value={falseLimit}
                    onChange={(e) => setFalseLimit(parseInt(e.target.value))}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-blue-500"
                />
                <div className="flex justify-between text-sm text-gray-500 mt-1">
                  <span>1</span>
                  <span>10</span>
                </div>
              </div>
              <button
                className="bg-blue-400 text-white py-2 px-4 mt-4 rounded hover:bg-blue-300"
                onClick={handleStart}
              >
                Devam
              </button>
            </div>
          </div>
        )}

        {isFinish && (
          <div className="bg-gray-500 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
            <div
              className="bg-white p-8 rounded-md text-center"
              style={{ width: '300px' }}
            >
              <p style={{ fontSize: '20px' }}>
                Tebrikler! Egzersizi tamamladınız.
              </p>
              <p>
                Egzersiz Tamamlama Süresi: {minutes}:{seconds}
              </p>
              <button
                className="mt-4 bg-blue-300 hover:bg-blue-200 text-white font-bold py-2 px-4 rounded"
                onClick={restartExercise}
              >
                Tekrar
              </button>
              <div>
                <button
                  className="mt-4 bg-blue-300 hover:bg-blue-200 text-white font-bold py-2 px-4 rounded"
                onClick={handleReturnDashboard}
                >
                  Egzersiz Sayfasına Dön
                </button>
              </div>
            </div>
          </div>
        )}

        <FullscreenAlert onConfirm={handleConfirm} />
      </div>
    </div>
  )
}

export default ColorMismatchGame

import React, { useState, useEffect, useRef } from 'react'
import { playCongrulationSound } from '../../effect/Congrulation'
import LoaderSimple from '../LoadPage/LoaderSimple'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import Header from '../Header'
import Footer from "../Footer";


const Nanogram = () => {
  const [isFinish, setIsFinish] = useState(false)
  const [gridSize, setGridSize] = useState(5)
  const [matrix, setMatrix] = useState([])
  const [solutionMatrix, setSolutionMatrix] = useState([])
  const [rowHints, setRowHints] = useState([])
  const [colHints, setColHints] = useState([])
  const [isStart, setIsStart] = useState(false)
  const [initialTimeMinutes, setInitialTimeMinutes] = useState(2)  // Burada round süresini ayarlayabilirsiniz (dakika cinsinden)


  const [totalRounds, setTotalRounds] = useState(3)
  const [currentRound, setCurrentRound] = useState(1)

  // Geri sayım özellikleri
  const roundDuration = initialTimeMinutes * 60
  const [timeLeft, setTimeLeft] = useState(roundDuration)

  // Toplam geçen süreyi de ölçmek için (istenirse)
  const [elapsedSeconds, setElapsedSeconds] = useState(0)

  const token = window.localStorage.getItem('token')

  const [isConfirmed, setIsConfirmed] = useState(false)

  // Süre dolduğunda doğru çözümü gösterme durumu
  const [showSolution, setShowSolution] = useState(false)

  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  const handleConfirm = () => {
    setIsConfirmed(true)
  }




  const handleStart = () => {
    setIsStart(true)
  }

  const handleRestart = () => {
    // Oyunu baştan başlat
    setCurrentRound(1)
    setElapsedSeconds(0)
    setIsFinish(false)
    setShowSolution(false)
    setIsStart(false)
    initializeGame(gridSize)
  }

  // Rastgele çözüm matrisi oluşturma fonksiyonu
  const generateSolutionMatrix = (size) => {
    const newMatrix = Array.from({ length: size }, () =>
        Array.from({ length: size }, () => 0)
    )

    for (let i = 0; i < size; i++) {
      let randomRow = Math.floor(Math.random() * size)
      let randomCol = Math.floor(Math.random() * size)
      newMatrix[i][randomCol] = 1
      newMatrix[randomRow][i] = 1
    }

    for (let i = 0; i < size; i++) {
      for (let j = 0; j < size; j++) {
        if (newMatrix[i][j] === 0) {
          newMatrix[i][j] = Math.round(Math.random())
        }
      }
    }

    return newMatrix
  }

  // Satır ve sütun ipuçlarını hesaplama fonksiyonu
  const generateHints = (matrix) => {
    const rowHints = []
    const colHints = []

    for (let i = 0; i < matrix.length; i++) {
      let currentRowHint = []
      let currentColHint = []
      let count = 0

      // Satır ipuçları
      for (let j = 0; j < matrix[i].length; j++) {
        if (matrix[i][j] === 1) {
          count++
        } else if (count > 0) {
          currentRowHint.push(count)
          count = 0
        }
      }
      if (count > 0) {
        currentRowHint.push(count)
      }
      rowHints.push(currentRowHint)

      // Sütun ipuçları
      count = 0
      for (let j = 0; j < matrix.length; j++) {
        if (matrix[j][i] === 1) {
          count++
        } else if (count > 0) {
          currentColHint.push(count)
          count = 0
        }
      }
      if (count > 0) {
        currentColHint.push(count)
      }
      colHints.push(currentColHint)
    }

    return [rowHints, colHints]
  }

  // Oyunu başlatma fonksiyonu
  const initializeGame = (size) => {
    const newSolutionMatrix = generateSolutionMatrix(size)
    const [newrowHints, newColHints] = generateHints(newSolutionMatrix)

    setSolutionMatrix(newSolutionMatrix)
    setRowHints(newrowHints)
    setColHints(newColHints)
    setMatrix(
        Array.from({ length: size }, () => Array.from({ length: size }, () => 0))
    )
    setIsFinish(false)
    // Her round başında süre tekrar ayarlanır
    setTimeLeft(roundDuration)
  }

  // Hücre tıklama fonksiyonu
  const toggleCell = (rowIndex, colIndex) => {
    if (showSolution) return; // Çözüm gösteriliyorsa işlem yapılmasın
    const newMatrix = [...matrix]
    newMatrix[rowIndex][colIndex] = newMatrix[rowIndex][colIndex] ? 0 : 1
    setMatrix(newMatrix)
  }

  // Matrisleri karşılaştırma fonksiyonu
  const checkMatricesEquality = () => {
    if (matrix.length === 0 || solutionMatrix.length === 0) {
      return false
    }

    for (let i = 0; i < matrix.length; i++) {
      for (let j = 0; j < matrix[i].length; j++) {
        if (matrix[i][j] !== solutionMatrix[i][j]) {
          return false
        }
      }
    }
    return true
  }

  // Bir sonraki adıma geçme veya egzersizi bitirme fonksiyonu
  const nextRoundOrFinish = () => {
    if (currentRound < totalRounds) {
      setCurrentRound(currentRound + 1)
      initializeGame(gridSize)
    } else {
      // Son tur tamamlandı, bitiş ekranını göster
      setIsFinish(true)
      setIsStart(false)
    }
  }

  // Süre dolunca yapılacak işlemler
  const handleTimeUp = () => {
    // Eğer çözülmediyse çözümü göster
    if (!checkMatricesEquality()) {
      setShowSolution(true)
      setTimeout(() => {
        setShowSolution(false)
        nextRoundOrFinish()
      }, 5000) // 5 saniye çözümü göster
    } else {
      // Çözülmüşse direkt sonraki adıma veya bitişe geç
      nextRoundOrFinish()
    }
  }

  useEffect(() => {
    initializeGame(gridSize)
  }, [gridSize])

  // Her matris güncellendiğinde kontrol et
  useEffect(() => {
    if (checkMatricesEquality()) {
      playCongrulationSound()

      // Eğer son tur değilse yeni turu başlat
      if (currentRound < totalRounds) {
        setTimeout(() => {
          nextRoundOrFinish()
        }, 1000)
      } else {
        // Son tur ise bitiş ekranını göster
        setIsFinish(true)
        setIsStart(false)
      }
    }
  }, [matrix, solutionMatrix])

  // Geri sayım efektini yönet
  useEffect(() => {
    let timer
    if (isStart && !isFinish && !showSolution) {
      if (timeLeft > 0) {
        timer = setTimeout(() => {
          setTimeLeft((prev) => prev - 1)
          setElapsedSeconds((prev) => prev + 1)
        }, 1000)
      } else {
        // Süre bitti
        handleTimeUp()
      }
    }
    return () => clearTimeout(timer)
  }, [isStart, isFinish, timeLeft, showSolution])

  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/attention-memory-logic-math`
  }

  // Süreyi dakika ve saniye cinsinden formatla (timeLeft için)
  const formattedTime = () => {
    const minutes = Math.floor(timeLeft / 60)
    const seconds = timeLeft % 60
    return `${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`
  }

  // Egzersiz bittiğinde gösterilecek toplam süreyi hesaplamak isterseniz `elapsedSeconds` kullanabilirsiniz.
  // Burada istenen tam net değil ama son ekranda istenen "Süreniz" ifadesinde
  // toplam geçen sürenizi göstermek için elapsedSeconds'u formatlayabilirsiniz.
  const formattedElapsedTime = () => {
    const minutes = Math.floor(elapsedSeconds / 60)
    const seconds = elapsedSeconds % 60
    return `${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`
  }
  const handleGridSizeChange = (size) => {
    setGridSize(size)
  }

  const handleRoundsChange = (e) => {
    setTotalRounds(parseInt(e.target.value))
  }

  const handleTimeChange = (e) => {
    setInitialTimeMinutes(parseInt(e.target.value))
  }
  return (
      <div className="h-screen overflow-hidden flex flex-col items-center justify-between">
          <div className="w-full fixed top-0 ">
            <Header title={'Nanogram'} returnType="attention-memory">
              <button
                  onClick={handleRestart}
                  className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
              >
                Sıfırla
              </button>
            </Header>

          </div>
          {isStart && !isFinish && !showSolution && (
              <div
                  className="fixed right-4 top-24 w-40 h-16 bg-gray-500 rounded-lg p-4 flex flex-col items-center justify-center">
                <div className="text-xl text-white">Süre: {formattedTime()}</div>
                <div className="text-xl text-white">
                  Tur: {currentRound}/{totalRounds}
                </div>
              </div>
          )}
          <div>
            <table className="table-fixed border-collapse border border-gray-400 mt-48">
              <thead>
              <tr>
                <th className="w-16 h-16 border border-gray-400"></th>
                {colHints.map((hints, colIndex) => (
                    <th key={colIndex} className="border border-gray-400">
                      <div className="flex flex-col items-center justify-center w-16">
                        {hints.map((hint, hintIndex) => (
                            <span key={hintIndex} className="text-xs my-1">
                          {hint}
                        </span>
                        ))}
                      </div>
                    </th>
                ))}
              </tr>
              </thead>
              <tbody>
              {(showSolution ? solutionMatrix : matrix).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <th className="w-16 h-16 border border-gray-400">
                      <div className="flex flex-row items-center justify-center h-full">
                        {rowHints[rowIndex].map((hint, hintIndex) => (
                            <span key={hintIndex} className="text-xs mx-1">
                          {hint}
                        </span>
                        ))}
                      </div>
                    </th>
                    {row.map((cell, colIndex) => (
                        <td
                            key={colIndex}
                            className={`border border-gray-400 w-16 h-16 cursor-pointer ${
                                (showSolution ? row[colIndex] : cell)
                                    ? 'bg-dashboard'
                                    : 'bg-white'
                            }`}
                            onClick={() => toggleCell(rowIndex, colIndex)}
                        ></td>
                    ))}
                  </tr>
              ))}
              </tbody>
            </table>
            {showSolution && (
                <div className="mt-4 text-center">
                  <p className="text-lg font-semibold text-red-500">
                    🚨 Çözüm Bu Şekilde Olmalıydı 🚨
                  </p>
                </div>
            )}
          </div>
          <Footer/>

        {/* Oyun bittiğinde gösterilecek ekran */}
        {isFinish && (
            <div className="bg-gray-500 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
              <div
                  className="bg-white p-8 rounded-md text-center"
                  style={{width: '300px'}}
              >
                <p style={{ fontSize: '20px' }}>
                  Tebrikler! Egzersizi tamamladınız.
                </p>
                <button
                    onClick={handleReturnDashboard}
                    className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
                >
                  Egzersiz Sayfasına Dön
                </button>
                <div>
                  <button
                      onClick={handleRestart}
                      className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
                  >
                    Tekrar
                  </button>
                </div>
              </div>
            </div>
        )}
        {!isStart && !isFinish && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
              <div className="bg-gray-300 p-8 rounded-md text-center w-1/3">
                <h2 className="font-semibold text-2xl p-1">Nanogram</h2>
                {/* Matris Ayarı */}
                <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
                  <label className="block text-gray-700 font-semibold mb-2">
                    📐 Matris Boyutu:
                  </label>
                  <div className="flex justify-center gap-4">
                    {[4, 5].map((size) => (
                        <button
                            key={size}
                            onClick={() => handleGridSizeChange(size)}
                            className={`px-3 py-2 rounded ${
                                gridSize === size
                                    ? 'bg-blue-500 text-white shadow-md'
                                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                            }`}
                        >
                          {size} x {size}
                        </button>
                    ))}
                  </div>
                </div>

                {/* Tur Sayısı Ayarı */}
                <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
                  <label className="block text-gray-700 font-semibold mb-2">
                    🔄 Toplam Adım: {totalRounds}
                  </label>
                  <input
                      type="range"
                      min="1"
                      max="10"
                      value={totalRounds}
                      onChange={handleRoundsChange}
                      className="w-full"
                  />
                  <div className="flex justify-between text-sm text-gray-500 mt-1">
                    <span>1</span>
                    <span>10</span>
                  </div>
                </div>

                {/* Süre Ayarı */}
                <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
                  <label className="block text-gray-700 font-semibold mb-2">
                    ⏱️ Tur Süresi: {initialTimeMinutes} dakika
                  </label>
                  <input
                      type="range"
                      min="1"
                      max="5"
                      value={initialTimeMinutes}
                      onChange={handleTimeChange}
                      className="w-full"
                  />
                  <div className="flex justify-between text-sm text-gray-500 mt-1">
                    <span>1 dk</span>
                    <span>5 dk</span>
                  </div>
                </div>
                <button
                    className="bg-blue-400 text-white py-2 px-4 mt-4 rounded hover:bg-blue-300"
                    onClick={handleStart}
                >
                  🚀 Başla
                </button>
              </div>
            </div>
        )}
        <FullscreenAlert onConfirm={handleConfirm} />
      </div>
  )
}

export default Nanogram
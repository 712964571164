import axios from "axios";
import { Component } from "react";
import { API_URL } from "../config/api";

class VocabolaryServices extends Component {
  constructor(props) {
    super(props);
    this.url = API_URL;
    this.token = localStorage.getItem("token");
  }

  getVocabolary(exerciseName) {
    return axios.get(
      this.url + "/getvocabolary?exercise_name=" + exerciseName,
      {
        headers: {
          token: this.token,
        },
      }
    );
  }
}

export default VocabolaryServices;

import React, { useState, useEffect, useRef } from 'react'
import { playCorrectSound } from '../../effect/Correct'
import { playInCorrectSound } from '../../effect/Incorrect'
import { playCongrulationSound } from '../../effect/Congrulation'
import LoaderSimple from '../LoadPage/LoaderSimple'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import Header from '../Header'
import Footer from '../Footer'
import { CircleCheckBig, CircleX } from 'lucide-react'
import VocabolaryServices from "../../Services/VocabolaryServices";


const DifferentPicture = () => {
  const [dayImages, setDayImages] = useState([])
  const [answerList, setAnswerList] = useState([])
  const [descriptionList, setDescriptionList] = useState([])
  const [vocabolery, setVocabolery] = useState([]);

  const token = window.localStorage.getItem('token')
  const [isLoading, setIsLoading] = useState(true)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const vocabolaryservices = new VocabolaryServices();

  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/attention-memory-logic-math`
  }

  const handleConfirm = () => {
    setIsConfirmed(true)
  }

  useEffect(() => {
    vocabolaryservices
        .getVocabolary("different-picture")
        .then((response) => {
          setVocabolery(response.data);
          // İlk veriyi yükle
          const initialData = response.data.find(item => item.level === '1');
          if (initialData && initialData.data && initialData.data.length > 0) {
            const levelData = initialData.data[0];
            setDayImages(levelData.img[0] || []);
            setAnswerList(levelData.answer[0] || []);
            setDescriptionList(levelData.description[0] || []);
          }
        })
        .catch((error) => {
          console.error("Kategorileri alırken hata oluştu:", error);
        });
  }, []);

  const [correctCount, setCorrectCount] = useState(0)
  const [passCount, setPassCount] = useState(0)
  const [isFinish, setIsFinish] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [selectedImageIndex, setSelectedImageIndex] = useState(null)
  const [isCorrect, setIsCorrect] = useState(null)
  const [isStart, setIsStart] = useState(false)
  const [elapsedSeconds, setElapsedSeconds] = useState(0)
  const [selectedLevel, setSelectedLevel] = useState(1);

  const handleLevelChange = (event) => {
    const level = event.target.value; // String olarak bırak
    setSelectedLevel(level);

    // Seçilen seviyeye ait veriyi bul
    const selectedData = vocabolery.find(item => item.level === level);

    console.log('Selected Level:', level);
    console.log('Selected Data:', selectedData);

    if (selectedData && selectedData.data && selectedData.data.length > 0) {
      const levelData = selectedData.data[0];

      if (levelData.img && levelData.img[0]) {
        setDayImages(levelData.img[0]);
      } else {
        console.warn(`Level ${level} için resim verisi bulunamadı`);
        setDayImages([]);
      }

      if (levelData.answer && levelData.answer[0]) {
        setAnswerList(levelData.answer[0]);
      } else {
        console.warn(`Level ${level} için cevap verisi bulunamadı`);
        setAnswerList([]);
      }

      if (levelData.description && levelData.description[0]) {
        setDescriptionList(levelData.description[0]);
      } else {
        console.warn(`Level ${level} için açıklama verisi bulunamadı`);
        setDescriptionList([]);
      }

      // Veri durumunu loglayarak kontrol et
      console.log('Updated State:', {
        images: levelData.img?.[0],
        answers: levelData.answer?.[0],
        descriptions: levelData.description?.[0]
      });
    } else {
      console.error(`Seviye ${level} için veri bulunamadı veya veri yapısı hatalı`);
      setDayImages([]);
      setAnswerList([]);
      setDescriptionList([]);
    }
  };

  const handleStart = () => {
    setIsStart(true)
    setCurrentImageIndex(0)
    setSelectedImageIndex(null)
    setIsCorrect(null)
  }

  useEffect(() => {
    if (isStart && !isFinish) {
      const timer = setInterval(() => {
        setElapsedSeconds((prevTime) => prevTime + 1)
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [isStart, isFinish])

  const handleImageClick = (index) => {
    setSelectedImageIndex(index)
  }

  const handlePass = () => {
    setPassCount((prevCount) => prevCount + 1)
    passToNext()
  }

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
  }

  const checkResponse = () => {
    const correctIndex = answerList[currentImageIndex];

    if (selectedImageIndex === correctIndex) {
      setIsCorrect(true);
      setCorrectCount((prevCount) => prevCount + 1);
      playCorrectSound();
    } else {
      setIsCorrect(false);
      setPassCount((prevCount) => prevCount + 1);
      playInCorrectSound();
    }

    setTimeout(() => {
      passToNext();
    }, 1000);
  };

  const passToNext = () => {
    setSelectedImageIndex(null);
    setIsCorrect(null);

    if (currentImageIndex === dayImages.length - 1) {
      setIsFinish(true);
      playCongrulationSound();
    } else {
      setCurrentImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const renderImage = (image, index) => {
    const isSelected = selectedImageIndex === index;
    const isCorrectOption = index === answerList[currentImageIndex];

    const borderColor = isSelected
        ? 'border-blue-500'
        : isCorrectOption
            ? 'border-green-500'
            : 'border-red-500';

    const imageClasses = `w-40 h-40 object-contain p-2 cursor-pointer hover:border-2 ${borderColor}`;

    return (
        <img
            key={index}
            src={image}
            alt={`Soru ${currentImageIndex + 1} Görsel ${index + 1}`}
            className={imageClasses}
            onClick={() => handleImageClick(index)}
            style={{ border: isSelected ? '2px solid blue' : 'none' }}
        />
    );
  };

  const restartExercise = () => {
    setIsStart(false); // Egzersizi durdur
    setIsFinish(false); // Tamamlandı durumunu sıfırla
    setCurrentImageIndex(0); // Görsel indeksini sıfırla
    setSelectedImageIndex(null); // Seçili görseli sıfırla
    setIsCorrect(null)
    setCorrectCount(0); // Doğru sayısını sıfırla
    setPassCount(0); // Pas sayısını sıfırla
    setElapsedSeconds(0); // Zamanlayıcıyı sıfırla
    setSelectedLevel(1); // Seviye seçimini başa al
    setDayImages([]); // Görselleri temizle
    setAnswerList([]); // Cevap listesini temizle
    setDescriptionList([]);
    const initialData = vocabolery.find(item => item.level === '1');
    if (initialData && initialData.data && initialData.data.length > 0) {
      const levelData = initialData.data[0];
      setDayImages(levelData.img[0] || []);
      setAnswerList(levelData.answer[0] || []);
      setDescriptionList(levelData.description[0] || []);
    } else {
      console.warn('Seviye 1 için veri bulunamadı veya veri yapısı hatalı');
      setDayImages([]);
      setAnswerList([]);
      setDescriptionList([]);
    }
  };
  return (
    <div className="h-screen overflow-hidden">
      <Header title={'Hangisi Farklı'} returnType="attention-memory">
        <button
            onClick={restartExercise}
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
        >
          Sıfırla
        </button>
      </Header>
      <div
          className="absolute top-0 bg-white right-0 mt-16 mr-8 p-4 w-40 flex flex-col justify-center items-center border-2 border-b-gray-100 rounded-md text-lg font-semibold">
        <div className="flex-col p-2 justify-center items-center">
          <span className="flex gap-2 items-center">
            <CircleCheckBig size={20} color="#14cb1f" strokeWidth={1.75} />
            {correctCount}
          </span>
        </div>
        <div>
          <span className="flex gap-2 items-center">
            <CircleX size={20} strokeWidth={1.75} color="#ff0000" />
            {passCount}
          </span>{' '}
        </div>
        <div className="p-2">Süre: {formatTime(elapsedSeconds)}</div>
      </div>
      <div className="h-full bg-gray-200 ml-0 mr-0 mt-0 flex flex-col items-center justify-center">
        <div className="bg-white h-auto w-1/2 rounded-xl mb-32 py-8 relative">
          <div className="flex flex-col items-center gap-8">
            <div className="text-2xl font-bold bg-white p-2 rounded-xl border-2">
              {descriptionList[currentImageIndex]}
            </div>
            <div className="flex justify-around items-center w-full">
              {dayImages[currentImageIndex]?.map((image, index) => renderImage(image, index))}
            </div>
          </div>
        </div>
        <div className="flex justify-center space-x-4 mt-4">
          <button
              onClick={handlePass}
              className="bg-blue-300 text-xl text-white py-3 px-6 rounded-xl hover:bg-red-300"
          >
            Pas
          </button>
          <button
              onClick={checkResponse}
              className="bg-green-400 text-xl text-white py-4 px-6 rounded-xl hover:bg-green-300"
          >
            Kontrol Et
          </button>
        </div>
      </div>

      <Footer/>
      {!isStart && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
            <div className="bg-gray-300 p-8 rounded-md text-center w-1/3">
              <h2 className="font-semibold text-2xl p-1">Hangisi Farklı Egzersizi</h2>
              <div className="space-y-6">
                {/* Seviye Seçimi */}
                <div className="bg-white p-4 rounded-lg shadow-sm">
                  <label htmlFor="levelSelect" className="block text-left text-gray-700 font-semibold mb-2">
                    📚 Seviye Seçimi:
                  </label>
                  <select
                      id="levelSelect"
                      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                      value={selectedLevel}
                      onChange={handleLevelChange}
                  >
                    {vocabolery.map((levelData) => (
                        <option key={levelData.level} value={levelData.level}>
                          Seviye {levelData.level}
                        </option>
                    ))}
                  </select>
                </div>
              </div>
              <button
                  className="bg-blue-400 text-white py-2 px-4 mt-4 rounded hover:bg-blue-300"
                  onClick={handleStart}
              >
                🚀 Başla
              </button>
            </div>
          </div>
      )}
      {isFinish && (
          <div>
            <div className="bg-gray-500 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
            <div
              className="bg-white p-8 rounded-md text-center"
              style={{ width: '300px' }}
            >
              <p style={{ fontSize: '20px' }}>
                Tebrikler! Egzersizi tamamladınız.
              </p>
              <p>Doğru: {correctCount}</p>
              <p>Pas: {passCount}</p>
              <p>Geçen Süre:{formatTime(elapsedSeconds)}</p>
              <button
                  className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-200"                onClick={restartExercise}
              >
                Tekrar
              </button>
              <div>
                <button
                  onClick={handleReturnDashboard}
                  className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-200"                >
                  Egzersiz Sayfasına Dön
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isCorrect !== null && (
        <div className="fixed inset-0 flex items-center justify-center">
          <span
            className={`text-custom-6xl font-bold animate-ping animate-once ${
              isCorrect ? 'text-green-500' : 'text-red-500'
            }`}
          >
            {isCorrect ? 'Doğru!' : 'Yanlış!'}
          </span>
        </div>
      )}
      <FullscreenAlert onConfirm={handleConfirm} />
    </div>
  )
}

export default DifferentPicture

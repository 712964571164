import React, { useRef, useState, useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import { PDFDocument, rgb } from 'pdf-lib'
import { saveAs } from 'file-saver'
import html2canvas from 'html2canvas'
import fontkit from '@pdf-lib/fontkit'
import { Chart, registerables } from 'chart.js'

Chart.register(...registerables)
const PDFGenerator = ({ selectedUser, formData }) => {
  const chart1Ref = useRef(null)
  const chart2Ref = useRef(null)
  const [showCharts, setShowCharts] = useState(false)
  const [pdfReady, setPdfReady] = useState(false)

  const generatePDF = async () => {
    try {
      // Var olan PDF'i yükle
      const existingPdfBytes = await fetch('/t.pdf').then((res) => {
        if (!res.ok) {
          throw new Error('PDF dosyası yüklenemedi')
        }
        return res.arrayBuffer()
      })

      const pdfDoc = await PDFDocument.load(existingPdfBytes)

      // Özel yazı tipini yükle
      pdfDoc.registerFontkit(fontkit)
      const fontBytes = await fetch('/Roboto-Regular.ttf').then((res) =>
        res.arrayBuffer()
      )
      const customFont = await pdfDoc.embedFont(fontBytes)

      const pages = pdfDoc.getPages()
      const firstPage = pages[0]
      const { width, height } = firstPage.getSize()

      // Öğrenci bilgileri ekleme
      firstPage.drawText(`${selectedUser.name}`, {
        x: 95,
        y: height - 120,
        size: 10,
        font: customFont,
        color: rgb(0, 0, 0),
      })
      firstPage.drawText(`${selectedUser.level}`, {
        x: 365,
        y: height - 120,
        size: 10,
        font: customFont,
        color: rgb(0, 0, 0),
      })
      firstPage.drawText(`${selectedUser.startDate}`, {
        x: 130,
        y: height - 140,
        size: 10,
        font: customFont,
        color: rgb(0, 0, 0),
      })
      firstPage.drawText(`${selectedUser.endDate}`, {
        x: 405,
        y: height - 140,
        size: 10,
        font: customFont,
        color: rgb(0, 0, 0),
      })
      firstPage.drawText(`${selectedUser.teacher}`, {
        x: 265,
        y: height - 730,
        size: 10,
        font: customFont,
        color: rgb(0, 0, 0),
      })
      firstPage.drawText(
        `${formData.exerciseCompletionRateStart}`,
        {
          x: 125,
          y: height - 162,
          size: 10,
          font: customFont,
          color: rgb(0, 0, 0),
        }
      )

      firstPage.drawText(
          `${formData.readingSpeedStart}`,
          {
            x: 420,
            y: height - 265,
            size: 10,
            font: customFont,
            color: rgb(0, 0, 0),
          }
      )

      firstPage.drawText(
          `${formData.readingSpeedEnd}`,
          {
            x: 522,
            y: height - 265,
            size: 10,
            font: customFont,
            color: rgb(0, 0, 0),
          }
      )

      firstPage.drawText(
          `${formData.silentReadingSpeedStart}`,
          {
            x: 420,
            y: height - 285,
            size: 10,
            font: customFont,
            color: rgb(0, 0, 0),
          }
      )

      firstPage.drawText(
          `${formData.silentReadingSpeedEnd}`,
          {
            x: 522,
            y: height - 285,
            size: 10,
            font: customFont,
            color: rgb(0, 0, 0),
          }
      )

      firstPage.drawText(
          `${formData.comprehensionRateStart}`,
          {
            x: 420,
            y: height - 307,
            size: 10,
            font: customFont,
            color: rgb(0, 0, 0),
          }
      )

      firstPage.drawText(
          `${formData.comprehensionRateEnd}`,
          {
            x: 522,
            y: height - 307,
            size: 10,
            font: customFont,
            color: rgb(0, 0, 0),
          }
      )

      firstPage.drawText(
          `${formData.readingSpeedIncreaseRateStart}`,
          {
            x: 522,
            y: height - 327,
            size: 10,
            font: customFont,
            color: rgb(0, 0, 0),
          }
      )

      firstPage.drawText(formData.instructorComments, {
        x: 50,
        y: height - 640,
        size: 10,
        font: customFont,
        color: rgb(0, 0, 0),
      })

      // Grafiklerin canvas görüntüsünü al ve PDF'e ekle
      const addChartToPDF = async (chartRef, yOffset, x) => {
        const canvas = await html2canvas(chartRef.current)
        const imgData = canvas.toDataURL('image/png')
        const img = await pdfDoc.embedPng(imgData)
        const imgDims = img.scale(0.6) // Grafik boyutunu küçült
        firstPage.drawImage(img, {
          x: x,
          y: yOffset,
          width: imgDims.width,
          height: imgDims.height,
        })
      }

      await addChartToPDF(chart1Ref, height - 530, 70)
      await addChartToPDF(chart2Ref, height - 530, 330)

      // PDF dosyasını kaydetme
      const pdfBytes = await pdfDoc.save()
      const blob = new Blob([pdfBytes], { type: 'application/pdf' })
      saveAs(blob, 'Report.pdf')
    } catch (error) {
      console.error('PDF oluşturma hatası:', error)
    }
  }

  useEffect(() => {
    if (showCharts) {
      // Grafikler gösterildikten sonra PDF hazır duruma getir
      setTimeout(() => {
        setPdfReady(true)
      }, 1000) // 1 saniye bekleme süresi, grafiklerin tamamen render edilmesi için
    } else {
      setPdfReady(false)
    }
  }, [showCharts])

  const chartData1 = {
    labels: ['Başlangıç', 'Bitiş'],
    datasets: [
      {
        label: 'Sessiz Okuma Hızı Artışı',
        data: [
          formData.silentReadingSpeedStart,
          formData.silentReadingSpeedEnd,
        ],
        backgroundColor: 'rgba(75,192,192,0.6)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
      },
    ],
  }

  const chartData2 = {
    labels: ['Başlangıç', 'Bitiş'],
    datasets: [
      {
        label: 'Anlama Oranı Artışı',
        data: [formData.comprehensionRateStart, formData.comprehensionRateEnd],
        backgroundColor: 'rgba(153,102,255,0.6)',
        borderColor: 'rgba(153,102,255,1)',
        borderWidth: 1,
      },
    ],
  }

  return (
    <div className="overflow-auto">
      <button
        onClick={generatePDF}
        className={`mt-4 bg-blue-500 text-white py-2 px-4 rounded ${
          !pdfReady ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        disabled={!pdfReady}
      >
        PDF Oluştur
      </button>
      <button
        onClick={() => setShowCharts(!showCharts)}
        className="mt-4 bg-green-500 text-white py-2 px-4 rounded ml-4"
      >
        {showCharts ? 'Grafikleri Kapat' : 'Grafikleri Gör'}
      </button>

      {showCharts && (
        <div className="relative flex flex-row">
          <div ref={chart1Ref} style={{ width: '50%', height: '150px' }}>
            <Bar data={chartData1} />
          </div>

          <div
            ref={chart2Ref}
            style={{ width: '50%', height: '150px' }}
            className="mt-4"
          >
            <Bar data={chartData2} />
          </div>
        </div>
      )}
    </div>
  )
}

export default PDFGenerator

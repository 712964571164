export const trueColors = [
    { id: 1, name: 'Kırmızı', color: 'red' },
    { id: 2, name: 'Mavi', color: 'blue' },
    { id: 3, name: 'Yeşil', color: 'green' },
    { id: 4, name: 'Sarı', color: 'yellow' },
    { id: 5, name: 'Mor', color: 'purple' },
    { id: 6, name: 'Turuncu', color: 'orange' },
    { id: 7, name: 'Pembe', color: 'pink' },
    { id: 8, name: 'Kahverengi', color: 'brown' },
    { id: 9, name: 'Gri', color: 'grey' },
    { id: 10, name: 'Açık Mavi', color: 'skyblue' },
    { id: 11, name: 'Lacivert', color: 'navy' },
    { id: 12, name: 'Siyah', color: 'black' },
];



export const generateFalseColors = () => {
    const falseColors = [];
    let idCounter = 100; // 10'dan başlat, çünkü trueColors 1-9 arasında

    trueColors.forEach((trueColor) => {
        trueColors.forEach((otherColor) => {
            if (trueColor.color !== otherColor.color) {
                falseColors.push({
                    id: idCounter++,
                    name: trueColor.name,
                    color: otherColor.color,
                });
            }
        });
    });

    return falseColors;
};
import React, { useState, useEffect, useRef } from 'react'
import { playCongrulationSound } from '../../effect/Congrulation'
import Header from '../Header'
import Footer from '../Footer'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import VocabolaryServices from '../../Services/VocabolaryServices'





const ReadUnderstandReply = () => {
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [availableOptions, setAvailableOptions] = useState([]);
  const [vocabolery, setVocabolery] = useState([]);
  const [isStart, setIsStart] = useState(false)
  const [showQuestions, setShowQuestions] = useState(false)
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [showResults, setShowResults] = useState(false)
  const [score, setScore] = useState(0)
  const [isFinish, setIsFinish] = useState(false)
  const [incorrectAnswer, setIncorrectAnswer] = useState(0)
  const [timeElapsed, setTimeElapsed] = useState(0)
  const [timerActive, setTimerActive] = useState(false)

  const token = window.localStorage.getItem('token')
  const questionsContainerRef = useRef(null)
  const wordCount = text.split(' ').length
  const [readingSpeed, setReadingSpeed] = useState(null)
  const [availableTitles, setAvailableTitles] = useState([]); // Hikaye başlıkları
  const [selectedTitle, setSelectedTitle] = useState(''); // Seçili hikaye


  const vocabolaryservices = new VocabolaryServices()


  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  useEffect(() => {
    vocabolaryservices
        .getVocabolary('readunderstandreply')
        .then((response) => {
          setVocabolery(response.data)
        })
        .catch((error) => {
          console.error('Kategorileri alırken hata oluştu:', error)
        })
  }, [])

  useEffect(() => {
    if (selectedLevel) {
      const selectedLevelData = vocabolery.find(
          (levelData) => levelData.level === selectedLevel
      );

      if (selectedLevelData && selectedLevelData.data) {
        // Extract all titles from the selected level
        const titleArray = selectedLevelData.data.reduce((acc, item) => {
          if (Array.isArray(item.title)) {
            return [...acc, ...item.title];
          }
          return [...acc, item.title];
        }, []);

        setAvailableTitles(titleArray.filter(Boolean)); // Filter out any null/undefined titles
      }
    } else {
      setAvailableTitles([]);
    }
  }, [selectedLevel, vocabolery]);

  // Update content when title is selected
  useEffect(() => {
    if (selectedLevel && selectedTitle) {
      const selectedLevelData = vocabolery.find(
          (levelData) => levelData.level === selectedLevel
      );

      if (selectedLevelData && selectedLevelData.data) {
        // Find the data item that contains the selected title
        const selectedData = selectedLevelData.data.find(item => {
          if (Array.isArray(item.title)) {
            return item.title.includes(selectedTitle);
          }
          return item.title === selectedTitle;
        });

        if (selectedData) {
          // If titles are in an array, find the index of the selected title
          const titleIndex = Array.isArray(selectedData.title)
              ? selectedData.title.indexOf(selectedTitle)
              : 0;

          // Set text based on whether it's an array or single value
          const selectedText = Array.isArray(selectedData.text)
              ? selectedData.text[titleIndex]
              : selectedData.text;

          // Set questions based on whether it's an array or single value
          const selectedQuestions = Array.isArray(selectedData.question)
              ? selectedData.question[titleIndex]
              : selectedData.question;

          setText(selectedText || '');
          setTitle(selectedTitle);
          setQuestions(selectedQuestions || []);
          setAnswers(Array(selectedQuestions?.length || 0).fill(''));
        }
      }
    }
  }, [selectedTitle, selectedLevel, vocabolery]);

  useEffect(() => {
    if (selectedLevel && selectedOption) {
      const selectedLevelData = vocabolery.find(
          (levelData) => levelData.level === selectedLevel
      );

      if (selectedLevelData && Array.isArray(selectedLevelData.data)) {
        const selectedDataIndex = parseInt(selectedOption.replace('Seçenek ', ''), 10) - 1;
        const selectedData = selectedLevelData.data[selectedDataIndex];
        console.log(selectedData.title)
        if (selectedData) {
          setText(selectedData.text?.join('\n') || '');
          setTitle(selectedData.title?.[selectedDataIndex] || 'Başlık');
          setQuestions(selectedData.question?.[selectedDataIndex] || []);
          setAnswers(
              Array(selectedData.question?.[selectedDataIndex]?.length || 0).fill('')
          );
        }
      }
    }
  }, [selectedOption, selectedLevel, vocabolery]);

  const [isConfirmed, setIsConfirmed] = useState(false)

  const handleLevelChange = (event) => {
    const level = event.target.value;
    setSelectedLevel(level);
    setAvailableOptions([]); // Alt seçenekleri sıfırla
    setSelectedOption(''); // Seçili alt seçeneği sıfırla
  };

  const handleOptionChange = (event) => {
    const option = event.target.value;
    setSelectedOption(option);
  };

  const handleConfirm = () => {
    setIsConfirmed(true)
  }

  const handleAnswerChange = (index, answer) => {
    const newAnswers = [...answers]
    newAnswers[index] = answer
    setAnswers(newAnswers)
  }
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${String(minutes).padStart(2, '0')}:${String(
        remainingSeconds
    ).padStart(2, '0')}`
  }
  useEffect(() => {
    let interval = null

    if (timerActive) {
      interval = setInterval(() => {
        setTimeElapsed((prevTime) => prevTime + 1)
      }, 1000)
    } else {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [timerActive])
  const handleStart = () => {
    setIsStart(true)
    setTimerActive(true)
  }

  const handleQuestionClick = (index) => {
    setCurrentQuestion(index)
  }

  useEffect(() => {
    if (questionsContainerRef.current) {
      const questionHeight =
          questionsContainerRef.current.children[currentQuestion].offsetHeight
      const containerHeight = questionsContainerRef.current.offsetHeight
      const scrollTop =
          questionsContainerRef.current.children[currentQuestion].offsetTop -
          (containerHeight - questionHeight) / 2

      questionsContainerRef.current.scrollTo({
        top: scrollTop,
        behavior: 'smooth',
      })
    }
  }, [currentQuestion])

  const handleShowQuestion = () => {
    setShowQuestions(true)
    setTimerActive(false)
  }
  const handleSubmit = () => {
    let newScore = 0
    let incorrect = 0
    if (timeElapsed > 0) {
      setReadingSpeed(Math.round((wordCount * 60) / timeElapsed))
    }
    answers.forEach((answer, index) => {
      if (answer === questions[index].answer) {
        newScore++
      } else {
        incorrect++
      }
    })

    setScore(newScore)
    setIncorrectAnswer(incorrect)

    playCongrulationSound()

    setIsFinish(true)
  }

  const restartExercise = () => {
    setIsStart(false)
    setShowQuestions(false)
    setAnswers(Array(questions.length).fill(''))
    setCurrentQuestion(0)
    setShowResults(false)
    setScore(0)
    setIsFinish(false)
    setIncorrectAnswer(0)
    setTimeElapsed(0)
    setTimerActive(false)
  }
  function splitTextIntoLines(text, wordsPerLine) {
    const words = text.split(' ')
    const lines = []
    for (let i = 0; i < words.length; i += wordsPerLine) {
      lines.push(words.slice(i, i + wordsPerLine).join(' '))
    }
    return lines
  }

  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/speed-reading-comprehension`
  }
  const handleNextQuestion = () => {
    setCurrentQuestion((prevQuestion) =>
        prevQuestion < questions.length - 1 ? prevQuestion + 1 : prevQuestion
    )
  }

  const handlePreviousQuestion = () => {
    setCurrentQuestion((prevQuestion) =>
        prevQuestion > 0 ? prevQuestion - 1 : prevQuestion
    )
  }

  return (
      <div className="h-screen flex">
        {isStart && (
            <div className="flex-grow flex flex-col">
              <Header title={'Oku Anla Cevapla'} returnType="speed-reading">
                <button
                    onClick={restartExercise}
                    className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
                >
                  Sıfırla
                </button>
              </Header>
              <div className="flex flex-col flex-grow justify-center items-center p-4 text-xl">
                <div className="flex flex-col items-center mb-8 w-full">
                  {!showQuestions && (
                      <>
                        <div className="text-center w-full  p-4 flex justify-center items-center ">
                          <div className="flex flex-col">
                            <div className="text-xl font-semibold mb-8">{title}</div>
                            <div className="w-full h-special overflow-y-auto text-left pl-8">
                              {splitTextIntoLines(text, 10).map((line, index) => (
                                  <p key={index} className="mb-2">
                                    {line}
                                  </p>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div>
                          <button
                              className="bg-green-500 text-white px-4 py-2 rounded-xl mt-4 hover:bg-green-400"
                              onClick={handleShowQuestion}
                          >
                            Okudum
                          </button>
                        </div>
                      </>
                  )}
                  {showQuestions && !showResults && (
                      <div
                          className="text-center w-2/3 min-h-[400px] flex flex-col justify-between bg-white rounded-lg shadow-lg p-6">
                        <div className="flex-grow flex flex-col">
                          <div className="text-left mb-6 border-b pb-4">
                            <span className="font-semibold">Soru {currentQuestion + 1}:</span>
                            <p className="mt-2 pl-4">{questions[currentQuestion].question}</p>
                          </div>
                          <div className="flex flex-col items-start mb-4 pl-4">
                            {questions[currentQuestion].options.map((option, index) => (
                                <label key={option}
                                       className="flex items-center mb-4 w-full cursor-pointer hover:bg-gray-50 p-2 rounded">
                                  <input
                                      type="radio"
                                      name={`question-${currentQuestion}`}
                                      value={option}
                                      checked={answers[currentQuestion] === option}
                                      onChange={() => handleAnswerChange(currentQuestion, option)}
                                      className="mr-3"
                                  />
                                  <span className="mr-2">{String.fromCharCode(97 + index)})</span>
                                  <span>{option}</span>
                                </label>
                            ))}
                          </div>
                        </div>
                        <div className="flex justify-between w-full mt-6 pt-4 border-t">
                          {currentQuestion > 0 && (
                              <button
                                  className="bg-blue-500 text-white px-6 py-2 rounded-xl mr-2 hover:bg-blue-400 transition-colors"
                                  onClick={handlePreviousQuestion}
                              >
                                Önceki Soru
                              </button>
                          )}
                          {currentQuestion < questions.length - 1 && (
                              <button
                                  className="bg-blue-500 text-white px-6 py-2 rounded-xl hover:bg-blue-400 transition-colors"
                                  onClick={handleNextQuestion}
                              >
                                Sonraki Soru
                              </button>
                          )}
                        </div>
                      </div>
                  )}
                  {showResults && (
                      <div className="text-center">
                        <p>
                          Toplam Skor: {score} / {questions.length}
                        </p>
                      </div>
                  )}
                </div>
              </div>
              <Footer/>
            </div>

        )}
        {showQuestions && (
            <div
                className="w-1/7 h-2/3 absolute top-24 right-3  p-4 overflow-y-auto shadow-xl flex flex-col rounded-xl">
              <div
                  ref={questionsContainerRef}
                  className="flex-grow flex flex-col space-y-4 overflow-y-auto"
              >
                {questions.map((question, qIndex) => (
                    <div key={qIndex} className="flex flex-col items-start  p-2">
                      <div className="underline mb-4 border-black flex justify-center items-center">
                        <button
                            className="text-black"
                            onClick={() => handleQuestionClick(qIndex)}
                        >
                          {qIndex + 1}.Soru
                        </button>
                      </div>
                      <div className="flex space-x-2">
                        {question.options.map((option, oIndex) => (
                            <label key={option} className="flex items-center">
                              <input
                                  type="radio"
                                  name={`optik-${qIndex}`}
                                  value={option}
                                  checked={answers[qIndex] === option}
                                  onChange={() => handleAnswerChange(qIndex, option)}
                                  className="hidden"
                              />
                              <span
                                  className={`w-8 h-8 border-2 rounded-full flex items-center justify-center ${
                                      answers[qIndex] === option ? 'bg-green-300' : ''
                                  }`}
                              >
                        {String.fromCharCode(97 + oIndex).toUpperCase()}
                      </span>
                            </label>
                        ))}
                      </div>
                    </div>
                ))}
                <button
                    className="bg-green-500 text-white px-4 py-2 rounded-xl mt-4 self-center hover:bg-green-400"
                    onClick={handleSubmit}
                >
                  Sınavı Bitir
                </button>
              </div>
            </div>
        )}
        {!isStart && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
              <div className="bg-gray-300 p-8 rounded-md text-center w-1/3">
                <h2 className="font-semibold text-2xl p-1">
                  Oku Anla Cevapla Egzersizi
                </h2>
                <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
                  <label
                      htmlFor="levelSelect"
                      className="block text-gray-700 font-semibold mb-2 text-left"
                  >
                    📚 Seviye Seçimi:
                  </label>
                  <select
                      id="levelSelect"
                      className="w-full p-2 border border-gray-300 rounded-md"
                      value={selectedLevel}
                      onChange={handleLevelChange}
                  >
                    <option value="" disabled>Seviye Seç</option>
                    {vocabolery.map((levelData) => (
                        <option key={levelData.level} value={levelData.level}>
                          Seviye {levelData.level}
                        </option>
                    ))}
                  </select>
                </div>

                {availableTitles.length > 0 && (
                    <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
                      <label
                          htmlFor="titleSelect"
                          className="block text-gray-700 font-semibold mb-2 text-left"
                      >
                        📖 Metin Seçimi:
                      </label>
                      <select
                          id="titleSelect"
                          className="w-full p-2 border border-gray-300 rounded-md"
                          value={selectedTitle}
                          onChange={(e) => setSelectedTitle(e.target.value)}
                      >
                        <option value="" disabled>Bir Metin Seç</option>
                        {availableTitles.map((title, index) => (
                            <option key={index} value={title}>
                              {title}
                            </option>
                        ))}
                      </select>
                    </div>
                )}
                <button
                    className="bg-blue-400 text-white py-2 px-4 mt-4 rounded hover:bg-blue-300"
                    onClick={handleStart}
                >
                  🚀 Başlat

                </button>
              </div>
            </div>
        )}
        {isFinish && (
            <div>
              <div className="bg-gray-500 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
                <div
                    className="bg-white p-8 rounded-md text-center"
                    style={{width: '300px'}}
                >
                  <p style={{fontSize: '20px'}}>
                    Tebrikler! Egzersizi tamamladınız.
                  </p>
                  <p>Doğru: {score}</p>
                  <p>Yanlış: {incorrectAnswer}</p>
                  <p>Okuma Süresi: {formatTime(timeElapsed)}</p>
                  <p>Okuma Hızı: {readingSpeed} kelime/dakika</p>
                  <button
                      className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
                      onClick={restartExercise}
                  >
                    Tekrar
                  </button>
                  <div>
                    <button
                        onClick={handleReturnDashboard}
                        className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
                    >
                      Egzersiz Sayfasına Dön
                    </button>
                  </div>
                </div>
              </div>
            </div>
        )}
        <FullscreenAlert onConfirm={handleConfirm} />
      </div>
  )
}

export default ReadUnderstandReply

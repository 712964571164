import React, { useState, useEffect, useRef } from 'react'
import { playCongrulationSound } from '../../effect/Congrulation'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import Footer from '../Footer'
import Header from '../Header'
import VocabolaryServices from "../../Services/VocabolaryServices";


const Shading = ({ dayNumber }) => {
  const [text, setText] = useState('')
  const [pages, setPages] = useState([])
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [currentLineIndex, setCurrentLineIndex] = useState(0)
  const [currentWordIndex, setCurrentWordIndex] = useState(0)
  const [showButtons, setShowButtons] = useState(false)
  const [allWordsShadowed, setAllWordsShadowed] = useState(false)
  const [groupCount, setGroupCount] = useState(2)
  const [speed, setSpeed] = useState(1000)
  const [started, setStarted] = useState(false)
  const [isStart, setIsStart] = useState(false)
  const [sentence, setSentence] = useState('')
  const [wordsPerLine, setWordsPerLine] = useState(8)
  const [vocabolery, setVocabolery] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true)


  const vocabolaryservices = new VocabolaryServices();

  const token = window.localStorage.getItem('token')

  // Create a ref to hold the interval ID
  const intervalRef = useRef(null)

  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])


  const handleConfirm = () => {
    console.log('Confirmation action triggered')
  }

  useEffect(() => {
    const calculateWordsPerLine = () => {
      if (groupCount === 2) {
        return 8
      } else {
        return 9
      }
    }

    setWordsPerLine(calculateWordsPerLine())
  }, [groupCount, sentence])

  useEffect(() => {
    const sampleWordGroup = sentence.split(' ')
    setText(sentence)

    const linesArray = []
    for (let i = 0; i < sampleWordGroup.length; i += wordsPerLine) {
      linesArray.push(sampleWordGroup.slice(i, i + wordsPerLine).join(' '))
    }

    const totalPages = Math.ceil(linesArray.length / 8)
    const pagesArray = Array.from({ length: totalPages }, (_, i) =>
      linesArray.slice(i * 8, (i + 1) * 8)
    )
    setPages(pagesArray)
  }, [sentence, wordsPerLine])


  const handleCategoryClick = (category) => {
    setSelectedCategory((prevCategory) =>
        prevCategory === category ? null : category
    );
  };

  const startShading = () => {
    if (!started) {
      setStarted(true)
    }
  }

  const stopShading = () => {
    clearInterval(intervalRef.current)
    setStarted(false)
  }

  const resetShading = () => {
    clearInterval(intervalRef.current)
    setCurrentPageIndex(0)
    setCurrentLineIndex(0)
    setCurrentWordIndex(0)
    setShowButtons(false)
    setAllWordsShadowed(false)
    setStarted(false)
  }

  const screenStart = () => {
    const calculateWordsPerLine = () => {
      if (groupCount === 2) {
        return 8
      } else {
        return 9
      }
    }

    setWordsPerLine(calculateWordsPerLine())
    setIsStart(true)
  }

  useEffect(() => {
    if (
      started &&
      currentWordIndex <
        pages[currentPageIndex][currentLineIndex]?.split(' ').length
    ) {
      // Store interval ID in ref
      intervalRef.current = setInterval(() => {
        setCurrentWordIndex((prevIndex) => {
          const nextIndex = prevIndex + groupCount
          return nextIndex <=
            pages[currentPageIndex][currentLineIndex].split(' ').length
            ? nextIndex
            : pages[currentPageIndex][currentLineIndex].split(' ').length
        })
      }, speed)

      // Clear on unmount or when started changes
      return () => clearInterval(intervalRef.current)
    } else if (
      started &&
      currentWordIndex >=
        pages[currentPageIndex][currentLineIndex]?.split(' ').length
    ) {
      if (currentLineIndex < pages[currentPageIndex].length - 1) {
        setCurrentLineIndex(currentLineIndex + 1)
        setCurrentWordIndex(0)
      } else if (currentPageIndex < pages.length - 1) {
        setCurrentPageIndex(currentPageIndex + 1)
        setCurrentLineIndex(0)
        setCurrentWordIndex(0)
      } else {
        setAllWordsShadowed(true)
        if (allWordsShadowed) {
          setShowButtons(true)
          playCongrulationSound()
        }
      }
    }
  }, [
    currentWordIndex,
    pages,
    allWordsShadowed,
    groupCount,
    started,
    speed,
    currentLineIndex,
    currentPageIndex,
  ])

  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/speed-reading-comprehension`
  }

  const handleRestart = () => {
    setCurrentPageIndex(0)
    setCurrentLineIndex(0)
    setCurrentWordIndex(0)
    setShowButtons(false)
    setAllWordsShadowed(false)
    setStarted(false)
    setIsStart(false)
    setSelectedCategory(null)
    setText("")
  }

  useEffect(() => {
    setIsLoading(true); // Yüklenme başlatılıyor
    vocabolaryservices
        .getVocabolary("vocabolary_library")
        .then((response) => {
          setVocabolery(response.data);
          setIsLoading(false); // Yüklenme tamamlandı
        })
        .catch((error) => {
          console.error("Kategorileri alırken hata oluştu:", error);
          setIsLoading(false); // Hata olsa bile yüklenmeyi durdur
        });
  }, []);

  return (
    <div className="w-full h-screen flex items-center justify-center flex-col">
      <div className="absolute top-0 w-full">
        <Header title={'Gölgeli Yazı'} returnType="speed-reading">
          <button
              onClick={handleRestart}
              className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
          >
            Sıfırla
          </button>
        </Header>
      </div>
      <div className="w-2/3 mt-5 h-3/4 border-2 p-3 flex justify-center items-center overflow-y-auto">
        <div className="text-xl text-left leading-relaxed p-4">
          {pages[currentPageIndex]?.map((line, lineIndex) => (
            <p key={lineIndex} className="mb-4">
              {line.split(' ').map((word, wordIndex) => {
                const isCurrentGroup =
                  lineIndex === currentLineIndex &&
                  wordIndex >= currentWordIndex &&
                  wordIndex < currentWordIndex + groupCount
                return (
                  <span
                    key={wordIndex}
                    className={isCurrentGroup ? 'bg-gray-300' : 'text-black'}
                  >
                    {word}{' '}
                  </span>
                )
              })}
            </p>
          ))}
        </div>
      </div>
      {/* Control Buttons */}
      <div>
        {!started && (
          <button
            className="bg-green-500 hover:bg-green-600 text-white text-xl py-2 px-4 rounded-xl mb-4 my-16 mr-4"
            onClick={startShading}
          >
            Başla
          </button>
        )}
        {started && (
          <button
            className="bg-yellow-500 hover:bg-yellow-600 text-white text-xl py-2 px-4 rounded-xl mb-4 my-16 mr-4"
            onClick={stopShading}
          >
            Durdur
          </button>
        )}
        <button
          className="bg-red-500 hover:bg-red-600 text-white text-xl py-2 px-4 rounded-xl mb-4 my-16"
          onClick={resetShading}
        >
          Sıfırla
        </button>
      </div>
      <Footer />
      {!isStart && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50 overflow-auto">
            {isLoading ? (
                <div className="text-white text-lg font-semibold">Yükleniyor...</div>
            ) : (
                <div className="bg-white p-6 rounded-xl shadow-lg w-full max-w-6xl">
                  <h1 className="text-3xl font-bold text-center mb-1">Gölgeli Yazı Egzersizine Hoşgeldiniz!</h1>
                  <h2 className="text-3xl font-bold text-center mb-6">📚 Metin Kütüphanesi</h2>

                  {/* Kategori Kartları */}
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 overflow-auto max-h-[60vh]">
                    {[...new Set(vocabolery.map((item) => item.category))].map(
                        (category, index) => (
                            <div
                                key={index}
                                className="relative group rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 cursor-pointer"
                            >
                              <div
                                  onClick={() => handleCategoryClick(category)}
                                  className={`bg-gradient-to-br from-blue-500 to-blue-700 text-white p-6 h-32 flex items-center justify-center ${
                                      selectedCategory === category ? 'border-4 border-green-500' : ''
                                  }`}
                              >
                                <h3 className="text-xl font-semibold text-center">
                                  {category}
                                </h3>
                              </div>
                              {selectedCategory === category && (
                                  <div className="absolute inset-0 bg-white p-4 rounded-lg shadow-lg z-10 overflow-auto max-h-48">
                                    <div className="flex justify-between items-center mb-4">
                                      <h4 className="text-lg font-semibold text-gray-800">📝 Başlıklar</h4>
                                      <button
                                          onClick={() => setSelectedCategory(null)}
                                          className="text-gray-500 hover:text-red-500 transition-colors"
                                      >
                                        ❌
                                      </button>
                                    </div>
                                    <div className="space-y-2">
                                      {vocabolery
                                          .filter((item) => item.category === category)
                                          .map((item, idx) => (
                                              <div
                                                  key={idx}
                                                  onClick={() => setSentence(item.text)}
                                                  className={`p-2 rounded cursor-pointer transition-colors ${
                                                      sentence === item.text
                                                          ? 'bg-green-100 text-green-700'
                                                          : 'bg-gray-100 hover:bg-blue-50'
                                                  }`}
                                              >
                                                {item.name}
                                              </div>
                                          ))}
                                    </div>
                                  </div>
                              )}
                            </div>
                        )
                    )}
                  </div>

                  {/* Ayarlar Bölümü */}
                  <div className="mt-8 bg-gray-100 p-4 rounded-lg shadow-inner space-y-4">
                    {/* Hız Ayarı */}
                    <div>
                      <label htmlFor="speed" className="block text-gray-700 font-semibold mb-2">
                        🕒 Hız Ayarı: {speed} ms
                      </label>
                      <input
                          id="speed"
                          type="range"
                          min="200"
                          max="2000"
                          step="50"
                          value={speed}
                          onChange={(e) => setSpeed(parseInt(e.target.value))}
                          className="w-full"
                      />
                    </div>
                    {/* Kelime Gruplama*/}
                    <label htmlFor="wordGroup" className="block text-gray-700 font-semibold">
                      Kelime Grubu Seçimi: {groupCount}
                    </label>
                    <select
                        id="wordGroup"
                        className="w-full mb-4 p-2 border"
                        value={groupCount}
                        onChange={(e) => setGroupCount(parseInt(e.target.value))}
                    >
                      <option value="1">1 Kelime</option>
                      <option value="2">2 Kelime</option>
                      <option value="3">3 Kelime</option>
                    </select>
                  </div>

                  {/* Başla Butonu */}
                  <div className="text-center mt-6">
                    <button
                        onClick={() => setIsStart(true)}
                        className="bg-green-500 text-white px-6 py-2 rounded-lg text-lg hover:bg-green-400 transition-colors"
                    >
                      🚀 Devam
                    </button>
                  </div>
                </div>
            )}
          </div>
      )}
      {showButtons && (
          <div className="bg-gray-300 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
          <div className="bg-white p-8 rounded-md text-center">
            <p>Tebrikler! Egzersizi tamamladınız.</p>
            <button
                className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-200"
                onClick={handleReturnDashboard}
            >
              Egzersiz Sayfasına Dön
            </button>
            <div>
              <button
                  className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-200"
                onClick={handleRestart}
              >
                Tekrarla
              </button>
            </div>
          </div>
        </div>
      )}
      <FullscreenAlert onConfirm={handleConfirm} />
    </div>
  )
}

export default Shading

import React, { useState, useEffect } from 'react'
import { playCorrectSound } from '../../effect/Correct'
import { playInCorrectSound } from '../../effect/Incorrect'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import Footer from '../Footer'
import Header from '../Header'
import VocabolaryServices from "../../Services/VocabolaryServices";


const SameLetter = () => {
  const [currentLetterIndex, setCurrentLetterIndex] = useState(0)
  const [correctList, setCorrectList] = useState([])
  const [incorrectList, setIncorrectList] = useState([])
  const [showingLetters, setShowingLetters] = useState(true)
  const [wordDisplayDuration, setWordDisplayDuration] = useState(500)
  const [isFinish, setIsFinish] = useState(false)
  const [isStart, setIsStart] = useState(false)
  const [isGreetingScreen, setIsGreetingScreen] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [selectedLevel, setSelectedLevel] = useState('')
  const [selectedLevelData, setSelectedLevelData] = useState(null)

  const [letters, setLetters] = useState([
  ])


  const [vocabolery, setVocabolery] = useState([])
  const token = window.localStorage.getItem('token')
  const [isConfirmed, setIsConfirmed] = useState(false)

  const vocabolaryservices = new VocabolaryServices()

  // Token kontrolü
  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  useEffect(() => {
    vocabolaryservices
        .getVocabolary('sameletter')
        .then((response) => {
          setVocabolery(response.data)
        })
        .catch((error) => {
          console.error('Kategorileri alırken hata oluştu:', error)
        })
  }, [])
  useEffect(() => {
    if (vocabolery.length > 0 && selectedLevel) {
      const selectedData = vocabolery.find(
          (levelData) => levelData.level === selectedLevel
      );

      if (selectedData && selectedData.data?.[0]?.letters) {
        let lettersData = selectedData.data[0].letters.map((item) => ({
          letter1: item.letter1 || 'Bilinmeyen',
          letter2: item.letter2 || 'Bilinmeyen',
          same: item.same ?? false, // undefined ise false ata
        }));

        // Kelime gruplarını karıştır
        lettersData = shuffleArray(lettersData);

        console.log('Karıştırılmış Harfler:', lettersData);

        setLetters(lettersData); // State güncelle
        setSelectedLevelData(selectedData); // Seviye verilerini güncelle
        setCurrentLetterIndex(0); // İndeksi sıfırla
      }
    }
  }, [selectedLevel, vocabolery]);

  useEffect(() => {
    if (vocabolery.length > 0 && !selectedLevel) {
      const firstLevel = vocabolery[0];
      setSelectedLevel(firstLevel.level);

      if (firstLevel.data?.[0]?.letters) {
        let lettersData = firstLevel.data[0].letters.map((item) => ({
          letter1: item.letter1 || 'Bilinmeyen',
          letter2: item.letter2 || 'Bilinmeyen',
          same: item.same ?? false,
        }));

        // Harfleri karıştır
        lettersData = shuffleArray(lettersData);

        console.log('Otomatik ilk seviye yüklendi ve karıştırıldı:', lettersData);

        setLetters(lettersData);
        setSelectedLevelData(firstLevel);
      }
    }
  }, [vocabolery]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap işlemi
    }
    return array;
  };

  const handleLevelChange = (event) => {
    const selected = event.target.value;
    setSelectedLevel(selected);
    setLetters([]); // Önce mevcut harf listesini temizle

    // Seçilen seviyeye ait veri çekiliyor
    const selectedData = vocabolery.find((levelData) => levelData.level === selected);

    if (selectedData && selectedData.data?.[0]?.letters) {
      let lettersData = selectedData.data[0].letters.map((item) => ({
        letter1: item.letter1 || 'Bilinmeyen',
        letter2: item.letter2 || 'Bilinmeyen',
        same: item.same ?? false, // undefined ise false ata
      }));

      // Kelime gruplarını karıştır
      lettersData = shuffleArray(lettersData);

      console.log('Karıştırılmış Harfler:', lettersData);

      setLetters(lettersData); // Yeni harf listesini state'e ata
      setSelectedLevelData(selectedData); // Seçilen seviye verilerini sakla
      setCurrentLetterIndex(0); // İndeksi sıfırla
    } else {
      console.warn('Seçilen seviyede geçerli bir letters verisi bulunamadı!');
      setSelectedLevelData(null);
      setLetters([]);
    }
  };
  // Yanıt işleme fonksiyonu
  const handleAnswer = (isCorrect) => {
    if (isProcessing || isFinish) return;

    setIsProcessing(true);
    setShowingLetters(false); // Hemen harfleri gizle

    if (isCorrect) {
      playCorrectSound();
      setCorrectList((prevList) => [...prevList, letters[currentLetterIndex]]);
    } else {
      playInCorrectSound();
      setIncorrectList((prevList) => [...prevList, letters[currentLetterIndex]]);
    }


    // Belirli bir süre sonra bir sonraki harfe geç
    setTimeout(() => {
      if (currentLetterIndex < letters.length - 1) {
        setCurrentLetterIndex(prevIndex => prevIndex + 1);
        setShowingLetters(true); // Yeni harfler gösterilmeden önce true yap
      } else {
        setIsFinish(true);
      }
      setIsProcessing(false);
    }, wordDisplayDuration);
  };

  // Zaman aşımı kontrolü
  useEffect(() => {
    let timeoutId;
    if (showingLetters && !isFinish && isStart && !isProcessing) {
      timeoutId = setTimeout(() => {
        handleAnswer(false); // Süre dolduğunda otomatik yanlış cevap
      }, wordDisplayDuration);
    }
    return () => clearTimeout(timeoutId);
  }, [showingLetters, isFinish, isStart, isProcessing, currentLetterIndex, wordDisplayDuration]);



  useEffect(() => {
    if (!showingLetters) {
      setIsProcessing(false); // Gösterim bittikten sonra işlem durumu sıfırlanır
    }
  }, [showingLetters]);

  // Tuş girişi kontrolü
  const handleKeyPress = (e) => {
    if (isFinish || isProcessing) return; // Egzersiz bittiyse veya işlem devam ediyorsa işlem yapılmaz

    // Başla butonu Enter ile tetiklenir
    if (e.key === 'Enter' && !isStart && isGreetingScreen) {
      handleStart();
      return;
    }

    // Oyun sırasında ok tuşları çalışır
    if (isStart) {
      if (e.key === 'ArrowRight' && letters[currentLetterIndex]?.same) {
        handleAnswer(true);
        playCorrectSound();
      } else if (e.key === 'ArrowLeft' && !letters[currentLetterIndex]?.same) {
        handleAnswer(true);
        playCorrectSound();
      } else if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
        handleAnswer(false);
        playInCorrectSound();
      }
    }
  };

// Tuş dinleyicisinin güvenli kullanımı
  useEffect(() => {
    const handleDocumentKeyPress = (e) => handleKeyPress(e);

    document.addEventListener('keydown', handleDocumentKeyPress);
    return () => {
      document.removeEventListener('keydown', handleDocumentKeyPress);
    };
  }, [currentLetterIndex, isFinish, isStart, isProcessing, isGreetingScreen, letters]);

  // Oyunu başlatma fonksiyonları
  const handleStart = () => {
    setIsStart(true)
  }

  const handleGreetingScreen = () => {
    setIsGreetingScreen(true)
  }

  // Restart fonksiyonu - Egzersizi sıfırlama
  const restartExercise = async () => {
    setCurrentLetterIndex(0);
    setCorrectList([]);
    setIncorrectList([]);
    setShowingLetters(true);
    setIsFinish(false);
    setIsStart(false);
    setIsGreetingScreen(false);
    setIsProcessing(false);

    // Seçili seviyeye göre harfleri tekrar yükle ve karıştır
    if (selectedLevelData?.data?.[0]?.letters) {
      let lettersData = selectedLevelData.data[0].letters.map((item) => ({
        letter1: item.letter1 || 'Bilinmeyen',
        letter2: item.letter2 || 'Bilinmeyen',
        same: item.same ?? false,
      }));

      // Harfleri karıştır
      lettersData = shuffleArray(lettersData);

      console.log('Restart sonrası karıştırılmış harfler:', lettersData);

      setLetters(lettersData);
    } else if (vocabolery.length > 0) {
      // Eğer seviye seçili değilse ilk seviyeyi yükle
      const firstLevel = vocabolery[0];
      setSelectedLevel(firstLevel.level);

      if (firstLevel.data?.[0]?.letters) {
        let lettersData = firstLevel.data[0].letters.map((item) => ({
          letter1: item.letter1 || 'Bilinmeyen',
          letter2: item.letter2 || 'Bilinmeyen',
          same: item.same ?? false,
        }));

        // Harfleri karıştır
        lettersData = shuffleArray(lettersData);

        console.log('Restart sırasında otomatik ilk seviye yüklendi:', lettersData);

        setLetters(lettersData);
        setSelectedLevelData(firstLevel);
      }
    } else {
      setLetters([]);
    }
  };
  // Panoya dönme
  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/attention-memory-logic-math`
  }

  // Ana render
  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <Header title={'Bir Yönden Benzer'} returnType="attention-memory">
        <button
            onClick={restartExercise}
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
        >
          Sıfırla
        </button>
      </Header>
      <div className="flex-grow flex">
        <div className="flex-grow flex justify-center items-center">
          <div className="flex justify-around items-center w-full max-w-2xl mx-auto">
            {showingLetters && !isFinish && isStart ? (
              <>
                <div className="text-2xl font-bold">
                  {letters[currentLetterIndex].letter1}
                </div>
                <div className="text-2xl font-bold">
                  {letters[currentLetterIndex].letter2}
                </div>
              </>
            ) : null}
            {!isStart && isGreetingScreen && (
              <button
                className="bg-blue-400 text-white py-4 px-8 text-xl rounded hover:bg-blue-300"
                onClick={handleStart}
              >
                Başla
              </button>
            )}
          </div>
        </div>
        {isStart && (
          <div className="w-1/5 flex flex-col justify-center items-center bg-gray-100">
            <div>
              <div className="mb-4 flex space-x-4 text-lg font-semibold">
                <h1 className="border-4 p-1 border-blue-300 cursor-pointer">
                  {'<='} Sol Tuş (Benzer Değil)
                </h1>
                <h1 className="border-4 p-1 border-blue-300 cursor-pointer">
                  Sağ Tuş (Benzer) {'=>'}
                </h1>
              </div>
              <div>
                <div className="mb-4 flex justify-center">
                  <h2 className="text-lg font-semibold ">
                    Doğru: {correctList.length}
                  </h2>
                </div>
                <div className="mb-4 flex justify-center">
                  <h2 className="text-lg font-semibold">
                    Yanlış: {incorrectList.length}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />

      {isFinish && (
        <div
          className={`bg-opacity-50 flex h-screen items-center justify-center fixed inset-0 ${
            incorrectList.length >= 3 ||
            incorrectList.length > correctList.length
              ? 'bg-red-400'
              : 'bg-gray-300'
          }`}
        >
          <div
            className="bg-white p-8 rounded-md text-center"
            style={{ width: '300px' }}
          >
            <p style={{ fontSize: '20px' }}>
              {incorrectList.length >= 3 ||
              incorrectList.length > correctList.length
                ? 'Üzgünüm,  egzersizi tamamlayamadınız.'
                : 'Tebrikler! Egzersizi tamamladınız.'}
            </p>
            <div>
              <p>Doğru Yanıtlar:</p>
              <ul>
                {correctList.map((item, index) => (
                  <li key={index} style={{ color: 'green' }}>
                    {item.letter1} - {item.letter2}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <p>Yanlış Yanıtlar:</p>
              <ul>
                {incorrectList.map((item, index) => (
                  <li key={index} style={{ color: 'red' }}>
                    {item.letter1} - {item.letter2}
                  </li>
                ))}
              </ul>
            </div>
            <button
                className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
              onClick={restartExercise}
            >
              Tekrar
            </button>
            <div>
              <button
                onClick={handleReturnDashboard}
                className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
              >
                Egzersiz Sayfasına Dön
              </button>
            </div>
          </div>
        </div>
      )}

      {!isGreetingScreen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
          <div className="bg-gray-300 p-8 rounded-md text-center w-1/3">
            <h2 className="font-semibold text-2xl p-1">
              Bir Yönden Benzer Egzersizi
            </h2>
            {/* Level Selection */}
            <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
              <label
                  htmlFor="levelSelect"
                  className="block text-gray-700 font-semibold mb-2 text-left"
              >
                📚 Seviye Seçimi:
              </label>
              <select
                  id="levelSelect"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={selectedLevel}
                  onChange={handleLevelChange}
              >
                {vocabolery.map((levelData) => (
                    <option key={levelData.level} value={levelData.level}>
                      Seviye {levelData.level}
                    </option>
                ))}
              </select>
            </div>
            {/* Hız Ayarı */}
            <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
              <label className="block text-gray-700 font-semibold mb-2">⚡ Hız Ayarı: {wordDisplayDuration} ms</label>
              <input
                  type="range"
                  min="500"
                  max="3000"
                  step="100"
                  value={wordDisplayDuration}
                  onChange={(e) => setWordDisplayDuration(parseInt(e.target.value))}
                  className="w-full"
              />
              <div className="flex justify-between text-sm text-gray-500 mt-1">
                <span>500 ms</span>
                <span>3000 ms</span>
              </div>
            </div>
            <button
              className="bg-blue-400 text-white py-2 px-4 mt-4 rounded hover:bg-blue-300"
              onClick={handleGreetingScreen}
            >
              🚀 Başlat

            </button>
          </div>
        </div>
      )}
      <FullscreenAlert onConfirm={() => setIsConfirmed(true)} />

    </div>
  )
}

export default SameLetter

import React, { useState, useEffect, useRef } from 'react'
import { playCorrectSound } from '../../effect/Correct'
import { playInCorrectSound } from '../../effect/Incorrect'
import { playCongrulationSound } from '../../effect/Congrulation'
import Footer from '../Footer'
import Header from '../Header'
import FullscreenAlert from '../LoadPage/FullscreenAlert'

const HowManyCharacter = () => {
  const [inputValue, setInputValue] = useState('')
  const [randomLetters, setRandomLetters] = useState([])
  const [targetLetter, setTargetLetter] = useState('')
  const [correctCount, setCorrectCount] = useState(0)
  const [incorrectCount, setIncorrectCount] = useState(0)
  const [isFinish, setIsFinish] = useState(false)
  const [isStart, setIsStart] = useState(true)
  const [timer, setTimer] = useState(1)
  const [exampleCount, setExampleCount] = useState(0)
  const [difficulty, setDifficulty] = useState('basic')

  const [isConfirmed, setIsConfirmed] = useState(false)
  const [showContinueScreen, setShowContinueScreen] = useState(true)
  const [timerActive, setTimerActive] = useState(false)
  const [showInstructions, setShowInstructions] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [attempts, setAttempts] = useState(1) // deneme sayısı
  const settingsRef = useRef({
    timer: 3,
    difficulty: 'basic',
  })
  const levelLabels = {
    basic: 'Kolay',
    normal: 'Normal',
    hard: 'Zor',
  }
  const inputRef = useRef(null)
  const letterSize = 40 // Harf boyutu (piksel)
  const padding = 10 // Harfler arası minimum mesafe (piksel)

  const token = window.localStorage.getItem('token')

  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  useEffect(() => {
    if (!showContinueScreen && !isFinish && !isStart) {
      inputRef.current?.focus()
    }
  }, [showContinueScreen, isFinish, isStart])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])
  useEffect(() => {
    if (showInstructions) {
      settingsRef.current = {
        timer: timer,
        difficulty: difficulty,
      }
    }
  }, [timer, difficulty, showInstructions])
  const generateRandomLetters = () => {
    const letters = 'ABCÇDEFGHIJKLMNOÖPQRSTUÜVWXYZŞ'
    let result = ''

    const targetLetter = letters.charAt(
      Math.floor(Math.random() * letters.length)
    )
    let otherLetter = targetLetter
    while (otherLetter === targetLetter) {
      otherLetter = letters.charAt(Math.floor(Math.random() * letters.length))
    }

    let totalLettersCount
    switch (difficulty) {
      case 'basic':
        totalLettersCount = 10
        break
      case 'hard':
        totalLettersCount = 15
        break
      case 'normal':
        totalLettersCount = 12
        break
      default:
        totalLettersCount = 10
    }

    let targetLetterCount
    switch (settingsRef.current.difficulty) {
      case 'basic':
        targetLetterCount = Math.floor(Math.random() * 4) + 1
        break
      case 'hard':
        targetLetterCount = Math.floor(Math.random() * 6) + 6
        break
      case 'normal':
        targetLetterCount = Math.floor(Math.random() * 5) + 3
        break
      default:
        targetLetterCount = Math.floor(Math.random() * 4) + 1 // Default to basic
    }

    for (let i = 0; i < targetLetterCount; i++) {
      result += targetLetter
    }

    const remainingLettersCount = totalLettersCount - targetLetterCount
    for (let i = 0; i < remainingLettersCount; i++) {
      result += otherLetter
    }

    result = result
      .split('')
      .sort(() => 0.5 - Math.random())
      .join('')

    return { result, targetLetter }
  }

  const checkOverlap = (positions, newPosition) => {
    for (const pos of positions) {
      const xDiff = Math.abs(pos.left - newPosition.left)
      const yDiff = Math.abs(pos.top - newPosition.top)
      if (xDiff < letterSize + padding && yDiff < letterSize + padding) {
        return true
      }
    }
    return false
  }

  const getRandomPosition = (containerWidth, containerHeight, positions) => {
    let attempts = 0
    const maxAttempts = 100

    while (attempts < maxAttempts) {
      const left =
        Math.random() * (containerWidth - letterSize - padding * 2) + padding
      const top =
        Math.random() * (containerHeight - letterSize - padding * 2) + padding

      const newPosition = { top, left }

      if (!checkOverlap(positions, newPosition)) {
        return {
          top: `${(top / containerHeight) * 100}%`,
          left: `${(left / containerWidth) * 100}%`,
        }
      }

      attempts++
    }

    // Eğer uygun pozisyon bulunamazsa grid sistemine geç
    const gridSize = Math.ceil(Math.sqrt(positions.length + 1))
    const cellWidth = containerWidth / gridSize
    const cellHeight = containerHeight / gridSize
    const gridIndex = positions.length
    const row = Math.floor(gridIndex / gridSize)
    const col = gridIndex % gridSize

    return {
      top: `${((row * cellHeight + padding) / containerHeight) * 100}%`,
      left: `${((col * cellWidth + padding) / containerWidth) * 100}%`,
    }
  }

  const showRandomLetters = () => {
    const { result, targetLetter } = generateRandomLetters()
    const containerWidth = window.innerWidth * 0.8 // container genişliği
    const containerHeight = window.innerHeight * 0.6 // container yüksekliği

    const positions = []

    const letters = result.split('').map((letter, index) => {
      const position = getRandomPosition(
        containerWidth,
        containerHeight,
        positions
      )
      positions.push(position)

      return {
        letter,
        top: position.top,
        left: position.left,
        id: index,
      }
    })

    setRandomLetters(letters)
    setTargetLetter(targetLetter)
    setExampleCount(exampleCount + 1)
    setShowContinueScreen(true)
    setTimerActive(false)
    setTimer(settingsRef.current.timer)
  }

  useEffect(() => {
    if (!isStart && !isFinish && timerActive) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer > 0) {
            return prevTimer - 1
          } else {
            showRandomLetters()
            setIncorrectCount(incorrectCount + 1)
            return timer
          }
        })
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [isStart, isFinish, timerActive])

  useEffect(() => {
    if (exampleCount === attempts + 1) {
      playCongrulationSound()
      setIsFinish(true)
    }
  }, [exampleCount])

  const handleInputChange = (e) => {
    setInputValue(e.target.value.toUpperCase())
  }

  const handleConfirm = () => {
    setIsConfirmed(true)
  }

  const handleSubmit = () => {
    if (isSubmitting) return
    setIsSubmitting(true)

    const correctAnswer = randomLetters.filter(
      (letterObj) => letterObj.letter === targetLetter
    ).length

    if (parseInt(inputValue) === correctAnswer) {
      playCorrectSound()
      setCorrectCount(correctCount + 1)
    } else {
      playInCorrectSound()
      setIncorrectCount(incorrectCount + 1)
    }

    setInputValue('')
    showRandomLetters()

    setTimeout(() => {
      setIsSubmitting(false)
    }, 500)
  }

  const handleKeyDown = (e) => {
    if (!showContinueScreen) {
      if (e.key === 'Enter') {
        handleSubmit()
      }
    }
  }

  const handleRepeatExercise = () => {
    setCorrectCount(0)
    setIncorrectCount(0)
    setExampleCount(0)
    setIsFinish(false)
    setIsStart(true)
    setShowInstructions(true)
    setTimer(1)
    setAttempts(1)
    setDifficulty('basic')
    setShowInstructions(true)
  }

  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/attention-memory-logic-math`
  }

  const handleContinue = (e) => {
    setShowContinueScreen(false)
    setTimerActive(true)
  }

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (
        e.key === 'Enter' &&
        showContinueScreen &&
        !isSubmitting &&
        !isFinish
      ) {
        handleContinue(e)
      }
    }

    window.addEventListener('keydown', handleKeyPress)
    return () => window.removeEventListener('keydown', handleKeyPress)
  }, [showContinueScreen, isSubmitting])

  const handleStartExercise = () => {
    setIsStart(false)
    setShowInstructions(false)
    showRandomLetters()
  }

  return (
    <div className="flex flex-col h-screen">
      <Header title={'Harf Odak'} returnType="attention-memory">
        <button
          onClick={handleRepeatExercise}
          className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
        >
          Sıfırla
        </button>
      </Header>
      <Footer />

      <div className="flex-grow flex flex-col justify-center items-center mt-16 mb-16">
        {showInstructions ? (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
            <div className="bg-gray-300 p-8 rounded-md text-center w-1/3 shadow-lg">
              <h2 className="font-semibold text-2xl p-1 mb-4 text-gray-800">
                Harf Odak
              </h2>

              {/* Süre Ayarı */}
              <div className="bg-white p-4 rounded-lg shadow-md mb-6">
                <label
                  htmlFor="speed"
                  className="block text-gray-700 font-semibold mb-2 text-left"
                >
                  🕒 Süre: <span className="font-bold">{timer}</span>
                </label>
                <input
                  id="speed"
                  type="range"
                  min="1"
                  max="10"
                  step="1"
                  value={timer}
                  onChange={(e) => setTimer(parseInt(e.target.value))}
                  className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-blue-500"
                />
                <div className="flex justify-between text-sm text-gray-500 mt-1">
                  <span>1</span>
                  <span>10</span>
                </div>
              </div>

              <div className="bg-white p-4 rounded-lg shadow-md mb-6">
                <label
                  htmlFor="speed"
                  className="block text-gray-700 font-semibold mb-2 text-left"
                >
                  Deneme Sayısı: <span className="font-bold">{attempts}</span>
                </label>
                <input
                  id="attempts"
                  type="range"
                  min="1"
                  max="30"
                  step="1"
                  value={attempts}
                  onChange={(e) => setAttempts(parseInt(e.target.value))}
                  className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-blue-500"
                />
                <div className="flex justify-between text-sm text-gray-500 mt-1">
                  <span>1</span>
                  <span>30</span>
                </div>
              </div>

              {/* Zorluk Seviyesi Ayarı */}
              <div className="bg-white p-4 rounded-lg shadow-md mb-6">
                <label
                  htmlFor="difficultyLevel"
                  className="block text-gray-700 font-semibold mb-2 text-left"
                >
                  🧠 Zorluk Seviyesi:{' '}
                  <span className="font-bold">
                    {levelLabels[difficulty] || 'Seviye Seçin'}
                  </span>
                </label>
                <select
                  id="difficultyLevel"
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-400 focus:outline-none"
                  value={difficulty}
                  onChange={(e) => setDifficulty(e.target.value)}
                >
                  <option value="basic">Kolay</option>
                  <option value="normal">Normal</option>
                  <option value="hard">Zor</option>
                </select>
              </div>

              <button
                className="bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-400 transition duration-200"
                onClick={handleStartExercise}
              >
                🚀 Başla
              </button>
            </div>
          </div>
        ) : showContinueScreen ? (
          <div className="flex flex-col items-center justify-center h-full">
            <button
              className="bg-blue-400 text-white py-2 px-4 rounded hover:bg-blue-300"
              onClick={handleContinue}
            >
              Devam Et
            </button>
          </div>
        ) : (
          <>
            <div className="flex justify-end">
              {timerActive && (
                <div className="w-[45px] h-[45px] bg-blue-400 text-white font-bold rounded-md flex items-center justify-center">
                  {timer}
                </div>
              )}
            </div>
            <div className="relative w-4/5 h-2/3 bg-gray-300 border border-gray-400 flex justify-center items-center mt-8">
              {randomLetters.map((letterObj) => (
                <div
                  key={letterObj.id}
                  className="absolute"
                  style={{
                    top: letterObj.top,
                    left: letterObj.left,
                    width: `${letterSize}px`,
                    height: `${letterSize}px`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <h1 className="text-4xl">{letterObj.letter}</h1>
                </div>
              ))}
            </div>

            <div className="w-4/5 flex flex-col justify-center items-center mt-4">
              <div className="flex items-center">
                <label htmlFor="charCount" className="mr-4 text-xl">
                  {targetLetter} Kaç Tane?
                </label>
                <input
                  ref={inputRef}
                  type="text"
                  id="charCount"
                  className="border border-gray-400 px-4 py-2 mr-4"
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  autoFocus
                />
                <button
                  onClick={handleSubmit}
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mr-4"
                >
                  Gönder
                </button>
                <div>
                  <span className="text-green-500 font-bold">
                    Doğru: {correctCount}
                  </span>
                  {' | '}
                  <span className="text-red-500 font-bold">
                    Yanlış: {incorrectCount}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {isFinish && (
        <div className="bg-gray-300 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
          <div className="bg-white p-8 rounded-md text-center">
            <p>Tebrikler! Egzersizi Tamamladınız.</p>
            <p>
              Doğru: {correctCount} | Yanlış: {incorrectCount}
            </p>
            <div>
              <div>
                <button
                  onClick={handleReturnDashboard}
                  className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
                >
                  Egzersiz Sayfasına Dön
                </button>
              </div>
              <div>
                <button
                  className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-300"
                  onClick={handleRepeatExercise}
                >
                  Tekrarla
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <FullscreenAlert onConfirm={handleConfirm} />
    </div>
  )
}

export default HowManyCharacter

import React, { useState, useEffect } from "react";
import UserServices from "../Services/UserServices";
import SuccessMessage from "../Components/SuccessMessage";

export default function User() {
  const [isOpenForm, setOpenForm] = useState(false);
  const [editOpenForm, setEditOpenForm] = useState(false);

  const [isOpenFormTeacher, setOpenFormTeacher] = useState(false);
  const [active, setActive] = useState(false);
  const [msg, setMsg] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Sayfa başına kullanıcı sayısı
  var token = localStorage.getItem("token");

  useEffect(() => {
    console.log("token değeri user", token);
    if (token === null) {
      return "token bulunamadı";
    }
  }, []); // Bağımlılık listesine boş bir dizi ekleyin


  const [formData, setFormData] = useState({
    name: "",
    user_name: "",
    phone_number: "",
    password: "",
    level: "1", // Varsayılan değer
  });

  const [formDataTeacher, setFormDataTeacher] = useState({
    name: "",
    user_name: "",
    email: "",
    password: "",
  });

  const usr = new UserServices();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    console.log("ödnerilne ", token);
    usr
      .getUser(token)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  const deleteUser = (index, user_name) => {
    const newUsers = [...users];
    usr
      .delUser(token, user_name)
      .then((response) => {
        if (response.status === 200) {
          newUsers.splice(index, 1);
          setUsers(newUsers);
          setSuccessMessage(`${user_name} başarıyla silindi!`);
        }
        console.log(response);
      })
      .catch((err) => {
        alert("işlem yapılırken hata oluştu" + err);
      });
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    console.log(`Updated ${e.target.name}: ${e.target.value}`);
  };

  const handleSubmit = () => {
    console.log("Submitting form with data:", formData);
    usr
      .AddUser(formData, token)
      .then((response) => {
        if (response.status === 200) {
          setMsg(response.data.message);
          setUsers([...users, formData]);
          // ✅ Success Message Ayarla
          setSuccessMessage(`${formData.name} öğrenci başarıyla kaydedildi!`);
          setFormData({
            name: "",
            user_name: "",
            phone_number: "",
            password: "",
            level: "1",
          });
          setOpenForm(false);
        } else {
          setMsg("kullanıcı eklenemedi");
        }
      })
      .catch((error) => {
        console.error("Hata oluştu:", error);
      });
  };

  const handleSubmitTeacher = () => {
    usr
      .AddTeacher(formDataTeacher, token)
      .then((response) => {
        if (response.status === 200) {
          setMsg(response.data.message);
          setFormDataTeacher([...users, formData]);
          // ✅ Success Message Ayarla
          // ✅ Success Message Ayarla
          setSuccessMessage(`${formDataTeacher.name} eğitici başarıyla kaydedildi!`);

          // Formu temizle
          setFormDataTeacher({
            name: "",
            user_name: "",
            email: "",
            password: "",
          });
          setOpenFormTeacher(false);

        } else {
          setMsg("kullanıcı eklenemedi");
        }
      })
      .catch((error) => {
        console.error("Hata oluştu:", error);
      });
  };

  const OpenForm = () => {
    setOpenForm(true);
  };

  const OpenFormTeacher = () => {
    setOpenFormTeacher(true);
  };
  const handleInputChangeTeacher = (e) => {
    setFormDataTeacher({
      ...formDataTeacher,
      [e.target.name]: e.target.value,
    });
    console.log(`Updated ${e.target.name}: ${e.target.value}`);
  };

  const closeForm = () => {
    setOpenForm(false);
  };

  const closeFormTeacher = () => {
    setOpenFormTeacher(false);
  };
  const closeEditForm = () => {
    setEditOpenForm(false);
  };

  const sortUsersByScoreDescending = () => {
    const sortedUsers = [...users].sort((a, b) => b.puan - a.puan);
    setUsers(sortedUsers);
  };

  const resetUsers = () => {
    setUsers(users);
  };

  const EditOpenForm = (user) => {
    setEditOpenForm(true);
    setFormData({
      name: user.name,
      user_name: user.user_name,
      phone_number: user.phone_number,
      password: user.password,
      level: user.level,
    });
  };

  const EditHandle = () => {
    console.log("Updating user with data:", formData); // Debugging log
    usr
      .updateUser(token, formData)
      .then((response) => {
        if (response.status === 200) {
          setMsg(response.data.message);
          // Optionally refresh the list of users
        } else {
          setMsg("Kullanıcı güncellenemedi");
        }
      })
      .catch((error) => {
        console.error("Hata oluştu:", error);
      });
  };
  const handleInputChangeEdit = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(`Updated ${name}: ${value}`); // Ensure this logs correctly
  };
  const [openIndex, setOpenIndex] = useState(null); // Hangi kullanıcının bilgileri açılacak

  const toggleDetails = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Eğer aynı kullanıcıya tıklanırsa kapat
  };

  const DelFirstUser = () => {
    const userServices = new UserServices();
    userServices.firstdelluser(token).then((response) => {
      if (response.status === 200) {
        setMsg(response.data.message);
      } else {
        setMsg("kullanıcı silinemedi");
      }
    });
  };

  // Sayfalama Hesaplamaları
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

// Toplam Sayfa Sayısı
  const totalPages = Math.ceil(users.length / itemsPerPage);

// Sayfa Değiştirici
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

// Sayfa Başına Kullanıcı Sayısı Değiştirici
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Sayfa sayısını sıfırla
  };

  return (
      <div className="w-full ">
        {/* Success Message Bileşeni */}
        {successMessage && (
            <SuccessMessage
                message={successMessage}
                onClose={() => setSuccessMessage("")}
            />
        )}
        {isOpenForm && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-md animate-slideIn">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-2xl font-bold text-gray-800">Yeni Öğrenci Ekle</h2>
                  <button
                      onClick={closeForm}
                      className="text-gray-500 hover:text-gray-700 text-xl"
                  >
                    ✖
                  </button>
                </div>

                <div className="space-y-4">
                  {/* Ad Soyad */}
                  <div>
                    <label className="block text-gray-600 font-medium mb-1">Ad Soyad</label>
                    <input
                        type="text"
                        name="name"
                        placeholder="Ad Soyad"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                  </div>

                  {/* Kullanıcı Adı */}
                  <div>
                    <label className="block text-gray-600 font-medium mb-1">Kullanıcı Adı</label>
                    <input
                        type="text"
                        name="user_name"
                        placeholder="Kullanıcı Adı"
                        value={formData.user_name}
                        onChange={handleInputChange}
                        className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                  </div>

                  {/* Telefon */}
                  <div>
                    <label className="block text-gray-600 font-medium mb-1">Telefon</label>
                    <input
                        type="text"
                        name="phone_number"
                        placeholder="Telefon"
                        value={formData.phone_number}
                        onChange={handleInputChange}
                        className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                  </div>

                  {/* Şifre ve Seviye */}
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-gray-600 font-medium mb-1">Şifre</label>
                      <input
                          type="password"
                          name="password"
                          placeholder="Şifre"
                          value={formData.password}
                          onChange={handleInputChange}
                          className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 font-medium mb-1">Seviye</label>
                      <select
                          name="level"
                          value={formData.level}
                          onChange={handleInputChange}
                          className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                    </div>
                  </div>
                </div>

                {/* Form Alt Butonu */}
                <div className="mt-6 flex justify-end space-x-4">
                  <button
                      onClick={closeForm}
                      className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-medium py-2 px-4 rounded-md"
                  >
                    İptal
                  </button>
                  <button
                      onClick={handleSubmit}
                      className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md"
                  >
                    Kaydet
                  </button>
                </div>
              </div>
            </div>
        )}
        {editOpenForm && (
            <div className="fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 p-5">
              <div className="absolute top-0 right-0 m-2">
                <button
                    onClick={closeEditForm}
                    className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-1 px-2 rounded"
                >
                  X
                </button>
              </div>
              <div className="w-96 h-3/6 bg-slate-200 p-10">
                <div className="mb-4">
                  <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="name"
                  >
                    Ad Soyad
                  </label>
                  <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="name"
                      type="text"
                      placeholder="name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChangeEdit}
                  />
                </div>
                <div className="mb-4">
                  <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="user_name"
                  >
                    kullanıcı adı
                  </label>
                  <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="user_name"
                      type="text"
                      placeholder="Email"
                      name="user_name"
                      value={formData.user_name}
                      disabled
                  />
                </div>
                <div className="mb-4">
                  <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="telefon"
                  >
                    Telefon
                  </label>
                  <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="phone_number"
                      type="text"
                      placeholder="Telefon "
                      name="phone_number"
                      value={formData.phone_number}
                      onChange={handleInputChangeEdit}
                  />
                </div>
                <div className="flex items-center justify-between">
                  <div className="mb-4">
                    <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="password"
                    >
                      Şifre
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="password"
                        type="text"
                        placeholder="Password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChangeEdit}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="level"
                    >
                      Seviye
                    </label>
                    <select
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="level"
                        name="level"
                        value={formData.level}
                        onChange={handleInputChangeEdit}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                  </div>
                </div>
                {msg !== "" && (
                    <div className="w-full flex justify-center">
                      <span className="text-red-500">{msg}</span>
                    </div>
                )}
                <button
                    onClick={EditHandle}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Güncelle
                </button>
              </div>
            </div>
        )}
        {isOpenFormTeacher && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-md animate-slideIn">
                {/* Form Başlığı */}
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-2xl font-bold text-gray-800">Yeni Öğretmen Ekle</h2>
                  <button
                      onClick={closeFormTeacher}
                      className="text-gray-500 hover:text-gray-700 text-xl"
                  >
                    ✖
                  </button>
                </div>

                {/* Form Alanları */}
                <div className="space-y-4">
                  {/* Ad Soyad */}
                  <div>
                    <label className="block text-gray-600 font-medium mb-1">Ad Soyad</label>
                    <input
                        type="text"
                        name="name"
                        placeholder="Ad Soyad"
                        value={formDataTeacher.name}
                        onChange={handleInputChangeTeacher}
                        className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                  </div>

                  {/* Kullanıcı Adı */}
                  <div>
                    <label className="block text-gray-600 font-medium mb-1">Kullanıcı Adı</label>
                    <input
                        type="text"
                        name="user_name"
                        placeholder="Kullanıcı Adı"
                        value={formDataTeacher.user_name}
                        onChange={handleInputChangeTeacher}
                        className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                  </div>

                  {/* Email */}
                  <div>
                    <label className="block text-gray-600 font-medium mb-1">Email</label>
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formDataTeacher.email}
                        onChange={handleInputChangeTeacher}
                        className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                  </div>

                  {/* Şifre */}
                  <div>
                    <label className="block text-gray-600 font-medium mb-1">Şifre</label>
                    <input
                        type="password"
                        name="password"
                        placeholder="Şifre"
                        value={formDataTeacher.password}
                        onChange={handleInputChangeTeacher}
                        className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                  </div>
                </div>

                {/* Alt Butonlar */}
                <div className="mt-6 flex justify-end space-x-4">
                  <button
                      onClick={closeFormTeacher}
                      className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-medium py-2 px-4 rounded-md"
                  >
                    İptal
                  </button>
                  <button
                      onClick={handleSubmitTeacher}
                      className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md"
                  >
                    Kaydet
                  </button>
                </div>
              </div>
            </div>
        )}

        <div className="mt-12 flex items-center">
          <h2 className="text-3xl font-semibold">KULLANICILAR</h2>
        </div>
        <div className="flex flex-col sm:flex-row gap-3 justify-end p-4 bg-white rounded-lg shadow-sm">
          <button
              onClick={OpenForm}
              className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-lg text-lg font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200 shadow-sm"
          >
            <svg
                className="w-4 h-4 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
            </svg>
            Öğrenci Ekle
          </button>

          <button
              onClick={OpenFormTeacher}
              className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-lg text-lg font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-all duration-200 shadow-sm"
          >
            <svg
                className="w-4 h-4 mr-2 "
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                    d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"/>
            </svg>
            Öğretmen Ekle
          </button>

          <button
              onClick={DelFirstUser}
              className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-lg text-lg font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-all duration-200 shadow-sm"
          >
            <svg
                className="w-4 h-4 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
            </svg>
            1 yılı geçmiş kullanıcıyı sil
          </button>
        </div>

        <div className=" mx-auto w-full ">
          {/* Table section */}
          <div className="overflow-x-auto bg-white rounded-lg shadow">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Son
                  Görülme
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ad
                  Soyad
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell">Kullanıcı
                  Adı
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell">Telefon</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden lg:table-cell">Şifre</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden lg:table-cell">Seviye</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden lg:table-cell">Kullanıcı
                  Tipi
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Aksiyonlar</th>
              </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
              {currentUsers.map((user, index) => (
                  <React.Fragment key={index}>
                    <tr className="hover:bg-gray-50 transition-colors duration-200">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.last_seen_data
                            ? (() => {
                              const [lastDate, lastTime] = user.last_seen_data.split(" ");
                              const lastSeenDateTime = new Date(`${lastDate}T${lastTime}`);
                              const now = new Date();
                              const timeDifferenceMs = now - lastSeenDateTime;
                              const timeDifferenceMinutes = timeDifferenceMs / (1000 * 60);

                              if (timeDifferenceMinutes < 5) {
                                return <span
                                    className="px-2 py-1 text-xs font-semibold text-green-900 bg-green-100 rounded-full">Aktif</span>;
                              } else if (timeDifferenceMinutes < 60) {
                                return `${Math.floor(timeDifferenceMinutes)} dakika önce`;
                              } else {
                                return `${Math.floor(timeDifferenceMinutes / 60)} saat önce`;
                              }
                            })()
                            : "Bilgi yok"}
                      </td>
                      <td
                          className={`px-6 py-4 whitespace-nowrap text-lg font-medium cursor-pointer
                ${user.user_type === "ADMIN" || user.user_type === "TEACHER"
                              ? "bg-yellow-100 text-yellow-900"
                              : "text-gray-900"}`}
                          onClick={() => toggleDetails(index)}
                      >
                        {user.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-lg text-gray-500 hidden md:table-cell">{user.user_name}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-lg text-gray-500 hidden md:table-cell">{user.phone_number || user.email}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-lg text-gray-500 hidden lg:table-cell">{user.password}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-lg text-gray-500 hidden lg:table-cell">{user.level}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-lg text-gray-500 hidden lg:table-cell">{user.user_type}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <div className="flex space-x-2">
                          <button
                              onClick={() => EditOpenForm(user)}
                              className="inline-flex items-center px-3 py-1 border border-transparent text-lg leading-4 font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                          >
                            Düzenle
                          </button>
                          <button
                              onClick={() => deleteUser(index, user.user_name)}
                              className="inline-flex items-center px-3 py-1 border border-transparent text-lg leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                          >
                            Sil
                          </button>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
              ))}
              </tbody>
            </table>

            {/* Pagination section */}
            <div className="bg-white px-4 py-3 border-t border-gray-200 sm:px-6">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                <div className="flex items-center space-x-4">
                  <label htmlFor="itemsPerPage" className="text-sm text-gray-700">
                    Sayfa başına göster
                  </label>
                  <select
                      id="itemsPerPage"
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                      className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                  </select>
                </div>

                <div className="flex items-center justify-between sm:justify-end space-x-2 mt-4 sm:mt-0">
                  <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:bg-gray-100 disabled:text-gray-400"
                  >
                    Önceki
                  </button>
                  <div className="flex items-center">
          <span className="text-sm text-gray-700">
            Sayfa <span className="font-medium">{currentPage}</span> / <span className="font-medium">{totalPages}</span>
          </span>
                  </div>
                  <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:bg-gray-100 disabled:text-gray-400"
                  >
                    Sonraki
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

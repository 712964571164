import React, { useState, useEffect } from 'react'
import '../../App.css'
import { playCorrectSound } from '../../effect/Correct'
import { playInCorrectSound } from '../../effect/Incorrect'
import { playCongrulationSound } from '../../effect/Congrulation'
import Footer from '../Footer'
import Header from '../Header'
import { CircleCheckBig, CircleX } from 'lucide-react'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import VocabolaryServices from "../../Services/VocabolaryServices";


const HintProverb = () => {
  const [proverbs, setProverbs] = useState([])
  const [currentProverbIndex, setCurrentProverbIndex] = useState(0)
  const [shuffledWords, setShuffledWords] = useState([])
  const [selectedWords, setSelectedWords] = useState([])
  const [remainingAttempts, setRemainingAttempts] = useState(3)
  const [correctAnswers, setCorrectAnswers] = useState(0)
  const [wrongAnswers, setWrongAnswers] = useState(0)
  const [isClickable, setIsClickable] = useState(true)
  const [isStart, setIsStart] = useState(false)
  const [isFinished, setIsFinished] = useState(false)
  const [elapsedTime, setElapsedTime] = useState(0)
  const [vocabolery, setVocabolery] = useState([]);
  const vocabolaryservices = new VocabolaryServices();
  const [selectedLevel, setSelectedLevel] = useState(1);
  const token = window.localStorage.getItem('token')

  const handleLevelChange = (event) => {
    const level = parseInt(event.target.value); // Seviye bir sayı olarak alınır
    setSelectedLevel(level);

    // Seçilen seviyeye ait veriyi bul
    const selectedData = vocabolery.find(item => item.level === level);

    console.log('Selected Level:', level);
    console.log('Selected Data:', selectedData);

    if (selectedData && selectedData.data && selectedData.data.length > 0) {
      const levelData = selectedData.data[0];
      console.log('Selected Proverbs:', levelData.proverbs);
      setProverbs(levelData.proverbs || []);
    } else {
      console.error(`Seviye ${level} için veri bulunamadı veya veri yapısı hatalı.`);
      setProverbs([]);
    }
  };

  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  const [isConfirmed, setIsConfirmed] = useState(false)
  useEffect(() => {
    vocabolaryservices
        .getVocabolary("hint-proverb")
        .then((response) => {
          console.log("API Response:", response.data);
          setVocabolery(response.data);

          // İlk seviyeyi yükle
          const initialData = response.data.find(item => item.level === 1); // level bir sayı
          if (initialData && initialData.data && initialData.data.length > 0) {
            const levelData = initialData.data[0];
            console.log("Initial Proverbs Data:", levelData.proverbs);
            setProverbs(levelData.proverbs || []);
          }
        })
        .catch((error) => {
          console.error("Kategorileri alırken hata oluştu:", error);
        });
  }, []);

  const handleConfirm = () => {
    setIsConfirmed(true)
  }

  useEffect(() => {
    if (isStart && !isFinished) {
      const timer = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1)
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [isStart, isFinished])

  useEffect(() => {
    if (isStart && proverbs && proverbs.length > 0) {
      shuffleWords()
    }
  }, [isStart, currentProverbIndex, proverbs])

  const shuffleWords = () => {
    const words = proverbs[currentProverbIndex].split(' ')
    const shuffled = [...words]
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]
    }
    setShuffledWords(shuffled)
  }

  const handleWordClick = (word, index) => {
    if (!isClickable || selectedWords.includes(word + '_' + index)) return
    setSelectedWords((prevWords) => [...prevWords, word + '_' + index])
  }

  const handleSelectedWordClick = (index) => {
    setSelectedWords((prevWords) => prevWords.filter((_, i) => i !== index))
  }

  const handleCheckAnswer = () => {
    setIsClickable(false)
    const currentProverb = proverbs[currentProverbIndex]
    const selectedWordsOnly = selectedWords.map((word) => word.split('_')[0])
    const isCorrect =
      selectedWordsOnly.join(' ') === currentProverb &&
      selectedWordsOnly.length === currentProverb.split(' ').length

    if (isCorrect) {
      playCorrectSound()
      setCorrectAnswers(correctAnswers + 1)
      setTimeout(() => {
        handleNextProverb()
      }, 1000)
    } else {
      playInCorrectSound()
      setRemainingAttempts((prevAttempts) => prevAttempts - 1)

      setTimeout(() => {
        setSelectedWords([])
        setIsClickable(true)
        if (remainingAttempts === 1) {
          setWrongAnswers(wrongAnswers + 1)
        }
      }, 100)
    }
  }

  const handleNextProverb = () => {
    const nextIndex = currentProverbIndex + 1
    if (nextIndex < proverbs.length) {
      setCurrentProverbIndex(nextIndex)
      setSelectedWords([])
      setRemainingAttempts(3)
      setIsClickable(true)
    } else {
      setIsFinished(true)
      playCongrulationSound()
    }
  }

  const startScreen = () => {
    setIsStart(true)
  }

  const resetExercise = () => {
    setCurrentProverbIndex(0)
    setShuffledWords([])
    setSelectedWords([])
    setRemainingAttempts(3)
    setCorrectAnswers(0)
    setWrongAnswers(0)
    setIsClickable(true)
    setIsStart(false)
    setIsFinished(false)
    setElapsedTime(0)
  }

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
  }

  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/word-recognition'`
  }

  return (
    <div className="h-screen overflow-hidden">
      {!isStart && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
            <div className="bg-gray-300 p-8 rounded-md text-center w-1/3">
              <h2 className="font-semibold text-2xl p-1">İpucu Atasözü Egzersizi</h2>
              <div className="space-y-6">
                {/* Seviye Seçimi */}
                <div className="bg-white p-4 rounded-lg shadow-sm w-full">
                  <label htmlFor="levelSelect" className="block text-left text-gray-700 font-semibold mb-2">
                    📚 Seviye Seçimi:
                  </label>
                  <select
                      id="levelSelect"
                      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                      value={selectedLevel}
                      onChange={handleLevelChange}
                  >
                    {vocabolery.map((levelData) => (
                        <option key={levelData.level} value={levelData.level}>
                          Seviye {levelData.level}
                        </option>
                    ))}
                  </select>
                </div>

                {/* Toplam Adım Sayısı */}
                <div className="bg-white p-4 rounded-lg shadow-md w-full">
                  <label htmlFor="speed" className="block text-gray-700 font-semibold mb-2 text-left">
                    ❤️ Hak Sayısı: <span className="font-bold">{remainingAttempts}</span>
                  </label>
                  <input
                      id="speed"
                      type="range"
                      min="1"
                      max="10"
                      step="1"
                      value={remainingAttempts}
                      onChange={(e) => setRemainingAttempts(parseInt(e.target.value))}
                      className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-blue-500"
                  />
                  <div className="flex justify-between text-sm text-gray-500 mt-1">
                    <span>Hızlı</span>
                    <span>Yavaş</span>
                  </div>
                </div>
              </div>

              {/* Başla Butonu */}
              <button
                  className="bg-blue-400 text-white py-2 px-4 mt-4 rounded hover:bg-blue-300 w-full"
                  onClick={startScreen}
              >
                🚀 Başla
              </button>
            </div>
          </div>
      )}

            {isStart && (
                <div className="h-screen overflow-hidden">
                  <Header title={'İpucu Atasözü'} returnType="word-recognition">
                    <button
                        onClick={resetExercise}
                        className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
                    >
                      Sıfırla
                    </button>
                  </Header>
                  {/* Skor ve Süre Göstergesi */}
                  <div
                      className="absolute top-0 right-0 mt-16 mr-8 p-4 w-40 flex flex-col justify-center items-center border-2 border-b-gray-100 rounded-md text-lg font-semibold">
                    <div className="flex-col p-2 justify-center items-center">
              <span className="flex gap-2 items-center">
                <CircleCheckBig size={20} color="#14cb1f" strokeWidth={1.75}/>
                {correctAnswers}
              </span>
                    </div>
                    <div>
              <span className="flex gap-2 items-center">
                <CircleX size={20} strokeWidth={1.75} color="#ff0000" />
                {wrongAnswers}
              </span>{' '}
            </div>
            <div className="p-2">Süre: {formatTime(elapsedTime)}</div>
          </div>

          <Footer />
          <div className="h-5/6 flex flex-col justify-center items-center m-4 md:m-16">
            <div className="flex gap-4 items-center mb-4 md:mb-8">
              <div className="text-lg md:text-xl font-semibold">Kalan Hak:</div>
              {[...Array(remainingAttempts)].map((_, i) => (
                <span key={i} className="text-xl md:text-2xl">
                  ❤️
                </span>
              ))}
            </div>

            <div
              className={`flex flex-wrap justify-center gap-4 md:gap-8 border-2 border-black rounded-xl p-4 md:p-8 text-lg md:text-xl font-semibold w-full md:w-5/6 text-center  items-center ${
                remainingAttempts === 0 ? 'animate-error' : ''
              }`}
            >
              {shuffledWords.map((word, index) => (
                <div
                  key={index}
                  onClick={() => handleWordClick(word, index)}
                  className={`p-2 rounded-full w-auto md:w-40 text-center cursor-pointer border-2 ${
                    proverbs[currentProverbIndex].startsWith(word)
                      ? 'text-red-500'
                      : 'text-black'
                  } ${
                    selectedWords.includes(word + '_' + index)
                      ? 'bg-gray-400 cursor-not-allowed'
                      : isClickable
                      ? 'hover:bg-gray-200'
                      : 'cursor-not-allowed '
                  }`}
                >
                  {word}
                </div>
              ))}
            </div>

            <div className="flex justify-center items-center m-8 md:m-12">
              <div className="flex flex-row flex-wrap max-w-full w-full md:w-[800px] h-auto md:h-28 items-center justify-center gap-4 border-2 border-black p-4">
                {selectedWords.map((wordWithIndex, index) => (
                  <div
                    key={index}
                    onClick={() => handleSelectedWordClick(index)}
                    className="p-4 md:p-7 rounded-full h-auto md:h-8 w-auto md:w-20 flex flex-col text-lg md:text-xl justify-center items-center text-center border-2 bg-gray-200 cursor-pointer hover:bg-gray-300"
                  >
                    {wordWithIndex.split('_')[0]}
                  </div>
                ))}
              </div>
            </div>
            {remainingAttempts > 0 && !isFinished && (
              <button
                onClick={handleCheckAnswer}
                disabled={!isClickable || selectedWords.length === 0}
                className="bg-blue-400 text-white py-2 px-4  rounded hover:bg-blue-300 disabled:opacity-50"
              >
                Kontrol Et
              </button>
            )}

            {remainingAttempts === 0 && !isFinished && (
              <div className=" flex flex-col justify-center items-center">
                <p className="text-red-500 font-semibold mt-4">
                  Yanlış Deneme! Doğru cevap:
                </p>
                <p className="text-lg font-semibold">
                  {proverbs[currentProverbIndex]}
                </p>
                <button
                  onClick={handleNextProverb}
                  className="bg-blue-400 text-white py-2 px-4 mt-4 rounded hover:bg-blue-300"
                >
                  Sonraki Atasözü
                </button>
              </div>
            )}
          </div>

          {isFinished && (
            <div className="bg-gray-700 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
              <div className="bg-white p-8 rounded-md text-center w-full md:w-1/2 lg:w-1/3">
                <p>Tebrikler! Egzersizi tamamladınız.</p>
                <p>Toplam süre: {formatTime(elapsedTime)}</p>
                <p>Doğru Cevap Sayısı: {correctAnswers}</p>
                <p>Yanlış Cevap Sayısı: {wrongAnswers}</p>
                <div>
                  <button
                    onClick={handleReturnDashboard}
                    className="bg-blue-400 text-white py-2 px-4 mt-4 rounded"
                  >
                    Egzersiz Sayfasına Dön
                  </button>
                </div>

                <button
                  className="bg-blue-400 text-white py-2 px-4 mt-4 rounded ml-4"
                  onClick={resetExercise}
                >
                  Tekrarla
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      <FullscreenAlert onConfirm={handleConfirm} />
    </div>
  )
}

export default HintProverb

import React, { useEffect, useState, useRef } from 'react'
import { playCorrectSound } from '../../effect/Correct'
import { playCongrulationSound } from '../../effect/Congrulation'
import { playInCorrectSound } from '../../effect/Incorrect'
import { CircleCheckBig, CircleX } from 'lucide-react'
import FullscreenAlert from '../LoadPage/FullscreenAlert'
import VocabolaryServices from "../../Services/VocabolaryServices"
import Footer from "../Footer";
import Header from "../Header";

const Resfebe = () => {
  const [currentExampleIndex, setCurrentExampleIndex] = useState(0)
  const [userInput, setUserInput] = useState('')
  const [isStart, setIsStart] = useState(false)
  const [correct, setCorrect] = useState(0)
  const [incorrect, setIncorrect] = useState(0)
  const [isFinish, setIsFinish] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)
  const [remainingAttempts, setRemainingAttempts] = useState(3)
  const [elapsedSeconds, setElapsedSeconds] = useState(0)
  const timerId = useRef(null)
  const [isCheckingAnswer, setIsCheckingAnswer] = useState(false)
  const inputRef = useRef(null)
  const [examples, setExamples] = useState([])
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [vocabolery, setVocabolery] = useState([])
  const [selectedLevel, setSelectedLevel] = useState("1")
  const [selectedLevelData, setSelectedLevelData] = useState(null); // Seçilen seviye verilerini tutmak için

  const token = window.localStorage.getItem('token')

  useEffect(() => {
    if (!token) {
      window.location.href = '/login'
    }
  }, [])

  const vocabolaryservices = new VocabolaryServices()

  // Initial data fetch
  useEffect(() => {
    vocabolaryservices
        .getVocabolary("resfebe")
        .then((response) => {
          setVocabolery(response.data);

          // İlk seviye verilerini otomatik olarak ayarla
          const firstLevelData = response.data.find(level => level.level === "1");
          setSelectedLevelData(firstLevelData); // firstLevelData'yı güncelle
          if (firstLevelData) {
            handleLevelDataChange(firstLevelData);
          }


        })
        .catch((error) => {
          console.error("Verileri alırken hata oluştu:", error);
        });
  }, []);

  const handleLevelChange = (event) => {
    const newLevel = event.target.value;
    setSelectedLevel(newLevel);

    const newLevelData = vocabolery.find(level => level.level === newLevel);
    setSelectedLevelData(newLevelData); // newLevelData'yı güncelle

    if(newLevelData){
      handleLevelDataChange(newLevelData)
    }

  };

  const handleLevelDataChange = (levelData) => {
    if (levelData && levelData.data && levelData.data.length > 0) { // data dizisinin boş olup olmadığını kontrol et
      const processedExamples = levelData.data[0].img.map((imagePath, index) => ({
        image1: imagePath,
        response: levelData.data[0].answers[index]
      }))

      setExamples(processedExamples)

    } else {
      console.warn("Seviye verileri eksik veya hatalı!");
      setExamples([]); // Hata durumunda örnekleri temizle
    }
  };

  useEffect(() => {
    if ((isStart || isCheckingAnswer) && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isStart, currentExampleIndex, isCheckingAnswer])

  const handleConfirm = () => {
    setIsConfirmed(true)
  }

  useEffect(() => {
    if (isStart && !isFinish) {
      timerId.current = setInterval(() => {
        setElapsedSeconds((prevSeconds) => prevSeconds + 1)
      }, 1000)
    } else {
      clearInterval(timerId.current)
    }

    return () => clearInterval(timerId.current)
  }, [isStart, isFinish])

  const handleStart = () => {
    const newLevelData = vocabolery.find(level => level.level === selectedLevel);

    if (newLevelData) {
      setSelectedLevelData(newLevelData); // Seçilen seviyenin verilerini ayarla
      handleLevelDataChange(newLevelData); // Seçilen seviyeye göre örnekleri yükle
      setIsStart(true); // Oyunu başlat
    } else {
      console.warn("Seçilen seviyeye ait veri bulunamadı!");
      setExamples([]); // Hata durumunda örnekleri temizle
      setIsStart(true); // Hata durumunda bile başlangıç ekranını göster
    }
  };

  const [feedbackMessage, setFeedbackMessage] = useState('')
  const [inputStyle, setInputStyle] = useState('')

  const handleInputChange = (event) => {
    setUserInput(event.target.value.toLocaleLowerCase('tr'))
  }

  const restartExercise = () => {
    setCurrentExampleIndex(0)
    setUserInput('')
    setIsFinish(false)
    setCorrect(0)
    setIncorrect(0)
    setRemainingAttempts(3)
    setElapsedSeconds(0)
    setIsStart(false); // isStart false olarak ayarlandı, başlangıç ekranı gösterilecek
    setExamples([]); // Örnekler temizlendi
    setSelectedLevelData(null);
  }

  const checkResponse = () => {
    setIsCheckingAnswer(true)

    if (userInput === examples[currentExampleIndex].response.toLocaleLowerCase('tr')) {
      setIsAnimating(true)
      setFeedbackMessage('Doğru!')
      playCorrectSound()
      setInputStyle('correct')
      setCorrect(correct + 1)
      setTimeout(() => {
        setFeedbackMessage('')
        setInputStyle('')
        setIsAnimating(false)
        setIsCheckingAnswer(false)
        nextExample()
      }, 1000)
    } else {
      handleIncorrectAnswer()
    }
  }

  const handleIncorrectAnswer = () => {
    setIsCheckingAnswer(true)
    setRemainingAttempts((prevAttempts) => prevAttempts - 1)
    setInputStyle('incorrect')
    playInCorrectSound()

    if (remainingAttempts === 1) {
      setIncorrect(incorrect + 1)
      setTimeout(() => {
        setInputStyle('')
        setUserInput(examples[currentExampleIndex].response)
      }, 1000)
      setTimeout(() => {
        setFeedbackMessage('')
        setIsCheckingAnswer(false)
        nextExample()
      }, 3000)
    } else {
      setTimeout(() => {
        setInputStyle('')
        setUserInput('')
        setIsCheckingAnswer(false)
      }, 1000)
    }
  }

  const nextExample = () => {
    if (currentExampleIndex < examples.length - 1) {
      setCurrentExampleIndex(currentExampleIndex + 1)
      setRemainingAttempts(3)
      setUserInput('')
    } else {
      setIsFinish(true)
      playCongrulationSound()
    }
  }

  const passToNext = () => {
    if (isCheckingAnswer) return

    setIsCheckingAnswer(true)
    setIncorrect(incorrect + 1)
    setInputStyle('incorrect')
    playInCorrectSound()

    setUserInput(examples[currentExampleIndex].response)

    setTimeout(() => {
      setFeedbackMessage('')
      setInputStyle('')
      setIsCheckingAnswer(false)
      nextExample()
    }, 1000)
  }

  const renderExample = () => {
    if (!examples || examples.length === 0 || !selectedLevelData || currentExampleIndex >= examples.length) { // selectedLevelData ve index kontrolü eklendi.
      return (
          <div className="text-center text-gray-500">
            Görüntülenecek bir örnek bulunamadı.
          </div>
      )
    }

    const example = examples[currentExampleIndex]
    return (
        <div className="flex justify-center items-center h-full" style={{ overflow: 'hidden' }}>
          {example.image1 && (
              <div className="relative text-center">
                <img
                    src={example.image1}
                    alt="Resfebe"
                    className="mx-auto pr-4"
                    style={{ width: '90%', height: '90%', border:'' }}
                />
              </div>
          )}
        </div>
    )
  }


  const handleReturnDashboard = () => {
    window.location.href = `/education/exercises/attention-memory-logic-math`
  }

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`
  }

  return (
      <div className="h-screen overflow-hidden">
        <Header title={"Resfebe"} returnType="attention-memory">
          <button
              onClick={restartExercise}
              className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-400"
          >
            Sıfırla
          </button>
        </Header>
        <div className="h-full relative bg-gray-200 ml-0 mr-0 mt-0 flex flex-col items-center justify-center">
          {isStart && (
              <div className="absolute top-4 right-4 font-semibold bg-white flex flex-col justify-center items-center rounded-md px-4 py-2">
                Süre: {formatTime(elapsedSeconds)}
                <div className="mb-4">Kalan Hak: {remainingAttempts}</div>
                <div className="flex space-x-8">
              <span className="flex gap-2 items-center">
                <CircleCheckBig size={20} color="#14cb1f" strokeWidth={1.75} />
                {correct}
              </span>
                  <span className="flex gap-2 items-center">
                <CircleX size={20} strokeWidth={1.75} color="#ff0000" />
                    {incorrect}
              </span>
                </div>
              </div>
          )}
          <div className="h-1/3 w-1/2 rounded-xl mb-20 ">{renderExample()}</div>

          <div className="mt-4 mb-16">
            <input
                ref={inputRef}
                value={userInput}
                onChange={handleInputChange}
                className={`shadow appearance-none border rounded w-full py-4 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                    inputStyle === 'incorrect'
                        ? 'bg-red-500'
                        : inputStyle === 'correct'
                            ? 'bg-green-500'
                            : ''
                }`}
                id="response"
                type="text"
                placeholder="Cevap"
                disabled={!isStart || isFinish || remainingAttempts === 0 || isCheckingAnswer}
                onKeyPress={(event) => {
                  if (event.key === 'Enter' && !isCheckingAnswer) {
                    checkResponse()
                  }
                }}
            />
          </div>
          <div className="flex justify-center space-x-4 mb-4">
            {isStart && !isFinish && (
                <button
                    onClick={passToNext}
                    className="bg-blue-300 text-xl text-white py-3 px-6 rounded-xl hover:bg-red-300"
                    disabled={isCheckingAnswer}
                >
                  Pas
                </button>
            )}
            {isStart && !isFinish && (
                <button
                    onClick={checkResponse}
                    className="bg-green-400 text-xl text-white py-2 px-4 rounded-xl hover:bg-green-300"
                    disabled={isCheckingAnswer}
                >
                  Kontrol Et
                </button>
            )}
          </div>
        </div>
        <Footer/>
        {feedbackMessage && (
            <div className="fixed inset-0 flex items-center justify-center">
          <span
              className={`text-9xl font-bold animate-ping animate-once ${
                  inputStyle === 'correct' ? 'text-green-500' : 'text-red-500'
              }`}
          >
            {feedbackMessage}
          </span>
            </div>
        )}
        {!isStart && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
              <div className="bg-gray-300 p-8 rounded-md text-center max-w-md w-full">
                <h1 className="font-semibold text-2xl mb-6 text-gray-800">
                  Resfebe Egzersizi
                </h1>
                <div className="space-y-6">
                  <div className="bg-white p-4 rounded-lg shadow-sm">
                    <label
                        htmlFor="levelSelect"
                        className="block text-left text-gray-700 font-semibold mb-2"
                    >
                      📚 Seviye Seçimi:
                    </label>
                    <select
                        id="levelSelect"
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                        value={selectedLevel}
                        onChange={handleLevelChange}
                    >
                      {vocabolery.map((levelData) => (
                          <option key={levelData.level} value={levelData.level}>
                            Seviye {levelData.level}
                          </option>
                      ))}
                    </select>
                  </div>
                </div>
                <button
                    className="mt-8 bg-blue-500 text-white text-xl py-3 px-6 rounded-lg hover:bg-blue-600 transition-colors duration-200 w-full"
                    onClick={handleStart}
                >
                  Başla
                </button>
              </div>
            </div>
        )}
        {isFinish && (
            <div className="bg-gray-500 bg-opacity-50 flex h-screen items-center justify-center fixed inset-0">
              <div
                  className="bg-white p-8 rounded-md text-center"
                  style={{ width: '300px' }}
              >
                <p style={{ fontSize: '20px' }}>
                  Tebrikler! Egzersizi tamamladınız.
                </p>
                <p>Doğru: {correct}</p>
                <p>Yanlış: {incorrect}</p>
                <p>Süre: {formatTime(elapsedSeconds)}</p>
                <button
                    className=" bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-200"
                    onClick={restartExercise}
                >
                  Tekrar
                </button>
                <div>
                  <button className="bg-gray-400 text-white py-2 px-4 mt-4 rounded hover:bg-gray-200"
                  onClick={handleReturnDashboard}>
                    Egzersiz Sayfasına Dön
                  </button>
                </div>
              </div>
            </div>
        )}
        <FullscreenAlert onConfirm={handleConfirm} />
      </div>
  )
}

export default Resfebe